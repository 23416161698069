import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  IonContent,
  IonPage,
  useIonViewWillLeave,
  useIonViewWillEnter,
} from "@ionic/react";

import TimelineBoxDetail from "../../../templates/TimelineBoxDetail";
import {
  Context,
  fetchComments,
  createReport,
  deleteComment,
  updateComment,
  createComment,
  removeFavorite,
  addFavorite,
  clearComments,
  clearRealizations,
  fetchTimelineBoxDetail,
  fetchCategories,
  clearSuccessMessage,
  clearSuccessResult,
  CREATE_COMMENT_SUCCESS_MESSAGE,
  UPDATE_COMMENT_SUCCESS_MESSAGE,
  DELETE_COMMENT_SUCCESS_MESSAGE,
  clearSelectedGearbox,
  clearPagyInfo,
  fetchTeachers,
  fetchGearBoxes,
  CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE,
  CREATE_REALIZATION_SUCCESS_MESSAGE,
  CREATE_CATEGORY_SUCCESS_MESSAGE,
  categorizedRealizationPath,
  createRealization,
  createCategory,
  fetchClasses,
  createRealizationStampReaction,
  CREATE_REALIZATION_STAMP_REACTION_SUCCESS,
} from "../../../store/student";
import styles from "../common.module.scss";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import FiltableHeader from "../../../organisms/NewFiltableHeader";
import FabButton from "../../../atoms/FabButton";
import {
  IRealizationCreateParams,
  IRealizationImage,
  ReactableType,
} from "../../../state";
import { Stamp } from "../../../libs/stamp";

const TimelineBoxDetailPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const params = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const history = useHistory();
  const [searchText, setSearchText] = React.useState("");
  const [isDisplaying, setIsDisplaying] = React.useState(false);
  const loadRealizations = React.useCallback(
    (page: number, searchText = "") => {
      dispatch(peep(clearSuccessMessage()));
      dispatch(
        peep(fetchTimelineBoxDetail(Number(params.id), page, searchText)),
      );
    },
    [dispatch, peep, params.id],
  );

  useIonViewWillEnter(() => {
    dispatch(fetchCategories());
    dispatch(fetchTimelineBoxDetail(Number(params.id)));
    setIsDisplaying(true);
    dispatch(fetchTeachers());
    dispatch(fetchGearBoxes({ is_check_archived: true }));
    dispatch(fetchClasses());
  }, [params.id]);

  useIonViewWillLeave(() => {
    dispatch(clearRealizations());
    dispatch(clearSelectedGearbox());
    dispatch(clearPagyInfo());
    setIsDisplaying(false);
  });

  React.useEffect(() => {
    if (calling) return;
    const categoryIDs = contextState.realization?.categories?.map(
      category => category.id,
    );
    if (contextState.success_message === CREATE_CATEGORY_SUCCESS_MESSAGE) {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchCategories()));
    } else if (
      contextState.success_message === CREATE_REALIZATION_SUCCESS_MESSAGE ||
      contextState.success_message ===
        CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      history.push(`${categorizedRealizationPath}/${categoryIDs?.[0] ?? 0}`);
    }
  }, [
    dispatch,
    contextState.success_message,
    contextState.realization,
    history,
    calling,
    peep,
  ]);

  React.useEffect(() => {
    if (
      contextState.success_message === CREATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === UPDATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === DELETE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === CREATE_REALIZATION_STAMP_REACTION_SUCCESS
    ) {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchComments(contextState.success_result["id"])));
      dispatch(clearSuccessResult());
    }
  }, [
    dispatch,
    contextState.success_message,
    contextState.success_result,
    peep,
  ]);

  const addFavoriteFunc = React.useCallback(
    (id: number) => {
      if (calling) return;
      dispatch(peep(addFavorite(id)));
    },
    [calling, dispatch, peep],
  );

  const removeFavoriteFunc = React.useCallback(
    (id: number) => {
      if (calling) return;
      dispatch(peep(removeFavorite(id)));
    },
    [calling, dispatch, peep],
  );

  const createCommentFunc = React.useCallback(
    (realization_id: number, content: string) => {
      dispatch(peep(createComment(realization_id, content)));
    },
    [dispatch, peep],
  );

  const updateCommentFunc = React.useCallback(
    (comment_id: number, content: string) => {
      if (calling) return;
      dispatch(peep(updateComment(comment_id, content)));
    },
    [calling, dispatch, peep],
  );

  const deleteCommentFunc = React.useCallback(
    (comment_id: number) => {
      if (calling) return;
      dispatch(peep(deleteComment(comment_id)));
    },
    [calling, dispatch, peep],
  );

  const createReportFunc = React.useCallback(
    (params: { realization_id?: number; comment_id?: number }) => {
      if (calling) return;
      dispatch(peep(createReport(params)));
    },
    [calling, dispatch, peep],
  );

  const fetchCommentsFunc = React.useCallback(
    (realization_id: number) => {
      dispatch(fetchComments(realization_id));
    },
    [dispatch],
  );

  const createStampReactionFunc = (
    realization_id: number,
    type: ReactableType,
    stamp: Stamp,
  ) => {
    dispatch(
      peep(
        createRealizationStampReaction(realization_id, {
          type,
          realization_stamp_id: stamp.id,
        }),
      ),
    );
  };

  const clearOldCommentsFunc = React.useCallback(() => {
    dispatch(clearComments());
  }, [dispatch]);

  const createRealizationFunc = React.useCallback(
    (
      realization: IRealizationCreateParams,
      submit_teacher_ids: number[],
      share_class_ids: number[],
      gearbox_id: number | null,
      image?: IRealizationImage,
    ) => {
      if (calling) return;
      dispatch(
        peep(
          createRealization(
            realization,
            submit_teacher_ids,
            share_class_ids,
            gearbox_id,
            image,
          ),
        ),
      );
    },
    [calling, dispatch, peep],
  );

  const createCategoryFunc = React.useCallback(
    (category: { name: string; color: string }) => {
      if (calling) return;
      dispatch(peep(createCategory(category)));
    },
    [calling, dispatch, peep],
  );

  return (
    <IonPage>
      <FiltableHeader
        title="BOX詳細"
        unreadNotiCount={
          contextState.unread_comments_count +
          contextState.unread_notification_count +
          (contextState.student.read_gear_release_note ? 0 : 1)
        }
        searchText={searchText}
        goBack
        onChangeFunc={text => {
          setSearchText(text);
          loadRealizations(1, text);
        }}
      />
      <IonContent className={`${styles.wrapper} ${styles.bgWhileBase}`}>
        <div className={styles.container}>
          <TimelineBoxDetail
            isDisplaying={isDisplaying}
            calling={calling}
            current_id={contextState.student.id}
            realizations={contextState.realizations}
            comments={contextState.comments}
            searchText={searchText}
            pagy_info={contextState.pagy_info}
            gearbox={contextState.selected_gearbox}
            success_message={contextState.success_message}
            pathname={pathname}
            teachers={contextState.teachers}
            gearBoxes={contextState.gearBoxes}
            categories={contextState.categories}
            classes={contextState.classes}
            student_id={contextState.student.id}
            loadRealizations={loadRealizations}
            addFavorite={addFavoriteFunc}
            removeFavorite={removeFavoriteFunc}
            createReport={createReportFunc}
            createComment={createCommentFunc}
            updateComment={updateCommentFunc}
            deleteComment={deleteCommentFunc}
            fetchComments={fetchCommentsFunc}
            clearOldComments={clearOldCommentsFunc}
            createRealization={createRealizationFunc}
            createCategory={createCategoryFunc}
            createStampReaction={createStampReactionFunc}
          />
        </div>
      </IonContent>
      <FabButton />
    </IonPage>
  );
};

export default TimelineBoxDetailPage;
