import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { filterOutline } from "ionicons/icons";

import useThemeTutorial from "../../../hooks/useThemeTutorial";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import boxIcon from "../../../assets/icons/box.svg";
import {
  addFavorite,
  boxesListPath,
  categorizedRealizationPath,
  clearComments,
  clearRealizationKlasses,
  clearSuccessMessage,
  clearSuccessResult,
  Context,
  CREATE_CATEGORY_SUCCESS_MESSAGE,
  CREATE_COMMENT_SUCCESS_MESSAGE,
  CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE,
  CREATE_REALIZATION_STAMP_REACTION_SUCCESS,
  CREATE_REALIZATION_SUCCESS_MESSAGE,
  createCategory,
  createComment,
  createRealization,
  createRealizationStampReaction,
  createReport,
  DELETE_COMMENT_SUCCESS_MESSAGE,
  deleteComment,
  FETCH_REALIZATION_KLASSES_SUCCESS_MESSAGE,
  fetchCategories,
  fetchClasses,
  fetchComments,
  fetchGearBoxes,
  fetchPreferredRealizations,
  fetchRealizationKlasses,
  fetchReferenceRealizations,
  fetchTeachers,
  removeFavorite,
  UPDATE_COMMENT_SUCCESS_MESSAGE,
  updateComment,
  updateReadLastRealizationKlass,
} from "../../../store/student";
import { IRealizationCreateParams, IRealizationImage } from "../../../state";
import FabButton from "../../../atoms/FabButton";
import ThemeHeader from "../../../molecules/ThemeHeader";
import FiltableHeader from "../../../organisms/NewFiltableHeader";
import Timeline from "../../../templates/Timeline";
import styles from "../common.module.scss";
import { Stamp } from "../../../libs/stamp";

const INITIAL_SEARCH_PARAM = {
  classId: 0,
  className: "すべてのグループ",
  text: "",
};

const TimelinePage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const history = useHistory();
  const { pathname } = useLocation();
  const [isDisplaying, setIsDisplaying] = React.useState(false);
  const [searchParam, setSearchParam] = React.useState({
    ...INITIAL_SEARCH_PARAM,
  });
  const [showSelectActionModal, setShowSelectActionModal] =
    React.useState(false);

  const [themeTutorial, updateThemeTutorial] = useThemeTutorial(
    contextState.geartheme,
    contextState.student.read_gear_tutorials,
    dispatch,
  );

  React.useEffect(() => {
    const latestEntry = contextState.realization_klasses[0];
    if (
      latestEntry &&
      contextState.success_message === FETCH_REALIZATION_KLASSES_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      dispatch(updateReadLastRealizationKlass(latestEntry.id));
    }
  }, [
    dispatch,
    contextState.success_message,
    contextState.realization_klasses,
  ]);

  React.useEffect(() => {
    if (
      contextState.success_message === CREATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === UPDATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === DELETE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === CREATE_REALIZATION_STAMP_REACTION_SUCCESS
    ) {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchComments(contextState.success_result["id"])));
      dispatch(clearSuccessResult());
    }
  }, [
    dispatch,
    contextState.success_message,
    contextState.success_result,
    peep,
  ]);

  const addFavoriteFunc = React.useCallback(
    (id: number) => {
      if (calling) return;
      dispatch(peep(addFavorite(id)));
    },
    [calling, dispatch, peep],
  );
  const removeFavoriteFunc = React.useCallback(
    (id: number) => {
      if (calling) return;
      dispatch(peep(removeFavorite(id)));
    },
    [calling, dispatch, peep],
  );

  const readMore = () => {
    fetchRealizationKlassesForReadMore();
    fetchPreferredRealizationsForReadMore();
  };

  const fetchRealizationKlassesForSearch = (args?: {
    classId?: number;
    className?: string;
    text?: string;
  }) => {
    const param = { ...searchParam, ...args };
    setSearchParam(param);
    dispatch(peep(fetchRealizationKlasses(param.classId, null, param.text)));
  };
  const fetchRealizationKlassesForReadMore = () => {
    const s = searchParam;
    const list = contextState.realization_klasses;
    if (list.length === 0) return;
    const prev_time = new Date(list[list.length - 1].created_at).toISOString();
    dispatch(peep(fetchRealizationKlasses(s.classId, prev_time, s.text)));
  };
  const fetchPreferredRealizationsForReadMore = () => {
    const list = contextState.preferred_realizations;
    if (list.length === 0) return;
    const prev_time = new Date(list[list.length - 1].created_at).toISOString();
    dispatch(peep(fetchPreferredRealizations(prev_time)));
  };

  const clearRealizationKlassesFunc = React.useCallback(() => {
    dispatch(clearRealizationKlasses());
  }, [dispatch]);

  const createCommentFunc = React.useCallback(
    (realization_id: number, content: string) => {
      dispatch(peep(createComment(realization_id, content)));
    },
    [dispatch, peep],
  );

  const updateCommentFunc = React.useCallback(
    (comment_id: number, content: string) => {
      if (calling) return;
      dispatch(peep(updateComment(comment_id, content)));
    },
    [calling, dispatch, peep],
  );

  const deleteCommentFunc = React.useCallback(
    (comment_id: number) => {
      if (calling) return;
      dispatch(peep(deleteComment(comment_id)));
    },
    [calling, dispatch, peep],
  );

  const createReportFunc = React.useCallback(
    (params: { realization_id?: number; comment_id?: number }) => {
      if (calling) return;
      dispatch(peep(createReport(params)));
    },
    [calling, dispatch, peep],
  );

  const fetchCommentsFunc = React.useCallback(
    (realization_id: number) => {
      dispatch(fetchComments(realization_id));
    },
    [dispatch],
  );

  const createRealizationFunc = React.useCallback(
    (
      realization: IRealizationCreateParams,
      submit_teacher_ids: number[],
      share_class_ids: number[],
      gearbox_id: number | null,
      image?: IRealizationImage,
    ) => {
      if (calling) return;
      dispatch(
        peep(
          createRealization(
            realization,
            submit_teacher_ids,
            share_class_ids,
            gearbox_id,
            image,
          ),
        ),
      );
    },
    [calling, dispatch, peep],
  );

  const createCategoryFunc = React.useCallback(
    (category: { name: string; color: string }) => {
      if (calling) return;
      dispatch(peep(createCategory(category)));
    },
    [calling, dispatch, peep],
  );

  const clearOldCommentsFunc = React.useCallback(() => {
    dispatch(clearComments());
  }, [dispatch]);

  const createStampReactionFunc = (realization_id: number, stamp: Stamp) => {
    dispatch(
      peep(
        createRealizationStampReaction(realization_id, {
          type: "comment",
          realization_stamp_id: stamp.id,
        }),
      ),
    );
  };

  useIonViewWillEnter(() => {
    dispatch(fetchCategories());
    dispatch(fetchClasses());
    dispatch(fetchTeachers());
    dispatch(fetchGearBoxes({ is_check_archived: true }));
    fetchRealizationKlassesForSearch();
    setIsDisplaying(true);
    dispatch(fetchReferenceRealizations());
    dispatch(fetchPreferredRealizations(null));
  }, []);

  useIonViewWillLeave(() => {
    setIsDisplaying(false);
    clearRealizationKlassesFunc();
  });

  React.useEffect(() => {
    if (calling) return;
    const categoryIDs = contextState.realization?.categories?.map(
      category => category.id,
    );
    if (contextState.success_message === CREATE_CATEGORY_SUCCESS_MESSAGE) {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchCategories()));
    } else if (
      contextState.success_message === CREATE_REALIZATION_SUCCESS_MESSAGE ||
      contextState.success_message ===
        CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      history.push(`${categorizedRealizationPath}/${categoryIDs?.[0] ?? 0}`);
    }
  }, [
    dispatch,
    contextState.success_message,
    contextState.realization,
    history,
    calling,
    peep,
  ]);

  return (
    <IonPage className={styles.page}>
      <FiltableHeader
        title="タイムライン"
        unreadNotiCount={
          contextState.unread_comments_count +
          contextState.unread_notification_count +
          (contextState.student.read_gear_release_note ? 0 : 1)
        }
        searchText={searchParam.text}
        onChangeFunc={text => {
          fetchRealizationKlassesForSearch({ text });
        }}
        rightComponent={
          <>
            <IonButton
              className={styles.button}
              routerLink={boxesListPath}
              routerDirection="root"
            >
              <IonIcon className={styles.boxIcon} src={boxIcon} />
            </IonButton>
            <IonButton onClick={() => setShowSelectActionModal(true)}>
              <IonIcon className={styles.filterIcon} src={filterOutline} />
            </IonButton>
          </>
        }
      />
      <IonContent className={styles.wrapper}>
        {contextState.classes.length > 0 && (
          <div className={styles.container}>
            {contextState.geartheme && (
              <ThemeHeader
                geartheme={contextState.geartheme}
                updateThemeTutorial={updateThemeTutorial}
                disableCreate={!themeTutorial.completed}
              />
            )}
            <Timeline
              isDisplaying={isDisplaying}
              calling={calling}
              searchParam={searchParam}
              current_id={contextState.student.id}
              classes={contextState.classes}
              realization_klasses={contextState.realization_klasses}
              comments={contextState.comments}
              categories={contextState.categories}
              timeline_pagination={contextState.timeline_pagination}
              success_message={contextState.success_message}
              pathname={pathname}
              teachers={contextState.teachers}
              gearBoxes={contextState.gearBoxes}
              student_id={contextState.student.id}
              showSelectActionModal={showSelectActionModal}
              closeSelectActionModal={() => setShowSelectActionModal(false)}
              addFavorite={addFavoriteFunc}
              removeFavorite={removeFavoriteFunc}
              search={fetchRealizationKlassesForSearch}
              readMore={readMore}
              clearRealizationKlasses={clearRealizationKlassesFunc}
              createRealization={createRealizationFunc}
              createCategory={createCategoryFunc}
              createReport={createReportFunc}
              createComment={createCommentFunc}
              updateComment={updateCommentFunc}
              deleteComment={deleteCommentFunc}
              fetchComments={fetchCommentsFunc}
              clearOldComments={clearOldCommentsFunc}
              redirectToBox={box_id =>
                history.push(`${boxesListPath}/${box_id}`)
              }
              reference_realizations={contextState.reference_realizations}
              preferred_realizations={contextState.preferred_realizations}
              createStampReaction={(realization_id, _type, stamp) =>
                createStampReactionFunc(realization_id, stamp)
              }
            />
          </div>
        )}
        <FabButton />
      </IonContent>
    </IonPage>
  );
};

export default TimelinePage;
