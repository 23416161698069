import { IonIcon } from "@ionic/react";
import React from "react";
import { caretUp, caretDown, checkmark } from "ionicons/icons";
import clsx from "clsx";

import { IClass } from "../../state";

import styles from "./ClassSelector.module.scss";

export interface ClassSelectorProps {
  className?: string;
  selectedClassName: string;
  selectedClassID: number;
  classes: IClass[];
  unread_realization_per_class?: { [class_id: string]: number };
  isPrimaryClassOnly?: boolean;
  isAnalyzeClass?: boolean;
  update: (args: { [key: string]: any }) => any;
  clear?: () => void;
  isTeacher?: boolean;
}

const ClassSelector = (props: ClassSelectorProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div
      className={clsx(
        props.isTeacher! ? styles.styleTeacherApp : "",
        props.className ?? "",
      )}
    >
      <div
        className={`${styles.labelArea} ${isOpen && styles.isOpen}`}
        onClick={() => {
          if (!isOpen) {
            setIsOpen(true);
          } else {
            setIsOpen(false);
          }
        }}
        tabIndex={0}
      >
        <span className={styles.label}>{props.selectedClassName}</span>
        <IonIcon
          icon={isOpen ? caretUp : caretDown}
          className={styles.arrowIcon}
        />
      </div>
      {isOpen && (
        <ul className={styles.menu} tabIndex={0}>
          {props.isAnalyzeClass ? (
            <li
              className={styles.options}
              onClick={() => {
                props.clear && props.clear();
                setIsOpen(false);
              }}
            >
              <span className={styles.optionLabel}>
                クラス／グループを選んでください
              </span>
            </li>
          ) : (
            (!props.isPrimaryClassOnly || props.classes.length === 0) && (
              <li
                className={styles.options}
                onClick={() => {
                  if (props.selectedClassID !== 0) {
                    props.clear && props.clear();
                    props.update({
                      selectedClassID: 0,
                      selectedClassName: "すべてのグループ",
                    });
                  }
                  setIsOpen(false);
                }}
              >
                <span className={styles.optionLabel}>すべてのグループ</span>

                {props.selectedClassID === 0 && (
                  <IonIcon src={checkmark} className={styles.checkIcon} />
                )}
              </li>
            )
          )}

          {props.classes.map(c => (
            <li
              key={c.id}
              className={styles.options}
              onClick={() => {
                if (props.selectedClassID !== c.id) {
                  props.clear && props.clear();
                  props.update({
                    selectedClassID: c.id,
                    selectedClassName: c.full_name,
                  });
                }
                setIsOpen(false);
              }}
            >
              <span className={styles.optionLabel}>
                {c.full_name}
                {props.unread_realization_per_class &&
                  props.unread_realization_per_class[`${c.id}`] > 0 && (
                    <span className={styles.dot}> ●</span>
                  )}
              </span>
              {props.selectedClassID === c.id && (
                <IonIcon src={checkmark} className={styles.checkIcon} />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ClassSelector;
