import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import React from "react";
import clsx from "clsx";

import styles from "../common.module.scss";
import {
  clearAnalyzeRealizations,
  clearAnalyzeStudent,
  clearAnalyzeRealizationsPagyInfo,
  Context,
  fetchAnalyzeGearboxes,
  fetchAnalyzeRealizations,
  fetchAnalyzeStudent,
  fetchCategories,
} from "../../../store/student";
import FabButton from "../../../atoms/FabButton";
import LoginHeader from "../../../organisms/LoginHeader";
import StudentDetail from "../../../templates/StudentDetail";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import { formatTimeToString } from "../../../libs/Util";

const StudentDetailPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);

  const onLoadRealizations = React.useCallback(
    (
      page: number,
      gearbox_id?: string | number | null,
      category_id?: string | number | null,
      start_date?: Date | null,
      end_date?: Date | null,
    ) => {
      dispatch(
        peep(
          fetchAnalyzeRealizations(
            gearbox_id,
            category_id,
            formatTimeToString(start_date),
            formatTimeToString(end_date),
            page,
          ),
        ),
      );
    },
    [dispatch, peep],
  );

  const fetchAnalyzeStudentFunc = React.useCallback(
    (startDate, endDate) => {
      if ((startDate && endDate) || (!startDate && !endDate)) {
        dispatch(
          peep(
            fetchAnalyzeStudent(
              formatTimeToString(startDate),
              formatTimeToString(endDate),
            ),
          ),
        );
      }
    },
    [dispatch, peep],
  );

  useIonViewWillEnter(() => {
    dispatch(peep(fetchAnalyzeGearboxes()));
    dispatch(peep(fetchCategories()));
  });

  useIonViewWillLeave(() => {
    dispatch(clearAnalyzeStudent());
    dispatch(clearAnalyzeRealizations());
    dispatch(clearAnalyzeRealizationsPagyInfo());
  });

  return (
    <IonPage>
      <LoginHeader
        title="分析"
        unreadNotiCount={
          contextState.unread_comments_count +
          contextState.unread_notification_count +
          (contextState.student.read_gear_release_note ? 0 : 1)
        }
      />
      <IonContent className={clsx(styles.wrapper, styles["wrapper--white"])}>
        <div className={styles.container}>
          <StudentDetail
            isLoading={calling}
            student_analyze={contextState.student_analyze}
            class_name={contextState.class_name}
            realizations={contextState.realizations_analyze}
            student={contextState.student}
            gearboxes={contextState.analyzeGearboxes}
            categories={contextState.categories}
            pagy_info_realizations={contextState.realizations_analyze_pagy_info}
            onLoadRealizations={onLoadRealizations}
            fetchAnalyzeStudent={fetchAnalyzeStudentFunc}
          />
        </div>
        <FabButton />
      </IonContent>
    </IonPage>
  );
};

export default StudentDetailPage;
