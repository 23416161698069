import React, { useCallback } from "react";
import { IonIcon, IonItemGroup, useIonViewDidLeave } from "@ionic/react";

import {
  ICategory,
  IClass,
  IStudentGearBox,
  ITeacher,
  IRealizationImage,
  IOriginRealization,
  IRealizationCreateParams,
} from "../../../state";
import { isImageFile } from "../../../libs/Util";
import convertIcon from "../../../assets/icons/convert.svg";
import { ReactComponent as ImageIcon } from "../../../assets/icons/image.svg";
import realizationIcon from "../../../assets/icons/realization.svg";
import WordCounter from "../../../atoms/WordCounter";
import RealizationTextArea, {
  CONTENT_MAX_LENGTH,
} from "../../../atoms/RealizationTextArea";
import RealizationThumbnailImage from "../../../atoms/RealizationThumbnailImage";
import CategoryForm from "../../../molecules/CategoryForm";
import ExperienceSelectForm from "../../../molecules/ExperienceSelectForm";
import ImageDetailModal from "../../../molecules/ImageDetailModal";
import ModalConfirm from "../../../molecules/ModalConfirm";
import OriginRealization from "../../../molecules/OriginRealization";
import Toast, { TToastType } from "../../../molecules/Toast";
import TargetGroup, { ETargetSwitch } from "../../../organisms/TargetGroup";
import {
  INPUT_REALIZATION_CONTENT_FAILURE_MESSAGE,
  SELECT_REALIZATION_BOX_FAILURE_MESSAGE,
  SELECT_REALIZATION_CLASS_FAILURE_MESSAGE,
  SELECT_REALIZATION_TEACHER_FAILURE_MESSAGE,
} from "../../../store/student";

import styles from "./DerivativeRealizationForm.module.scss";

export interface DerivativeRealizationFormProps {
  createRealization?: (
    realization: IRealizationCreateParams,
    submit_teacher_ids: number[],
    share_class_ids: number[],
    gearbox_id: number | null,
    image?: IRealizationImage,
  ) => void;
  createCategory?: (category: { name: string; color: string }) => void;
  pathname: string;
  success_message: string;
  categories: ICategory[];
  teachers: ITeacher[];
  classes: IClass[];
  gearBoxes: IStudentGearBox[];
  calling: boolean;
  originRealization?: IOriginRealization | null;
}

const INITIAL_STATE = {
  content: "",
  color: "",
  category_ids: [],
  experience_nos: [],
  selected_teacher_ids: [],
  selected_class_ids: [],
  shared: false,
  image: undefined,
  toast: null,
  showCategoryForm: false,
  showSubmitForm: false,
  showShareForm: false,
  showBoxForm: false,
  showImageInvalid: false,
  showImageExceedsSize: false,
  showImageDetail: false,
  selected_box_id: null,
  targetSwitch: ETargetSwitch.ONLY_ME,
};

const DerivativeRealizationForm = (props: DerivativeRealizationFormProps) => {
  const realizationImgInputRef = React.useRef<HTMLInputElement>(null);
  const [state, setState] = React.useState<{
    content: string;
    color: string;
    category_ids: number[];
    experience_nos?: number[];
    selected_teacher_ids: number[];
    selected_class_ids: number[];
    selected_box_id: number | null;
    shared: boolean;
    image?: IRealizationImage;
    toast: {
      type: TToastType;
      message: string;
    } | null;
    showCategoryForm: boolean;
    showSubmitForm: boolean;
    showShareForm: boolean;
    showBoxForm: boolean;
    showImageInvalid: boolean;
    showImageExceedsSize: boolean;
    showImageDetail: boolean;
    targetSwitch: ETargetSwitch;
  }>(INITIAL_STATE);

  const update = (args: { [key: string]: any }) => {
    setState(prevState => ({ ...prevState, ...args }));
  };

  useIonViewDidLeave(() => {
    update(INITIAL_STATE);
  });

  const removeImg = useCallback((e?) => {
    if (e) {
      e.stopPropagation();
    }
    realizationImgInputRef.current &&
      (realizationImgInputRef.current.value = "");
    setState(s => ({ ...s, image: undefined }));
  }, []);

  const uploadImg = useCallback(
    e => {
      e.persist();
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      if (!isImageFile(file)) {
        removeImg();
        setState(s => ({ ...s, showImageInvalid: true }));
        return;
      }
      const fileSizeByMB = file.size / 1024 / 1024;
      if (fileSizeByMB > 5) {
        removeImg();
        setState(s => ({ ...s, showImageExceedsSize: true }));
        return;
      }
      setState(s => ({
        ...s,
        image: {
          name: file.name,
          url: URL.createObjectURL(file),
          blob: file,
        },
      }));
    },
    [removeImg],
  );

  const onSubmit = (kind: "roots" | "will") => {
    if (!state.content) {
      update({
        toast: {
          type: "danger",
          message: INPUT_REALIZATION_CONTENT_FAILURE_MESSAGE,
        },
      });
      return;
    }
    if (state.targetSwitch === ETargetSwitch.BOX && !state.selected_box_id) {
      update({
        toast: {
          type: "danger",
          message: SELECT_REALIZATION_BOX_FAILURE_MESSAGE,
        },
      });
      return;
    }
    if (
      state.targetSwitch === ETargetSwitch.GROUP &&
      !state.selected_class_ids.length
    ) {
      update({
        toast: {
          type: "danger",
          message: SELECT_REALIZATION_CLASS_FAILURE_MESSAGE,
        },
      });
      return;
    }
    if (
      state.targetSwitch === ETargetSwitch.TEACHER &&
      !state.selected_teacher_ids.length
    ) {
      update({
        toast: {
          type: "danger",
          message: SELECT_REALIZATION_TEACHER_FAILURE_MESSAGE,
        },
      });
      return;
    }
    const selectedBox = state.selected_box_id
      ? props.gearBoxes.find(box => box.id === state.selected_box_id)
      : undefined;
    props.createRealization &&
      props.createRealization(
        {
          category_ids: state.category_ids,
          experience_nos: state.experience_nos,
          content: state.content,
          submitted: state.selected_teacher_ids.length > 0,
          shared: state.selected_class_ids.length > 0,
          kind,
          origin_realization_id: props.originRealization?.id,
        },
        selectedBox ? [selectedBox.teacher.id] : state.selected_teacher_ids,
        selectedBox
          ? selectedBox.klasses.map(cls => cls.id)
          : state.selected_class_ids,
        state.selected_box_id,
        state.image,
      );
  };

  return (
    <IonItemGroup className={styles.wrapper}>
      <Toast
        type={state.toast?.type}
        showToast={!!state.toast}
        onClose={() => update({ toast: null })}
        message={state.toast?.message ?? ""}
      />
      <ModalConfirm
        isOpen={state.showImageInvalid}
        message={`無効なファイル形式です。\njpg/jpeg/png のみアップロード可能です。`}
        buttons={[
          {
            title: "キャンセル",
            type: "None",
            action: () => setState(s => ({ ...s, showImageInvalid: false })),
          },
          {
            title: "OK",
            type: "Success",
            action: () => setState(s => ({ ...s, showImageInvalid: false })),
          },
        ]}
      />
      <ModalConfirm
        isOpen={state.showImageExceedsSize}
        message={`アップロードに失敗しました。\nファイルサイズを5MB以下にしてください。`}
        buttons={[
          {
            title: "OK",
            type: "None",
            action: () =>
              setState(s => ({ ...s, showImageExceedsSize: false })),
          },
        ]}
      />
      {state.image && (
        <ImageDetailModal
          show={state.showImageDetail}
          imageUrl={state.image.url}
          imageName={state.image.name}
          onClose={() => setState(s => ({ ...s, showImageDetail: false }))}
        />
      )}
      <div className={styles.container}>
        <div className={styles.inputArea}>
          <div className={styles.textAreaWrapper}>
            <RealizationTextArea
              content={state.content}
              onChange={value => setState({ ...state, content: value })}
            />
          </div>
          <div className={styles.imgInputWrapper}>
            {state.image ? (
              <div className={styles.realizationImg}>
                <RealizationThumbnailImage
                  alt="Upload Image"
                  src={state.image.url}
                  onClick={e => {
                    e.stopPropagation();
                    setState(s => ({
                      ...s,
                      showImageDetail: true,
                    }));
                  }}
                  isEdit={true}
                />
                <button onClick={removeImg}>✕</button>
              </div>
            ) : (
              <>
                <input
                  ref={realizationImgInputRef}
                  accept=".png,.jpg,.jpeg"
                  type="file"
                  onChange={uploadImg}
                />
                <ImageIcon
                  onClick={() => realizationImgInputRef.current?.click()}
                />
              </>
            )}
          </div>
        </div>
        <div className={styles.counter}>
          <WordCounter
            maxLength={CONTENT_MAX_LENGTH}
            targetLength={state.content.length}
          />
        </div>
        {props.originRealization && (
          <div className={styles.originWrapper}>
            <OriginRealization
              realization={props.originRealization}
              showAuthor
            />
          </div>
        )}
        {props.createCategory && (
          <CategoryForm
            pathname={props.pathname}
            success_message={props.success_message}
            calling={props.calling}
            isOpen={state.showCategoryForm}
            categories={props.categories}
            selectedCategoryIDs={state.category_ids}
            update={update}
            createCategory={props.createCategory}
          />
        )}
        <TargetGroup
          gearboxes={props.gearBoxes}
          teachers={props.teachers}
          classes={props.classes}
          showSubmitForm={state.showSubmitForm}
          showShareForm={state.showShareForm}
          showBoxForm={state.showBoxForm}
          targetSwitch={state.targetSwitch}
          selected_box_id={state.selected_box_id}
          selected_teacher_ids={state.selected_teacher_ids}
          selected_class_ids={state.selected_class_ids}
          update={update}
        />
        <ExperienceSelectForm
          checkedExperienceNos={state.experience_nos}
          update={update}
        />
      </div>
      <div className={styles.wrapperButton}>
        <button
          className={styles.createRealization}
          onClick={() => onSubmit("roots")}
        >
          <IonIcon
            icon={realizationIcon}
            slot="start"
            className={styles.icon}
          />
          <span>
            ROOTSを
            <br />
            ストック
          </span>
        </button>
        <button
          className={styles.createConversion}
          onClick={() => onSubmit("will")}
        >
          <IonIcon icon={convertIcon} slot="start" className={styles.icon} />
          <span>
            WILLを
            <br />
            ストック
          </span>
        </button>
      </div>
    </IonItemGroup>
  );
};

export default DerivativeRealizationForm;
