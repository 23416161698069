import React from "react";
import { IonItem, IonSelect, IonSelectOption } from "@ionic/react";

import BarChart from "../../molecules/BarChart";
import IconCounterList from "../../molecules/IconCounterList";
import { IRealizationSummary } from "../../state";

import styles from "./AnalyzeBarChart.module.scss";

export interface AnalyzeBarChartProps {
  realization_summaries: IRealizationSummary[];
  width: number;
}

const initialState = { realization_num: 0, conversion_num: 0, action_num: 0 };

const options = {
  className: "category-interface",
};

const AnalyzeBarChart = (props: AnalyzeBarChartProps) => {
  const [state, setState] = React.useState({
    categoryID: 0,
  });
  const realizationInfo =
    props.realization_summaries.find(r => r.category_id === state.categoryID) ||
    initialState;
  const handledRealizationSummaries = [
    ...props.realization_summaries.slice(-2).reverse(),
    ...props.realization_summaries.slice(0, -2),
  ];

  return (
    <div className={styles.content}>
      <IonItem color={"rgba(255,255,255,0)"} lines={"none"}>
        <span className={styles.itemTitle}>フォルダ:</span>
        <IonSelect
          className="category-select"
          interface="popover"
          interfaceOptions={options}
          value={state.categoryID}
          onIonChange={e => {
            setState({
              ...state,
              categoryID: parseInt(e.detail.value, 10),
            });
          }}
        >
          {handledRealizationSummaries.map(realization => (
            <IonSelectOption
              key={realization.category_id}
              value={realization.category_id}
            >
              {realization.category_name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
      <BarChart
        dataSet={[
          {
            name: "ROOTS",
            value: realizationInfo.realization_num,
            color: "#3e9eff",
          },
          {
            name: "WILL",
            value: realizationInfo.conversion_num,
            color: "#195cfb",
          },
          {
            name: "EXPERIENCE",
            value: realizationInfo.action_num,
            color: "#e7c06c",
          },
        ]}
        options={{ width: props.width - 32 }}
      />
      <IconCounterList {...realizationInfo} />
    </div>
  );
};

export default AnalyzeBarChart;
