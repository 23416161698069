import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { chevronBackOutline as backIcon } from "ionicons/icons";
import React from "react";
import clsx from "clsx";

import { ShareRealization } from "../../state";
import CommentModal from "../../atoms/CommentModal";
import ShareRealizationContent from "../../molecules/ShareRealizationContent";
import ShareRealizationUserIcon from "../../assets/icons/share_realization_user_icon.svg";

import styles from "./TimelineEntryShareRealizationDetailModal.module.scss";

export interface TimelineEntryDetailModalProps {
  showDetail: boolean;
  shareRealization: ShareRealization | undefined;
  isOtherStudent: boolean;
  addFavorite?: (id: number) => void;
  removeFavorite?: (id: number) => void;
  addDerivative: () => void;
  onClose: () => void;
}

const TimelineEntryShareRealizationDetailModal: React.FC<
  TimelineEntryDetailModalProps
> = ({
  showDetail,
  shareRealization,
  isOtherStudent,
  addDerivative,
  addFavorite,
  removeFavorite,
  onClose,
}) => {
  const onClickDerivative = () => {
    addDerivative !== undefined && addDerivative();
  };

  const onClickFavorite = () => {
    if (shareRealization === undefined)
      throw new Error("shareRealization is undefined");
    if (shareRealization.is_favorite) {
      removeFavorite !== undefined && removeFavorite(shareRealization.id);
      return;
    }
    addFavorite !== undefined && addFavorite(shareRealization.id);
  };

  return (
    <CommentModal
      isOpen={showDetail}
      onClickBackdrop={() => {
        onClose();
      }}
    >
      <IonContent className={clsx(styles.wrapper)}>
        <div className={styles.contentComment}>
          <IonHeader className={styles.header} mode="ios">
            <IonToolbar className={styles.toolBar}>
              <IonTitle className={styles.title}>詳細</IonTitle>
              <IonButtons slot="start">
                <IonButton
                  onClick={() => {
                    onClose();
                  }}
                >
                  <IonIcon icon={backIcon} color="light" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <div className={clsx(styles.realizationItem)}>
            <div className={styles.avatarArea}>
              <IonAvatar color="light" className={styles.avatar}>
                <IonImg alt="プロフィール画像" src={ShareRealizationUserIcon} />
              </IonAvatar>
            </div>
            {shareRealization !== undefined && (
              <IonGrid>
                <IonRow className={styles.row}>
                  <IonCol>
                    <IonLabel color="light">
                      <span className={styles.name}>
                        {shareRealization.student.full_name}
                      </span>
                    </IonLabel>
                  </IonCol>
                  <IonCol size="12" className={styles.contentWrapper}>
                    <ShareRealizationContent
                      shareRealization={shareRealization}
                      derivable={isOtherStudent}
                      onClickDerivative={onClickDerivative}
                      onClickFavorite={onClickFavorite}
                      isDetail={true}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            )}
          </div>
        </div>
      </IonContent>
    </CommentModal>
  );
};

export default TimelineEntryShareRealizationDetailModal;
