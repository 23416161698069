const BASE_COLOR = {
  green: "#7AAE59",
  red: "#DA5951",
  yellow: "#EEC84D",
  blue: "#93D4DD",
  orange: "#E39844",
  gray: "#9AAEBF",
  purple: "#696DD9",
};

export const EXPERIENCE_DATA = [
  {
    no: 1,
    label: "自ら動く",
    styles: {
      border: `1px solid ${BASE_COLOR.green}`,
      color: BASE_COLOR.green,
    },
    checkedStyles: {
      border: `1px solid ${BASE_COLOR.green}`,
      backgroundColor: BASE_COLOR.green,
    },
  },
  {
    no: 2,
    label: "最後までやりきる",
    styles: {
      border: `1px solid ${BASE_COLOR.red}`,
      color: BASE_COLOR.red,
    },
    checkedStyles: {
      border: `1px solid ${BASE_COLOR.red}`,
      backgroundColor: BASE_COLOR.red,
    },
  },
  {
    no: 3,
    label: "みんなのために",
    styles: {
      border: `1px solid ${BASE_COLOR.yellow}`,
      color: BASE_COLOR.yellow,
    },
    checkedStyles: {
      border: `1px solid ${BASE_COLOR.yellow}`,
      backgroundColor: BASE_COLOR.yellow,
    },
  },
  {
    no: 4,
    label: "社会のために",
    styles: {
      border: `1px solid ${BASE_COLOR.blue}`,
      color: BASE_COLOR.blue,
    },
    checkedStyles: {
      border: `1px solid ${BASE_COLOR.blue}`,
      backgroundColor: BASE_COLOR.blue,
    },
  },
  {
    no: 5,
    label: "成長のチャンス",
    styles: {
      border: `1px solid ${BASE_COLOR.orange}`,
      color: BASE_COLOR.orange,
    },
    checkedStyles: {
      border: `1px solid ${BASE_COLOR.orange}`,
      backgroundColor: BASE_COLOR.orange,
    },
  },
  {
    no: 6,
    label: "視野を拡げる",
    styles: {
      border: `1px solid ${BASE_COLOR.gray}`,
      color: BASE_COLOR.gray,
    },
    checkedStyles: {
      border: `1px solid ${BASE_COLOR.gray}`,
      backgroundColor: BASE_COLOR.gray,
    },
  },
  {
    no: 7,
    label: "目的を描く",
    styles: {
      border: `1px solid ${BASE_COLOR.purple}`,
      color: BASE_COLOR.purple,
    },
    checkedStyles: {
      border: `1px solid ${BASE_COLOR.purple}`,
      backgroundColor: BASE_COLOR.purple,
    },
  },
];
