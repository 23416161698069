import React from "react";
import { PieChart, Pie, Cell } from "recharts";

import { Context } from "../../store/student";
import { IRealizationCountByExp } from "../../state";
import { ASSESSMENT_STATUS } from "../../constants/assessment-content";

import styles from "./RealizationPieChart.module.scss";

interface ScoreData {
  exp1: string;
  exp2: string;
  exp3: string;
  exp4: string;
  exp5: string;
  exp6: string;
  exp7: string;
}

const RealizationPieChart = () => {
  const { contextState } = React.useContext(Context);

  const [realizationCountByExp, setRealizationCountByExp] =
    React.useState<IRealizationCountByExp>({
      realization_count: 0,
      scores: { exp1: 0, exp2: 0, exp3: 0, exp4: 0, exp5: 0, exp6: 0, exp7: 0 },
    });

  const realizationCountByExpWrap = React.useMemo(
    () => contextState.realization_count_by_exp,
    [contextState.realization_count_by_exp],
  );

  React.useEffect(() => {
    setRealizationCountByExp(realizationCountByExpWrap);
  }, [realizationCountByExp, realizationCountByExpWrap]);

  const stockCounts = React.useMemo(() => {
    return (
      Object.keys(realizationCountByExp.scores) as (keyof ScoreData)[]
    ).map(key => ({
      name: key,
      value: realizationCountByExp.scores[key],
    }));
  }, [realizationCountByExp]);

  return (
    <div className={styles.realization}>
      <div className={styles.title}>ストック数の割合</div>
      <div className={styles.chart}>
        <PieChart width={200} height={200}>
          <Pie
            data={stockCounts}
            innerRadius={92.5}
            outerRadius={100}
            dataKey="value"
            strokeWidth={0}
          >
            {ASSESSMENT_STATUS.map(assessmentData => (
              <Cell
                key={assessmentData.experienceNo}
                fill={assessmentData.colorCode}
              />
            ))}
          </Pie>
        </PieChart>
        <div className={styles.stock}>
          <span className={styles.count}>
            {realizationCountByExp.realization_count}
          </span>
          <span className={styles.text}>ストック</span>
        </div>
      </div>
    </div>
  );
};
export default RealizationPieChart;
