import React from "react";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";

import AnalyzeBarChart from "../../organisms/AnalyzeBarChart";
import { IRealizationHistory, IRealizationSummary } from "../../state";
import AnalyzeLineChart from "../../organisms/AnalyzeLineChart";

import styles from "./AnalyzeChart.module.scss";

export interface AnalyzeChartProps {
  entrance_year: number;
  realization_summaries: IRealizationSummary[];
  realization_histories: IRealizationHistory[];
}

const AnalyzeChart = (props: AnalyzeChartProps) => {
  const [state, setState] = React.useState({ type: "line" });
  const [width, setWidth] = React.useState(0);

  const updateWidth = React.useCallback(
    () => setWidth(window.innerWidth < 1000 ? window.innerWidth : 1000),
    [],
  );

  React.useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [updateWidth]);

  return (
    <div className={styles.wrapper}>
      <IonSegment
        mode="md"
        value={state.type}
        onIonChange={e =>
          setState({ ...state, type: e.detail.value as string })
        }
      >
        <IonSegmentButton className={styles.segmentButton} value="line">
          <IonLabel className={state.type !== "line" ? styles.inactive : ""}>
            累積数
          </IonLabel>
        </IonSegmentButton>
        <IonSegmentButton className={styles.segmentButton} value="bar">
          <IonLabel className={state.type !== "bar" ? styles.inactive : ""}>
            活かし先別
          </IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <div className={styles.chart}>
        {state.type === "bar" ? (
          props.realization_summaries.length > 0 ? (
            <AnalyzeBarChart
              realization_summaries={props.realization_summaries}
              width={width}
            />
          ) : null
        ) : props.realization_histories.length > 0 ? (
          <AnalyzeLineChart
            entrance_year={props.entrance_year}
            realization_histories={props.realization_histories}
            width={width}
          />
        ) : null}
      </div>
    </div>
  );
};
export default AnalyzeChart;
