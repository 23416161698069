import { IonButton, IonItemGroup, IonLabel } from "@ionic/react";
import React from "react";

import CommentModal from "../../../atoms/CommentModal";

import styles from "./ReportForm.module.scss";

export interface ReportFormProps {
  isOpen: boolean;
  close: () => void;
  report: () => void;
}

const ReportForm = (props: ReportFormProps) => {
  return (
    <CommentModal
      isOpen={props.isOpen}
      className={styles.wrapper}
      onClickBackdrop={props.close}
    >
      <div>
        <div className={styles.form}>
          <IonItemGroup className={styles.item}>
            <IonLabel color="light" className={styles.label}>
              <h2 className={styles.title}>報告</h2>
              不適切又は攻撃的な内容を含む投稿であり、
              <br />
              人の尊厳を脅かし、人権を侵害する
              <br />
              身の危険を感じたため報告します。
            </IonLabel>
            <div className={styles.buttonArea}>
              <IonButton className={styles.cancelButton} onClick={props.close}>
                キャンセル
              </IonButton>
              <IonButton className={styles.reportButton} onClick={props.report}>
                報告する
              </IonButton>
            </div>
          </IonItemGroup>
        </div>
      </div>
    </CommentModal>
  );
};

export default ReportForm;
