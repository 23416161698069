import { IonButton, IonIcon } from "@ionic/react";
import clsx from "clsx";
import {
  chatbubbleOutline,
  chevronDownOutline,
  heartOutline,
} from "ionicons/icons";
import React from "react";

import CategoryTag from "../../../atoms/CategoryTag";
import Dropdown from "../../../atoms/Dropdown";
import Tick from "../../../assets/icons/tick_exp_icon.svg";
import {
  ICategory,
  IPagyInfo,
  IRealizationAnalyze,
  ITeacherGearbox,
} from "../../../state";
import { convertDateToDateString, simpleDateTimeStr } from "../../../libs/Util";
import DatePicker from "../../../atoms/DatePicker";

import styles from "./TimelineTabContent.module.scss";

const INITIAL_FILTER = {
  categoryID: 0,
  boxID: 0,
  startDate: null,
  endDate: null,
};

export interface TimelineTabContentProps {
  realizations: IRealizationAnalyze[];
  gearboxes: ITeacherGearbox[];
  categories: ICategory[];
  isLoading: boolean;
  pagy_info_realizations: IPagyInfo;
  onLoadRealizations: (
    page: number,
    gearbox_id?: string | number | null,
    category_id?: string | number | null,
    start_date?: Date | null,
    end_date?: Date | null,
  ) => void;
}

const TimelineTabContent: React.FC<TimelineTabContentProps> = ({
  realizations,
  gearboxes,
  onLoadRealizations,
  categories,
  pagy_info_realizations,
  isLoading,
}) => {
  const [filter, setFilter] = React.useState<{
    boxID: number;
    categoryID: number;
    startDate: Date | null;
    endDate: Date | null;
  }>(INITIAL_FILTER);
  const onDatePickerChange = React.useCallback(([start, end]) => {
    setFilter(f => ({
      ...f,
      startDate: start,
      endDate: end,
    }));
  }, []);

  const onDropdownSelect = React.useCallback(
    ({ name, value }) =>
      setFilter(s => ({
        ...s,
        [name]: Number(value),
      })),
    [],
  );

  React.useEffect(() => {
    onFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.endDate, filter.startDate, filter.categoryID, filter.boxID]);

  const onNextPage = React.useCallback(() => {
    const { page, pages } = pagy_info_realizations;
    const { boxID, categoryID, startDate, endDate } = filter;
    if (page < pages) {
      onLoadRealizations(page + 1, boxID, categoryID, startDate, endDate);
    }
  }, [filter, onLoadRealizations, pagy_info_realizations]);

  const onClear = React.useCallback(() => {
    setFilter(s => ({
      ...s,
      ...INITIAL_FILTER,
    }));
    onFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gearboxesData = React.useMemo(
    () =>
      gearboxes.map(cls => ({
        value: cls.id,
        label: `${cls.is_archived ? "[提出終了] " : ""}${
          cls.name_status === "unpublish" ? "[匿名共有] " : ""
        }${cls.title}`,
      })),
    [gearboxes],
  );

  const categoriesData = React.useMemo(
    () =>
      categories.map(ctg => ({
        value: ctg.id,
        label: ctg.name,
      })),
    [categories],
  );

  const onFilter = React.useCallback(() => {
    const { boxID, categoryID, startDate, endDate } = filter;
    if ((startDate && !endDate) || (!startDate && endDate)) return;
    onLoadRealizations(1, boxID, categoryID, startDate, endDate);
  }, [filter, onLoadRealizations]);

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Dropdown
          name="categoryID"
          className={clsx(styles.dropdown, isLoading ? styles.disabled : "")}
          data={categoriesData}
          defaultLabel="活かし先未選択"
          defaultValue={0}
          selectedValue={filter.categoryID}
          onSelect={onDropdownSelect}
        />
        <Dropdown
          name="boxID"
          className={clsx(styles.dropdown, isLoading ? styles.disabled : "")}
          data={gearboxesData}
          defaultLabel="BOX未選択"
          defaultValue={0}
          selectedValue={filter.boxID}
          onSelect={onDropdownSelect}
        />
        <DatePicker
          disabled={isLoading}
          className={styles.dropdown}
          label={
            filter.startDate || filter.endDate
              ? `${
                  filter.startDate
                    ? convertDateToDateString(filter.startDate)
                    : ""
                }→${
                  filter.endDate ? convertDateToDateString(filter.endDate) : ""
                }`
              : "期間未指定"
          }
          dateFormat="yyyy/MM/dd"
          locale="ja"
          selected={filter.startDate}
          onChange={onDatePickerChange}
          startDate={filter.startDate}
          endDate={filter.endDate}
          selectsRange
        />
        <IonButton
          expand="block"
          fill="outline"
          className={styles.btnClear}
          onClick={onClear}
          disabled={
            isLoading ||
            (filter.endDate === null &&
              filter.startDate === null &&
              filter.categoryID === 0 &&
              filter.boxID === 0)
          }
        >
          クリア
        </IonButton>
      </div>

      <div className={styles.table}>
        <div className={styles.headerTitle}>投稿詳細</div>
        <div className={styles.content}>
          <div className={styles.titleCols}>
            <div>ROOTS</div>
            <div>WILL</div>
            <div>Exp.</div>
          </div>
          <div className={styles.realizations}>
            {realizations.map(realization => (
              <div className={styles.item} key={realization.id}>
                {realization.kind === "roots" ? (
                  <div className={styles.realizationContent}>
                    <div className={styles.nameStudent}>
                      {realization.student_name}
                    </div>
                    <div className={styles.textContent}>
                      {realization.content}
                    </div>
                    <div className={styles.createAt}>
                      {simpleDateTimeStr(new Date(realization.created_at))}
                    </div>
                    <div className={styles.footer}>
                      <div className={styles.categoryArea}>
                        {realization.categories.map(category => (
                          <CategoryTag
                            key={category.id}
                            fontSmall
                            label={category.name ?? "すべて"}
                            color="#959595"
                            verticalMargin
                          />
                        ))}
                      </div>
                      <div className={styles.actionArea}>
                        <IonIcon icon={heartOutline} className={styles.icon} />
                        <span>{realization.favorite_count}</span>
                        <IonIcon
                          icon={chatbubbleOutline}
                          className={styles.icon}
                        />
                        <span>{realization.comment_count}</span>
                        <div
                          className={clsx(styles.label, styles.label__roots)}
                        >
                          ROOTS
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.emptyBlock} />
                )}
                {realization.kind === "will" ? (
                  <div className={styles.realizationContent}>
                    <div className={styles.nameStudent}>
                      {realization.student_name}
                    </div>
                    <div className={styles.textContent}>
                      {realization.content}
                    </div>
                    <div className={styles.createAt}>
                      {simpleDateTimeStr(new Date(realization.created_at))}
                    </div>
                    <div className={styles.footer}>
                      <div className={styles.categoryArea}>
                        {realization.categories.map(category => (
                          <CategoryTag
                            key={category.id}
                            fontSmall
                            label={category.name ?? "すべて"}
                            color="#959595"
                            verticalMargin
                          />
                        ))}
                      </div>
                      <div className={styles.actionArea}>
                        <IonIcon icon={heartOutline} className={styles.icon} />
                        <span>{realization.favorite_count}</span>
                        <IonIcon
                          icon={chatbubbleOutline}
                          className={styles.icon}
                        />
                        <span>{realization.comment_count}</span>
                        <div className={clsx(styles.label, styles.label__will)}>
                          WILL
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.emptyBlock} />
                )}
                {realization.executed_at ? (
                  <div className={styles.expContent}>
                    <IonIcon src={Tick} className={styles.iconTick} />
                    <div className={clsx(styles.label, styles.label__exp)}>
                      EXP.
                    </div>
                  </div>
                ) : (
                  <div className={styles.emptyBlock} />
                )}
              </div>
            ))}
            {pagy_info_realizations.page !== pagy_info_realizations.pages && (
              <IonButton
                expand="block"
                color="support"
                onClick={onNextPage}
                disabled={isLoading}
              >
                <IonIcon icon={chevronDownOutline} className={styles.icon} />
              </IonButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelineTabContent;
