import { useLocation } from "react-router-dom";

type QueryParam<T extends string | undefined> = T extends string
  ? string
  : string | null;

type QueryParams<T extends { [key: string]: string | undefined }> = {
  [K in keyof T]: QueryParam<T[K]>;
};

export function useQueryParams<
  T extends { [key: string]: string | undefined },
>() {
  const searchParams = new URLSearchParams(useLocation().search);

  return Object.fromEntries(
    Array.from(searchParams.entries()),
  ) as QueryParams<T>;
}
