import { IonContent, useIonViewWillLeave } from "@ionic/react";
import React, { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useIntersection from "../../hooks/useIntersection";
import RealizationTypeSelector from "../../molecules/RealizationTypeSelector";
import SelfRealizationItem from "../../molecules/SelfRealizationItem";
import TemplateModal from "../../organisms/TemplateModal";
import {
  ICategory,
  IRealization,
  IPagyInfo,
  ITeacher,
  IClass,
  IFeedback,
  IComment,
  IStudentGearBox,
  IRealizationCreateParams,
  IRealizationImage,
  IRealizationUpdate,
  IStudent,
  ReactableType,
} from "../../state";
import {
  CREATE_COMMENT_SUCCESS_MESSAGE,
  CREATE_FEEDBACK_SUCCESS_MESSAGE,
  DELETE_COMMENT_SUCCESS_MESSAGE,
  DELETE_FEEDBACK_SUCCESS_MESSAGE,
  DELETE_REALIZATION_SUCCESS_MESSAGE,
  EXECUTE_REALIZATION_SUCCESS_MESSAGE,
  UPDATE_COMMENT_SUCCESS_MESSAGE,
  UPDATE_FEEDBACK_SUCCESS_MESSAGE,
  UPDATE_REALIZATION_SUCCESS_MESSAGE,
  CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE,
  categorizedRealizationPath,
  CREATE_REALIZATION_STAMP_REACTION_SUCCESS,
} from "../../store/student";
import RealizationDetail from "../RealizationDetail";
import type { Stamp } from "../../libs/stamp";

import styles from "./RealizationBoxesList.module.scss";

export interface RealizationBoxesListProps {
  student: IStudent;
  boxId: number;
  isDisplaying: boolean;
  realizations: IRealization[];
  searchText: string;
  categories: ICategory[];
  calling: boolean;
  teachers: ITeacher[];
  classes: IClass[];
  feedbacks: IFeedback[];
  comments: IComment[];
  pathname: string;
  success_message: string;
  gearBoxes: IStudentGearBox[];
  pagy_info: IPagyInfo;
  type?: string;
  fetchBoxRealization: (page: number, type?: string) => void;
  clearSuccessMessage: () => void;
  clearComments: () => void;
  clearFeedbacks: () => void;
  createCategory: (category: ICategory) => void;
  fetchFeedbacks: (realization_id: number) => void;
  createFeedback: (realization_id: number, content: string) => void;
  updateFeedback: (feedback_id: number, content: string) => void;
  deleteFeedback: (feedback_id: number) => void;
  fetchComments: (realization_id: number) => void;
  createComment: (realization_id: number, content: string) => void;
  updateComment: (comment_id: number, content: string) => void;
  deleteComment: (comment_id: number) => void;
  createReport: (params: {
    realization_id?: number;
    comment_id?: number;
  }) => void;
  deleteRealization: (realization_id: number) => void;
  createWillRealization(
    realization: IRealizationCreateParams,
    submit_teacher_ids: number[],
    share_class_ids: number[],
    gearbox_id: number | null,
    image?: IRealizationImage,
  ): void;
  updateRealization(
    realization: IRealizationUpdate,
    submit_teacher_ids: number[],
    share_class_ids: number[],
  ): void;
  shareRealization(realization_id: number): void;
  executeRealization(realization_id: number): void;
  updateReadLastComment: (realization_id: number, comment_id: number) => void;
  updateReadLastFeedback: (realization_id: number, feedback_id: number) => void;
  redirectToWillList: (pathname: string, category_id: number) => void;
  redirectToExecuteList: (pathname: string, category_id: number) => void;
  createStampReaction: (
    realization_id: number,
    type: ReactableType,
    stamp: Stamp,
  ) => void;
}

const RealizationBoxesList = (props: RealizationBoxesListProps) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const location = useLocation();

  const categoryMap = { 0: { name: "すべて", color: "blue" } } as {
    [key: number]: { name: string; color: string };
  };

  props.categories.forEach(c => {
    categoryMap[c.id] = { name: c.name, color: c.color };
  });

  const [values, updateValues] = React.useState({
    realizationDetailId: 0,
    convert_category_id: 0,
  });

  const update = (args: { [key: string]: any }) => {
    updateValues(prevValues => ({ ...prevValues, ...args }));
  };

  const realizationDetail = React.useMemo(
    () => props.realizations.find(r => r.id === values.realizationDetailId),
    [props.realizations, values.realizationDetailId],
  );

  const filterRealizations = React.useMemo(() => {
    return props.realizations
      .filter(r => {
        return r.gearbox_id === props.boxId;
      })
      .filter(realization => {
        if (!props.type) return true;
        return props.type === "roots"
          ? realization.kind === "roots"
          : props.type === "will"
          ? realization.kind === "will" && !realization.executed_at
          : realization.kind === "will" && realization.executed_at;
      });
  }, [props.boxId, props.realizations, props.type]);

  const contentRef = React.useRef<HTMLIonContentElement>(null);
  const fetchContents = React.useCallback(
    (realization_id: number, fetchFunctions: ((id: number) => void)[]) => {
      props.clearSuccessMessage();
      fetchFunctions.forEach(
        func => realization_id !== 0 && func(realization_id),
      );
      setTimeout(() => {
        contentRef.current?.scrollToBottom(500);
      }, 500);
    },
    [props],
  );

  useIntersection({
    target: loadMoreRef,
    enabled:
      props.isDisplaying &&
      !props.calling &&
      props.pagy_info.page < props.pagy_info.pages,
    onIntersect: () =>
      props.fetchBoxRealization(props.pagy_info.page + 1, props.type),
  });

  React.useEffect(() => {
    if (values.realizationDetailId) {
      props.fetchComments(values.realizationDetailId);
      props.fetchFeedbacks(values.realizationDetailId);
    } else {
      props.clearComments();
      props.clearFeedbacks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.realizationDetailId]);

  React.useEffect(() => {
    if (
      props.success_message ===
        CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE &&
      props.isDisplaying
    ) {
      props.clearSuccessMessage();
      update({
        realizationDetailId: 0,
        convert_category_id: 0,
      });
      props.redirectToWillList(
        categorizedRealizationPath,
        values.convert_category_id,
      );
    }
    if (
      props.success_message === UPDATE_REALIZATION_SUCCESS_MESSAGE &&
      props.isDisplaying
    ) {
      props.clearSuccessMessage();
    }
    if (
      props.success_message === EXECUTE_REALIZATION_SUCCESS_MESSAGE &&
      props.isDisplaying
    ) {
      props.clearSuccessMessage();
      update({ realizationDetailId: 0 });
      props.redirectToExecuteList(
        categorizedRealizationPath,
        realizationDetail?.categories?.[0]?.id ?? 0,
      );
    }
    if (
      props.success_message === CREATE_COMMENT_SUCCESS_MESSAGE ||
      props.success_message === UPDATE_COMMENT_SUCCESS_MESSAGE ||
      props.success_message === DELETE_COMMENT_SUCCESS_MESSAGE
    ) {
      fetchContents(values.realizationDetailId, [props.fetchComments]);
    }
    if (
      props.success_message === CREATE_FEEDBACK_SUCCESS_MESSAGE ||
      props.success_message === UPDATE_FEEDBACK_SUCCESS_MESSAGE ||
      props.success_message === DELETE_FEEDBACK_SUCCESS_MESSAGE
    ) {
      fetchContents(values.realizationDetailId, [props.fetchFeedbacks]);
    }
    if (props.success_message === CREATE_REALIZATION_STAMP_REACTION_SUCCESS) {
      fetchContents(values.realizationDetailId, [
        props.fetchComments,
        props.fetchFeedbacks,
      ]);
    }
    if (props.success_message === DELETE_REALIZATION_SUCCESS_MESSAGE) {
      props.clearSuccessMessage();
      update({
        realizationDetailId: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.success_message, fetchContents]);

  useIonViewWillLeave(() => {
    update({
      realizationDetailId: 0,
      convert_category_id: 0,
    });
  });

  const changeWillRealization = React.useCallback(
    (
      realization: IRealizationCreateParams,
      submit_teacher_ids: number[],
      share_class_ids: number[],
      gearbox_id: number | null,
      image?: IRealizationImage,
    ) => {
      update({
        convert_category_id: realization.category_ids?.[0] ?? 0,
      });
      props.createWillRealization(
        realization,
        submit_teacher_ids,
        share_class_ids,
        gearbox_id,
        image,
      );
    },
    [props],
  );

  const updateRootRealization = React.useCallback(
    (
      realization: IRealizationUpdate,
      submit_teacher_ids: number[],
      share_class_ids: number[],
    ) => {
      update({
        convert_category_id: realization.category_ids?.[0] ?? 0,
      });
      props.updateRealization(realization, submit_teacher_ids, share_class_ids);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const changeFilterList = (type?: "roots" | "will" | "exp") => {
    if (props.type === type) return;
    history.replace({
      search: type ? `?type=${type}` : undefined,
      state: location.state,
    });
    props.fetchBoxRealization(1, type);
  };

  return (
    <>
      <RealizationTypeSelector
        type={props.type}
        changeFilterList={changeFilterList}
      />
      <div className={styles.RealizationBoxesList}>
        {realizationDetail && (
          <TemplateModal
            headerTitle="詳細"
            isOpen={!!values.realizationDetailId}
            onClose={() =>
              update({
                realizationDetailId: 0,
              })
            }
          >
            <IonContent ref={contentRef}>
              <RealizationDetail
                calling={props.calling}
                student={props.student}
                realization={realizationDetail}
                categories={props.categories}
                teachers={props.teachers}
                classes={props.classes}
                feedbacks={props.feedbacks}
                comments={props.comments}
                pathname={props.pathname}
                success_message={props.success_message}
                gearBoxes={props.gearBoxes}
                clearSuccessMessage={props.clearSuccessMessage}
                createCategory={props.createCategory}
                createFeedback={props.createFeedback}
                updateFeedback={props.updateFeedback}
                deleteFeedback={props.deleteFeedback}
                createComment={props.createComment}
                updateComment={props.updateComment}
                deleteComment={props.deleteComment}
                createReport={props.createReport}
                createWillRealization={changeWillRealization}
                deleteRealization={props.deleteRealization}
                updateRealization={updateRootRealization}
                executeRealization={props.executeRealization}
                updateReadLastComment={props.updateReadLastComment}
                updateReadLastFeedback={props.updateReadLastFeedback}
                createStampReaction={props.createStampReaction}
              />
            </IonContent>
          </TemplateModal>
        )}
        {filterRealizations.map(realization => (
          <SelfRealizationItem
            key={realization.id}
            realization={realization}
            onAction={() => update({ realizationDetailId: realization.id })}
            searchText={props.searchText}
          />
        ))}
        <div className={styles.loadMore} ref={loadMoreRef}>
          {props.calling ? "読み込み中" : ""}
        </div>
      </div>
    </>
  );
};

export default RealizationBoxesList;
