import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import {
  addCircleOutline as addIcon,
  chevronDownOutline as downIcon,
  chevronUpOutline as upIcon,
  checkmark,
} from "ionicons/icons";
import clsx from "clsx";

import NewCategoryForm from "../NewCategoryForm";
import CategoryLabel from "../../atoms/CategoryLabel";
import { ICategory } from "../../state";
import {
  editCategoriesPath,
  CREATE_CATEGORY_SUCCESS_MESSAGE,
} from "../../store/student";

import styles from "./CategoryForm.module.scss";

export interface CategoryFormProps {
  pathname: string;
  success_message: string;
  isOpen: boolean;
  categories: ICategory[];
  calling?: boolean;
  selectedCategoryIDs?: number[];
  update: (args: { [key: string]: any }) => void;
  createCategory(category: { name: string; color: string }): void;
}

const CategoryForm = (props: CategoryFormProps) => {
  const [state, setState] = React.useState({
    showNewCategoryForm: false,
    displayName: "",
    isCreatedNewCategory: false,
  });
  const update = (args: { [key: string]: any }) => {
    setState(prevState => ({ ...prevState, ...args }));
  };

  const displayCategories = React.useMemo(
    () => props.categories.filter(c => c.id > 0),
    [props.categories],
  );

  React.useEffect(() => {
    if (!props.selectedCategoryIDs) {
      update({ displayName: "選択しない" });
    } else {
      const selectedCategories = props.categories.filter(category =>
        props.selectedCategoryIDs?.includes(category.id),
      );
      update({
        displayName:
          selectedCategories.map(category => category.name).join(", ") ||
          "選択しない",
      });
    }
  }, [props.categories, props.selectedCategoryIDs]);

  React.useEffect(() => {
    if (
      props.pathname !== editCategoriesPath &&
      props.success_message === CREATE_CATEGORY_SUCCESS_MESSAGE
    ) {
      update({ isCreatedNewCategory: true });
    }
  }, [props.pathname, props.success_message]);

  React.useEffect(() => {
    if (state.isCreatedNewCategory) {
      const newCategoryId = props.categories[props.categories.length - 1]?.id;
      if (newCategoryId) {
        props.update({
          category_ids: [...(props.selectedCategoryIDs ?? []), newCategoryId],
        });
      }
      update({ isCreatedNewCategory: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.categories]);

  return (
    <div className={styles.wrapper}>
      <NewCategoryForm
        calling={props.calling}
        visible={state.showNewCategoryForm}
        update={update}
        values={state}
        createCategory={props.createCategory}
      />
      <IonItem
        className={styles.categoryItemToggle}
        onClick={() => {
          props.update({
            showCategoryForm: !props.isOpen,
            showSubmitForm: false,
            showShareForm: false,
            showBoxForm: false,
          });
        }}
      >
        <IonLabel color="light">活かし先</IonLabel>
        <div className={styles.selectedName}>{state.displayName}</div>
        {props.isOpen ? (
          <IonIcon icon={upIcon} slot="end" className={styles.arrowIcon} />
        ) : (
          <IonIcon icon={downIcon} slot="end" className={styles.arrowIcon} />
        )}
      </IonItem>
      <div className={props.isOpen ? styles.open : styles.close}>
        <IonItem className={styles.categoryItem}>
          <IonLabel color="light">活かし先を新規作成</IonLabel>
          <IonIcon
            className={styles.addIcon}
            slot="end"
            icon={addIcon}
            onClick={() => {
              setState({ ...state, showNewCategoryForm: true });
            }}
          />
        </IonItem>
      </div>
      <div className={props.isOpen ? styles.categoryArea : styles.close}>
        <ul className={styles.categorySelector}>
          {displayCategories.map(category => (
            <li
              key={category.id}
              className={styles.categoryItem}
              onClick={() => {
                const categorySet = new Set(props.selectedCategoryIDs);
                if (categorySet.has(category.id)) {
                  categorySet.delete(category.id);
                } else {
                  categorySet.add(category.id);
                }
                props.update({
                  category_ids: Array.from(categorySet),
                });
              }}
            >
              <div className={styles.categoryName}>
                <CategoryLabel
                  selectable
                  label={category.name}
                  color={category.color}
                />
                <IonIcon
                  slot="end"
                  icon={checkmark}
                  className={clsx(
                    styles.checkIcon,
                    !props.selectedCategoryIDs?.includes(category.id) &&
                      styles.hide,
                  )}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CategoryForm;
