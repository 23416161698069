import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  IonButton,
  useIonViewWillLeave,
} from "@ionic/react";
import React from "react";

import useWaitApiCall from "../../../hooks/useWaitApiCall";
import { ICategory } from "../../../state";
import {
  clearSuccessMessage,
  Context,
  createCategory,
  CREATE_CATEGORY_SUCCESS_MESSAGE,
  destroyCategory,
  DESTROY_CATEGORY_SUCCESS_MESSAGE,
  fetchCategoriesWithCount,
  reorderCategories,
  updateCategory,
  UPDATE_CATEGORY_SUCCESS_MESSAGE,
} from "../../../store/student";
import EditCategories from "../../../templates/EditCategories";
import NavHeader from "../../../organisms/NavHeader";
import styles from "../common.module.scss";

type FormValues = {
  editable: boolean;
  showCategoryDeleteForm: boolean;
  showNewCategoryForm: boolean;
  category_id: number;
  category_name: string;
  categories: ICategory[];
  [key: string]: any;
};

const EditCategoriesPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const [isDisplaying, setIsDisplaying] = React.useState(false);
  const [values, updateValues] = React.useState<FormValues>({
    editable: false,
    showCategoryDeleteForm: false,
    showNewCategoryForm: false,
    category_id: 0,
    category_name: "",
    categories: [],
  });

  const update = React.useCallback(
    (args: { [key: string]: any }) =>
      updateValues(v => ({
        ...v,
        ...args,
      })),
    [],
  );

  useIonViewWillEnter(() => {
    setIsDisplaying(true);
    dispatch(fetchCategoriesWithCount());
  });

  const categories = React.useMemo(
    () => contextState.categories.filter(c => c.id),
    [contextState.categories],
  );

  React.useEffect(() => {
    if (!isDisplaying) return;
    if (
      contextState.success_message === DESTROY_CATEGORY_SUCCESS_MESSAGE ||
      contextState.success_message === CREATE_CATEGORY_SUCCESS_MESSAGE ||
      contextState.success_message === UPDATE_CATEGORY_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      dispatch(fetchCategoriesWithCount());
    }
  }, [dispatch, contextState.success_message, isDisplaying]);

  const destroyCategoryFunc = React.useCallback(
    (category_id: number) => {
      dispatch(peep(destroyCategory(category_id)));
    },
    [dispatch, peep],
  );

  const reorderCategoryFunc = React.useCallback(
    (categories: ICategory[]) => {
      dispatch(
        peep(
          reorderCategories(
            JSON.stringify(
              categories.map(({ student_category_id }) => ({
                student_category_id,
              })),
            ),
          ),
        ),
      );
    },
    [dispatch, peep],
  );

  const createCategoryFunc = React.useCallback(
    (category: ICategory) => {
      dispatch(peep(createCategory(category)));
    },
    [dispatch, peep],
  );

  const updateCategoryFunc = React.useCallback(
    (category_id: number, category: ICategory) => {
      dispatch(peep(updateCategory(category_id, category)));
    },
    [dispatch, peep],
  );

  useIonViewWillLeave(() => {
    updateValues({ ...values, editable: false });
    setIsDisplaying(false);
  });

  return (
    <IonPage>
      <NavHeader
        title="活かし先設定"
        rightComponent={
          <IonButton
            className={styles.editButton}
            disabled={calling}
            onClick={() => {
              reorderCategoryFunc(values.categories);
              updateValues({ ...values, editable: !values.editable });
            }}
          >
            {values.editable ? "完了" : "編集"}
          </IonButton>
        }
      />
      <IonContent className={styles.wrapper}>
        <div className={styles.container}>
          <EditCategories
            calling={calling}
            values={values}
            update={update}
            categories={categories}
            updateCategory={updateCategoryFunc}
            createCategory={createCategoryFunc}
            destroyCategory={destroyCategoryFunc}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EditCategoriesPage;
