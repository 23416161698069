import React from "react";

import { ShareRealization } from "../../state";
import RealizationTypeTag from "../../atoms/RealizationTypeTag";
import { simpleDateTimeStr } from "../../libs/Util";

import styles from "./ShareRealizationContentHeader.module.scss";

type Props = {
  shareRealization: ShareRealization;
};

const ShareRealizationContentHeader = ({ shareRealization }: Props) => {
  return (
    <div className={styles.wrapper}>
      <RealizationTypeTag kind={shareRealization.kind} executed={false} />
      <div className={styles.date}>
        <span className={styles.text}>
          {simpleDateTimeStr(new Date(shareRealization.created_at))}
        </span>
      </div>
    </div>
  );
};

export default ShareRealizationContentHeader;
