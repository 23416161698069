import React from "react";
import clsx from "clsx";

import { ReactComponent as ArrowLeftUp } from "../../assets/icons/arrow_left_up.svg";
import RealizationContentHeader from "../RealizationContentHeader";
import { IOriginRealization } from "../../state";

import styles from "./OriginRealization.module.scss";

type Props = {
  realization: IOriginRealization;
  isDetail?: boolean;
  showAuthor?: boolean;
};

const OriginRealization = ({ realization, isDetail, showAuthor }: Props) => {
  return (
    <div className={styles.rootWrapper}>
      <ArrowLeftUp className={styles.icon} />
      <div className={styles.content}>
        {!realization.deleted && (
          <RealizationContentHeader
            realization={realization}
            isOrigin
            showAuthor={showAuthor}
          />
        )}
        <p
          className={clsx(
            styles.text,
            isDetail ? styles.detail : styles.listItem,
            realization.deleted && styles.deleted,
          )}
        >
          {realization.content}
        </p>
      </div>
    </div>
  );
};

export default OriginRealization;
