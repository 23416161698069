import { IonLabel } from "@ionic/react";
import React from "react";

import ClassSelectForm from "../../molecules/ClassSelectForm";
import TeacherSelectForm from "../../molecules/TeacherSelectForm";
import BoxSelectForm from "../../molecules/BoxSelectForm";
import { IClass, IRealization, IStudentGearBox, ITeacher } from "../../state";
import { objectArrayToKeyArray } from "../../libs/Util";

import styles from "./TargetGroup.module.scss";

export enum ETargetSwitch {
  ONLY_ME = "自分のみ",
  GROUP = "クラス・グループ",
  TEACHER = "先生に提出",
  BOX = "BOXに提出",
}

export interface TargetGroupProps {
  realization?: IRealization;
  teachers: ITeacher[];
  classes: IClass[];
  gearboxes: IStudentGearBox[];
  showShareForm: boolean;
  showSubmitForm: boolean;
  showBoxForm: boolean;
  targetSwitch: ETargetSwitch;
  selected_box_id: number | null;
  selected_class_ids: number[];
  selected_teacher_ids: number[];
  stock_c_to_c_enabled?: boolean;
  setBoxTemplate?: (text: string) => void;
  update: (args: { [key: string]: any }) => void;
}

const TargetGroup: React.FC<TargetGroupProps> = ({
  realization,
  teachers,
  classes,
  gearboxes,
  showShareForm,
  showSubmitForm,
  showBoxForm,
  targetSwitch,
  selected_box_id,
  selected_class_ids,
  selected_teacher_ids,
  stock_c_to_c_enabled,
  setBoxTemplate,
  update,
}) => {
  const defaultTargetSwitch = realization
    ? realization.gearbox?.id
      ? ETargetSwitch.BOX
      : realization.shared_classes?.length
      ? ETargetSwitch.GROUP
      : realization.submitted_teachers?.length
      ? ETargetSwitch.TEACHER
      : ETargetSwitch.ONLY_ME
    : ETargetSwitch.ONLY_ME;

  const radioName = React.useMemo(
    () =>
      styles.targetSwitchItem +
      (realization?.id ?? 0) +
      window.location.pathname.replace(/\//g, ""),
    [realization],
  );

  const renderTargetSelection = () => {
    const Components = {
      [ETargetSwitch.ONLY_ME]: <div className={styles.emptyItem} />,
      [ETargetSwitch.GROUP]: (
        <ClassSelectForm
          isOpen={showShareForm}
          classes={classes}
          selected_class_ids={selected_class_ids}
          multiple
          onClick={() => {
            update({
              showCategoryForm: false,
              showSubmitForm: false,
              showBoxForm: false,
              showShareForm: !showShareForm,
            });
          }}
          update={update}
          isSecond={true}
          stock_c_to_c_enabled={stock_c_to_c_enabled}
        />
      ),
      [ETargetSwitch.TEACHER]: (
        <TeacherSelectForm
          isOpen={showSubmitForm}
          teachers={teachers}
          submitted_teachers={realization?.submitted_teachers}
          selected_teacher_ids={selected_teacher_ids}
          update={update}
          isSecond={true}
        />
      ),
      [ETargetSwitch.BOX]: (
        <BoxSelectForm
          isOpen={showBoxForm}
          gearBoxes={gearboxes}
          selected_box_id={selected_box_id}
          update={update}
          isSecond={true}
          setBoxTemplate={setBoxTemplate}
          stock_c_to_c_enabled={stock_c_to_c_enabled}
        />
      ),
    };
    return Components[targetSwitch];
  };

  const handleTargetSwitchChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      let updateParams: {
        showCategoryForm: boolean;
        showSubmitForm: boolean;
        showBoxForm: boolean;
        showShareForm: boolean;
        selected_box_id: number | null;
        selected_teacher_ids: number[];
        selected_class_ids: number[];
        targetSwitch: ETargetSwitch;
      } = {
        showCategoryForm: false,
        showSubmitForm: value === ETargetSwitch.TEACHER,
        showBoxForm: value === ETargetSwitch.BOX,
        showShareForm: value === ETargetSwitch.GROUP,
        selected_box_id: null,
        selected_teacher_ids: [],
        selected_class_ids: [],
        targetSwitch: value as ETargetSwitch,
      };
      if (realization && defaultTargetSwitch === value) {
        if (value === ETargetSwitch.BOX && realization.gearbox?.id) {
          updateParams = {
            ...updateParams,
            selected_box_id: realization.gearbox.id,
          };
        }
        if (
          value === ETargetSwitch.GROUP &&
          realization.shared_classes?.length
        ) {
          updateParams = {
            ...updateParams,
            selected_class_ids: objectArrayToKeyArray(
              realization.shared_classes,
            ),
          };
        }
        if (
          value === ETargetSwitch.TEACHER &&
          realization.submitted_teachers?.length
        ) {
          updateParams = {
            ...updateParams,
            selected_teacher_ids: objectArrayToKeyArray(
              realization.submitted_teachers,
            ),
          };
        }
      }
      update(updateParams);
    },
    [defaultTargetSwitch, realization, update],
  );

  return (
    <>
      <div className={styles.wrapper}>
        <IonLabel color="light" className={styles.label}>
          共有範囲
        </IonLabel>
        <div className={styles.targetSwitch}>
          <div className={styles.targetSwitchItem}>
            <input
              type="radio"
              name={radioName}
              id={radioName + "0"}
              checked={targetSwitch === ETargetSwitch.ONLY_ME}
              value={ETargetSwitch.ONLY_ME}
              onChange={handleTargetSwitchChange}
            />
            <label htmlFor={radioName + "0"}>{ETargetSwitch.ONLY_ME}</label>
          </div>
          <div className={styles.targetSwitchItem}>
            <input
              type="radio"
              name={radioName}
              id={radioName + "1"}
              checked={targetSwitch === ETargetSwitch.GROUP}
              value={ETargetSwitch.GROUP}
              onChange={handleTargetSwitchChange}
            />
            <label htmlFor={radioName + "1"}>{ETargetSwitch.GROUP}</label>
          </div>
          <div className={styles.targetSwitchItem}>
            <input
              type="radio"
              name={radioName}
              id={radioName + "2"}
              checked={targetSwitch === ETargetSwitch.TEACHER}
              value={ETargetSwitch.TEACHER}
              onChange={handleTargetSwitchChange}
            />
            <label htmlFor={radioName + "2"}>{ETargetSwitch.TEACHER}</label>
          </div>
          {gearboxes.length > 0 && (
            <div className={styles.targetSwitchItem}>
              <input
                type="radio"
                name={radioName}
                id={radioName + "3"}
                checked={targetSwitch === ETargetSwitch.BOX}
                value={ETargetSwitch.BOX}
                onChange={handleTargetSwitchChange}
              />
              <label htmlFor={radioName + "3"}>{ETargetSwitch.BOX}</label>
            </div>
          )}
        </div>
      </div>
      <div className={styles.targetSelectWrapper}>
        {renderTargetSelection()}
      </div>
    </>
  );
};
export default TargetGroup;
