import React from "react";

export interface LineChartOption {
  yAxisNum?: number;
  width?: number;
  height?: number;
  fillColor?: string;
  strokeWidth?: number;
  yAxisTextColor?: string;
  yAxisColor?: string;
  yAxisFontSize?: number;
  xAxisFontSize?: number;
  xAxisTextColor?: string;
  yAxisWidth?: number;
  yAxisMarginTop?: number;
  yAxisMarginBottom?: number;
  backgroundColor?: string;
}

const initialData: Required<LineChartOption> = {
  yAxisNum: 6,
  width: 300,
  height: 240,
  strokeWidth: 2,
  fillColor: "#099",
  yAxisTextColor: "#ccc",
  yAxisColor: "#888",
  yAxisFontSize: 10,
  xAxisFontSize: 10,
  xAxisTextColor: "#888",
  yAxisWidth: 20,
  yAxisMarginTop: 20,
  yAxisMarginBottom: 20,
  backgroundColor: "#131b24",
};

export interface LineChartProps {
  dataSet: {
    x: string[];
    elements: { name: string; color: string; data: number[] }[];
  };
  options: LineChartOption;
}

function calcMax(data: number[], yAxisNum: number) {
  const max = Math.max.apply(null, data);
  const mod = max % yAxisNum;
  if (mod === 0) {
    return max;
  }
  return max + (yAxisNum - mod);
}

const LineChart = (props: LineChartProps) => {
  if (props.dataSet.x.length === 0) {
    return null;
  }
  const options = { ...initialData, ...props.options };
  const max =
    calcMax(props.dataSet.elements.map(d => d.data).flat(), options.yAxisNum) ||
    1;
  const yHeight = (options.height - options.yAxisMarginTop * 2) / max;
  const xWidth = (options.width - options.yAxisWidth) / props.dataSet.x.length;
  const xGapValue =
    (options.width - options.yAxisWidth) / props.dataSet.x.map(d => d).length;
  const yGapValue =
    (options.height - options.yAxisMarginTop - options.yAxisMarginBottom) /
    options.yAxisNum;
  return (
    <div>
      <svg
        version="1.1"
        width={options.width}
        height={options.height}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x={0}
          y={0}
          width={options.width}
          height={options.height}
          fill={options.backgroundColor}
        />
        {[...Array(options.yAxisNum + 1)].map((_, idx) => (
          <React.Fragment key={idx}>
            <line
              stroke={options.yAxisColor}
              strokeWidth={0.6}
              x1={0}
              x2={options.width}
              y1={Math.floor(
                options.height - idx * yGapValue - options.yAxisMarginBottom,
              )}
              y2={Math.floor(
                options.height - idx * yGapValue - options.yAxisMarginBottom,
              )}
            />
            <text
              fill={options.yAxisTextColor}
              x={10}
              y={
                options.height - idx * yGapValue - 6 - options.yAxisMarginBottom
              }
              fontSize={options.yAxisFontSize}
            >
              {Math.round(max / options.yAxisNum) * idx}
            </text>
          </React.Fragment>
        ))}
        {props.dataSet.elements.map(e => {
          const { data, name, color } = e;
          return (
            <path
              key={name}
              strokeWidth={options.strokeWidth}
              stroke={color}
              fill={"none"}
              d={data.reduce((accumlator, currentValue, idx) => {
                return (
                  accumlator +
                  `L ${Math.ceil(
                    options.yAxisWidth + idx * xWidth,
                  )} ${Math.ceil(
                    options.height -
                      options.yAxisMarginTop -
                      currentValue * yHeight,
                  )} `
                );
              }, ` M  ${Math.ceil(options.yAxisWidth)}  ${Math.ceil(options.height - options.yAxisMarginTop - data[0] * yHeight)} `)}
            />
          );
        })}
        {props.dataSet.x
          .map(d => d)
          .map((title, idx) => (
            <text
              key={idx}
              fill={options.xAxisTextColor}
              x={idx * xGapValue}
              y={options.height}
              fontSize={options.xAxisFontSize}
            >
              {title}
            </text>
          ))}
      </svg>
    </div>
  );
};

export default LineChart;
