import { IonContent, IonPage } from "@ionic/react";
import * as React from "react";
import { useHistory } from "react-router-dom";

import {
  Context,
  UPDATE_STUDENT_PASSWORD_SUCCESS_MESSAGE,
  clearSuccessMessage,
  updateStudentPassword,
  homePath,
} from "../../../store/student";
import ResetPasswordForm from "../../../templates/ResetPasswordForm";
import styles from "../common.module.scss";
import useWaitApiCall from "../../../hooks/useWaitApiCall";

const UpdateStudentPassword = (props: {
  match: { params: { uuid: string } };
}) => {
  const { contextState, dispatch } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const history = useHistory();

  React.useEffect(() => {
    if (
      contextState.success_message === UPDATE_STUDENT_PASSWORD_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      history.push(homePath);
    }
  }, [history, dispatch, contextState.success_message]);

  const updateStudentPasswordFunc = React.useCallback(
    (password: string) => {
      dispatch(peep(updateStudentPassword(props.match.params.uuid, password)));
    },
    [dispatch, props.match.params.uuid, peep],
  );

  return (
    <IonPage>
      <IonContent className={styles.subWrapper}>
        <ResetPasswordForm
          error={contextState.error}
          calling={calling}
          updatePassword={updateStudentPasswordFunc}
        />
      </IonContent>
    </IonPage>
  );
};

export default UpdateStudentPassword;
