import { IonCheckbox, IonLabel } from "@ionic/react";
import React, { useEffect } from "react";

import styles from "./ColorPicker.module.scss";

export interface ColorPickerProps {
  colorValue?: string;
  update: (args: { [key: string]: any }) => void;
}

const ColorPicker = (props: ColorPickerProps) => {
  const colors = [
    "#0216FF",
    "#ABF4E6",
    "#12FFFF",
    "#B869FF",
    "#8494fc",
    "#000071",
    "#17BEB5",
    "#D2FFFF",
    "#5D37FF",
    "#178AFF",
    "#5DFF9F",
    "#212CB5",
  ];
  const [state, setState] = React.useState({
    selectedColor: "",
  });

  useEffect(() => {
    if (props.colorValue) {
      setState({ selectedColor: props.colorValue });
    }
  }, [props.colorValue]);

  return (
    <>
      <IonLabel className={styles.label} color="light">
        色を選択
      </IonLabel>
      <div className={styles.checkboxWrapper}>
        {colors.map(color => {
          const colorStyle = {
            "--background-checked": color,
            "--background": color,
            "--border-color": color,
            "--border-color-checked": color,
          };
          return (
            <IonCheckbox
              key={color}
              value={color}
              checked={state.selectedColor === color}
              className={styles.checkbox}
              style={colorStyle}
              onIonChange={e => {
                if (e.detail.checked) {
                  setState({ ...state, selectedColor: e.detail.value });
                  props.update({ color });
                }
              }}
            />
          );
        })}
      </div>
    </>
  );
};

export default ColorPicker;
