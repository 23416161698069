import {
  IonCol,
  IonGrid,
  IonItem,
  IonItemSliding,
  IonList,
  IonRow,
} from "@ionic/react";
import React from "react";

import { INotification } from "../../state";
import { convertLink, simpleDateTimeStr } from "../../libs/Util";

import styles from "./NotificationList.module.scss";

export interface NotificationListProps {
  notifications: INotification[];
}

const NotificationList = (props: NotificationListProps) => {
  return (
    <div className={styles.wrapper}>
      <IonList className={styles.wrapper}>
        {props.notifications &&
          props.notifications.map(notification => (
            <IonItemSliding key={notification.id}>
              <IonItem className={styles.item}>
                <IonGrid className={styles.grid}>
                  <IonRow>
                    <IonCol size="12">
                      <div
                        className={styles.content}
                        dangerouslySetInnerHTML={{
                          __html: convertLink(notification.content),
                        }}
                      />
                    </IonCol>
                    <IonCol size={"12"}>
                      <div className={styles.date}>
                        {simpleDateTimeStr(notification.created_at)}
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
            </IonItemSliding>
          ))}
      </IonList>
    </div>
  );
};

export default NotificationList;
