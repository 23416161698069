import React from "react";

import styles from "./Error503Page.module.scss";

export const Error503Page = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1>サービスが一時的に利用できません</h1>
        <p>このページが表示される場合は、以下の原因が考えられます。</p>
        <ul>
          <li>システムメンテナンス中</li>
          <li>アクセスが集中し混み合っている</li>
          <li>システム障害が発生している、など</li>
        </ul>
        <p>
          ご利用の皆様にはご迷惑をおかけし、申し訳ございません。しばらく時間をおいてから再度アクセスしてください。
        </p>
        <a href={window.location.href}>戻る</a>
      </div>
    </div>
  );
};

export default Error503Page;
