import React, { useRef } from "react";
import { useIonViewWillLeave } from "@ionic/react";

import {
  IComment,
  IRealization,
  IStudentGearBox,
  IPagyInfo,
  IRealizationImage,
  ITeacher,
  ICategory,
  IClass,
  IRealizationCreateParams,
  ReactableType,
} from "../../state";
import BoxItem from "../../atoms/BoxItem";
import RealizationItem from "../../molecules/RealizationItem";
import TimelineEntryDetailModal from "../../organisms/TimelineEntryDetailModal";
import useIntersection from "../../hooks/useIntersection";
import TemplateModal from "../../organisms/TemplateModal";
import DerivativeRealizationForm from "../../organisms/Forms/DerivativeRealizationForm";
import type { Stamp } from "../../libs/stamp";

import styles from "./TimelineBoxDetail.module.scss";

export interface TimelineBoxDetailProps {
  isDisplaying: boolean;
  calling: boolean;
  current_id: number;
  realizations: IRealization[];
  comments: IComment[];
  pagy_info: IPagyInfo;
  searchText: string;
  gearbox: IStudentGearBox | null;
  pathname: string;
  categories: ICategory[];
  classes: IClass[];
  success_message?: string;
  teachers: ITeacher[];
  gearBoxes: IStudentGearBox[];
  student_id: number;
  loadRealizations: (page: number, searchText: string) => void;
  addFavorite?: (id: number) => void;
  removeFavorite?: (id: number) => void;
  createReport: (params: {
    realization_id?: number;
    comment_id?: number;
  }) => void;
  createComment: (realization_id: number, content: string) => void;
  updateComment: (realization_id: number, content: string) => void;
  deleteComment: (comment_id: number) => void;
  fetchComments: (realization_id: number) => void;
  clearOldComments: () => void;
  createRealization: (
    realization: IRealizationCreateParams,
    submit_teacher_ids: number[],
    share_class_ids: number[],
    gearbox_id: number | null,
    image?: IRealizationImage,
  ) => void;
  createCategory: (category: { name: string; color: string }) => void;
  createStampReaction: (
    realization_id: number,
    type: ReactableType,
    stamp: Stamp,
  ) => void;
}

const TimelineBoxDetail: React.FC<TimelineBoxDetailProps> = ({
  isDisplaying,
  calling,
  realizations,
  current_id,
  comments,
  pagy_info,
  searchText,
  gearbox,
  pathname,
  success_message,
  teachers,
  categories,
  classes,
  gearBoxes,
  student_id,
  loadRealizations,
  addFavorite,
  removeFavorite,
  createReport,
  createComment,
  updateComment,
  deleteComment,
  fetchComments,
  clearOldComments,
  createRealization,
  createCategory,
  createStampReaction,
}) => {
  const [state, setState] = React.useState({
    selectedRealizationID: 0,
  });
  const [showRealizationDerivativeModal, setShowRealizationDerivativeModal] =
    React.useState(false);

  const loadMoreRef = useRef<HTMLDivElement>(null);

  const selectedRealization = React.useMemo(
    () => realizations.find(r => r.id === state.selectedRealizationID),
    [realizations, state.selectedRealizationID],
  );

  useIonViewWillLeave(() => {
    setState(prevState => ({ ...prevState, selectedRealizationID: 0 }));
    setShowRealizationDerivativeModal(false);
  });

  useIntersection({
    target: loadMoreRef,
    enabled:
      isDisplaying &&
      !calling &&
      !!realizations.length &&
      pagy_info.page < pagy_info.pages,
    onIntersect: () => loadRealizations(pagy_info.page + 1, searchText),
  });

  return (
    <div className={styles.wrapper}>
      {showRealizationDerivativeModal && selectedRealization && (
        <TemplateModal
          isOpen={showRealizationDerivativeModal}
          onClose={() => {
            setState(prevState => ({ ...prevState, selectedRealizationID: 0 }));
            setShowRealizationDerivativeModal(false);
          }}
          headerTitle="ストック"
        >
          <DerivativeRealizationForm
            calling={!!calling}
            createRealization={createRealization}
            createCategory={createCategory}
            originRealization={{
              id: selectedRealization.id,
              content: selectedRealization.content,
              // @ts-ignore TODO IRealization と IOriginalRealization で型が違うので検証のうえ合わせてください
              created_at: selectedRealization.created_at,
              kind: selectedRealization.kind,
              executed_at: selectedRealization.executed_at || null,
              student: selectedRealization.student,
            }}
            pathname={pathname || ""}
            success_message={success_message || ""}
            teachers={teachers || []}
            categories={categories}
            classes={classes}
            gearBoxes={gearBoxes || []}
          />
        </TemplateModal>
      )}
      {selectedRealization && !showRealizationDerivativeModal && (
        <TimelineEntryDetailModal
          current_id={current_id}
          realization={selectedRealization}
          isOtherStudent={student_id !== selectedRealization.student_id}
          comments={comments}
          showDetail={!!selectedRealization}
          onClose={() => setState(s => ({ ...s, selectedRealizationID: 0 }))}
          deleteComment={deleteComment}
          createReport={createReport}
          addFavorite={addFavorite}
          removeFavorite={removeFavorite}
          createComment={createComment}
          updateComment={updateComment}
          clearOldComments={clearOldComments}
          addDerivative={() => {
            setShowRealizationDerivativeModal(true);
          }}
          createStampReaction={createStampReaction}
        />
      )}
      <BoxItem
        className={styles.boxInfo}
        color="dark"
        realizationsCount={gearbox?.realizations_count ?? 0}
        isArchived={!!gearbox?.is_archived}
        nameStatus={gearbox?.name_status ?? "unpublish"}
        title={gearbox?.title ?? "ー"}
        boxCreatorName={gearbox?.teacher.full_name ?? "ー"}
      />
      {realizations.map(realization => (
        <RealizationItem
          key={realization.id}
          calling={calling}
          realization={realization}
          searchText={searchText}
          isOtherStudent={student_id !== realization.student_id}
          onClick={() => {
            setState(s => ({ ...s, selectedRealizationID: realization.id }));
            fetchComments(realization.id);
          }}
          addFavorite={addFavorite}
          removeFavorite={removeFavorite}
          addDerivative={() => {
            setState(s => ({ ...s, selectedRealizationID: realization.id }));
            setShowRealizationDerivativeModal(true);
          }}
        />
      ))}
      <div className={styles.loadMore} ref={loadMoreRef}>
        {calling ? "読み込み中" : ""}
      </div>
    </div>
  );
};

export default TimelineBoxDetail;
