import { IonContent, IonPage } from "@ionic/react";
import React from "react";

import styles from "../common.module.scss";
import { IFile } from "../../../state";
import {
  clearSuccessMessage,
  clearErrorMessage,
  Context,
  fetchInitialData,
  timelinePath,
  updatePhoto,
  UPDATE_PHOTO_SUCCESS_MESSAGE,
  UPDATE_PHOTO_FAILURE_MESSAGE,
} from "../../../store/student";
import StudentProfileForm from "../../../templates/StudentProfileForm";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import LoginHeader from "../../../organisms/LoginHeader";

const EditProfile = (prop: { history: any }) => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);

  const updatePhotoFunc = React.useCallback(
    (photo: IFile) => dispatch(peep(updatePhoto(photo))),
    [dispatch, peep],
  );

  React.useEffect(() => {
    if (contextState.success_message === UPDATE_PHOTO_SUCCESS_MESSAGE) {
      dispatch(clearSuccessMessage());
      dispatch(fetchInitialData());
      prop.history.push(timelinePath);
    }
    if (contextState.error === UPDATE_PHOTO_FAILURE_MESSAGE) {
      dispatch(clearErrorMessage());
    }
  }, [
    prop.history,
    dispatch,
    contextState.success_message,
    contextState.error,
  ]);

  const cancelFunc = React.useCallback(
    () => prop.history.push(timelinePath),
    [prop.history],
  );

  return (
    <IonPage>
      <LoginHeader
        title="プロフィール編集"
        unreadNotiCount={
          contextState.unread_comments_count +
          contextState.unread_notification_count +
          (contextState.student.read_gear_release_note ? 0 : 1)
        }
      />
      <IonContent className={styles.wrapper}>
        {contextState.student.id ? (
          <div className={styles.container}>
            <StudentProfileForm
              calling={calling}
              {...contextState.student}
              cancel={cancelFunc}
              school_name={contextState.school_name}
              class_name={contextState.class_name}
              updatePhoto={updatePhotoFunc}
              error={contextState.error}
            />
          </div>
        ) : null}
      </IonContent>
    </IonPage>
  );
};

export default EditProfile;
