import React from "react";
import clsx from "clsx";

import styles from "./RealizationTypeSelector.module.scss";

export interface RealizationTypeSelectorProps {
  type?: string;
  changeFilterList: (type?: "roots" | "will" | "exp") => void;
}

const RealizationTypeSelector = (props: RealizationTypeSelectorProps) => {
  return (
    <div className={styles.wrapper}>
      <button
        className={clsx(!props.type && styles.active)}
        onClick={() => props.changeFilterList()}
      >
        All
      </button>
      <button
        className={clsx(props.type === "roots" && styles.active)}
        onClick={() => props.changeFilterList("roots")}
      >
        ROOTS
      </button>
      <button
        className={clsx(props.type === "will" && styles.active)}
        onClick={() => props.changeFilterList("will")}
      >
        WILL
      </button>
      <button
        className={clsx(props.type === "exp" && styles.active)}
        onClick={() => props.changeFilterList("exp")}
      >
        EXP
      </button>
    </div>
  );
};

export default RealizationTypeSelector;
