import React from "react";
import clsx from "clsx";
import { IonPage, IonContent } from "@ionic/react";

import {
  Context,
  fetchRealizationFeedbacks,
  bulkUpdateReadAtToRealizationFeedbacks,
} from "../../../store/student";
import PastRealizationFeedbackList from "../../../templates/PastRealizationFeedbackList";
import LoginHeader from "../../../organisms/LoginHeader";
import common from "../common.module.scss";

import styles from "./PastRealizationFeedbackPage.module.scss";

const PastRealizationFeedbackPage = () => {
  const { dispatch } = React.useContext(Context);

  React.useEffect(() => {
    dispatch(fetchRealizationFeedbacks());
    dispatch(bulkUpdateReadAtToRealizationFeedbacks());
  }, [dispatch]);

  return (
    <IonPage>
      <LoginHeader title="過去のフィードバック" unreadNotiCount={0} />
      <IonContent className={common.wrapper}>
        <div className={clsx(common.container, styles.container)}>
          <PastRealizationFeedbackList />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PastRealizationFeedbackPage;
