import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router-dom";

import { parseQueryParams } from "../../../libs/Util";
import { login, updateStudentPasswordPath } from "../../../store/student";
import { Context } from "../../../store/student";
import LoginForm from "../../../templates/LoginForm";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import styles from "../common.module.scss";

const Login = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const history = useHistory();
  const [calling, peep] = useWaitApiCall(contextState);
  const loginFunc = React.useCallback(
    (identity: string, password: string) => {
      dispatch(peep(login(identity, password)));
    },
    [dispatch, peep],
  );
  React.useEffect(() => {
    if (contextState.uuid) {
      history.push(`${updateStudentPasswordPath}/${contextState.uuid}`);
    }
  }, [history, contextState.uuid]);

  const [error, setError] = React.useState(
    parseQueryParams(window.location.href)["message"],
  );
  React.useEffect(() => {
    if (contextState.error) {
      setError(contextState.error);
    }
  }, [contextState.error]);

  return (
    <IonPage>
      <IonContent className={styles.subWrapper}>
        <LoginForm
          calling={calling}
          login={loginFunc}
          error={error}
          clearError={() => setError("")}
        />
      </IonContent>
    </IonPage>
  );
};

export default Login;
