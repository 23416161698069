import React from "react";

import styles from "./WordCounter.module.scss";

export interface WordCounterProps {
  maxLength: number;
  targetLength: number;
  threshold?: number;
}

const WordCounter = (props: WordCounterProps) => {
  const threshold = props.threshold || 50;

  return (
    <div
      className={`${styles.counter} ${
        props.maxLength - props.targetLength <= threshold && styles.warning
      }`}
    >
      残り{props.maxLength - props.targetLength}文字
    </div>
  );
};

export default WordCounter;
