import React, { SVGProps } from "react";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";

import styles from "./Tabs.module.scss";

export type Props = {
  className?: string;
  listTabsTitle: {
    title: string;
    icon?: SVGProps<SVGSVGElement>;
    id: string;
    link?: string;
    hide?: boolean;
  }[];
};

export const Tabs: React.FC<Props> = ({
  children,
  listTabsTitle,
  className,
}) => {
  const history = useHistory();
  const tabContents = React.useRef<HTMLDivElement>(null);
  const tabLinks = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!tabContents.current) return;
    const tabContent = tabContents.current.querySelectorAll(".tab-content");
    Array.from(tabContent as any as HTMLCollectionOf<HTMLElement>).forEach(
      tab => {
        tab.style.display = tab.id === listTabsTitle[0].id ? "initial" : "none";
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickTab = (id: string) => {
    if (!tabContents.current || !tabLinks.current) return;
    const tabContent = tabContents.current.querySelectorAll(".tab-content");
    Array.from(tabContent as any as HTMLCollectionOf<HTMLElement>).forEach(
      tab => {
        tab.style.display = tab.id === id ? "initial" : "none";
      },
    );
    const tabTitle = tabLinks.current.querySelectorAll(".tab-title");
    tabTitle.forEach(tab => {
      tab.classList.remove("active");
    });
    const currentTab = tabLinks.current.querySelector(`#tab-${id}`);
    currentTab && currentTab.classList.add("active");
  };

  return (
    <div className={clsx(styles.tabs, className)}>
      <div className={clsx(styles.tabs__header)} ref={tabLinks}>
        {listTabsTitle.map((tab, index) =>
          tab.link ? (
            <Link
              key={index}
              to={tab.link}
              className={clsx(
                history.location.pathname === tab.link && "active",
                "tab-title",
              )}
              onClick={() => onClickTab(tab.id)}
            >
              <div className={styles.wrapper_title}>
                {tab.icon}
                <p>{tab.title}</p>
              </div>
            </Link>
          ) : (
            <div
              id={`tab-${tab.id}`}
              key={tab.id}
              className={clsx(
                "tab-title",
                tab.id === listTabsTitle[0].id && "active",
              )}
              onClick={() => onClickTab(tab.id)}
            >
              <div className={styles.wrapper_title}>
                {tab.icon}
                <p>{tab.title}</p>
              </div>
            </div>
          ),
        )}
      </div>
      <div className={styles.tabs__content} ref={tabContents}>
        {children}
      </div>
    </div>
  );
};
export default Tabs;
