import React, { useRef } from "react";
import { useIonViewWillLeave } from "@ionic/react";

import RealizationItem from "../../molecules/RealizationItem";
import TimelineEntryDetailModal from "../../organisms/TimelineEntryDetailModal";
import {
  ICategory,
  IClass,
  IComment,
  IRealizationCreateParams,
  IRealizationImage,
  IRealizationKlass,
  IStudentGearBox,
  ITeacher,
  ITimelinePagination,
  ReactableType,
} from "../../state";
import useIntersection from "../../hooks/useIntersection";
import TemplateModal from "../../organisms/TemplateModal";
import DerivativeRealizationForm from "../../organisms/Forms/DerivativeRealizationForm";
import type { Stamp } from "../../libs/stamp";

import styles from "./TimelineFiltered.module.scss";

export interface TimelineFilteredProps {
  isDisplaying: boolean;
  current_id: number;
  classes: IClass[];
  realization_klasses: IRealizationKlass[];
  comments: IComment[];
  categories: ICategory[];
  calling?: boolean;
  timeline_pagination: ITimelinePagination;
  pathname?: string;
  success_message?: string;
  teachers?: ITeacher[];
  gearBoxes?: IStudentGearBox[];
  student_id: number;
  addFavorite?: (id: number) => void;
  removeFavorite?: (id: number) => void;
  readMore: () => void;
  clearRealizationKlassesFiltered: () => void;
  createReport: (params: {
    realization_id?: number;
    comment_id?: number;
  }) => void;
  createComment: (realization_id: number, content: string) => void;
  updateComment: (realization_id: number, content: string) => void;
  deleteComment: (comment_id: number) => void;
  fetchComments: (realization_id: number) => void;
  clearOldComments: () => void;
  createStampReaction: (
    realization_id: number,
    type: ReactableType,
    stamp: Stamp,
  ) => void;
  createRealization?: (
    realization: IRealizationCreateParams,
    submit_teacher_ids: number[],
    share_class_ids: number[],
    gearbox_id: number | null,
    image?: IRealizationImage,
  ) => void;
  createCategory?: (category: { name: string; color: string }) => void;
}

const TimelineFiltered = (props: TimelineFilteredProps) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const [selectedRealizationKlassId, setSelectedRealizationKlassId] =
    React.useState(0);
  const [showRealizationDerivativeModal, setShowRealizationDerivativeModal] =
    React.useState(false);

  const selectedRealizationKlass = React.useMemo(
    () =>
      props.realization_klasses.find(t => t.id === selectedRealizationKlassId),
    [props.realization_klasses, selectedRealizationKlassId],
  );

  useIntersection({
    target: loadMoreRef,
    enabled:
      props.isDisplaying &&
      !props.calling &&
      props.timeline_pagination.has_next,
    onIntersect: props.readMore,
  });

  useIonViewWillLeave(() => {
    setSelectedRealizationKlassId(0);
    setShowRealizationDerivativeModal(false);
  });

  return (
    <div className={styles.wrapper}>
      {showRealizationDerivativeModal && selectedRealizationKlass && (
        <TemplateModal
          isOpen={showRealizationDerivativeModal}
          onClose={() => {
            setSelectedRealizationKlassId(0);
            setShowRealizationDerivativeModal(false);
          }}
          headerTitle="ストック"
        >
          <DerivativeRealizationForm
            calling={!!props.calling}
            createRealization={props.createRealization}
            createCategory={props.createCategory}
            originRealization={{
              id: selectedRealizationKlass.realization.id,
              content: selectedRealizationKlass.realization.content,
              // @ts-ignore TODO IRealization と IOriginalRealization で型が違うので検証のうえ合わせてください
              created_at: selectedRealizationKlass.realization.created_at,
              kind: selectedRealizationKlass.realization.kind,
              executed_at:
                selectedRealizationKlass.realization.executed_at || null,
              student: selectedRealizationKlass.realization.student,
            }}
            pathname={props.pathname || ""}
            success_message={props.success_message || ""}
            teachers={props.teachers || []}
            categories={props.categories}
            classes={props.classes}
            gearBoxes={props.gearBoxes || []}
          />
        </TemplateModal>
      )}
      {selectedRealizationKlass && !showRealizationDerivativeModal && (
        <TimelineEntryDetailModal
          current_id={props.current_id}
          realization={{
            ...selectedRealizationKlass.realization,
            is_favorite: selectedRealizationKlass.is_favorite,
            has_comment: selectedRealizationKlass.has_comment,
            student_name: selectedRealizationKlass.student_name,
            comment_count: selectedRealizationKlass.comment_count,
            created_at: selectedRealizationKlass.created_at,
            photo: selectedRealizationKlass.photo,
          }}
          comments={props.comments}
          isOtherStudent={
            props.student_id !== selectedRealizationKlass.realization.student_id
          }
          showDetail={!!selectedRealizationKlass}
          onClose={() => setSelectedRealizationKlassId(0)}
          deleteComment={props.deleteComment}
          createReport={props.createReport}
          addFavorite={props.addFavorite}
          removeFavorite={props.removeFavorite}
          createComment={props.createComment}
          updateComment={props.updateComment}
          clearOldComments={props.clearOldComments}
          addDerivative={() => {
            setShowRealizationDerivativeModal(true);
          }}
          createStampReaction={props.createStampReaction}
        />
      )}
      {props.realization_klasses.map(realization_klass => {
        const {
          realization: { categories },
        } = realization_klass;
        return (
          <RealizationItem
            calling={props.calling}
            key={realization_klass.id}
            realization={{
              ...realization_klass.realization,
              is_favorite: realization_klass.is_favorite,
              has_comment: realization_klass.has_comment,
              categories,
              comment_count: realization_klass.comment_count,
              created_at: realization_klass.created_at,
              student: realization_klass.realization.student,
            }}
            onClick={() => {
              setSelectedRealizationKlassId(realization_klass.id);
              props.fetchComments(realization_klass.realization.id);
            }}
            addFavorite={props.addFavorite}
            removeFavorite={props.removeFavorite}
            isOtherStudent={
              props.student_id !== realization_klass.realization.student_id
            }
            addDerivative={() => {
              setSelectedRealizationKlassId(realization_klass.id);
              setShowRealizationDerivativeModal(true);
            }}
          />
        );
      })}
      {props.realization_klasses.length > 0 && (
        <div className={styles.loadMore} ref={loadMoreRef}>
          {props.calling ? "読み込み中" : ""}
        </div>
      )}
    </div>
  );
};

export default TimelineFiltered;
