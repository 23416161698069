import React from "react";
import { IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";

import { Stamp } from "../../libs/stamp";

import commonStyles from "./StampSelector.module.scss";

type Props = {
  rows: Stamp[];
  onSelect: (id: number) => void;
};

export default function FlatLayout(props: Props) {
  return (
    <IonGrid>
      <IonRow>
        {props.rows.map((stamp: Stamp, i: number) => (
          <IonCol key={i} size="3">
            <div className={commonStyles.stampContainer}>
              <IonImg
                src={stamp.image}
                onClick={() => props.onSelect(stamp.id)}
              />
            </div>
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
}
