import React from "react";
import clsx from "clsx";

import styles from "./AssessmentButton.module.scss";

export interface AssessmentButtonProps {
  saveText: string;
  handleBackToAssessmentList: () => void;
  handleSaveAssessment: () => void;
}

const AssessmentButton: React.FC<AssessmentButtonProps> = ({
  saveText,
  handleBackToAssessmentList,
  handleSaveAssessment,
}) => {
  return (
    <>
      <button
        className={clsx(styles.button, styles.return)}
        onClick={handleBackToAssessmentList}
      >
        素養一覧に戻る
      </button>
      <button
        className={clsx(styles.button, styles.save)}
        onClick={handleSaveAssessment}
      >
        {saveText}
      </button>
    </>
  );
};

export default AssessmentButton;
