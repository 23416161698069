import React from "react";
import { IonList } from "@ionic/react";
import { useHistory } from "react-router-dom";

import { IStudentGearBox } from "../../state";
import { boxesListPath } from "../../store/student";
import BoxItem from "../../atoms/BoxItem";

import styles from "./TimelineBoxList.module.scss";

export interface TimelineBoxListProps {
  gearBoxes: IStudentGearBox[];
  searchText: string;
}

const TimelineBoxList: React.FC<TimelineBoxListProps> = ({
  gearBoxes,
  searchText,
}) => {
  const history = useHistory();
  return (
    <div className={styles.wrapper}>
      <IonList>
        {gearBoxes.map(b => (
          <BoxItem
            key={b.id}
            color="dark"
            title={b.title}
            realizationsCount={b.realizations_count ?? 0}
            isArchived={b.is_archived}
            nameStatus={b.name_status}
            boxCreatorName={b.teacher.full_name}
            searchText={searchText || ""}
            newestRealization={b.last_realization}
            redirectTo={() => history.push(`${boxesListPath}/${b.id}`)}
          />
        ))}
      </IonList>
    </div>
  );
};

export default TimelineBoxList;
