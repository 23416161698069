import { IonButton, IonIcon, IonInput, IonLabel, IonText } from "@ionic/react";
import React from "react";
import {
  eyeOutline as eyesIcon,
  eyeOffOutline as eyesOffIcon,
} from "ionicons/icons";

import { API_URL } from "../../store/student";
import Toast from "../../molecules/Toast";

import styles from "./LoginForm.module.scss";

export interface LoginFormProps {
  calling: boolean;
  error: string;
  login: (identity: string, password: string) => void;
  clearError: () => void;
}
const validates: { [key: string]: (value: any) => string } = {};

validates["identity"] = val => {
  return val.identity.length < 6 || val.identity.length > 32
    ? "IDは6文字以上32文字以内で入力してください。"
    : "";
};

validates["password"] = val => {
  return val.password.length < 6 || val.password.length > 32
    ? "パスワードは6文字以上32文字以内で入力してください。"
    : "";
};

export const LoginForm = (props: LoginFormProps) => {
  const [state, setState] = React.useState({
    identity: "",
    password: "",
    isSubmitted: false,
    error: props.error,
    errorMessages: {} as { [key: string]: string },
  });
  const [isShowPassword, setIsShowPassword] = React.useState(false);

  React.useEffect(() => {
    setState(s => ({ ...s, error: props.error }));
  }, [props.error]);

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const errorMessages = {} as { [key: string]: string };
      Object.keys(validates).forEach(key => {
        const ret = validates[key](state);
        if (ret) {
          errorMessages[key] = ret;
        }
      });
      if (Object.keys(errorMessages).length > 0) {
        setState(s => ({ ...s, errorMessages }));
        return;
      }
      props.login(state.identity, state.password);
    },
    [state, props],
  );

  const onChange = React.useCallback((ev: any) => {
    const e = ev as React.SyntheticEvent<HTMLInputElement>;
    e.preventDefault();
    const val = e.currentTarget.value;
    const key = e.currentTarget.name;
    const args = { [key]: val };
    const ret = validates[key](args);
    setState(prevState => ({
      ...prevState,
      [key]: val,
      errorMessages: { ...prevState.errorMessages, [key]: ret },
    }));
  }, []);

  const clearError = React.useCallback(() => {
    setState({ ...state, error: "" });
    props.clearError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className={styles.wrapper}>
      <Toast
        type="danger"
        showToast={!!state.error}
        onClose={clearError}
        message={state.error}
      />
      <div className={styles.topTitle}>
        <IonText>
          <h3>GEAR</h3>
        </IonText>
      </div>
      <div className={styles.inputArea}>
        <IonLabel color="light">ID</IonLabel>
        <IonInput
          name="identity"
          onIonChange={onChange}
          className={styles.input}
        />
        <div className={styles.invalidFeedback}>
          {state.errorMessages["identity"]}
        </div>
        <IonLabel color="light">パスワード</IonLabel>
        <IonInput
          type={isShowPassword ? "text" : "password"}
          name="password"
          onIonChange={onChange}
          className={styles.input}
        />
        <IonIcon
          className={styles.eyesIcon}
          icon={isShowPassword ? eyesIcon : eyesOffIcon}
          onClick={() => {
            setIsShowPassword(!isShowPassword);
          }}
        />
        <div className={styles.invalidFeedback}>
          {state.errorMessages["password"]}
        </div>
      </div>
      <IonButton
        className={styles.loginButton}
        expand="block"
        onClick={onSubmit}
        disabled={props.calling}
      >
        ログイン
      </IonButton>
      <div className={styles.text}>
        <IonLabel color="light">
          パスワードを忘れた方は先生に聞いてください
        </IonLabel>
      </div>
      <div className={styles.btnArea}>
        <button
          className={styles.googleBtn}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            window.location.href = `${API_URL?.replace(
              "/api/v1/student",
              "",
            )}/auth/google_oauth2?kind=gear_student`;
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path
              fill="#FFC107"
              d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 12.955 4 4 12.955 4 24s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"
            />
            <path
              fill="#FF3D00"
              d="m6.306 14.691 6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 16.318 4 9.656 8.337 6.306 14.691z"
            />
            <path
              fill="#4CAF50"
              d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0 1 24 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44z"
            />
            <path
              fill="#1976D2"
              d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 0 1-4.087 5.571l.003-.002 6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917z"
            />
          </svg>
          <p className={styles.btnTitle}>Google</p>
        </button>
        <button
          className={styles.microsoftBtn}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            window.location.href = `${API_URL?.replace(
              "/api/v1/student",
              "",
            )}/auth/azure_activedirectory_v2?kind=gear_student&prompt=select_account`;
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path fill="#ff5722" d="M22 22H6V6h16z" />
            <path fill="#4caf50" d="M42 22H26V6h16z" />
            <path fill="#ffc107" d="M42 42H26V26h16z" />
            <path fill="#03a9f4" d="M22 42H6V26h16z" />
          </svg>
          <p className={styles.btnTitle}>Microsoft</p>
        </button>
      </div>
    </div>
  );
};

export default LoginForm;
