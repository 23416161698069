import React from "react";
import { IonIcon } from "@ionic/react";
import { caretForwardSharp, checkmarkCircle } from "ionicons/icons";

import RealizationTypeTag from "../../atoms/RealizationTypeTag";
import { IOriginRealization, IRealization } from "../../state";
import { simpleDateTimeStr } from "../../libs/Util";
import derivativeIcon from "../../assets/icons/derivative.svg";

import styles from "./RealizationContentHeader.module.scss";

type Props = {
  realization: IRealization | IOriginRealization;
  isOrigin?: boolean;
  showAuthor?: boolean;
  showChecked?: boolean;
};

const RealizationContentHeader = ({
  realization,
  isOrigin,
  showAuthor,
  showChecked,
}: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        {isOrigin && realization.student && (
          <IonIcon src={derivativeIcon} className={styles.icon} />
        )}
        <RealizationTypeTag
          kind={realization.kind}
          executed={!!realization.executed_at}
          isOrigin={isOrigin}
        />
      </div>
      <div className={styles.date}>
        <span className={styles.text}>
          {simpleDateTimeStr(new Date(realization.created_at))}
        </span>
        {!isOrigin && !!realization.executed_at && (
          <>
            <IonIcon className={styles.arrowIcon} icon={caretForwardSharp} />
            <span className={styles.text}>
              {simpleDateTimeStr(new Date(realization.executed_at))}
            </span>
          </>
        )}
      </div>
      {showChecked && realization.is_checked && (
        <div className={styles.checkedStatus}>
          <IonIcon icon={checkmarkCircle} className={styles.checkIcon} />
          <span>先生確認済</span>
        </div>
      )}
      {isOrigin && realization.student && showAuthor && (
        <span className={styles.text}>{realization.student.full_name}</span>
      )}
    </div>
  );
};

export default RealizationContentHeader;
