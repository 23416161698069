import {
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonRow,
  IonBadge,
} from "@ionic/react";
import React from "react";

import { ICommentNotification } from "../../state";
import { simpleDateTimeStr } from "../../libs/Util";
import { realizationDetailPath } from "../../store/student";

import styles from "./CommentNotifications.module.scss";

export interface CommentNotificationsProps {
  commentNotifications: ICommentNotification[];
}

const CommentNotifications: React.FC<CommentNotificationsProps> = ({
  commentNotifications,
}) => {
  return (
    <div className={styles.wrapper}>
      <IonList className={styles.wrapper}>
        {commentNotifications.length ? (
          commentNotifications.map(
            (
              {
                commentor_name,
                unread_count,
                content,
                created_at,
                realization,
              },
              index,
            ) => (
              <IonItem
                key={index}
                routerLink={`${realizationDetailPath}/${realization.id}`}
                className={styles.item}
              >
                <IonGrid className={styles.grid}>
                  <IonRow>
                    <IonCol size="12">
                      <div className={styles.content}>
                        <span>
                          {commentor_name
                            ? `${commentor_name} さんがコメントしました`
                            : "コメントが追加されました"}
                        </span>
                        {unread_count > 1 && (
                          <IonBadge color="danger">
                            他{unread_count - 1}件
                          </IonBadge>
                        )}
                      </div>
                    </IonCol>
                    <IonCol size="12">
                      <div className={styles.footer}>
                        <span>{content}</span>
                        <span>{simpleDateTimeStr(created_at)}</span>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
            ),
          )
        ) : (
          <p className={styles.emptyData}>コメント通知はありません。</p>
        )}
      </IonList>
    </div>
  );
};

export default CommentNotifications;
