import React from "react";
import ReactDOM from "react-dom";
import { datadogRum } from "@datadog/browser-rum";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "@ionic/react/css/core.css";
import "@ionic/react/css/padding.css";

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID!,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN!,
  site: "datadoghq.com",
  service: "gear4s",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
