import React from "react";
import { IonButton, IonIcon, IonPopover } from "@ionic/react";

import closeIcon from "../../assets/icons/close.svg";

import styles from "./Popover.module.scss";

type Props = React.ComponentProps<typeof IonPopover> & {
  onClose?: () => void;
};

const Popover = React.forwardRef<HTMLIonPopoverElement, Props>(function (
  props,
  ref,
) {
  const handleClose = () => {
    props.onClose && props.onClose();
  };

  React.useEffect(() => {
    // IonPopover は IonApp 直下に DOM を作成するため、親コンポーネントがアンマウントされると isOpen での制御が効かなくなるので、
    // 直接アトリビュートを操作して消す（他にいい方法があれば置き換えてください。この方法だと IonPopover が複数表示された場合にどのコンポーネントを消すか制御できません）
    return () => {
      document.querySelector("ion-popover")?.setAttribute("is-open", "false");
    };
  }, []);

  return (
    <IonPopover ref={ref} {...props}>
      <div className={styles.closeButton}>
        <IonButton size="small" fill="clear" onClick={handleClose}>
          <IonIcon slot="icon-only" icon={closeIcon} color="medium" />
        </IonButton>
      </div>
      {props.children}
    </IonPopover>
  );
});

export default Popover;
