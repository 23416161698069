import { IonIcon } from "@ionic/react";
import React from "react";
import { chevronForwardOutline as rightArrowIcon } from "ionicons/icons";

import boxIcon from "../../assets/icons/box.svg";
import BoxTitle from "../../atoms/BoxTitle";
import { replaceSearchTextAndUrl } from "../../libs/Convert";
import { TNameStatus } from "../../state";

import styles from "./BoxLabel.module.scss";

export interface BoxLabelProps {
  label: string;
  count?: number;
  isArchived?: boolean;
  nameStatus?: TNameStatus;
  searchText?: string;
  onClick?: () => void;
}

const BoxLabel = (props: BoxLabelProps) => {
  return (
    <div className={styles.wrapper} onClick={props.onClick}>
      <IonIcon
        slot="end"
        color="light"
        icon={boxIcon}
        className={styles.icon}
      />
      <div className={styles.labelArea}>
        <BoxTitle
          isArchived={props.isArchived}
          nameStatus={props.nameStatus}
          title={
            <span
              dangerouslySetInnerHTML={{
                __html: replaceSearchTextAndUrl(
                  props.label,
                  props.searchText || "",
                ),
              }}
            />
          }
        />
        {props.count !== undefined && (
          <span className={styles.count}>[{props.count}]</span>
        )}
      </div>
      <IonIcon
        color="light"
        icon={rightArrowIcon}
        slot="end"
        className={styles.arrowIcon}
      />
    </div>
  );
};

export default BoxLabel;
