import { IonButton, IonInput, IonLabel, IonText } from "@ionic/react";
import React from "react";

import Toast from "../../molecules/Toast";

import styles from "./ResetPasswordForm.module.scss";

export interface ResetPasswordFormFormProps {
  error: string;
  calling: boolean;
  updatePassword: (password: string) => void;
}
const validates: { [key: string]: (value: any) => string } = {};

validates["password"] = val => {
  return val.password.length < 6 || val.password.length > 32
    ? "パスワードは6文字以上32文字以内で入力してください。"
    : "";
};
validates["password_confirm"] = val => {
  return val.password.length >= 6 && val.password_confirm !== val.password
    ? "パスワードと確認用パスワードが一致しません。"
    : "";
};

export const ResetPasswordFormForm = (props: ResetPasswordFormFormProps) => {
  const [state, setState] = React.useState({
    identity: "",
    password: "",
    password_confirm: "",
    isSubmitted: false,
    error: props.error,
    errorMessages: {} as { [key: string]: string },
  });

  React.useEffect(() => {
    setState(s => ({ ...s, error: props.error }));
  }, [props.error]);

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const errorMessages = {} as { [key: string]: string };
      Object.keys(validates).forEach(key => {
        const ret = validates[key](state);
        if (ret) {
          errorMessages[key] = ret;
        }
      });
      if (Object.keys(errorMessages).length > 0) {
        setState(s => ({ ...s, errorMessages }));
        return;
      }
      props.updatePassword(state.password);
    },
    [state, props],
  );

  const onChange = React.useCallback((ev: any) => {
    const e = ev as React.SyntheticEvent<HTMLInputElement>;
    e.preventDefault();
    const val = e.currentTarget.value;
    const key = e.currentTarget.name;
    setState(prevState => ({
      ...prevState,
      [key]: val,
      errorMessages: {
        ...prevState.errorMessages,
        [key]: validates[key]({ ...prevState, [key]: val }),
      },
    }));
  }, []);

  return (
    <div className={styles.wrapper}>
      <Toast
        type="danger"
        showToast={!!state.error}
        onClose={() => setState({ ...state, error: "" })}
        message={state.error}
      />
      <div className={styles.topTitle}>
        <IonText>
          <h3>パスワード設定</h3>
        </IonText>
      </div>
      <div className={styles.inputArea}>
        <IonLabel color="light">パスワード</IonLabel>
        <IonInput
          type="password"
          name="password"
          onIonChange={onChange}
          className={styles.input}
        />
        <div className={styles.invalidFeedback}>
          {state.errorMessages["password"]}
        </div>
        <IonLabel color="light">確認用パスワード</IonLabel>
        <IonInput
          type="password"
          name="password_confirm"
          onIonChange={onChange}
          className={styles.input}
        />
        <div className={styles.invalidFeedback}>
          {state.errorMessages["password_confirm"]}
        </div>
      </div>
      <IonButton
        className={styles.loginButton}
        disabled={props.calling}
        expand="block"
        onClick={onSubmit}
      >
        登録
      </IonButton>
    </div>
  );
};

export default ResetPasswordFormForm;
