import React from "react";

interface BarChartBarProps {
  x: number;
  y: number;
  title: string;
  width: number;
  height: number;
  gap: number;
  color: string;
  textColor: string;
  textPosition: number;
  fontSize: number;
}
const BarChartBar = (props: BarChartBarProps) => {
  const [state, setState] = React.useState(props.x);
  const textRef = React.useRef<SVGTextElement>(null);
  React.useEffect(() => {
    if (textRef.current) {
      const bbox = textRef.current.getBBox();
      const width = bbox.width;
      setState(props.x + (props.width - width) / 2);
    }
  }, [props.x, props.width]);
  return (
    <>
      <rect
        x={props.x + props.gap}
        y={props.y}
        width={props.width - props.gap * 2}
        height={props.height}
        fill={props.color}
      />
      <text
        ref={textRef}
        fill={props.textColor}
        x={state}
        y={props.textPosition}
        fontSize={props.fontSize}
      >
        {props.title}
      </text>
    </>
  );
};
export default BarChartBar;
