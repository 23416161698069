import { IonContent, IonPage, useIonViewWillEnter } from "@ionic/react";
import React from "react";

import styles from "../common.module.scss";
import {
  Context,
  fetchRealizationCategoryBySummary,
  fetchRealizationHistories,
} from "../../../store/student";
import FabButton from "../../../atoms/FabButton";
import AnalyzeChart from "../../../templates/AnalyzeChart";
import LoginHeader from "../../../organisms/LoginHeader";

const AnalyzePage = () => {
  const { dispatch, contextState } = React.useContext(Context);

  useIonViewWillEnter(() => {
    dispatch(fetchRealizationCategoryBySummary());
    dispatch(fetchRealizationHistories());
  });

  return (
    <IonPage>
      <LoginHeader
        title="分析"
        unreadNotiCount={
          contextState.unread_comments_count +
          contextState.unread_notification_count +
          (contextState.student.read_gear_release_note ? 0 : 1)
        }
      />
      <IonContent className={styles.wrapper}>
        <div className={styles.container}>
          <AnalyzeChart
            entrance_year={contextState.student.entrance_year}
            realization_histories={contextState.realization_histories}
            realization_summaries={contextState.realization_summaries}
          />
        </div>
        <FabButton />
      </IonContent>
    </IonPage>
  );
};

export default AnalyzePage;
