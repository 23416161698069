import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  useIonViewWillLeave,
} from "@ionic/react";
import React from "react";

import convert from "../../assets/icons/convert.svg";
import trashIcon from "../../assets/icons/trash.svg";
import editIcon from "../../assets/icons/edit.svg";
import {
  ICategory,
  IClass,
  IComment,
  IFeedback,
  IRealization,
  ITeacher,
  IStudentGearBox,
  IRealizationCreateParams,
  IRealizationImage,
  IRealizationUpdate,
  IStudent,
  ReactableType,
} from "../../state";
import RealizationConversionForm from "../../organisms/Forms/RealizationConversionForm";
import RealizationDeleteForm from "../../organisms/Forms/RealizationDeleteForm";
import CommentFeedbackTabs from "../../organisms/CommentFeedbackTabs";
import TemplateModal from "../../organisms/TemplateModal";
import ImageDetailModal from "../../molecules/ImageDetailModal";
import RealizationEditForm from "../RealizationEditForm";
import {
  CREATE_COMMENT_SUCCESS_MESSAGE,
  CREATE_FEEDBACK_SUCCESS_MESSAGE,
  CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE,
  CREATE_REALIZATION_STAMP_REACTION_SUCCESS,
  UPDATE_REALIZATION_SUCCESS_MESSAGE,
} from "../../store/student";
import RealizationContent from "../../molecules/RealizationContent";
import type { Stamp } from "../../libs/stamp";

import styles from "./RealizationDetail.module.scss";

export interface RealizationDetailProps {
  student: IStudent;
  realization: IRealization;
  categories: ICategory[];
  teachers: ITeacher[];
  classes: IClass[];
  feedbacks: IFeedback[];
  comments: IComment[];
  calling?: boolean;
  pathname: string;
  success_message: string;
  gearBoxes: IStudentGearBox[];
  clearSuccessMessage: () => void;
  createCategory: (category: ICategory) => void;
  createFeedback: (realization_id: number, content: string) => void;
  updateFeedback: (feedback_id: number, content: string) => void;
  deleteFeedback: (feedback_id: number) => void;
  createComment: (realization_id: number, content: string) => void;
  updateComment: (comment_id: number, content: string) => void;
  deleteComment: (comment_id: number) => void;
  createReport: (params: {
    realization_id?: number;
    comment_id?: number;
  }) => void;
  deleteRealization: (realization_id: number) => void;
  updateRealization(
    realization: IRealizationUpdate,
    submit_teacher_ids: number[],
    share_class_ids: number[],
  ): void;
  executeRealization(realization_id: number): void;
  createWillRealization(
    realization: IRealizationCreateParams,
    submit_teacher_ids: number[],
    share_class_ids: number[],
    gearbox_id: number | null,
    image?: IRealizationImage,
  ): void;
  updateReadLastComment: (realization_id: number, comment_id: number) => void;
  updateReadLastFeedback: (realization_id: number, feedback_id: number) => void;
  createStampReaction?: (
    realization_id: number,
    type: ReactableType,
    stamp: Stamp,
  ) => void;
}

const RealizationDetail = (props: RealizationDetailProps) => {
  const [values, updateValues] = React.useState({
    showConversionModal: false,
    showRealizationDeleteForm: false,
    showImageDetail: false,
    showRealizationEditModal: false,
  });

  const update = (args: { [key: string]: any }) => {
    updateValues(prevValues => ({ ...prevValues, ...args }));
  };

  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (
      props.success_message === UPDATE_REALIZATION_SUCCESS_MESSAGE ||
      props.success_message === CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE
    ) {
      props.clearSuccessMessage();
      update({ showRealizationEditModal: false });
    }
    if (
      props.success_message === CREATE_COMMENT_SUCCESS_MESSAGE ||
      props.success_message === CREATE_FEEDBACK_SUCCESS_MESSAGE ||
      props.success_message === CREATE_REALIZATION_STAMP_REACTION_SUCCESS
    ) {
      setTimeout(() => {
        contentRef.current?.scrollTo(1, 10000);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.success_message]);

  useIonViewWillLeave(() => {
    update({
      showConversionModal: false,
      showRealizationDeleteForm: false,
      showImageDetail: false,
      showRealizationEditModal: false,
    });
  });

  return (
    <>
      <div className={styles.wrapper} ref={contentRef}>
        <RealizationDeleteForm
          calling={props.calling}
          isOpen={values.showRealizationDeleteForm}
          update={update}
          delete={props.deleteRealization}
          realization_id={props.realization.id}
        />
        <RealizationConversionForm
          calling={props.calling}
          isOpen={values.showConversionModal}
          close={() => update({ showConversionModal: false })}
          realization={props.realization}
          categories={props.categories}
          teachers={props.teachers}
          classes={props.classes}
          gearboxes={props.gearBoxes}
          pathname={props.pathname}
          success_message={props.success_message}
          createCategory={props.createCategory}
          createWillRealization={props.createWillRealization}
        />
        {props.realization.image && (
          <ImageDetailModal
            show={values.showImageDetail}
            imageUrl={props.realization.image.url}
            imageName={props.realization.image.name}
            onClose={() => update({ showImageDetail: false })}
          />
        )}
        {props.realization && (
          <TemplateModal
            isOpen={values.showRealizationEditModal}
            onClose={() =>
              update({
                showRealizationEditModal: false,
              })
            }
          >
            <RealizationEditForm
              calling={props.calling}
              realization={props.realization}
              categories={props.categories}
              gearBoxes={props.gearBoxes}
              teachers={props.teachers}
              classes={props.classes}
              pathname={props.pathname}
              success_message={props.success_message}
              updateRealization={props.updateRealization}
              createCategory={props.createCategory}
            />
          </TemplateModal>
        )}
        <IonGrid className={styles.realizationWrapper}>
          <IonRow>
            <IonCol size="12" className={styles.contentWrapper}>
              <RealizationContent
                realization={props.realization}
                isSelf
                isDetail
                onClickThumbnailImage={() => {
                  update({ showImageDetail: true });
                }}
              />
            </IonCol>
          </IonRow>
          <IonButtons className={styles.btn_containers}>
            <IonButton
              disabled={props.calling}
              onClick={() => update({ showRealizationEditModal: true })}
            >
              <IonIcon icon={editIcon} className={styles.editIcon} />
            </IonButton>
            <IonButton
              disabled={props.calling}
              onClick={() => {
                update({ showRealizationDeleteForm: true });
              }}
            >
              <IonIcon icon={trashIcon} className={styles.deleteIcon} />
            </IonButton>
          </IonButtons>
          {props.realization.kind === "roots" && (
            <div className={styles.convertButtonArea}>
              <button
                disabled={props.calling}
                className={styles.convertButton}
                onClick={() => {
                  update({ showConversionModal: true });
                }}
              >
                <IonIcon icon={convert} slot="start" className={styles.icon} />
                <span>WILL化</span>
              </button>
            </div>
          )}
          {props.realization.kind === "will" && !props.realization.executed_at && (
            <IonButton
              className={styles.executionButton}
              disabled={props.calling}
              strong
              expand="block"
              fill="outline"
              onClick={() => {
                props.executeRealization(props.realization.id);
              }}
            >
              実行完了
            </IonButton>
          )}
        </IonGrid>
        <CommentFeedbackTabs
          calling={props.calling}
          current_student_id={props.student.id}
          realization={props.realization}
          comments={props.comments}
          feedbacks={props.feedbacks}
          createFeedback={props.createFeedback}
          updateFeedback={props.updateFeedback}
          deleteFeedback={props.deleteFeedback}
          createComment={props.createComment}
          updateComment={props.updateComment}
          deleteComment={props.deleteComment}
          updateReadLastComment={props.updateReadLastComment}
          updateReadLastFeedback={props.updateReadLastFeedback}
          createReport={props.createReport}
          createStampReaction={props.createStampReaction}
          hasTab
        />
      </div>
    </>
  );
};

export default RealizationDetail;
