import React from "react";
import clsx from "clsx";

import NavHeader from "../../organisms/NavHeader";
import Portal from "../../atoms/Portal";

import styles from "./TemplateModal.module.scss";

export interface TemplateModalProps {
  isOpen: boolean;
  headerTitle?: string;
  onClose: () => void;
  theme?: "light" | "dark";
  headerColorVariant?: "analyze";
}

const TemplateModal: React.FC<TemplateModalProps> = ({
  isOpen,
  headerTitle = "編集",
  onClose,
  children,
  theme = "dark",
  headerColorVariant,
}) => {
  return isOpen ? (
    <Portal>
      <div className={styles.container}>
        <NavHeader
          title={headerTitle}
          goBack={onClose}
          className={clsx(
            styles.header,
            headerColorVariant && styles[`header--${headerColorVariant}`],
          )}
        />
        <div className={clsx(styles.wrapper, styles[`bg-${theme}`])}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </Portal>
  ) : null;
};
export default TemplateModal;
