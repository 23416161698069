import React, { ChangeEvent } from "react";

import styles from "./RadioButton.module.scss";

interface RadioButtonProps {
  value: number;
  selected: number;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  value,
  handleChange,
  selected,
}) => {
  return (
    <label className={styles.radioButton} htmlFor={`experienceScore-${value}`}>
      <input
        type="radio"
        name="experienceScore"
        value={value}
        id={`experienceScore-${value}`}
        checked={selected === value}
        onChange={handleChange}
      />
      <span className={styles.check} />
    </label>
  );
};

export default RadioButton;
