import React from "react";
import { useHistory } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { bulbOutline } from "ionicons/icons";

import { gearthemesListPath } from "../../store/student";
import { IGearTheme } from "../../state";

import styles from "./ThemeHeader.module.scss";

type ThemeHeaderProps = {
  geartheme: IGearTheme;
  disableList?: boolean;
  disableCreate?: boolean;
  hideList?: boolean;
  hideCreate?: boolean;
  is_teacher?: boolean;
  updateThemeTutorial?: () => void;
};

const ThemeHeader = ({
  geartheme,
  disableList = false,
  // disableCreate = false,
  hideList = false,
  // hideCreate = false,
  is_teacher = false,
  updateThemeTutorial,
}: ThemeHeaderProps) => {
  const history = useHistory();
  const url = is_teacher
    ? "https://www.enageed.jp/teacher/gearfes"
    : "https://www.enageed.jp/student/gearfes";

  // const expired = new Date() > new Date(2023, 9, 23);

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.container}>
          <div className={styles.topTitle}>
            <span className={styles.fesTitle}>
              <IonIcon className={styles.bulbIcon} icon={bulbOutline} />
              第3回 GEARフェス結果発表！
            </span>
          </div>
          <div className={styles.contentsWrapper}>
            <div className={styles.themelabel}>テーマ</div>
            <div className={styles.contents}>
              <div className={styles.themeTitle}>
                <p className={styles.subTitle}>
                  趣味、食べ物、場所、スポーツ、あなたの推し…
                </p>
                <p className={styles.mainTitle}>
                  <span>好きなものと、</span>
                  <span>おすすめポイントを教えて！</span>
                </p>
              </div>
              <div className={styles.announcement} />
              <div className={styles.actionButtons}>
                <button
                  onClick={() => {
                    window.open(url, "theme");
                    updateThemeTutorial && updateThemeTutorial();
                  }}
                >
                  結果発表を見る
                </button>
                {!hideList && (
                  <button
                    disabled={disableList}
                    onClick={() => {
                      history.push(`${gearthemesListPath}/${geartheme.id}`);
                    }}
                  >
                    みんなの回答を見る
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeHeader;
