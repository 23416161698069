import React from "react";
import { IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";

import { Stamp, stampCategories } from "../../libs/stamp";

import type { StampRow } from "./type";
import commonStyles from "./StampSelector.module.scss";
import styles from "./CategoriezedLayout.module.scss";

type Props = {
  rows: Record<string, Stamp[]>;
  onSelect: (id: number) => void;
};

function make_rows(stampsByCategory: Record<string, Stamp[]>): StampRow[] {
  const rows: StampRow[] = [];
  let i = -1;
  const max_col = 6;
  Object.keys(stampsByCategory).forEach(category => {
    const stamps = stampsByCategory[category];
    const current_row = rows[i] ?? null;
    const current_len =
      current_row
        ?.map(row => row.stamps.length)
        .reduce((acc, len) => acc + len) ?? max_col;
    if (current_len + stamps.length > max_col) {
      rows.push([{ category, stamps }]);
      i++;
    } else {
      const row = current_row ?? { keys: [], values: [] };
      row.push({ category, stamps });
    }
  });
  return rows;
}

export default function CategorizedLayout(props: Props) {
  const rows = make_rows(props.rows);

  return (
    <>
      {rows.map((row, i) => {
        const outerSize = row.length === 1 ? 12 : 6;
        const innerSize = row.length === 1 ? 2 : 4;

        return (
          <IonGrid key={i} className={styles.outerGrid}>
            <IonRow>
              {row.map(col => {
                const label = stampCategories[col.category];
                const stamps = col.stamps;

                return (
                  <IonCol key={label} size="12" sizeSm={`${outerSize}`}>
                    <div className={styles.categoryLabel}>{label}</div>
                    <IonGrid className={styles.innerGrid}>
                      <IonRow>
                        {stamps.map(stamp => (
                          <IonCol
                            key={stamp.id}
                            size="2"
                            sizeSm={`${innerSize}`}
                          >
                            <div className={commonStyles.stampContainer}>
                              <IonImg
                                src={stamp.image}
                                onClick={() => props.onSelect(stamp.id)}
                              />
                            </div>
                          </IonCol>
                        ))}
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>
        );
      })}
    </>
  );
}
