import React from "react";
import clsx from "clsx";
import { IonContent, IonPage } from "@ionic/react";
import { Link } from "react-router-dom";

import { assessmentDescriptionPath } from "../../../store/student";
import {
  ASSESSMENT_STATUS,
  ASSESSMENT_AXIS,
} from "../../../constants/assessment-content";
import LoginHeader from "../../../organisms/LoginHeader";
import locationImg from "../../../assets/images/Assessment/location_img.webp";
import common from "../common.module.scss";

import styles from "./AssessmentReferencePage.module.scss";

const AssessmentReferencePage = () => {
  return (
    <IonPage>
      <LoginHeader title="自己評価ツール" unreadNotiCount={0} />
      <IonContent className={common.wrapper}>
        <div className={clsx(common.container, styles.container)}>
          <section className={clsx(styles.section, styles.location)}>
            <p className={styles.heading}>
              <span className={styles.main}>現在地を把握し記録する</span>
              <span className={styles.sub}>
                今までの経験による”現在地”と、次の成長に必要な”次の経験”を把握します。
              </span>
            </p>
            <div className={styles.map}>
              <img
                className={styles.image}
                src={locationImg}
                alt="現在地を把握し記録する"
              />
              <p className={styles.text}>
                自分の素養を可視化することで、毎日が楽しく、周りの人に価値を提供している実感を持てるようにします。より良い人生を描いて実現し続けている状態を作ります。
              </p>
            </div>
          </section>
          <section className={clsx(styles.section, styles.sevenStatus)}>
            <p className={styles.heading}>
              <span className={styles.main}>7つの素養から経験を振り返る</span>
            </p>
            <ul className={styles.list}>
              {ASSESSMENT_STATUS.map(status => (
                <li
                  key={status.experienceNo}
                  className={styles.item}
                  style={{
                    borderLeft: `solid 5px ${status.colorCode}`,
                  }}
                >
                  <p className={styles.desc}>{status.desc}</p>
                  <p className={styles.title}>{status.title}</p>
                  <p className={styles.text}>{status.reference}</p>
                </li>
              ))}
            </ul>
          </section>
          <section className={clsx(styles.section, styles.assessmentAxis)}>
            <p className={styles.heading}>
              <span className={styles.main}>素養の評価軸</span>
              <span className={styles.sub}>
                素養を身につけていくには、”理解”だけではなく、実際の”体感”や”実感”が必要です。
                <br />
                だからこそ多くの”経験”を積み重ねて「自分で考えて動く」ことで成長していきます。
              </span>
            </p>
            <ul className={styles.list}>
              {ASSESSMENT_AXIS.map(axis => (
                <li key={axis.no} className={styles.item}>
                  <div className={styles.no}>{axis.no}</div>
                  <div className={styles.labelWrapper}>
                    <p className={styles.label}>{axis.label}</p>
                    {axis.summary !== "" && (
                      <p className={styles.summary}>{axis.summary}</p>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </section>
          <Link className={styles.return} to={assessmentDescriptionPath}>
            入力画面に戻る
          </Link>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AssessmentReferencePage;
