import React from "react";

import BoxLabel from "../../atoms/BoxLabel";
import { IStudentGearBox } from "../../state";

import styles from "./BoxesList.module.scss";

export type TKeyValue = { [key: string]: any };

export interface BoxesListProps {
  gearBoxes: IStudentGearBox[];
  pathname: string;
  search?: {
    searching: boolean;
    searchText: string;
  };
  toBoxDetail: (box: IStudentGearBox) => void;
}

const BoxesList = (props: BoxesListProps) => {
  const sortType = "my_realizations_created_at";
  const countType = "my_realizations_count";

  const gearboxes = props.gearBoxes.sort((a, b) => {
    if (!a[sortType] && !b[sortType]) return b.id - a.id;
    if (!a[sortType]) return 1;
    if (!b[sortType]) return -1;
    return a[sortType]! > b[sortType]! ? -1 : 1;
  });

  return (
    <div className={styles.boxesList}>
      {gearboxes.map((box, index) => (
        <BoxLabel
          key={index}
          searchText={
            props.search && (props.search || { searchText: "" }).searchText
          }
          label={box.title}
          isArchived={box.is_archived}
          nameStatus={box.name_status}
          count={(box as TKeyValue)[countType] ?? 0}
          onClick={() => props.toBoxDetail(box)}
        />
      ))}
    </div>
  );
};

export default BoxesList;
