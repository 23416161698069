import React from "react";
import clsx from "clsx";
import { IonIcon } from "@ionic/react";
import { heart, heartOutline } from "ionicons/icons";

import { ShareRealization } from "../../state";
import { convertBrAndHref } from "../../libs/Convert";
import ShareRealizationContentHeader from "../ShareRealizationContentHeader";
import { EXPERIENCE_DATA } from "../../constants/experienceConstant";
import derivativeIcon from "../../assets/icons/derivative.svg";

import styles from "./ShareRealizationContent.module.scss";

type ExperienceLabel = {
  no: number;
  label: string;
  styles: React.CSSProperties;
};

type Props = {
  shareRealization: ShareRealization;
  color?: "light" | "dark";
  derivable: boolean;
  onClickDerivative: () => void;
  onClickFavorite: () => void;
  isDetail: boolean;
};

const ShareRealizationContent = ({
  shareRealization,
  color = "dark",
  derivable,
  onClickDerivative,
  onClickFavorite,
  isDetail,
}: Props) => {
  const experienceLabels: ExperienceLabel[] = EXPERIENCE_DATA.filter(e =>
    shareRealization.experience_nos.includes(e.no),
  );

  return (
    <div className={styles.wrapper}>
      <ShareRealizationContentHeader shareRealization={shareRealization} />
      <div
        className={clsx(
          styles.content,
          isDetail ? "" : styles.listItem,
          color === "dark" ? styles.dark : styles.light,
        )}
      >
        {convertBrAndHref(shareRealization.content)}
      </div>

      <div className={styles.labelGroup}>
        {experienceLabels.map(e => (
          <p key={e.no} className={styles.experience} style={e.styles}>
            {e.label}
          </p>
        ))}
      </div>

      <div className={styles.footWrapper}>
        {derivable && (
          <div className={styles.reactionArea}>
            <IonIcon
              src={derivativeIcon}
              onClick={e => {
                e.stopPropagation();
                onClickDerivative();
              }}
              className={clsx(
                styles.icon,
                styles.enabled,
                shareRealization.has_derivative && styles.active,
              )}
            />
          </div>
        )}
        <div className={styles.reactionArea}>
          <IonIcon
            icon={shareRealization.is_favorite ? heart : heartOutline}
            onClick={e => {
              if (onClickFavorite) {
                e.stopPropagation();
                onClickFavorite();
              }
            }}
            className={clsx(
              styles.icon,
              onClickFavorite && styles.enabled,
              shareRealization.is_favorite && styles.active,
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ShareRealizationContent;
