import React, { useRef, useState } from "react";
import { menuController } from "@ionic/core/components";
import { menu as menuIcon, search, searchOutline } from "ionicons/icons";
import { chevronBackOutline as backIcon } from "ionicons/icons";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonIcon,
  IonButton,
  useIonRouter,
} from "@ionic/react";

import cross from "../../assets/icons/cross.svg";
import BoxTitle from "../../atoms/BoxTitle";

import styles from "./FiltableHeader.module.scss";

export interface FiltableHeaderProps {
  title: string;
  unreadNotiCount: number;
  searchText: string;
  onChangeFunc: (text: string) => void;
  goBack?: boolean;
  goBackUrl?: string;
  rightComponent?: React.ReactNode;
}

const FiltableHeader = (props: FiltableHeaderProps) => {
  const router = useIonRouter();

  const searchInput = useRef<HTMLInputElement>(null);
  const [isSearchable, setIsSearchable] = useState(false);
  const [keyword, setKeyword] = useState("");

  React.useEffect(() => {
    setKeyword(props.searchText);
  }, [props.searchText]);

  const submitSearch = () => {
    props.onChangeFunc(keyword);
    searchInput.current?.blur();
  };

  return (
    <>
      <IonHeader className={styles.header}>
        <IonToolbar className={styles.toolbar}>
          {isSearchable ? (
            <>
              <div className={styles.searchArea}>
                <IonIcon
                  icon={backIcon}
                  color="light"
                  className={styles.cancelBtn}
                  onClick={() => {
                    setIsSearchable(false);
                    props.onChangeFunc("");
                  }}
                />
                <input
                  ref={searchInput}
                  type="text"
                  enterKeyHint="search"
                  className={styles.searchBox}
                  value={keyword}
                  onChange={e => setKeyword(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === "Enter" && !e.nativeEvent.isComposing) {
                      submitSearch();
                    }
                  }}
                />
                <img
                  className={styles.cross}
                  src={cross}
                  alt={"消す"}
                  onClick={() => {
                    setKeyword("");
                    props.onChangeFunc("");
                  }}
                />
                <button className={styles.searchBtn} onClick={submitSearch}>
                  <IonIcon icon={search} className={styles.searchIcon} />
                </button>
              </div>
              <IonButtons slot="start" onClick={() => setIsSearchable(false)} />
            </>
          ) : (
            <>
              <IonTitle className={styles.title}>
                <BoxTitle title={props.title} />
              </IonTitle>
              <IonButtons slot="start">
                {props.goBack ? (
                  <IonButton
                    onClick={() => {
                      props.goBackUrl
                        ? router.push(props.goBackUrl, "back", "pop")
                        : window.history.back();
                    }}
                  >
                    <IonIcon icon={backIcon} color="light" />
                  </IonButton>
                ) : (
                  <IonMenuButton
                    autoHide={false}
                    onClick={() => menuController.open("sideMenuContainer")}
                  >
                    <IonIcon icon={menuIcon} className={styles.menuIcon} />
                    {props.unreadNotiCount > 0 && (
                      <div className={styles.mark}>●</div>
                    )}
                  </IonMenuButton>
                )}
              </IonButtons>
              {props.rightComponent && (
                <IonButtons slot="end">{props.rightComponent}</IonButtons>
              )}
              <IonIcon
                slot="end"
                icon={searchOutline}
                onClick={() => setIsSearchable(true)}
                className={styles.icon}
              />
            </>
          )}
        </IonToolbar>
      </IonHeader>
    </>
  );
};

export default FiltableHeader;
