import React from "react";

export const useWaitApiCall = (contextState: {
  lastAction: string;
}): [boolean, any] => {
  const [calling, setCalling] = React.useState<boolean>(false);
  const [reg, setReg] = React.useState<RegExp | null>(null);
  React.useEffect(() => {
    if (reg) {
      if (contextState.lastAction.match(reg)) {
        setCalling(false);
        setReg(null);
      }
    }
  }, [contextState.lastAction, reg]);

  const peep = React.useCallback((action: any) => {
    setCalling(true);
    setReg(new RegExp(`${action.types}_.*`));
    return action;
  }, []);
  return [calling, peep];
};

export default useWaitApiCall;
