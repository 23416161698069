import React from "react";
import clsx from "clsx";
import { personCircle as personIcon } from "ionicons/icons";
import { IonIcon, useIonViewWillEnter } from "@ionic/react";

import AnalyzeLineStudentChart from "../../organisms/AnalyzeLineStudentChart";
import Tabs from "../../atoms/Tabs";
import {
  ICategory,
  IPagyInfo,
  IRealizationAnalyze,
  IStudent,
  IStudentAnalyze,
  ITeacherGearbox,
} from "../../state";
import DatePicker from "../../atoms/DatePicker";
import { convertDateToDateString } from "../../libs/Util";
import TimelineTabContent from "../teacher/TimelineTabContent";
import useDidMountEffect from "../../hooks/useDidMountEffect";

import styles from "./StudentDetail.module.scss";

export interface StudentDetailProps {
  isLoading: boolean;
  student_analyze: IStudentAnalyze | null;
  realizations: IRealizationAnalyze[];
  gearboxes: ITeacherGearbox[];
  pagy_info_realizations: IPagyInfo;
  class_name: string;
  categories: ICategory[];
  student: IStudent;
  fetchAnalyzeStudent: (start_date: Date | null, end_date: Date | null) => void;
  onLoadRealizations: (
    page: number,
    gearbox_id?: string | number | null,
    category_id?: string | number | null,
    start_date?: Date | null,
    end_date?: Date | null,
  ) => void;
}

const StudentDetail: React.FC<StudentDetailProps> = ({
  student_analyze,
  class_name,
  student,
  realizations,
  gearboxes,
  categories,
  pagy_info_realizations,
  isLoading,
  fetchAnalyzeStudent,
  onLoadRealizations,
}) => {
  const [widthChart, setWidthChart] = React.useState<number>(460);
  const [dateRange, setDateRange] = React.useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: null,
    endDate: null,
  });
  const chart = React.useRef<HTMLDivElement>(null);

  const realizationHistories = React.useMemo(
    () =>
      student_analyze?.realizations.map(r => {
        const { created_at, id, is_executed, kind } = r;
        const createdAt: Date = new Date(created_at);
        const data: any = {
          created_at: createdAt,
          id,
          kind,
        };
        if (is_executed) {
          data.executed_at = createdAt;
        }
        return data;
      }) ?? [],
    [student_analyze?.realizations],
  );

  const percentRealizations = React.useMemo(() => {
    const { roots_count, will_count, exp_count } = student_analyze || {
      roots_count: 0,
      will_count: 0,
      exp_count: 0,
    };
    const maxCount = Math.max(roots_count, will_count);
    return {
      root: !maxCount ? 0 : (roots_count / maxCount) * 100,
      will: !maxCount ? 0 : (will_count / maxCount) * 100,
      exp: !maxCount ? 0 : (exp_count / maxCount) * 100,
    };
  }, [student_analyze]);

  const onDatePickerChange = ([startDate, endDate]: [
    Date | null,
    Date | null,
  ]) => {
    setDateRange({ startDate, endDate });
  };

  useIonViewWillEnter(() => {
    if (dateRange.startDate || dateRange.endDate) {
      setDateRange({ startDate: null, endDate: null });
    } else {
      fetchAnalyzeStudent(null, null);
    }
  }, [dateRange]);

  useDidMountEffect(() => {
    fetchAnalyzeStudent(dateRange.startDate, dateRange.endDate);
  }, [dateRange, fetchAnalyzeStudent]);

  React.useEffect(() => {
    if (chart.current) {
      const chartRef = chart.current;
      const onResize = () => {
        const elmRect = chartRef.getBoundingClientRect();
        elmRect.width && setWidthChart(elmRect.width + 5);
      };
      setTimeout(() => {
        onResize();
      });
      window.addEventListener("resize", onResize);
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.headerContent}>
        <div className={styles.wrapperTopInfo}>
          <div className={styles.infoStudent}>
            {student.photo?.url ? (
              <img
                className={styles.avatar}
                src={student.photo.url}
                alt="avatar"
              />
            ) : (
              <IonIcon
                className={styles.avatar}
                icon={personIcon}
                slot="start"
                color="light"
              />
            )}
            <span>{class_name}</span>
            <span>{`${student.last_name} ${student.first_name}`}</span>
          </div>
          <DatePicker
            disabled={isLoading}
            className={styles.selectorDate}
            label={
              dateRange.startDate || dateRange.endDate
                ? `${
                    dateRange.startDate
                      ? convertDateToDateString(dateRange.startDate)
                      : ""
                  }→${
                    dateRange.endDate
                      ? convertDateToDateString(dateRange.endDate)
                      : ""
                  }`
                : "期間を指定"
            }
            dateFormat="yyyy/MM/dd"
            locale="ja"
            selected={dateRange.startDate}
            onChange={onDatePickerChange}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            selectsRange
          />
        </div>
        <div className={styles.wrapperParameter}>
          <div className={styles.blockLeft}>
            <div className={styles.wrapperChart}>
              <div className={styles.realizationsCount}>
                <p>総Stock数</p>
                <p>{student_analyze?.realizations_count ?? "ー"}</p>
              </div>
              <div className={styles.realizationAnalysis}>
                <div className={clsx(styles.analysisItem, styles.root)}>
                  <div
                    style={{
                      width: `${percentRealizations.root}%`,
                    }}
                  />
                  <span
                    style={{
                      left: `max(${percentRealizations.root}%, ${50}px)`,
                    }}
                  >
                    {student_analyze?.roots_count ?? "ー"}
                  </span>
                </div>
                <div className={clsx(styles.analysisItem, styles.will)}>
                  <div
                    style={{
                      width: `${percentRealizations.will}%`,
                    }}
                  />
                  <span
                    style={{
                      left: `max(${percentRealizations.will}%, ${50}px)`,
                    }}
                  >
                    {student_analyze?.will_count ?? "ー"}
                  </span>
                </div>
                <div className={clsx(styles.analysisItem, styles.exp)}>
                  <div
                    style={{
                      width: `${percentRealizations.exp}%`,
                    }}
                  />
                  <span
                    style={{
                      left: `max(${percentRealizations.exp}%, ${50}px)`,
                    }}
                  >
                    {student_analyze?.exp_count ?? "ー"}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.actionCountGroup}>
              <div className={styles.actionCount}>
                <p>
                  いいね
                  <wbr />
                  された数
                </p>
                <div>{student_analyze?.favorites_count ?? "ー"}</div>
              </div>
              <div className={styles.actionCount}>
                <p>
                  コメント
                  <wbr />
                  された数
                </p>
                <div>{student_analyze?.comments_count ?? "ー"}</div>
              </div>
              <div className={styles.actionCount}>
                <p>
                  引用
                  <wbr />
                  された数
                </p>
                <div>{student_analyze?.derived_count ?? "ー"}</div>
              </div>
            </div>
          </div>
          <div className={styles.chartContainer} ref={chart}>
            <AnalyzeLineStudentChart
              rangeDate={dateRange}
              realization_histories={realizationHistories}
              options={{ backgroundColor: "transparent" }}
              widthContainer={widthChart}
            />
          </div>
        </div>
      </div>
      <Tabs
        listTabsTitle={[
          {
            title: "タイムライン",
            id: "timeline",
          },
        ]}
      >
        <div className="tab-content" id="timeline">
          {student_analyze && (
            <TimelineTabContent
              isLoading={isLoading}
              realizations={realizations}
              gearboxes={gearboxes}
              categories={categories}
              pagy_info_realizations={pagy_info_realizations}
              onLoadRealizations={onLoadRealizations}
            />
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default StudentDetail;
