import React from "react";
import clsx from "clsx";

import styles from "./RealizationTypeTag.module.scss";

export interface RealizationTypeTagProps {
  kind: "roots" | "will";
  executed: boolean;
  isOrigin?: boolean;
}

const RealizationTypeTag: React.FC<RealizationTypeTagProps> = ({
  kind,
  executed,
  isOrigin,
}) => {
  return kind === "roots" ? (
    <div className={isOrigin ? styles.origin : clsx(styles.tag, styles.roots)}>
      ROOTS
    </div>
  ) : kind === "will" && !executed ? (
    <div className={isOrigin ? styles.origin : clsx(styles.tag, styles.will)}>
      WILL
    </div>
  ) : (
    <div className={isOrigin ? styles.origin : clsx(styles.tag, styles.exp)}>
      EXP
    </div>
  );
};

export default RealizationTypeTag;
