import React from "react";
import { IonCheckbox, IonLabel } from "@ionic/react";

import { EXPERIENCE_DATA } from "../../constants/experienceConstant";

import styles from "./ExperienceSelectForm.module.scss";

export interface ExperienceSelectFormProps {
  checkedExperienceNos?: number[];
  update: (args: { [key: string]: any }) => void;
}

const ExperienceSelectForm = (props: ExperienceSelectFormProps) => {
  const nos = React.useMemo(() => {
    return EXPERIENCE_DATA.map(e => ({
      ...e,
      checked: Boolean(props.checkedExperienceNos?.includes(e.no)),
    }));
  }, [props.checkedExperienceNos]);

  const checkedIds = React.useMemo(
    () => [...(props.checkedExperienceNos || [])],
    [props.checkedExperienceNos],
  );

  return (
    <div className={styles.experienceNos}>
      <IonLabel color="light" className={styles.label}>
        意識すること
      </IonLabel>
      <div className={styles.checkBoxList}>
        {nos.map(n => (
          <div
            key={n.no}
            className={styles.checkBoxItem}
            style={n.checked ? n.checkedStyles : n.styles}
            onClick={() => {
              const targetId = n.no;
              n.checked = !n.checked;
              n.checked
                ? checkedIds.push(targetId)
                : checkedIds.splice(checkedIds.indexOf(targetId), 1);
              const result = Array.from(new Set([...checkedIds]));
              props.update({ experience_nos: result });
            }}
          >
            <IonCheckbox
              value={n.no}
              className={styles.checkbox}
              checked={n.checked}
              aria-labelledby={`nos_checkbox_${n.no}`}
            />
            <IonLabel className={styles.checkboxLabel}>{n.label}</IonLabel>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExperienceSelectForm;
