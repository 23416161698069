import React from "react";
import clsx from "clsx";
import { IonIcon } from "@ionic/react";

import { TNameStatus, TeacherGearboxDirectory } from "../../state";
import folderIcon from "../../assets/icons/folder_white.svg";

import styles from "./BoxTitle.module.scss";

type BoxTitleProps = {
  title: string | JSX.Element;
  isArchived?: boolean;
  nameStatus?: TNameStatus;
  wrap?: boolean;
  className?: string;
  gearboxDirectoryId?: number | undefined;
  teacherGearboxDirectories?: TeacherGearboxDirectory[];
};

const BoxTitle = (props: BoxTitleProps) => {
  return (
    <span
      className={clsx(
        styles.wrapper,
        !props.wrap && styles.nowrap,
        props.className,
      )}
    >
      {props.isArchived && (
        <span className={clsx(styles.label, styles.tag)}>提出終了</span>
      )}
      {props.nameStatus === "unpublish" && (
        <span className={clsx(styles.label, styles.tag)}>匿名共有</span>
      )}
      {props.gearboxDirectoryId && (
        <span className={clsx(styles.label, styles.directoryLabel)}>
          <IonIcon className={styles.icon} src={folderIcon} />
          {
            props.teacherGearboxDirectories?.find(
              directory => directory.id === props.gearboxDirectoryId,
            )?.name
          }
        </span>
      )}
      {props.title}
    </span>
  );
};

export default BoxTitle;
