import { IonToast } from "@ionic/react";
import React from "react";

import styles from "./Toast.module.scss";

export type TToastType = "danger" | "success" | "";

interface ToastProps {
  showToast: boolean;
  onClose: () => void;
  message: string;
  type?: TToastType;
}

const Toast = ({ showToast, message, onClose, type = "" }: ToastProps) => {
  return (
    <IonToast
      cssClass={type === "danger" ? styles.danger : styles.success}
      position={"top"}
      isOpen={showToast}
      onDidDismiss={onClose}
      message={message}
      duration={1500}
    />
  );
};

export default Toast;
