import React, { SyntheticEvent } from "react";
import { IonIcon } from "@ionic/react";
import { caretDown } from "ionicons/icons";
import {
  default as ReactDatePicker,
  registerLocale,
  ReactDatePickerProps,
} from "react-datepicker";
import ja from "date-fns/locale/ja";
import { isValid, isAfter, startOfDay, endOfDay } from "date-fns";
import clsx from "clsx";

import arrowLeft from "../../assets/icons/triangle_arrow_left.svg";
import arrowRight from "../../assets/icons/triangle_arrow_right.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/dropdown_secondary_arrow.svg";
import useDebounceCallback from "../../hooks/useDebounceCallback";
import {
  convertDateToDateString,
  convertDateStringToDate,
} from "../../libs/Util";

import styles from "./DatePicker.module.scss";

registerLocale("ja", ja);

export interface DatePickerProps extends ReactDatePickerProps {
  variant?: "primary" | "secondary";
  label: string;
  error?: string;
  className?: string;
  classNameDatePickerInput?: string;
}

const validates: { [key: string]: (value: any) => string } = {};

validates["startDateInput"] = val => {
  if (val.startDate && !isValid(val.startDate)) return "開始日が無効です";
  if (val.startDate && !val.endDate) return "開始日と終了日が必要です";
  return "";
};

validates["endDateInput"] = val => {
  if (val.endDate && !isValid(val.endDate)) return "無効な終了日";
  if (val.endDate && !val.startDate) return "開始日と終了日が必要です";
  if (
    val.endDate &&
    isValid(val.endDate) &&
    val.startDate &&
    isValid(val.startDate) &&
    isAfter(startOfDay(val.startDate), endOfDay(val.endDate))
  )
    return "終了日は開始日よりも大きくする必要があります";
  return "";
};

const DatePicker: React.FC<DatePickerProps> = ({
  variant = "primary",
  label,
  className,
  classNameDatePickerInput,
  error,
  customInput,
  startDate,
  endDate,
  selectsRange,
  ...rest
}) => {
  const [state, setState] = React.useState({
    startDateInput: "",
    endDateInput: "",
    isOpen: false,
    errorMessages: {} as { [key: string]: string },
  });

  React.useEffect(() => {
    setState(s => ({
      ...s,
      startDateInput: startDate ? convertDateToDateString(startDate) : "",
      endDateInput: endDate ? convertDateToDateString(endDate) : "",
      errorMessages: {
        startDateInput: startDate ? "" : s.errorMessages.startDateInput,
        endDateInput: endDate ? "" : s.errorMessages.endDateInput,
      },
    }));
  }, [startDate, endDate]);

  const [onDateChangeDebounced] = useDebounceCallback(
    (convertedStartDate, convertedEndDate, event) => {
      if (
        convertedStartDate &&
        isValid(convertedStartDate) &&
        convertedEndDate &&
        isValid(convertedEndDate) &&
        isAfter(endOfDay(convertedEndDate), startOfDay(convertedStartDate))
      ) {
        rest.onChange([convertedStartDate, convertedEndDate], event);
        return;
      }
      if (!convertedStartDate && !convertedEndDate) {
        rest.onChange([null, null], event);
        return;
      }
    },
    1500,
  );

  const onChange = React.useCallback(
    (startDateInput, endDateInput, event: SyntheticEvent<any, Event>) => {
      const convertedStartDate = startDateInput
        ? convertDateStringToDate(startDateInput)
        : null;
      const convertedEndDate = endDateInput
        ? convertDateStringToDate(endDateInput)
        : null;
      const errorMessages = {} as { [key: string]: string };
      Object.keys(validates).forEach(key => {
        const ret = validates[key]({
          startDate: convertedStartDate,
          endDate: convertedEndDate,
        });
        if (ret) {
          errorMessages[key] = ret;
        }
      });
      setState(s => ({
        ...s,
        startDateInput,
        endDateInput,
        errorMessages,
      }));
      onDateChangeDebounced(convertedStartDate, convertedEndDate, event);
    },
    [onDateChangeDebounced],
  );

  return (
    <div
      className={clsx(
        styles.datePickerWrapper,
        styles[variant],
        !state.isOpen && startDate && endDate && styles.active,
        !state.isOpen && error && styles.error,
        className,
      )}
    >
      <ReactDatePicker
        dateFormat="yyyy年MM月dd日"
        locale="ja"
        customInput={
          <div style={{ opacity: state.isOpen ? 0 : 1 }}>
            {customInput || (
              <div
                className={clsx(
                  styles.datePickerInput,
                  classNameDatePickerInput,
                )}
              >
                <span className={styles.label}>{label}</span>
                {variant === "primary" && (
                  <IonIcon icon={caretDown} className={styles.arrowIcon} />
                )}
                {variant === "secondary" && <ArrowIcon />}
              </div>
            )}
            {!state.isOpen && !!error && (
              <p className={styles.errorMessage}>{error}</p>
            )}
          </div>
        }
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className={styles.datePickerHeader}>
            {(startDate || endDate) && (
              <div className={styles.datePickerPickedDate}>{`${
                startDate ? convertDateToDateString(startDate) : ""
              }${endDate ? "→" + convertDateToDateString(endDate) : ""}`}</div>
            )}
            <div className={styles.datePickerInputGroup}>
              <input
                style={{ width: selectsRange ? "50%" : "100%" }}
                placeholder="開始日"
                value={state.startDateInput || ""}
                onChange={e =>
                  onChange(e.currentTarget.value, state.endDateInput, e)
                }
              />
              {selectsRange && (
                <input
                  placeholder="終了日"
                  value={state.endDateInput || ""}
                  onChange={e =>
                    onChange(state.startDateInput, e.currentTarget.value, e)
                  }
                />
              )}
            </div>
            <div className={styles.invalidDate}>
              {state.errorMessages["startDateInput"] ||
                state.errorMessages["endDateInput"]}
            </div>
            <div className={styles.datePickerHeaderActions}>
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <IonIcon icon={arrowLeft} />
              </button>
              <div>{`${date.getFullYear()}年 ${date.getMonth() + 1}月`}</div>
              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <IonIcon icon={arrowRight} />
              </button>
            </div>
          </div>
        )}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        disabledKeyboardNavigation
        onCalendarOpen={() => setState(s => ({ ...s, isOpen: true }))}
        onCalendarClose={() => setState(s => ({ ...s, isOpen: false }))}
        {...rest}
      />
    </div>
  );
};

export default DatePicker;
