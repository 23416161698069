import * as React from "react";
import { addHours } from "date-fns";

import { parseQueryParams } from "../libs/Util";
import {
  CreateRealizationStampReactionParams,
  IAssessment,
  IAssessmentBulkUpdate,
  IAssessmentPast,
  ICategory,
  IComment,
  ICommentNotification,
  IFeedback,
  IFile,
  IGearTheme,
  INotification,
  IPagyInfo,
  IRealization,
  IRealizationAnalyze,
  IRealizationCountByExp,
  IRealizationCreateParams,
  IRealizationHistory,
  IRealizationKlass,
  IRealizationSummary,
  IRealizationUpdate,
  IStudent,
  IStudentAnalyze,
  IStudentGearboxParams,
  ITeacherGearbox,
  ITimelinePagination,
  IStudentRealizationFeedback,
  PreferredRealization,
  ReferenceRealization,
} from "../state";
import { TimelineSelectAction } from "../molecules/student/ModalSelectActions";

import {
  IClass,
  IRealizationImage,
  IStudentGearBox,
  ITeacher,
} from "./../state.d";
import {
  update_realization_klasses_state,
  update_realizations_state,
} from "./common";

export const FETCH_INITIAL_DATA = "FETCH_INITIAL_DATA";
export const FETCH_INITIAL_DATA_SUCCESS = "FETCH_INITIAL_DATA_SUCCESS";
export const FETCH_INITIAL_DATA_FAILURE = "FETCH_INITIAL_DATA_FAILURE";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const UPDATE_PHOTO = "UPDATE_PHOTO";
export const UPDATE_PHOTO_SUCCESS = "UPDATE_PHOTO_SUCCESS";
export const UPDATE_PHOTO_FAILURE = "UPDATE_PHOTO_FAILURE";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const CLEAR_SUCCESS_MESSAGE = "CLEAR_SUCCESS_MESSAGE";
export const CLEAR_SUCCESS_RESULT = "CLEAR_SUCCESS_RESULT";
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export const CLEAR_REALIZATION_KLASSES = "CLEAR_REALIZATION_KLASSES";
export const CLEAR_REALIZATION_KLASSES_FILTERED =
  "CLEAR_REALIZATION_KLASSES_FILTERED";
export const CLEAR_REALIZATION_KLASSES_PAST = "CLEAR_REALIZATION_KLASSES_PAST";
export const CLEAR_REALIZATION = "CLEAR_REALIZATION";
export const CLEAR_COMMENTS = "CLEAR_COMMENTS";
export const CLEAR_FEEDBACKS = "CLEAR_FEEDBACKS";
export const CLEAR_REALIZATIONS = "CLEAR_REALIZATIONS";
export const CLEAR_ANALYZE_REALIZATIONS = "CLEAR_ANALYZE_REALIZATIONS";
export const CLEAR_ANALYZE_REALIZATIONS_PAGY_INFO =
  "CLEAR_ANALYZE_REALIZATIONS_PAGY_INFO";
export const CLEAR_ANALYZE_STUDENT = "CLEAR_ANALYZE_STUDENT";
export const CLEAR_SELECTED_GEARBOX = "CLEAR_SELECTED_GEARBOX";
export const CLEAR_PAGY_INFO = "CLEAR_PAGY_INFO";

export const UPDATE_PHOTO_SUCCESS_MESSAGE =
  "プロフィール写真の更新に成功しました";
export const UPDATE_PHOTO_FAILURE_MESSAGE =
  "プロフィール写真の更新に失敗しました";
export const FETCH_TEACHERS = "FETCH_TEACHERS";
export const FETCH_TEACHERS_SUCCESS = "FETCH_TEACHERS_SUCCESS";
export const FETCH_TEACHERS_FAILURE = "FETCH_TEACHERS_FAILURE";
export const FETCH_CLASSES = "FETCH_CLASSES";
export const FETCH_CLASSES_SUCCESS = "FETCH_CLASSES_SUCCESS";
export const FETCH_CLASSES_FAILURE = "FETCH_CLASSES_FAILURE";
export const FETCH_CLASSES_PAST = "FETCH_CLASSES_PAST";
export const FETCH_CLASSES_PAST_SUCCESS = "FETCH_CLASSES_PAST_SUCCESS";
export const FETCH_CLASSES_PAST_FAILURE = "FETCH_CLASSES_PAST_FAILURE";
export const FETCH_REALIZATION = "FETCH_REALIZATION";
export const FETCH_REALIZATION_SUCCESS = "FETCH_REALIZATION_SUCCESS";
export const FETCH_REALIZATION_FAILURE = "FETCH_REALIZATION_FAILURE";
export const FETCH_MY_REALIZATIONS = "FETCH_MY_REALIZATIONS";
export const FETCH_MY_REALIZATIONS_SUCCESS = "FETCH_MY_REALIZATIONS_SUCCESS";
export const FETCH_MY_REALIZATIONS_FAILURE = "FETCH_MY_REALIZATIONS_FAILURE";
export const FETCH_REALIZATION_COUNT_BY_EXPERIENCE =
  "FETCH_REALIZATION_COUNT_BY_EXPERIENCE";
export const FETCH_REALIZATION_COUNT_BY_EXPERIENCE_SUCCESS =
  "FETCH_REALIZATION_COUNT_BY_EXPERIENCE_SUCCESS";
export const FETCH_REALIZATION_COUNT_BY_EXPERIENCE_FAILURE =
  "FETCH_REALIZATION_COUNT_BY_EXPERIENCE_FAILURE";
export const FETCH_ANALYZE_REALIZATIONS = "FETCH_ANALYZE_REALIZATIONS";
export const FETCH_ANALYZE_REALIZATIONS_SUCCESS =
  "FETCH_ANALYZE_REALIZATIONS_SUCCESS";
export const FETCH_ANALYZE_GEARBOXES = "FETCH_ANALYZE_GEARBOXES";
export const FETCH_ANALYZE_GEARBOXES_SUCCESS =
  "FETCH_ANALYZE_GEARBOXES_SUCCESS";
export const FETCH_ANALYZE_STUDENT = "FETCH_ANALYZE_STUDENT";
export const FETCH_ANALYZE_STUDENT_SUCCESS = "FETCH_ANALYZE_STUDENT_SUCCESS";
export const FETCH_CATEGORIZED_REALIZATION = "FETCH_CATEGORIZED_REALIZATION";
export const FETCH_CATEGORIZED_REALIZATION_SUCCESS =
  "FETCH_CATEGORIZED_REALIZATION_SUCCESS";
export const FETCH_CATEGORIZED_REALIZATION_FAILURE =
  "FETCH_CATEGORIZED_REALIZATION_FAILURE";
export const CREATE_REALIZATION = "CREATE_REALIZATION";
export const CREATE_REALIZATION_SUCCESS = "CREATE_REALIZATION_SUCCESS";
export const CREATE_REALIZATION_FAILURE = "CREATE_REALIZATION_FAILURE";
export const UPDATE_REALIZATION = "UPDATE_REALIZATION";
export const UPDATE_REALIZATION_SUCCESS = "UPDATE_REALIZATION_SUCCESS";
export const UPDATE_REALIZATION_FAILURE = "UPDATE_REALIZATION_FAILURE";
export const DELETE_REALIZATION = "DELETE_REALIZATION";
export const DELETE_REALIZATION_SUCCESS = "DELETE_REALIZATION_SUCCESS";
export const DELETE_REALIZATION_FAILURE = "DELETE_REALIZATION_FAILURE";
export const SHARE_REALIZATION = "SHARE_REALIZATION";
export const SHARE_REALIZATION_SUCCESS = "SHARE_REALIZATION_SUCCESS";
export const SHARE_REALIZATION_FAILURE = "SHARE_REALIZATION_FAILURE";
export const EXECUTE_REALIZATION = "EXECUTE_REALIZATION";
export const EXECUTE_REALIZATION_SUCCESS = "EXECUTE_REALIZATION_SUCCESS";
export const EXECUTE_REALIZATION_FAILURE = "EXECUTE_REALIZATION_FAILURE";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";
export const FETCH_CATEGORIES_WITH_COUNT = "FETCH_CATEGORIES_WITH_COUNT";
export const FETCH_CATEGORIES_WITH_COUNT_SUCCESS =
  "FETCH_CATEGORIES_WITH_COUNT_SUCCESS";
export const FETCH_CATEGORIES_WITH_COUNT_FAILURE =
  "FETCH_CATEGORIES_WITH_COUNT_FAILURE";
export const REORDER_CATEGORIES = "REORDER_CATEGORIES";
export const REORDER_CATEGORIES_SUCCESS = "REORDER_CATEGORIES_SUCCESS";
export const REORDER_CATEGORIES_FAILURE = "REORDER_CATEGORIES_FAILURE";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";
export const DESTROY_CATEGORY = "DESTROY_CATEGORY";
export const DESTROY_CATEGORY_SUCCESS = "DESTROY_CATEGORY_SUCCESS";
export const DESTROY_CATEGORY_FAILURE = "DESTROY_CATEGORY_FAILURE";
export const SUBSCRIBE_REALIZATION_KLASSES = "SUBSCRIBE_REALIZATION_KLASSES";
export const SUBSCRIBE_REALIZATION_KLASSES_SUCCESS =
  "SUBSCRIBE_REALIZATION_KLASSES_SUCCESS";
export const SUBSCRIBE_REALIZATION_KLASSES_FAILURE =
  "SUBSCRIBE_REALIZATION_KLASSES_FAILURE";
export const FETCH_REALIZATION_KLASSES = "FETCH_REALIZATION_KLASSES";
export const FETCH_REALIZATION_KLASSES_SUCCESS =
  "FETCH_REALIZATION_KLASSES_SUCCESS";
export const FETCH_REALIZATION_KLASSES_FAILURE =
  "FETCH_REALIZATION_KLASSES_FAILURE";
export const FETCH_REALIZATION_KLASSES_PAST = "FETCH_REALIZATION_KLASSES_PAST";
export const FETCH_REALIZATION_KLASSES_PAST_SUCCESS =
  "FETCH_REALIZATION_KLASSES_PAST_SUCCESS";
export const FETCH_REALIZATION_KLASSES_PAST_FAILURE =
  "FETCH_REALIZATION_KLASSES_PAST_FAILURE";
export const FETCH_REALIZATION_KLASSES_FILTERED =
  "FETCH_REALIZATION_KLASSES_FILTERED";
export const FETCH_REALIZATION_KLASSES_FILTERED_SUCCESS =
  "FETCH_REALIZATION_KLASSES_FILTERED_SUCCESS";
export const FETCH_REALIZATION_KLASSES_FILTERED_FAILURE =
  "FETCH_REALIZATION_KLASSES_FILTERED_FAILURE";
export const FETCH_COMMENTS = "FETCH_COMMENTS";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_FAILURE = "FETCH_COMMENTS_FAILURE";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_FAILURE = "CREATE_COMMENT_FAILURE";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAILURE = "UPDATE_COMMENT_FAILURE";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";
export const CREATE_FEEDBACK = "CREATE_FEEDBACK";
export const CREATE_FEEDBACK_SUCCESS = "CREATE_FEEDBACK_SUCCESS";
export const CREATE_FEEDBACK_FAILURE = "CREATE_FEEDBACK_FAILURE";
export const UPDATE_FEEDBACK = "UPDATE_FEEDBACK";
export const UPDATE_FEEDBACK_SUCCESS = "UPDATE_FEEDBACK_SUCCESS";
export const UPDATE_FEEDBACK_FAILURE = "UPDATE_FEEDBACK_FAILURE";
export const DELETE_FEEDBACK = "DELETE_FEEDBACK";
export const DELETE_FEEDBACK_SUCCESS = "DELETE_FEEDBACK_SUCCESS";
export const DELETE_FEEDBACK_FAILURE = "DELETE_FEEDBACK_FAILURE";
export const FETCH_FEEDBACKS = "FETCH_FEEDBACKS";
export const FETCH_FEEDBACKS_SUCCESS = "FETCH_FEEDBACKS_SUCCESS";
export const FETCH_FEEDBACKS_FAILURE = "FETCH_FEEDBACKS_FAILURE";

export const CREATE_REALIZATION_STAMP_REACTION =
  "CREATE_REALIZATION_STAMP_REACTION";
export const CREATE_REALIZATION_STAMP_REACTION_SUCCESS =
  "CREATE_REALIZATION_STAMP_REACTION_SUCCESS";
export const CREATE_REALIZATION_STAMP_REACTION_FAILURE =
  "CREATE_REALIZATION_STAMP_REACTION_FAILURE";

export const FETCH_REALIZATION_SUMMARY_BY_CATEGORY =
  "FETCH_REALIZATION_SUMMARY_BY_CATEGORY";
export const FETCH_REALIZATION_SUMMARY_BY_CATEGORY_SUCCESS =
  "FETCH_REALIZATION_SUMMARY_BY_CATEGORY_SUCCESS";
export const FETCH_REALIZATION_SUMMARY_BY_CATEGORY_FAILURE =
  "FETCH_REALIZATION_SUMMARY_BY_CATEGORY_FAILURE";
export const FETCH_REALIZATION_HISTORIES = "FETCH_REALIZATION_HISTORIES";
export const FETCH_REALIZATION_HISTORIES_SUCCESS =
  "FETCH_REALIZATION_HISTORIES_SUCCESS";
export const FETCH_REALIZATION_HISTORIES_FAILURE =
  "FETCH_REALIZATION_HISTORIES_FAILURE";
export const UPDATE_STUDENT_PASSWORD = "UPDATE_STUDENT_PASSWORD";
export const UPDATE_STUDENT_PASSWORD_SUCCESS =
  "UPDATE_STUDENT_PASSWORD_SUCCESS";
export const UPDATE_STUDENT_PASSWORD_FAILURE =
  "UPDATE_STUDENT_PASSWORD_FAILURE";

export const UPDATE_READ_LAST_REALIZATION_KLASS =
  "UPDATE_READ_LAST_REALIZATION_KLASS";
export const UPDATE_READ_LAST_REALIZATION_KLASS_SUCCESS =
  "UPDATE_READ_LAST_REALIZATION_KLASS_SUCCESS";
export const UPDATE_READ_LAST_REALIZATION_KLASS_FAILURE =
  "UPDATE_READ_LAST_REALIZATION_KLASS_FAILURE";
export const UPDATE_READ_LAST_NOTIFICATION = "UPDATE_READ_LAST_NOTIFICATION";
export const UPDATE_READ_LAST_NOTIFICATION_SUCCESS =
  "UPDATE_READ_LAST_NOTIFICATION_SUCCESS";
export const UPDATE_READ_LAST_NOTIFICATION_FAILURE =
  "UPDATE_READ_LAST_NOTIFICATION_FAILURE";

export const ADD_FAVORITE = "ADD_FAVORITE";
export const ADD_FAVORITE_SUCCESS = "ADD_FAVORITE_SUCCESS";
export const ADD_FAVORITE_FAILURE = "ADD_FAVORITE_FAILURE";
export const REMOVE_FAVORITE = "REMOVE_FAVORITE";
export const REMOVE_FAVORITE_SUCCESS = "REMOVE_FAVORITE_SUCCESS";
export const REMOVE_FAVORITE_FAILURE = "REMOVE_FAVORITE_FAILURE";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";
export const FETCH_COMMENT_NOTIFICATIONS = "FETCH_COMMENT_NOTIFICATIONS";
export const FETCH_COMMENT_NOTIFICATIONS_SUCCESS =
  "FETCH_COMMENT_NOTIFICATIONS_SUCCESS";
export const CREATE_REPORT = "CREATE_REPORT";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_FAILURE = "CREATE_REPORT_FAILURE";
export const UPDATE_READ_LAST_COMMENT = "UPDATE_READ_LAST_COMMENT";
export const UPDATE_READ_LAST_COMMENT_SUCCESS =
  "UPDATE_READ_LAST_COMMENT_SUCCESS";
export const UPDATE_READ_LAST_COMMENT_FAILURE =
  "UPDATE_READ_LAST_COMMENT_FAILURE";
export const UPDATE_READ_LAST_FEEDBACK = "UPDATE_READ_LAST_FEEDBACK";
export const UPDATE_READ_LAST_FEEDBACK_SUCCESS =
  "UPDATE_READ_LAST_FEEDBACK_SUCCESS";
export const UPDATE_READ_LAST_FEEDBACK_FAILURE =
  "UPDATE_READ_LAST_FEEDBACK_FAILURE";
export const UPDATE_READ_GEAR_TUTORIALS = "UPDATE_READ_GEAR_TUTORIALS";
export const UPDATE_READ_GEAR_TUTORIALS_SUCCESS =
  "UPDATE_READ_GEAR_TUTORIALS_SUCCESS";
export const UPDATE_READ_GEAR_TUTORIALS_FAILURE =
  "UPDATE_READ_GEAR_TUTORIALS_FAILURE";
export const UPDATE_READ_GEAR_RELEASE_NOTE = "UPDATE_READ_GEAR_RELEASE_NOTE";
export const UPDATE_READ_GEAR_RELEASE_NOTE_SUCCESS =
  "UPDATE_READ_GEAR_RELEASE_NOTE_SUCCESS";
export const UPDATE_READ_GEAR_RELEASE_NOTE_FAILURE =
  "UPDATE_READ_GEAR_RELEASE_NOTE_FAILURE";
export const UPDATE_READ_RELEASE_ADS = "UPDATE_READ_RELEASE_ADS";
export const UPDATE_READ_RELEASE_ADS_SUCCESS =
  "UPDATE_READ_RELEASE_ADS_SUCCESS";
export const FETCH_GEAR_BOXES = "FETCH_GEAR_BOXES";
export const FETCH_GEAR_BOXES_SUCCESS = "FETCH_GEAR_BOXES_SUCCESS";
export const FETCH_BOX_REALIZATION = "FETCH_BOX_REALIZATION";
export const FETCH_BOX_REALIZATION_SUCCESS = "FETCH_BOX_REALIZATION_SUCCESS";
export const FETCH_TIMELINE_BOX_DETAIL = "FETCH_TIMELINE_BOX_DETAIL";
export const FETCH_TIMELINE_BOX_DETAIL_SUCCESS =
  "FETCH_TIMELINE_BOX_DETAIL_SUCCESS";
export const FETCH_GEAR_THEME_REALIZATION = "FETCH_GEAR_THEME_REALIZATION";
export const FETCH_GEAR_THEME_REALIZATION_SUCCESS =
  "FETCH_GEAR_THEME_REALIZATION_SUCCESS";
export const FETCH_ASSESSMENT = "FETCH_ASSESSMENT";
export const FETCH_ASSESSMENT_SUCCESS = "FETCH_ASSESSMENT_SUCCESS";
export const FETCH_ASSESSMENT_FAILURE = "FETCH_ASSESSMENT_FAILURE";
export const FETCH_ASSESSMENT_PAST = "FETCH_ASSESSMENT_PAST";
export const FETCH_ASSESSMENT_PAST_SUCCESS = "FETCH_ASSESSMENT_PAST_SUCCESS";
export const FETCH_ASSESSMENT_PAST_FAILURE = "FETCH_ASSESSMENT_PAST_FAILURE";
export const SUBMIT_EXPERIENCE = "SUBMIT_EXPERIENCE";
export const SUBMIT_EXPERIENCE_SUCCESS = "SUBMIT_EXPERIENCE_SUCCESS";
export const SUBMIT_EXPERIENCE_FAILURE = "SUBMIT_EXPERIENCE_FAILURE";
export const CREATE_ASSESSMENT_SUCCESS_MSG = "保存しました";
export const FETCH_CONVERTED_REALIZATION_SUCCESS_MESSAGE =
  "msg_fetch_converted_realization_success";
export const FETCH_EXECUTED_REALIZATION_SUCCESS_MESSAGE =
  "msg_fetch_executed_realization_success";
export const CREATE_CATEGORY_SUCCESS_MESSAGE = "活かし先の作成に成功しました";
export const DESTROY_CATEGORY_SUCCESS_MESSAGE = "活かし先の削除に成功しました";
export const UPDATE_CATEGORY_SUCCESS_MESSAGE = "活かし先が正常に更新されました";
export const UPDATE_CATEGORY_FAILED_MESSAGE = "活かし先はすでに存在します";
export const CREATE_REALIZATION_SUCCESS_MESSAGE = "気づきの作成に成功しました";
export const CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE =
  "コンバージョンで意識を高めることに成功する";
export const UPDATE_REALIZATION_SUCCESS_MESSAGE = "気づきの更新に成功しました";
export const EXECUTE_REALIZATION_SUCCESS_MESSAGE =
  "転化した気づきの実行に成功しました";
export const DELETE_REALIZATION_SUCCESS_MESSAGE = "気づきの削除に成功しました";
export const CREATE_COMMENT_SUCCESS_MESSAGE = "コメントの返信に成功しました";
export const UPDATE_COMMENT_SUCCESS_MESSAGE = "コメントの更新に成功しました";
export const DELETE_COMMENT_SUCCESS_MESSAGE = "コメントの削除に成功しました";
export const CREATE_FEEDBACK_SUCCESS_MESSAGE =
  "フィードバックの返信に成功しました";
export const UPDATE_FEEDBACK_SUCCESS_MESSAGE =
  "フィードバックの更新に成功しました";
export const DELETE_FEEDBACK_SUCCESS_MESSAGE =
  "フィードバックの削除に成功しました";
export const CREATE_REPORT_SUCCESS_MESSAGE = "通報に成功しました";
export const TOGGLE_STATE_SUCCESS_MESSAGE = "いいねの追加/解除に成功しました";
export const UPDATE_STUDENT_PASSWORD_SUCCESS_MESSAGE =
  "パスワードの更新に成功しました";
export const FETCH_REALIZATION_KLASSES_SUCCESS_MESSAGE =
  "タイムラインの取得に成功しました";
export const FETCH_REALIZATION_KLASSES_FILTERED_SUCCESS_MESSAGE =
  "タイムラインの取得に成功しました";
export const FETCH_REALIZATION_KLASSES_PAST_SUCCESS_MESSAGE =
  "過去のタイムラインの取得に成功しました";
export const FETCH_NOTIFICATIONS_SUCCESS_MESSAGE =
  "お知らせの取得に成功しました";
export const TIMELINE_BOX_DETAIL_SUCCESS_MESSAGE =
  "message_timeline_box_detail_success";
export const FETCH_REALIZATION_SUCCESS_MESSAGE = "気づきの取得に成功しました";
export const FETCH_BOX_REALIZATION_SUCCESS_MESSAGE =
  "msg_fetch_box_realization_success";
export const FETCH_GEAR_THEME_REALIZATION_SUCCESS_MESSAGE =
  "msg_fetch_gear_theme_realization_success";
export const FETCH_CATEGORIZED_REALIZATION_SUCCESS_MESSAGE =
  "msg_fetch_categorized_realization_success";
export const INPUT_REALIZATION_CONTENT_FAILURE_MESSAGE =
  "気づきが入力されていません";
export const SELECT_REALIZATION_BOX_FAILURE_MESSAGE = "BOXを選択してください。";
export const SELECT_REALIZATION_CLASS_FAILURE_MESSAGE =
  "共有先を選択してください。";
export const SELECT_REALIZATION_TEACHER_FAILURE_MESSAGE =
  "先生を選択してください。";

export const LOGOUT_SUCCESS_MESSAGE = "ログアウトしました。";

export const ACTION_503 = "ACTION_503";
export const ACTION_503_MESSAGE = "サービスが利用できません";

export const FETCH_REFERENCE_REALIZATIONS = "FETCH_REFERENCE_REALIZATIONS";
export const FETCH_REFERENCE_REALIZATIONS_SUCCESS =
  "FETCH_REFERENCE_REALIZATIONS_SUCCESS";
export const FETCH_PREFERRED_REALIZATIONS = "FETCH_PREFERRED_REALIZATIONS";
export const FETCH_PREFERRED_REALIZATIONS_SUCCESS =
  "FETCH_PREFERRED_REALIZATIONS_SUCCESS";

export const CREATE_REALIZATION_FEEDBACKS = "CREATE_REALIZATION_FEEDBACKS";
export const CREATE_REALIZATION_FEEDBACKS_SUCCESS =
  "CREATE_REALIZATION_FEEDBACKS_SUCCESS";
export const CREATE_REALIZATION_FEEDBACKS_FAILURE =
  "CREATE_REALIZATION_FEEDBACKS_FAILURE";
export const CREATE_REALIZATION_FEEDBACKS_SUCCESS_MESSAGE =
  "フィードバックの作成に成功しました";
export const CREATE_REALIZATION_FEEDBACKS_FAILURE_MESSAGE =
  "フィードバックの取得に失敗しました。時間を置いて再度お試しください。";

export const FETCH_REALIZATION_FEEDBACKS = "FETCH_REALIZATION_FEEDBACKS";
export const FETCH_REALIZATION_FEEDBACKS_SUCCESS =
  "FETCH_REALIZATION_FEEDBACKS_SUCCESS";

export const UPDATE_READ_AT_TO_REALIZATION_FEEDBACK =
  "UPDATE_READ_AT_TO_REALIZATION_FEEDBACK";
export const UPDATE_READ_AT_TO_REALIZATION_FEEDBACK_SUCCESS =
  "UPDATE_READ_AT_TO_REALIZATION_FEEDBACK_SUCCESS";
export const UPDATE_READ_AT_TO_REALIZATION_FEEDBACK_FAILURE =
  "UPDATE_READ_AT_TO_REALIZATION_FEEDBACK_FAILURE";

export const BULK_UPDATE_READ_AT_TO_REALIZATION_FEEDBACKS =
  "BULK_UPDATE_READ_AT_TO_REALIZATION_FEEDBACKS";
export const BULK_UPDATE_READ_AT_TO_REALIZATION_FEEDBACKS_SUCCESS =
  "BULK_UPDATE_READ_AT_TO_REALIZATION_FEEDBACKS_SUCCESS";
export const BULK_UPDATE_READ_AT_TO_REALIZATION_FEEDBACKS_FAILURE =
  "BULK_UPDATE_READ_AT_TO_REALIZATION_FEEDBACKS_FAILURE";

export const API_URL = `${
  process.env.REACT_APP_API_URL ??
  window.location.protocol + "//" + window.location.host
}/api/v1/student`;

export const prefixPath = "/student";
export const homePath = "/";
export const newRealizationPath = "/realization";
export const editCategoriesPath = "/categories";
export const editClassesPath = "/classes";
export const timelinePath = "/timeline";
export const timelinePastPath = "/timeline_past";
export const realizationListPath = "/realizations";
export const analyzePath = "/analyze";
export const categorizedRealizationPath = "/realizations/category";
export const realizationDetailPath = "/realizations/category/realization";
export const editProfilePath = "/edit_profile";
export const updateStudentPasswordPath = "/update_student_password";
export const commentNotificationsPath = "/comment_notifications";
export const notificationListPath = "/notifications";
export const boxesListPath = "/boxes";
export const gearthemesListPath = "/gear_themes";
export const timelineFilteredPath = "/realization/select_action";
export const assessmentPath = `/assessment`;
export const assessmentDescriptionPath = `/assessment_description`;
export const assessmentListPath = `/assessment_list`;
export const assessmentReferencePath = `/assessment_reference`;
export const pastRealizationFeedbackPath = `/past_realization_feedback`;

export const login = (identity: string, password: string) => ({
  types: LOGIN as typeof LOGIN,
  payload: {
    request: {
      data: { identity, password },
      method: "post",
      url: `${API_URL}/login`,
    },
  },
});

export const logout = () => ({
  types: LOGOUT as typeof LOGOUT,
  payload: {
    request: {
      data: {},
      method: "delete",
      url: `${API_URL}/logout`,
    },
  },
});

export const fetchInitialData = () => ({
  types: FETCH_INITIAL_DATA as typeof FETCH_INITIAL_DATA,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/students/initial_data`,
    },
  },
});

export const updatePhoto = (photo: IFile) => {
  const args: { [key: string]: any } = {};
  if (photo.blob) {
    args["photo"] = photo.blob;
  }
  if (photo.deleted && photo.id) {
    args["delete_photo"] = photo.id;
  }
  return {
    types: UPDATE_PHOTO as typeof UPDATE_PHOTO,
    payload: {
      request: {
        data: args,
        method: "post",
        url: `${API_URL}/students/update_photo`,
      },
    },
  };
};

export const updateStudentPassword = (uuid: string, password: string) => ({
  types: UPDATE_STUDENT_PASSWORD as typeof UPDATE_STUDENT_PASSWORD,
  payload: {
    request: {
      data: { uuid, password },
      method: "PUT",
      url: `${API_URL}/passwords`,
    },
  },
});

export const updateReadLastNotification = (notification_id: number) => ({
  types: UPDATE_READ_LAST_NOTIFICATION as typeof UPDATE_READ_LAST_NOTIFICATION,
  payload: {
    request: {
      data: { notification_id },
      method: "post",
      url: `${API_URL}/students/update_read_last_notification`,
    },
  },
});
export const updateReadLastRealizationKlass = (
  realization_klass_id: number,
) => ({
  types:
    UPDATE_READ_LAST_REALIZATION_KLASS as typeof UPDATE_READ_LAST_REALIZATION_KLASS,
  payload: {
    request: {
      data: { realization_klass_id },
      method: "post",
      url: `${API_URL}/students/update_read_last_realization_klass`,
    },
  },
});

export const updateReadLastComment = (
  realization_id: number,
  comment_id: number,
) => ({
  types: UPDATE_READ_LAST_COMMENT as typeof UPDATE_READ_LAST_COMMENT,
  payload: {
    request: {
      data: { realization_id, comment_id },
      method: "post",
      url: `${API_URL}/realizations/update_read_last_comment`,
    },
  },
});

export const updateReadLastFeedback = (
  realization_id: number,
  feedback_id: number,
) => ({
  types: UPDATE_READ_LAST_FEEDBACK as typeof UPDATE_READ_LAST_FEEDBACK,
  payload: {
    request: {
      data: { realization_id, feedback_id },
      method: "post",
      url: `${API_URL}/realizations/update_read_last_feedback`,
    },
  },
});

export const clearSuccessMessage = () => ({
  types: CLEAR_SUCCESS_MESSAGE as typeof CLEAR_SUCCESS_MESSAGE,
  payload: {},
});

export const clearSuccessResult = () => ({
  types: CLEAR_SUCCESS_RESULT as typeof CLEAR_SUCCESS_RESULT,
  payload: {},
});

export const clearErrorMessage = () => ({
  types: CLEAR_ERROR_MESSAGE as typeof CLEAR_ERROR_MESSAGE,
  payload: {},
});

export const clearRealizationKlasses = () => ({
  types: CLEAR_REALIZATION_KLASSES as typeof CLEAR_REALIZATION_KLASSES,
  payload: {},
});

export const clearRealizationKlassesFiltered = () => ({
  types:
    CLEAR_REALIZATION_KLASSES_FILTERED as typeof CLEAR_REALIZATION_KLASSES_FILTERED,
  payload: {},
});

export const clearRealizationKlassesPast = () => ({
  types:
    CLEAR_REALIZATION_KLASSES_PAST as typeof CLEAR_REALIZATION_KLASSES_PAST,
  payload: {},
});

export const clearRealization = () => ({
  types: CLEAR_REALIZATION as typeof CLEAR_REALIZATION,
  payload: {},
});

export const clearComments = () => ({
  types: CLEAR_COMMENTS as typeof CLEAR_COMMENTS,
  payload: {},
});

export const clearFeedbacks = () => ({
  types: CLEAR_FEEDBACKS as typeof CLEAR_FEEDBACKS,
  payload: {},
});

export const clearRealizations = () => ({
  types: CLEAR_REALIZATIONS as typeof CLEAR_REALIZATIONS,
  payload: {},
});

export const clearAnalyzeRealizations = () => ({
  types: CLEAR_ANALYZE_REALIZATIONS as typeof CLEAR_ANALYZE_REALIZATIONS,
  payload: {},
});

export const clearAnalyzeRealizationsPagyInfo = () => ({
  types:
    CLEAR_ANALYZE_REALIZATIONS_PAGY_INFO as typeof CLEAR_ANALYZE_REALIZATIONS_PAGY_INFO,
  payload: {},
});

export const clearAnalyzeStudent = () => ({
  types: CLEAR_ANALYZE_STUDENT as typeof CLEAR_ANALYZE_STUDENT,
  payload: {},
});

export const clearSelectedGearbox = () => ({
  types: CLEAR_SELECTED_GEARBOX as typeof CLEAR_SELECTED_GEARBOX,
  payload: {},
});

export const clearPagyInfo = () => ({
  types: CLEAR_PAGY_INFO as typeof CLEAR_PAGY_INFO,
  payload: {},
});

export const fetchClasses = () => ({
  types: FETCH_CLASSES as typeof FETCH_CLASSES,
  payload: {
    request: {
      method: "get",
      data: {},
      url: `${API_URL}/students_sub_classes`,
    },
  },
});

export const fetchClassesPast = () => ({
  types: FETCH_CLASSES_PAST as typeof FETCH_CLASSES_PAST,
  payload: {
    request: {
      method: "get",
      data: {},
      url: `${API_URL}/students_sub_classes/past`,
    },
  },
});

export const addFavorite = (id: number) => ({
  types: ADD_FAVORITE as typeof ADD_FAVORITE,
  payload: {
    request: {
      data: {},
      method: "post",
      url: `${API_URL}/realizations/${id}/add_favorite`,
    },
  },
});

export const removeFavorite = (id: number) => ({
  types: REMOVE_FAVORITE as typeof REMOVE_FAVORITE,
  payload: {
    request: {
      data: {},
      method: "delete",
      url: `${API_URL}/realizations/${id}/remove_favorite`,
    },
  },
});

export const fetchRealization = (realization_id: number) => ({
  types: FETCH_REALIZATION as typeof FETCH_REALIZATION,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/realizations/${realization_id}`,
    },
  },
});

export const fetchRealizationCountByExperience = () => ({
  types:
    FETCH_REALIZATION_COUNT_BY_EXPERIENCE as typeof FETCH_REALIZATION_COUNT_BY_EXPERIENCE,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/realizations/count_by_experience/`,
    },
  },
});

export const fetchMyRealizations = () => ({
  types: FETCH_MY_REALIZATIONS as typeof FETCH_MY_REALIZATIONS,
  payload: {
    request: {
      data: {
        page: 1,
      },
      method: "get",
      url: `${API_URL}/realizations/`,
    },
  },
});

export const fetchRealizationCategoryBySummary = () => ({
  types:
    FETCH_REALIZATION_SUMMARY_BY_CATEGORY as typeof FETCH_REALIZATION_SUMMARY_BY_CATEGORY,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/realizations/summary_by_category`,
    },
  },
});

export const fetchRealizationHistories = () => ({
  types: FETCH_REALIZATION_HISTORIES as typeof FETCH_REALIZATION_HISTORIES,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/realizations/histories`,
    },
  },
});

export const fetchCategorizedRealization = (
  category_id: number,
  name = "",
  page = 1,
  converted?: boolean,
  executed?: boolean,
  roots?: boolean,
) => {
  const data: any = { category_id, page, name };
  if (converted) data.converted = converted;
  if (executed) data.executed = executed;
  if (roots) data.roots = roots;
  return {
    types:
      FETCH_CATEGORIZED_REALIZATION as typeof FETCH_CATEGORIZED_REALIZATION,
    payload: {
      request: {
        data,
        method: "get",
        url: `${API_URL}/realizations`,
      },
    },
  };
};

export const fetchNotifications = () => ({
  types: FETCH_NOTIFICATIONS as typeof FETCH_NOTIFICATIONS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/notifications`,
    },
  },
});

export const fetchCommentNotifications = () => ({
  types: FETCH_COMMENT_NOTIFICATIONS as typeof FETCH_COMMENT_NOTIFICATIONS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/comments/unread_by_realization`,
    },
  },
});

export const fetchGearBoxes = (args?: IStudentGearboxParams) => {
  return {
    types: FETCH_GEAR_BOXES as typeof FETCH_GEAR_BOXES,
    payload: {
      request: {
        method: "get",
        data: args,
        url: `${API_URL}/gearboxes`,
      },
    },
  };
};

export const fetchTimelineBoxDetail = (
  gearbox_id: number,
  page = 1,
  name = "",
) => ({
  types: FETCH_TIMELINE_BOX_DETAIL as typeof FETCH_TIMELINE_BOX_DETAIL,
  payload: {
    request: {
      method: "get",
      data: { page, name },
      url: `${API_URL}/gearboxes/${gearbox_id}`,
    },
  },
});

export const fetchBoxRealization = (
  gearbox_id: number,
  page = 1,
  name = "",
  converted?: boolean,
  executed?: boolean,
  roots?: boolean,
) => {
  const data: any = { gearbox_id, page, name };
  if (converted) data.converted = converted;
  if (executed) data.executed = executed;
  if (roots) data.roots = roots;
  return {
    types: FETCH_BOX_REALIZATION as typeof FETCH_BOX_REALIZATION,
    payload: {
      request: {
        method: "get",
        data,
        url: `${API_URL}/realizations`,
      },
    },
  };
};

export const fetchGearthemeRealization = (
  geartheme_id: number,
  prev_id: number | null,
  name = "",
) => {
  return {
    types: FETCH_GEAR_THEME_REALIZATION as typeof FETCH_GEAR_THEME_REALIZATION,
    payload: {
      request: {
        method: "get",
        data: {
          geartheme_id,
          ...(prev_id && { prev_id }),
          ...(name && { name }),
        },
        url: `${API_URL}/realizations/geartheme`,
      },
    },
  };
};

export const createRealization = (
  params: IRealizationCreateParams,
  submit_teacher_ids: number[],
  share_class_ids: number[],
  gearbox_id: number | null,
  image?: IRealizationImage,
  geartheme_id?: number,
) => {
  return {
    types: CREATE_REALIZATION as typeof CREATE_REALIZATION,
    payload: {
      request: {
        data: {
          ...params,
          submit_teacher_ids,
          share_class_ids,
          gearbox_id,
          ...(image ? { image: image.blob } : {}),
          ...(geartheme_id ? { geartheme_id } : {}),
        },
        method: "post",
        url: `${API_URL}/realizations`,
      },
    },
  };
};

export const createReport = (params: {
  realization_id?: number;
  comment_id?: number;
}) => ({
  types: CREATE_REPORT as typeof CREATE_REPORT,
  payload: {
    request: {
      data: { ...params },
      method: "post",
      url: `${API_URL}/reports`,
    },
  },
});

export const updateRealization = (
  params: IRealizationUpdate,
  submit_teacher_ids: number[],
  share_class_ids: number[],
) => ({
  types: UPDATE_REALIZATION as typeof UPDATE_REALIZATION,
  payload: {
    request: {
      data: {
        ...params,
        ...(params.image ? { image: params.image.blob } : {}),
        delete_image: params.delete_image || false,
        submit_teacher_ids,
        share_class_ids,
      },
      method: "put",
      url: `${API_URL}/realizations/${params.id}`,
    },
  },
});

export const updateReadAtToRealizationFeedback = (
  realization_feedback_id: number,
) => ({
  types:
    UPDATE_READ_AT_TO_REALIZATION_FEEDBACK as typeof UPDATE_READ_AT_TO_REALIZATION_FEEDBACK,
  payload: {
    request: {
      method: "put",
      url: `${API_URL}/realization_feedbacks/${realization_feedback_id}/read`,
    },
  },
});

export const bulkUpdateReadAtToRealizationFeedbacks = () => ({
  types:
    BULK_UPDATE_READ_AT_TO_REALIZATION_FEEDBACKS as typeof BULK_UPDATE_READ_AT_TO_REALIZATION_FEEDBACKS,
  payload: {
    request: {
      method: "put",
      url: `${API_URL}/realization_feedbacks/bulk_read`,
    },
  },
});

export const deleteRealization = (realization_id: number) => ({
  types: DELETE_REALIZATION as typeof DELETE_REALIZATION,
  payload: {
    request: {
      data: {},
      method: "delete",
      url: `${API_URL}/realizations/${realization_id}`,
    },
  },
});

export const shareRealization = (realization_id: number) => ({
  types: SHARE_REALIZATION as typeof SHARE_REALIZATION,
  payload: {
    request: {
      method: "post",
      data: {},
      url: `${API_URL}/realizations/${realization_id}/share`,
    },
  },
});

export const executeRealization = (realization_id: number) => ({
  types: EXECUTE_REALIZATION as typeof EXECUTE_REALIZATION,
  payload: {
    request: {
      data: {},
      method: "post",
      url: `${API_URL}/realizations/${realization_id}/execute`,
    },
  },
});

export const fetchCategories = () => ({
  types: FETCH_CATEGORIES as typeof FETCH_CATEGORIES,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/categories`,
    },
  },
});

export const fetchAnalyzeStudent = (
  start_date?: string | null,
  end_date?: string | null,
) => {
  const data = start_date && end_date ? { start_date, end_date } : {};
  return {
    types: FETCH_ANALYZE_STUDENT as typeof FETCH_ANALYZE_STUDENT,
    payload: {
      request: {
        data,
        method: "get",
        url: `${API_URL}/analyze/students`,
      },
    },
  };
};

export const fetchAnalyzeRealizations = (
  gearbox_id?: string | number | null,
  category_id?: string | number | null,
  start_date?: string | null,
  end_date?: string | null,
  page?: number,
) => {
  const data = {
    ...(gearbox_id && { gearbox_id }),
    ...(category_id && { category_id }),
    ...(start_date && end_date && { start_date, end_date }),
    page: page ?? 1,
  };
  return {
    types: FETCH_ANALYZE_REALIZATIONS as typeof FETCH_ANALYZE_REALIZATIONS,
    payload: {
      request: {
        data,
        method: "get",
        url: `${API_URL}/analyze/realizations`,
      },
    },
  };
};

export const fetchAnalyzeGearboxes = () => ({
  types: FETCH_ANALYZE_GEARBOXES as typeof FETCH_ANALYZE_GEARBOXES,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/analyze/gearboxes`,
    },
  },
});

export const fetchCategoriesWithCount = () => ({
  types: FETCH_CATEGORIES_WITH_COUNT as typeof FETCH_CATEGORIES_WITH_COUNT,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/categories/index_with_count`,
    },
  },
});

export const subscribeRealizationKlasses = () => ({
  types: SUBSCRIBE_REALIZATION_KLASSES as typeof SUBSCRIBE_REALIZATION_KLASSES,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/realization_klasses/latest`,
    },
  },
});

export const fetchRealizationKlasses = (
  class_id: number,
  prev_time: string | null,
  name?: string,
) => ({
  types: FETCH_REALIZATION_KLASSES as typeof FETCH_REALIZATION_KLASSES,
  payload: {
    request: {
      data: {
        class_id,
        ...(prev_time && { prev_time }),
        ...(name && { name }),
      },
      method: "get",
      url: `${API_URL}/realization_klasses`,
    },
  },
});

export const fetchRealizationKlassesPast = (
  past_class_id: number,
  prev_time: string | null,
) => ({
  types:
    FETCH_REALIZATION_KLASSES_PAST as typeof FETCH_REALIZATION_KLASSES_PAST,
  payload: {
    request: {
      data: { past_class_id, ...(prev_time && { prev_time }) },
      method: "get",
      url: `${API_URL}/realization_klasses/past`,
    },
  },
});

export const fetchRealizationKlassesFiltered = (
  prev_time: string | null,
  select_action: TimelineSelectAction,
) => ({
  types:
    FETCH_REALIZATION_KLASSES_FILTERED as typeof FETCH_REALIZATION_KLASSES_FILTERED,
  payload: {
    request: {
      data: {
        ...(prev_time && { prev_time }),
        select_action,
      },
      method: "get",
      url: `${API_URL}/realization_klasses/filtered`,
    },
  },
});

export const fetchComments = (realization_id: number) => ({
  types: FETCH_COMMENTS as typeof FETCH_COMMENTS,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/comments?realization_id=${realization_id}`,
    },
  },
});

export const createComment = (realization_id: number, content: string) => ({
  types: CREATE_COMMENT as typeof CREATE_COMMENT,
  payload: {
    request: {
      data: { realization_id, content },
      method: "post",
      url: `${API_URL}/comments`,
    },
  },
});

export const updateComment = (comment_id: number, content: string) => ({
  types: UPDATE_COMMENT as typeof UPDATE_COMMENT,
  payload: {
    request: {
      data: { content },
      method: "put",
      url: `${API_URL}/comments/${comment_id}`,
    },
  },
});

export const deleteComment = (comment_id: number) => ({
  types: DELETE_COMMENT as typeof DELETE_COMMENT,
  payload: {
    request: {
      data: {},
      method: "delete",
      url: `${API_URL}/comments/${comment_id}`,
    },
  },
});

export const fetchFeedbacks = (realization_id: number) => ({
  types: FETCH_FEEDBACKS as typeof FETCH_FEEDBACKS,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/feedbacks?realization_id=${realization_id}`,
    },
  },
});

export const createFeedback = (realization_id: number, content: string) => ({
  types: CREATE_FEEDBACK as typeof CREATE_FEEDBACK,
  payload: {
    request: {
      data: { realization_id, content },
      method: "post",
      url: `${API_URL}/feedbacks`,
    },
  },
});

export const updateFeedback = (feedback_id: number, content: string) => ({
  types: UPDATE_FEEDBACK as typeof UPDATE_FEEDBACK,
  payload: {
    request: {
      data: { content },
      method: "put",
      url: `${API_URL}/feedbacks/${feedback_id}`,
    },
  },
});

export const deleteFeedback = (feedback_id: number) => ({
  types: DELETE_FEEDBACK as typeof DELETE_FEEDBACK,
  payload: {
    request: {
      data: {},
      method: "delete",
      url: `${API_URL}/feedbacks/${feedback_id}`,
    },
  },
});

export const createRealizationStampReaction = (
  realization_id: number,
  data: CreateRealizationStampReactionParams,
) => ({
  types:
    CREATE_REALIZATION_STAMP_REACTION as typeof CREATE_REALIZATION_STAMP_REACTION,
  payload: {
    request: {
      data,
      method: "post",
      url: `${API_URL}/realizations/${realization_id}/realization_stamp_reactions`,
    },
  },
});

export const createCategory = (params: { name: string; color: string }) => ({
  types: CREATE_CATEGORY as typeof CREATE_CATEGORY,
  payload: {
    request: {
      data: { ...params },
      method: "post",
      url: `${API_URL}/categories`,
    },
  },
});

export const updateCategory = (
  category_id: number,
  params: { name: string; color: string },
) => ({
  types: UPDATE_CATEGORY as typeof UPDATE_CATEGORY,
  payload: {
    request: {
      data: { ...params },
      method: "put",
      url: `${API_URL}/categories/${category_id}`,
    },
  },
});

export const destroyCategory = (category_id: number) => ({
  types: DESTROY_CATEGORY as typeof DESTROY_CATEGORY,
  payload: {
    request: {
      data: {},
      method: "delete",
      url: `${API_URL}/categories/${category_id}`,
    },
  },
});

export const reorderCategories = (categories: string) => ({
  types: REORDER_CATEGORIES as typeof REORDER_CATEGORIES,
  payload: {
    request: {
      data: { categories },
      method: "post",
      url: `${API_URL}/categories/reorder`,
    },
  },
});

export const fetchTeachers = () => ({
  types: FETCH_TEACHERS as typeof FETCH_TEACHERS,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/teachers`,
    },
  },
});

export const fetchAssessment = () => ({
  types: FETCH_ASSESSMENT as typeof FETCH_ASSESSMENT,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/assessments/current`,
    },
  },
});

export const fetchAssessmentPast = () => ({
  types: FETCH_ASSESSMENT_PAST as typeof FETCH_ASSESSMENT_PAST,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/assessments/in_past`,
    },
  },
});

export const updateReadGearTutorial = (tutorials: string[]) => ({
  types: UPDATE_READ_GEAR_TUTORIALS as typeof UPDATE_READ_GEAR_TUTORIALS,
  payload: {
    request: {
      data: { tutorials: JSON.stringify(tutorials) },
      method: "PUT",
      url: `${API_URL}/students/update_read_gear_tutorials`,
    },
  },
});

export const updateReadGearReleaseNote = () => ({
  types: UPDATE_READ_GEAR_RELEASE_NOTE as typeof UPDATE_READ_GEAR_RELEASE_NOTE,
  payload: {
    request: {
      data: {},
      method: "PUT",
      url: `${API_URL}/students/update_read_gear_release_note`,
    },
  },
});

export const updateReadReleaseAds = (release_ad_ids: number[]) => ({
  types: UPDATE_READ_RELEASE_ADS as typeof UPDATE_READ_RELEASE_ADS,
  payload: {
    request: {
      data: { release_ad_ids },
      method: "PUT",
      url: `${API_URL}/students/update_read_release_ads`,
    },
  },
});

export const submitExperience = (data: IAssessmentBulkUpdate) => {
  return {
    types: SUBMIT_EXPERIENCE as typeof SUBMIT_EXPERIENCE,
    payload: {
      request: {
        data,
        method: "PUT",
        url: `${API_URL}/assessments/bulk_update_current`,
      },
    },
  };
};

export const fetchReferenceRealizations = () => {
  return {
    types: FETCH_REFERENCE_REALIZATIONS as typeof FETCH_REFERENCE_REALIZATIONS,
    payload: {
      request: {
        data: {},
        method: "get",
        url: `${API_URL}/reference_realizations`,
      },
    },
  };
};

export const fetchPreferredRealizations = (prev_time: string | null) => {
  return {
    types: FETCH_PREFERRED_REALIZATIONS as typeof FETCH_PREFERRED_REALIZATIONS,
    payload: {
      request: {
        data: { ...(prev_time && { prev_time }) },
        method: "get",
        url: `${API_URL}/preferred_realizations`,
      },
    },
  };
};

export const createRealizationFeedbacks = () => ({
  types: CREATE_REALIZATION_FEEDBACKS as typeof CREATE_REALIZATION_FEEDBACKS,
  payload: {
    request: {
      method: "post",
      url: `${API_URL}/students/create_realization_feedbacks`,
    },
  },
});

export const fetchRealizationFeedbacks = () => ({
  types: FETCH_REALIZATION_FEEDBACKS as typeof FETCH_REALIZATION_FEEDBACKS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/realization_feedbacks`,
    },
  },
});

type fetchInitialDataSuccess = {
  types: typeof FETCH_INITIAL_DATA_SUCCESS;
  payload: {
    data: {
      student: IStudent;
      school_name: string;
      has_left_classes: boolean;
      class_name: string;
      unread_comments_count: number;
      unread_notification_count: number;
      unread_realization_klass_count: number;
      geartheme: IGearTheme;
      stock_c_to_c_enabled: boolean;
      realization_feedback_creatable: boolean;
    };
  };
};

type fetchInitialDataFailure = {
  types: typeof FETCH_INITIAL_DATA_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type loginSuccess = {
  types: typeof LOGIN_SUCCESS;
  payload: {
    data: {
      uuid?: string;
      session_id?: string;
      student?: IStudent;
    };
  };
};

type loginFailure = {
  types: typeof LOGIN_FAILURE;
  payload: {
    error: {
      message: string;
      field: string;
      status: number;
    };
  };
};

type logoutSuccess = {
  types: typeof LOGOUT_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type fetchTeachersSuccess = {
  types: typeof FETCH_TEACHERS_SUCCESS;
  payload: {
    data: { teachers: ITeacher[] };
  };
};

type fetchTeachersFailure = {
  types: typeof FETCH_TEACHERS_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchClassesSuccess = {
  types: typeof FETCH_CLASSES_SUCCESS;
  payload: {
    data: { classes: IClass[]; class: IClass };
  };
};

type fetchClassesPastSuccess = {
  types: typeof FETCH_CLASSES_PAST_SUCCESS;
  payload: {
    data: { past_classes: IClass[] };
  };
};

type updatePhotoSuccess = {
  types: typeof UPDATE_PHOTO_SUCCESS;
};

type updatePhotoFailure = {
  types: typeof UPDATE_PHOTO_FAILURE;
};

type updateStudentPasswordSuccess = {
  types: typeof UPDATE_STUDENT_PASSWORD_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type updateReadLastNotificationSuccess = {
  types: typeof UPDATE_READ_LAST_NOTIFICATION_SUCCESS;
  payload: {
    data: { student: IStudent };
  };
};

type updateReadLastRealizationKlassSuccess = {
  types: typeof UPDATE_READ_LAST_REALIZATION_KLASS_SUCCESS;
  payload: {
    data: { student: IStudent };
  };
};

type updateReadLastCommentSuccess = {
  types: typeof UPDATE_READ_LAST_COMMENT_SUCCESS;
  payload: {
    data: { realization: IRealization };
  };
};

type updateReadLastFeedbackSuccess = {
  types: typeof UPDATE_READ_LAST_FEEDBACK_SUCCESS;
  payload: {
    data: { realization: IRealization };
  };
};

type fetchCategoriesSuccess = {
  types: typeof FETCH_CATEGORIES_SUCCESS;
  payload: {
    data: {
      categories: ICategory[];
    };
  };
};

type fetchAnalyzeStudentSuccess = {
  types: typeof FETCH_ANALYZE_STUDENT_SUCCESS;
  payload: {
    data: { student_analyze: IStudentAnalyze };
  };
};

type fetchAnalyzeRealizationsSuccess = {
  types: typeof FETCH_ANALYZE_REALIZATIONS_SUCCESS;
  payload: {
    data: {
      realizations: IRealizationAnalyze[];
      meta: { pagy_info: IPagyInfo };
    };
  };
};

type fetchAnalyzeGearboxesSuccess = {
  types: typeof FETCH_ANALYZE_GEARBOXES_SUCCESS;
  payload: {
    data: { gearboxes: ITeacherGearbox[] };
  };
};

type fetchAssessmentSuccess = {
  types: typeof FETCH_ASSESSMENT_SUCCESS;
  payload: {
    data: {
      assessment: IAssessment;
    };
  };
};

type fetchAssessmentPastSuccess = {
  types: typeof FETCH_ASSESSMENT_PAST_SUCCESS;
  payload: {
    data: {
      assessments: IAssessmentPast[];
    };
  };
};

type submitExperienceSuccess = {
  types: typeof SUBMIT_EXPERIENCE_SUCCESS;
  payload: {
    data: {
      assessment: IAssessment;
    };
  };
};

type fetchCategoriesFailure = {
  types: typeof FETCH_CATEGORIES_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchCategoriesWithCountSuccess = {
  types: typeof FETCH_CATEGORIES_WITH_COUNT_SUCCESS;
  payload: {
    data: {
      categories: ICategory[];
    };
  };
};

type fetchCategoriesWithCountFailure = {
  types: typeof FETCH_CATEGORIES_WITH_COUNT_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchRealizationSuccess = {
  types: typeof FETCH_REALIZATION_SUCCESS;
  payload: {
    data: {
      realization: IRealization;
    };
  };
};

type fetchMyRealizationsSuccess = {
  types: typeof FETCH_MY_REALIZATIONS_SUCCESS;
  payload: {
    data: {
      data: {
        realizations: IRealization[];
      };
    };
  };
};

type fetchMyRealizationsFailure = {
  types: typeof FETCH_MY_REALIZATIONS_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchRealizationCountByExperienceSuccess = {
  types: typeof FETCH_REALIZATION_COUNT_BY_EXPERIENCE_SUCCESS;
  payload: {
    data: {
      realization_count_by_exp: IRealizationCountByExp;
    };
  };
};

type fetchRealizationCountByExperienceFailure = {
  types: typeof FETCH_REALIZATION_COUNT_BY_EXPERIENCE_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchRealizationSummarySuccess = {
  types: typeof FETCH_REALIZATION_SUMMARY_BY_CATEGORY_SUCCESS;
  payload: {
    data: {
      realization_summaries: IRealizationSummary[];
    };
  };
};

type fetchRealizationFailure = {
  types: typeof FETCH_REALIZATION_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchCategorizedRealizationSuccess = {
  types: typeof FETCH_CATEGORIZED_REALIZATION_SUCCESS;
  payload: {
    data: {
      data: {
        realizations: IRealization[];
      };
      meta: {
        pagy_info: IPagyInfo;
      };
    };
  };
};

type subscribeRealizationKlassesFailure = {
  types: typeof SUBSCRIBE_REALIZATION_KLASSES_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};
type subscribeRealizationKlassesSuccess = {
  types: typeof SUBSCRIBE_REALIZATION_KLASSES_SUCCESS;
  payload: {
    data: {
      unread_realization_klass_count: number;
      realization_feedback_creatable: boolean;
    };
  };
};

type fetchRealizationKlassesSuccess = {
  types: typeof FETCH_REALIZATION_KLASSES_SUCCESS;
  payload: {
    params: {
      prev_time?: string;
    };
    data: {
      data: {
        realization_klasses: IRealizationKlass[];
      };
      has_next: boolean;
    };
  };
};

type fetchRealizationKlassesFilteredSuccess = {
  types: typeof FETCH_REALIZATION_KLASSES_FILTERED_SUCCESS;
  payload: {
    params: {
      prev_time?: string;
    };
    data: {
      data: {
        realization_klasses: IRealizationKlass[];
      };
      has_next: boolean;
    };
  };
};

type fetchRealizationKlassesPastSuccess = {
  types: typeof FETCH_REALIZATION_KLASSES_PAST_SUCCESS;
  payload: {
    data: {
      data: {
        realization_klasses: IRealizationKlass[];
      };
      has_next: boolean;
    };
  };
};

type fetchNotificationsSuccess = {
  types: typeof FETCH_NOTIFICATIONS_SUCCESS;
  payload: {
    data: {
      notifications: INotification[];
    };
  };
};

type fetchCommentNotificationsSuccess = {
  types: typeof FETCH_COMMENT_NOTIFICATIONS_SUCCESS;
  payload: {
    data: {
      comments: ICommentNotification[];
    };
  };
};

type fetchCommentsSuccess = {
  types: typeof FETCH_COMMENTS_SUCCESS;
  payload: {
    data: { comments: IComment[]; unread_comments_count: number };
    params: { url: string };
  };
};

type fetchCommentsFailure = {
  types: typeof FETCH_COMMENTS_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type createReportSuccess = {
  types: typeof CREATE_REPORT_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};
type createCommentSuccess = {
  types: typeof CREATE_COMMENT_SUCCESS;
  payload: {
    data: { realization_id: number };
  };
};

type createCommentFailure = {
  types: typeof CREATE_COMMENT_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type updateCommentSuccess = {
  types: typeof UPDATE_COMMENT_SUCCESS;
  payload: {
    data: { realization_id: number };
  };
};

type updateCommentFailure = {
  types: typeof UPDATE_COMMENT_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type deleteCommentSuccess = {
  types: typeof DELETE_COMMENT_SUCCESS;
  payload: {
    data: { realization_id: number; has_my_comment: boolean };
  };
};

type deleteCommentFailure = {
  types: typeof DELETE_COMMENT_FAILURE;
  payload: {
    error: { message: string };
  };
};

type fetchFeedbacksSuccess = {
  types: typeof FETCH_FEEDBACKS_SUCCESS;
  payload: {
    data: { feedbacks: IFeedback[] };
    params: { url: string };
  };
};

type fetchFeedbacksFailure = {
  types: typeof FETCH_FEEDBACKS_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type createFeedbackSuccess = {
  types: typeof CREATE_FEEDBACK_SUCCESS;
  payload: {
    data: { realization_id: number };
  };
};

type createFeedbackFailure = {
  types: typeof CREATE_FEEDBACK_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type updateFeedbackSuccess = {
  types: typeof UPDATE_FEEDBACK_SUCCESS;
  payload: {
    data: { feedback: IFeedback };
  };
};

type updateFeedbackFailure = {
  types: typeof UPDATE_FEEDBACK_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type deleteFeedbackSuccess = {
  types: typeof DELETE_FEEDBACK_SUCCESS;
  payload: {
    data: { realization_id: number; has_my_feedback: boolean };
  };
};

type deleteFeedbackFailure = {
  types: typeof DELETE_FEEDBACK_FAILURE;
  payload: {
    error: { message: string };
  };
};

type createRealizationStampReactionSuccess = {
  types: typeof CREATE_REALIZATION_STAMP_REACTION_SUCCESS;
  payload: {
    data: { id: number; realization_id: number };
  };
};

type createRealizationStampReactionFailure = {
  types: typeof CREATE_REALIZATION_STAMP_REACTION_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchCategorizedRealizationFailure = {
  types: typeof FETCH_CATEGORIZED_REALIZATION_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type createRealizationSuccess = {
  types: typeof CREATE_REALIZATION_SUCCESS;
  payload: {
    data: { realization: IRealization };
  };
};

type createRealizationFailure = {
  types: typeof CREATE_REALIZATION_FAILURE;
  payload: {
    error: { message: string };
  };
};

type fetchRealizationHistoriesSuccess = {
  types: typeof FETCH_REALIZATION_HISTORIES_SUCCESS;
  payload: {
    data: { realization_histories: IRealizationHistory[] };
  };
};

type updateRealizationSuccess = {
  types: typeof UPDATE_REALIZATION_SUCCESS;
  payload: {
    data: { realization: IRealization };
  };
};

type updateReadAtToRealizationFeedbackSuccess = {
  types: typeof UPDATE_READ_AT_TO_REALIZATION_FEEDBACK_SUCCESS;
  payload: {
    data: { realization_feedback_id: number };
  };
};

type bulkUpdateReadAtToRealizationFeedbacksSuccess = {
  types: typeof UPDATE_READ_AT_TO_REALIZATION_FEEDBACK_SUCCESS;
};

type deleteRealizationSuccess = {
  types: typeof DELETE_REALIZATION_SUCCESS;
  payload: {
    data: { id: number };
  };
};

type deleteRealizationFailure = {
  types: typeof DELETE_REALIZATION_FAILURE;
  payload: {
    error: { message: string };
  };
};

type addFavoriteSuccess = {
  types: typeof ADD_FAVORITE_SUCCESS;
  payload: {
    params: { url: string };
  };
};

type removeFavoriteSuccess = {
  types: typeof REMOVE_FAVORITE_SUCCESS;
  payload: {
    params: { url: string };
  };
};

type updateRealizationFailure = {
  types: typeof UPDATE_REALIZATION_FAILURE;
  payload: {
    error: { message: string };
  };
};

type shareRealizationSuccess = {
  types: typeof SHARE_REALIZATION_SUCCESS;
  payload: {
    data: { realization: IRealization };
  };
};

type shareRealizationFailure = {
  types: typeof SHARE_REALIZATION_FAILURE;
  payload: {
    error: { message: string };
  };
};

type executeRealizationSuccess = {
  types: typeof EXECUTE_REALIZATION_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type executeRealizationFailure = {
  types: typeof EXECUTE_REALIZATION_FAILURE;
  payload: {
    error: { message: string };
  };
};

type createCategorySuccess = {
  types: typeof CREATE_CATEGORY_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type createCategoryFailure = {
  types: typeof CREATE_CATEGORY_FAILURE;
  payload: {
    error: { message: string };
  };
};

type updateCategorySuccess = {
  types: typeof UPDATE_CATEGORY_SUCCESS;
  payload: {
    data: {
      color: string;
      id: number;
      name: string;
    };
  };
};

type updateCategoryFailure = {
  types: typeof UPDATE_CATEGORY_FAILURE;
  payload: {
    error: { message: string };
  };
};

type destroyCategorySuccess = {
  types: typeof DESTROY_CATEGORY_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type destroyCategoryFailure = {
  types: typeof DESTROY_CATEGORY_FAILURE;
  payload: {
    error: { message: string };
  };
};

type reorderCategoriesSuccess = {
  types: typeof REORDER_CATEGORIES_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type reorderCategoriesFailure = {
  types: typeof REORDER_CATEGORIES_FAILURE;
  payload: {
    error: { message: string };
  };
};

type updateReadGearTutorialSuccess = {
  types: typeof UPDATE_READ_GEAR_TUTORIALS_SUCCESS;
  payload: {
    data: {
      data: {
        read_gear_tutorials: string[];
      };
    };
  };
};

type updateReadReleaseAdsSuccess = {
  types: typeof UPDATE_READ_RELEASE_ADS_SUCCESS;
};

type updateReadGearReleaseNoteSuccess = {
  types: typeof UPDATE_READ_GEAR_RELEASE_NOTE_SUCCESS;
  payload: {
    data: {
      read_gear_release_note: boolean;
    };
  };
};

type fetchGearBoxesSuccess = {
  types: typeof FETCH_GEAR_BOXES_SUCCESS;
  payload: {
    data: {
      gearboxes: IStudentGearBox[];
    };
  };
};

type fetchBoxRealizationSuccess = {
  types: typeof FETCH_BOX_REALIZATION_SUCCESS;
  payload: {
    data: {
      data: {
        realizations: IRealization[];
      };
      meta: {
        pagy_info: IPagyInfo;
      };
    };
  };
};

type fetchTimelineBoxDetailSuccess = {
  types: typeof FETCH_TIMELINE_BOX_DETAIL_SUCCESS;
  payload: {
    data: {
      gearbox: IStudentGearBox;
      meta: {
        pagy_info: IPagyInfo;
      };
    };
  };
};

type fetchGearthemeRealizationSuccess = {
  types: typeof FETCH_GEAR_THEME_REALIZATION_SUCCESS;
  payload: {
    params: {
      prev_id?: number;
    };
    data: {
      geartheme: {
        id: number;
        realizations: IRealization[];
        title: string;
      };
      has_next: boolean;
    };
  };
};

type fetchReferenceRealizationsSuccess = {
  types: typeof FETCH_REFERENCE_REALIZATIONS_SUCCESS;
  payload: {
    data: {
      reference_realizations: ReferenceRealization[];
    };
  };
};

type fetchPreferredRealizationsSuccess = {
  types: typeof FETCH_PREFERRED_REALIZATIONS_SUCCESS;
  payload: {
    params: {
      prev_time?: string;
    };
    data: {
      preferred_realizations: PreferredRealization[];
    };
  };
};

type createRealizationFeedbacksSuccess = {
  types: typeof CREATE_REALIZATION_FEEDBACKS_SUCCESS;
};

type createRealizationFeedbacksFailure = {
  types: typeof CREATE_REALIZATION_FEEDBACKS_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchRealizationFeedbacksSuccess = {
  types: typeof FETCH_REALIZATION_FEEDBACKS_SUCCESS;
  payload: {
    data: {
      student: IStudentRealizationFeedback;
    };
  };
};

type action503 = {
  types: typeof ACTION_503;
};

export type Actions =
  | ReturnType<typeof login>
  | ReturnType<typeof fetchInitialData>
  | ReturnType<typeof updatePhoto>
  | ReturnType<typeof updateStudentPassword>
  | ReturnType<typeof updateReadLastRealizationKlass>
  | ReturnType<typeof updateReadLastNotification>
  | ReturnType<typeof updateReadLastComment>
  | ReturnType<typeof updateReadLastFeedback>
  | ReturnType<typeof clearSuccessMessage>
  | ReturnType<typeof clearSuccessResult>
  | ReturnType<typeof clearErrorMessage>
  | ReturnType<typeof clearRealizationKlasses>
  | ReturnType<typeof clearRealizationKlassesFiltered>
  | ReturnType<typeof clearRealizationKlassesPast>
  | ReturnType<typeof clearRealization>
  | ReturnType<typeof clearComments>
  | ReturnType<typeof clearFeedbacks>
  | ReturnType<typeof clearRealizations>
  | ReturnType<typeof clearAnalyzeRealizations>
  | ReturnType<typeof clearAnalyzeRealizationsPagyInfo>
  | ReturnType<typeof clearAnalyzeStudent>
  | ReturnType<typeof clearSelectedGearbox>
  | ReturnType<typeof clearPagyInfo>
  | ReturnType<typeof logout>
  | ReturnType<typeof fetchAssessment>
  | ReturnType<typeof fetchAssessmentPast>
  | ReturnType<typeof fetchTeachers>
  | ReturnType<typeof fetchClasses>
  | ReturnType<typeof fetchNotifications>
  | ReturnType<typeof fetchCommentNotifications>
  | ReturnType<typeof fetchCategories>
  | ReturnType<typeof fetchCategoriesWithCount>
  | ReturnType<typeof fetchAnalyzeStudent>
  | ReturnType<typeof fetchAnalyzeRealizations>
  | ReturnType<typeof fetchAnalyzeGearboxes>
  | ReturnType<typeof fetchRealization>
  | ReturnType<typeof fetchRealizationCountByExperience>
  | ReturnType<typeof fetchMyRealizations>
  | ReturnType<typeof fetchRealizationCategoryBySummary>
  | ReturnType<typeof fetchRealizationHistories>
  | ReturnType<typeof fetchCategorizedRealization>
  | ReturnType<typeof subscribeRealizationKlasses>
  | ReturnType<typeof fetchRealizationKlasses>
  | ReturnType<typeof fetchRealizationKlassesPast>
  | ReturnType<typeof fetchClassesPast>
  | ReturnType<typeof fetchComments>
  | ReturnType<typeof createComment>
  | ReturnType<typeof updateComment>
  | ReturnType<typeof deleteComment>
  | ReturnType<typeof fetchFeedbacks>
  | ReturnType<typeof createFeedback>
  | ReturnType<typeof updateFeedback>
  | ReturnType<typeof deleteFeedback>
  | ReturnType<typeof createRealizationStampReaction>
  | ReturnType<typeof createReport>
  | ReturnType<typeof createRealization>
  | ReturnType<typeof updateRealization>
  | ReturnType<typeof deleteRealization>
  | ReturnType<typeof addFavorite>
  | ReturnType<typeof removeFavorite>
  | ReturnType<typeof shareRealization>
  | ReturnType<typeof executeRealization>
  | ReturnType<typeof createCategory>
  | ReturnType<typeof updateCategory>
  | ReturnType<typeof destroyCategory>
  | ReturnType<typeof reorderCategories>
  | ReturnType<typeof updateReadGearTutorial>
  | ReturnType<typeof updateReadGearReleaseNote>
  | ReturnType<typeof fetchGearBoxes>
  | ReturnType<typeof fetchBoxRealization>
  | ReturnType<typeof fetchTimelineBoxDetail>
  | ReturnType<typeof fetchGearthemeRealization>
  | ReturnType<typeof fetchReferenceRealizations>
  | ReturnType<typeof fetchPreferredRealizations>
  | ReturnType<typeof fetchPreferredRealizations>
  | ReturnType<typeof createRealizationFeedbacks>
  | ReturnType<typeof fetchRealizationFeedbacks>
  | ReturnType<typeof updateReadReleaseAds>
  | loginSuccess
  | loginFailure
  | logoutSuccess
  | fetchInitialDataSuccess
  | updatePhotoSuccess
  | updatePhotoFailure
  | updateStudentPasswordSuccess
  | updateReadLastRealizationKlassSuccess
  | updateReadLastNotificationSuccess
  | updateReadLastCommentSuccess
  | updateReadLastFeedbackSuccess
  | fetchAssessmentSuccess
  | fetchAssessmentPastSuccess
  | submitExperienceSuccess
  | fetchInitialDataFailure
  | fetchTeachersSuccess
  | fetchTeachersFailure
  | fetchClassesSuccess
  | fetchNotificationsSuccess
  | fetchCommentNotificationsSuccess
  | fetchRealizationSummarySuccess
  | fetchRealizationHistoriesSuccess
  | subscribeRealizationKlassesSuccess
  | subscribeRealizationKlassesFailure
  | fetchRealizationKlassesSuccess
  | fetchRealizationKlassesFilteredSuccess
  | fetchRealizationKlassesPastSuccess
  | fetchClassesPastSuccess
  | fetchCommentsSuccess
  | fetchCommentsFailure
  | createCommentSuccess
  | createCommentFailure
  | updateCommentSuccess
  | updateCommentFailure
  | deleteCommentSuccess
  | deleteCommentFailure
  | fetchFeedbacksSuccess
  | fetchFeedbacksFailure
  | createFeedbackSuccess
  | createFeedbackFailure
  | updateFeedbackSuccess
  | updateFeedbackFailure
  | deleteFeedbackSuccess
  | deleteFeedbackFailure
  | createRealizationStampReactionSuccess
  | createRealizationStampReactionFailure
  | fetchCategoriesSuccess
  | fetchCategoriesFailure
  | fetchCategoriesWithCountSuccess
  | fetchCategoriesWithCountFailure
  | fetchAnalyzeStudentSuccess
  | fetchAnalyzeRealizationsSuccess
  | fetchAnalyzeGearboxesSuccess
  | fetchMyRealizationsSuccess
  | fetchMyRealizationsFailure
  | fetchRealizationCountByExperienceSuccess
  | fetchRealizationCountByExperienceFailure
  | fetchRealizationSuccess
  | fetchRealizationFailure
  | fetchCategorizedRealizationSuccess
  | fetchCategorizedRealizationFailure
  | createRealizationSuccess
  | createRealizationFailure
  | createReportSuccess
  | addFavoriteSuccess
  | removeFavoriteSuccess
  | updateRealizationSuccess
  | updateRealizationFailure
  | updateReadAtToRealizationFeedbackSuccess
  | bulkUpdateReadAtToRealizationFeedbacksSuccess
  | deleteRealizationSuccess
  | deleteRealizationFailure
  | shareRealizationSuccess
  | shareRealizationFailure
  | executeRealizationSuccess
  | executeRealizationFailure
  | createCategorySuccess
  | createCategoryFailure
  | updateCategorySuccess
  | updateCategoryFailure
  | destroyCategorySuccess
  | destroyCategoryFailure
  | reorderCategoriesSuccess
  | updateReadGearTutorialSuccess
  | updateReadReleaseAdsSuccess
  | updateReadGearReleaseNoteSuccess
  | reorderCategoriesFailure
  | fetchGearBoxesSuccess
  | fetchBoxRealizationSuccess
  | fetchTimelineBoxDetailSuccess
  | fetchGearthemeRealizationSuccess
  | fetchReferenceRealizationsSuccess
  | fetchPreferredRealizationsSuccess
  | createRealizationFeedbacksSuccess
  | createRealizationFeedbacksFailure
  | fetchRealizationFeedbacksSuccess
  | action503;

export type AppState = {
  session_id: string;
  error: string;
  error_field: string;
  success_message: string;
  success_result: { [key: string]: number };
  uuid: string;
  isServiceUnavailable: boolean;
  isLoaderVisible: boolean;
  student: IStudent;
  teachers: ITeacher[];
  classes: IClass[];
  past_classes: IClass[];
  school_name: string;
  class_name: string;
  categories: ICategory[];
  realization?: IRealization;
  realizations: IRealization[];
  realization_klasses: IRealizationKlass[];
  realization_klasses_past: IRealizationKlass[];
  student_realization_feedbacks: IStudentRealizationFeedback;
  unread_realization_klass_count: number;
  unread_comments_count: number;
  unread_notification_count: number;
  realization_summaries: IRealizationSummary[];
  realization_histories: IRealizationHistory[];
  commentNotifications: ICommentNotification[];
  notifications: INotification[];
  comments: IComment[];
  feedbacks: IFeedback[];
  has_left_classes: boolean;
  stock_c_to_c_enabled: boolean;
  realization_feedback_creatable: boolean;
  pagy_info: IPagyInfo;
  gearBoxes: IStudentGearBox[];
  analyzeGearboxes: ITeacherGearbox[];
  student_analyze: IStudentAnalyze | null;
  realizations_analyze: IRealizationAnalyze[];
  realizations_analyze_pagy_info: IPagyInfo;
  realization_count_by_exp: IRealizationCountByExp;
  lastAction: string;
  selected_gearbox: IStudentGearBox | null;
  timeline_pagination: ITimelinePagination;
  geartheme_pagination: ITimelinePagination;
  expires: Date;
  geartheme: IGearTheme | null;
  assessment: IAssessment;
  assessments_past: IAssessmentPast[];
  reference_realizations: ReferenceRealization[];
  preferred_realizations: PreferredRealization[];
};

export const InitialAppState: AppState = {
  session_id: "",
  error: "",
  error_field: "",
  success_message: "",
  success_result: {},
  isServiceUnavailable: false,
  isLoaderVisible: false,
  school_name: "",
  class_name: "",
  uuid: "",
  student: {
    id: 0,
    read_gear_tutorials: [],
    school_id: 0,
    class_id: 0,
    identity: "",
    first_name: "",
    last_name: "",
    first_name_kana: "",
    last_name_kana: "",
    photo: null,
    entrance_year: 0,
    current_grade: 0,
    next_grade: 0,
    serviced: false,
    g4s_enabled: false,
    read_last_realization_klass_id: 0,
    read_last_notification_id: 0,
    read_gear_release_note: true,
    unread_release_ads: [],
  },
  teachers: [],
  classes: [],
  past_classes: [],
  categories: [],
  realizations: [],
  realization_klasses: [],
  realization_klasses_past: [],
  unread_realization_klass_count: 0,
  unread_comments_count: 0,
  unread_notification_count: 0,
  realization_summaries: [],
  realization_histories: [],
  commentNotifications: [],
  notifications: [],
  comments: [],
  feedbacks: [],
  pagy_info: {
    count: 0,
    items: 0,
    page: 0,
    pages: 0,
  },
  has_left_classes: false,
  stock_c_to_c_enabled: false,
  realization_feedback_creatable: false,
  gearBoxes: [],
  analyzeGearboxes: [],
  student_analyze: null,
  realizations_analyze: [],
  realization_count_by_exp: {
    realization_count: 0,
    scores: {
      exp1: 0,
      exp2: 0,
      exp3: 0,
      exp4: 0,
      exp5: 0,
      exp6: 0,
      exp7: 0,
    },
  },
  realizations_analyze_pagy_info: {
    count: 0,
    items: 0,
    page: 0,
    pages: 0,
  },
  student_realization_feedbacks: {
    id: NaN,
    first_name: "",
    last_name: "",
    first_name_kana: "",
    last_name_kana: "",
    realization_feedbacks: [],
  },
  lastAction: "",
  selected_gearbox: null,
  timeline_pagination: {
    has_next: false,
  },
  geartheme_pagination: {
    has_next: false,
  },
  expires: addHours(new Date(), 6),
  geartheme: null,
  assessment: {
    id: 0,
    assessment_experiences: [],
    assessment_next_actions: [],
    term_no: 0,
    created_at: "",
    last_assessment: {
      assessment_experiences: [],
    },
  },
  assessments_past: [],
  reference_realizations: [],
  preferred_realizations: [],
};

export const reducer = (state: AppState, action: Actions): AppState => {
  const newState = { ...state, lastAction: action.types };
  switch (action.types) {
    case ADD_FAVORITE:
    case REMOVE_FAVORITE: {
      return {
        ...newState,
      };
    }
    case LOGIN:
      return {
        ...newState,
        error: "",
        error_field: "",
      };
    case LOGIN_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        error: "",
        error_field: "",
        uuid: data.uuid ?? "",
        session_id: data.session_id ?? "",
        student: data.student ?? newState.student,
      };
    }
    case UPDATE_PHOTO_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: UPDATE_PHOTO_SUCCESS_MESSAGE,
      };
    }
    case UPDATE_PHOTO_FAILURE: {
      return {
        ...newState,
        error: UPDATE_PHOTO_FAILURE_MESSAGE,
        error_field: UPDATE_PHOTO_FAILURE_MESSAGE,
      };
    }
    case UPDATE_READ_GEAR_TUTORIALS_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...newState,
        error: "",
        error_field: "",
        student: {
          ...newState.student,
          read_gear_tutorials: data.read_gear_tutorials,
        },
      };
    }
    case UPDATE_READ_RELEASE_ADS_SUCCESS: {
      return {
        ...newState,
      };
    }
    case UPDATE_READ_GEAR_RELEASE_NOTE_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        student: {
          ...newState.student,
          read_gear_release_note: action.payload.data.read_gear_release_note,
        },
      };
    }
    case UPDATE_STUDENT_PASSWORD_SUCCESS: {
      return {
        ...newState,
        uuid: "",
        error: "",
        error_field: "",
        success_message: UPDATE_STUDENT_PASSWORD_SUCCESS_MESSAGE,
      };
    }
    case UPDATE_READ_LAST_NOTIFICATION_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        error: "",
        error_field: "",
        student: {
          ...newState.student,
          read_last_notification_id: data.student.read_last_notification_id,
        },
      };
    }
    case UPDATE_READ_LAST_REALIZATION_KLASS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        error: "",
        error_field: "",
        student: {
          ...newState.student,
          read_last_realization_klass_id:
            data.student.read_last_realization_klass_id,
        },
      };
    }
    case UPDATE_READ_LAST_COMMENT_SUCCESS:
    case UPDATE_READ_LAST_FEEDBACK_SUCCESS: {
      const { realization } = action.payload.data;
      const updatedRealization = {
        ...realization,
        created_at: new Date(realization.created_at),
      };
      return {
        ...newState,
        error: "",
        error_field: "",
        realization: updatedRealization,
        realizations: state.realizations.map(r =>
          r.id === updatedRealization.id ? { ...r, ...updatedRealization } : r,
        ),
      };
    }
    case LOGIN_FAILURE:
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: action.payload.error.field,
      };
    case FETCH_INITIAL_DATA_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        error: "",
        error_field: "",
        student: data.student,
        school_name: data.school_name,
        class_name: data.class_name,
        unread_notification_count: data.unread_notification_count,
        unread_comments_count: data.unread_comments_count,
        unread_realization_klass_count: data.unread_realization_klass_count,
        session_id: data.student.id ? newState.session_id : "",
        has_left_classes: data.has_left_classes,
        geartheme: data.geartheme,
        stock_c_to_c_enabled: data.stock_c_to_c_enabled,
        realization_feedback_creatable: data.realization_feedback_creatable,
      };
    }
    case FETCH_TEACHERS_SUCCESS: {
      return {
        ...newState,
        teachers: action.payload.data.teachers,
      };
    }
    case FETCH_GEAR_BOXES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        gearBoxes: [...data.gearboxes],
      };
    }
    case FETCH_BOX_REALIZATION_SUCCESS: {
      const { data, meta } = action.payload.data;
      const realizations: IRealization[] = [];
      if (meta.pagy_info.page <= 1) {
        realizations.push(...data.realizations);
      } else {
        realizations.push(...newState.realizations, ...data.realizations);
      }
      return {
        ...newState,
        success_message: FETCH_BOX_REALIZATION_SUCCESS_MESSAGE,
        pagy_info: meta.pagy_info,
        realizations: realizations.map(r => ({
          ...r,
          created_at: new Date(r.created_at),
          executed_at: r.executed_at ? new Date(r.executed_at) : undefined,
        })),
      };
    }
    case FETCH_GEAR_THEME_REALIZATION_SUCCESS: {
      const { geartheme, has_next } = action.payload.data;
      const { prev_id } = action.payload.params;
      return {
        ...newState,
        realizations: !prev_id
          ? geartheme.realizations
          : [
              ...newState.realizations,
              ...geartheme.realizations.map(e => ({
                ...e,
                created_at: new Date(e.created_at),
              })),
            ],
        geartheme_pagination: {
          has_next,
        },
        success_message: FETCH_GEAR_THEME_REALIZATION_SUCCESS_MESSAGE,
      };
    }
    case FETCH_TEACHERS_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
      };
    }
    case FETCH_CLASSES_SUCCESS: {
      return {
        ...newState,
        classes: [...action.payload.data.classes, action.payload.data.class],
      };
    }
    case FETCH_CLASSES_PAST_SUCCESS: {
      return {
        ...newState,
        past_classes: [...action.payload.data.past_classes],
      };
    }
    case FETCH_REALIZATION_SUMMARY_BY_CATEGORY_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        realization_summaries: action.payload.data.realization_summaries,
      };
    }
    case FETCH_REALIZATION_HISTORIES_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        realization_histories: action.payload.data.realization_histories.map(
          h => ({
            ...h,
            created_at: new Date(h.created_at),
            executed_at: h.executed_at ? new Date(h.executed_at) : undefined,
          }),
        ),
      };
    }
    case FETCH_NOTIFICATIONS_SUCCESS: {
      return {
        ...newState,
        notifications: action.payload.data.notifications.map(n => ({
          ...n,
          created_at: new Date(n.created_at),
          updated_at: new Date(n.updated_at),
        })),
        unread_notification_count: 0,
        success_message: FETCH_NOTIFICATIONS_SUCCESS_MESSAGE,
      };
    }
    case FETCH_COMMENT_NOTIFICATIONS: {
      return {
        ...newState,
        commentNotifications: [],
      };
    }
    case FETCH_COMMENT_NOTIFICATIONS_SUCCESS: {
      const { comments } = action.payload.data;
      return {
        ...newState,
        commentNotifications: comments.map(notification => ({
          ...notification,
          created_at: new Date(notification.created_at),
          realization: {
            ...notification.realization,
            created_at: new Date(notification.realization.created_at),
          },
        })),
      };
    }
    case FETCH_ASSESSMENT_SUCCESS: {
      return {
        ...newState,
        assessment: action.payload.data.assessment,
      };
    }
    case FETCH_ASSESSMENT_PAST_SUCCESS: {
      const { assessments } = action.payload.data;
      return {
        ...newState,
        assessments_past: assessments,
      };
    }
    case SUBMIT_EXPERIENCE_SUCCESS:
      const { assessment } = action.payload.data;
      return {
        ...newState,
        student: {
          ...newState.student,
          max_term_no: assessment.term_no,
        } as IStudent,
        assessment,
        success_message: CREATE_ASSESSMENT_SUCCESS_MSG,
      };
    case CLEAR_SUCCESS_MESSAGE:
      return { ...newState, success_message: "" };
    case CLEAR_SUCCESS_RESULT:
      return { ...newState, success_result: {} };
    case CLEAR_ERROR_MESSAGE:
      return { ...newState, error: "", error_field: "" };
    case CLEAR_REALIZATION_KLASSES:
      return { ...newState, realization_klasses: [] };
    case CLEAR_REALIZATION_KLASSES_FILTERED:
      return { ...newState, realization_klasses: [] };
    case CLEAR_REALIZATION_KLASSES_PAST:
      return { ...newState, realization_klasses_past: [] };
    case CLEAR_REALIZATION:
      return { ...newState, realization: undefined };
    case CLEAR_COMMENTS:
      return { ...newState, comments: [] };
    case CLEAR_FEEDBACKS:
      return { ...newState, feedbacks: [] };
    case CLEAR_REALIZATIONS:
      return { ...newState, realizations: [] };
    case CLEAR_ANALYZE_REALIZATIONS:
      return {
        ...newState,
        realizations_analyze: [],
      };
    case CLEAR_ANALYZE_REALIZATIONS_PAGY_INFO:
      return {
        ...newState,
        realizations_analyze_pagy_info: {
          count: 0,
          items: 0,
          page: 0,
          pages: 0,
        },
      };
    case CLEAR_ANALYZE_STUDENT:
      return {
        ...newState,
        student_analyze: null,
      };
    case CLEAR_SELECTED_GEARBOX:
      return { ...newState, selected_gearbox: null };
    case CLEAR_PAGY_INFO:
      return {
        ...newState,
        pagy_info: {
          count: 0,
          items: 0,
          page: 0,
          pages: 0,
        },
      };
    case LOGOUT:
      return { ...InitialAppState };
    case LOGOUT_SUCCESS:
      return { ...InitialAppState, success_message: LOGOUT_SUCCESS_MESSAGE };
    case FETCH_INITIAL_DATA_FAILURE: {
      return { ...InitialAppState };
    }
    case FETCH_REALIZATION: {
      return { ...newState, realization: undefined };
    }
    case FETCH_REALIZATION_SUCCESS: {
      const data = action.payload.data.realization;
      const realization = {
        ...data,
        created_at: new Date(data.created_at),
        executed_at: data.executed_at ? new Date(data.executed_at) : undefined,
      };
      return {
        ...newState,
        realization,
        success_message: FETCH_REALIZATION_SUCCESS_MESSAGE,
      };
    }
    case FETCH_MY_REALIZATIONS_SUCCESS: {
      const { data } = action.payload.data;
      const realizations = data.realizations;
      return {
        ...newState,
        realizations: realizations.map(r => ({
          ...r,
          created_at: new Date(r.created_at),
          executed_at: r.executed_at ? new Date(r.executed_at) : undefined,
        })),
        success_message: FETCH_REALIZATION_SUCCESS_MESSAGE,
      };
    }
    case FETCH_REALIZATION_COUNT_BY_EXPERIENCE_SUCCESS: {
      const data = action.payload.data.realization_count_by_exp;

      return {
        ...newState,
        realization_count_by_exp: data,
        success_message: FETCH_REALIZATION_SUCCESS_MESSAGE,
      };
    }
    case FETCH_TIMELINE_BOX_DETAIL_SUCCESS: {
      const {
        gearbox,
        meta: { pagy_info },
      } = action.payload.data;
      return {
        ...newState,
        pagy_info,
        success_message: TIMELINE_BOX_DETAIL_SUCCESS_MESSAGE,
        selected_gearbox: { ...gearbox, realizations_count: pagy_info.count },
        realizations: [
          ...(pagy_info.page > 1 ? state.realizations : []),
          ...(gearbox.realizations
            ? gearbox.realizations.map(r => ({
                ...r,
                created_at: new Date(r.created_at),
                executed_at: r.executed_at
                  ? new Date(r.executed_at)
                  : undefined,
              }))
            : []),
        ],
      };
    }
    case SUBSCRIBE_REALIZATION_KLASSES_SUCCESS: {
      return {
        ...newState,
        unread_realization_klass_count:
          action.payload.data.unread_realization_klass_count,
        realization_feedback_creatable:
          action.payload.data.realization_feedback_creatable,
      };
    }
    case SUBSCRIBE_REALIZATION_KLASSES_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case FETCH_REALIZATION_KLASSES:
    case FETCH_REALIZATION_KLASSES_PAST: {
      return { ...newState };
    }
    case FETCH_REALIZATION_KLASSES_SUCCESS: {
      const { data, has_next } = action.payload.data;
      const { prev_time } = action.payload.params;
      return {
        ...newState,
        realization_klasses: !prev_time
          ? data.realization_klasses
          : [
              ...newState.realization_klasses,
              ...data.realization_klasses.map(e => ({
                ...e,
                created_at: new Date(e.created_at),
              })),
            ],
        timeline_pagination: {
          has_next,
        },
        unread_realization_klass_count: 0,
        success_message: FETCH_REALIZATION_KLASSES_SUCCESS_MESSAGE,
      };
    }
    case FETCH_REALIZATION_KLASSES_FILTERED_SUCCESS: {
      const { data, has_next } = action.payload.data;
      const { prev_time } = action.payload.params;
      return {
        ...newState,
        realization_klasses: !prev_time
          ? data.realization_klasses
          : [
              ...newState.realization_klasses,
              ...data.realization_klasses.map(e => ({
                ...e,
                created_at: new Date(e.created_at),
              })),
            ],
        timeline_pagination: {
          has_next,
        },
        unread_realization_klass_count: 0,
        success_message: FETCH_REALIZATION_KLASSES_FILTERED_SUCCESS_MESSAGE,
      };
    }
    case FETCH_REALIZATION_KLASSES_PAST_SUCCESS: {
      const {
        data: { realization_klasses },
        has_next,
      } = action.payload.data;
      const data = [
        ...newState.realization_klasses_past,
        ...realization_klasses.map(e => ({
          ...e,
          created_at: new Date(e.created_at),
        })),
      ];
      return {
        ...newState,
        realization_klasses_past: data.filter(
          (item, index, self) =>
            index === self.findIndex(d => d.id === item.id),
        ),
        timeline_pagination: {
          has_next,
        },
        success_message: FETCH_REALIZATION_KLASSES_PAST_SUCCESS_MESSAGE,
      };
    }
    case CREATE_REPORT_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: CREATE_REPORT_SUCCESS_MESSAGE,
      };
    }
    case CREATE_COMMENT_SUCCESS: {
      const realizationId = action.payload.data.realization_id;
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: CREATE_COMMENT_SUCCESS_MESSAGE,
        success_result: { id: realizationId },
        realization_klasses: update_realization_klasses_state(
          newState.realization_klasses,
          realizationId,
        ),
        realizations: update_realizations_state(
          newState.realizations,
          realizationId,
        ),
      };
    }
    case CREATE_COMMENT_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case UPDATE_COMMENT_SUCCESS: {
      return {
        ...newState,
        success_message: UPDATE_COMMENT_SUCCESS_MESSAGE,
        success_result: { id: action.payload.data.realization_id },
      };
    }
    case UPDATE_COMMENT_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
      };
    }
    case DELETE_COMMENT_SUCCESS: {
      const { realization_id, has_my_comment } = action.payload.data;
      return {
        ...newState,
        realization_klasses: newState.realization_klasses.map(
          realization_klass => ({
            ...realization_klass,
            comment_count:
              realization_klass.realization.id === realization_id
                ? realization_klass.comment_count - 1
                : realization_klass.comment_count,
            has_comment:
              realization_klass.realization.id === realization_id
                ? realization_klass.comment_count - 1 === 0
                  ? false
                  : true
                : realization_klass.has_comment,
            realization: {
              ...realization_klass.realization,
              has_my_comment:
                realization_klass.realization.id === realization_id
                  ? has_my_comment
                  : realization_klass.realization.has_my_comment,
            },
          }),
        ),
        realizations: state.realizations.map(realization => ({
          ...realization,
          comment_count:
            realization.id === realization_id
              ? realization.comment_count! - 1
              : realization.comment_count,
          has_comment:
            realization.id === realization_id
              ? realization.comment_count! - 1 === 0
                ? false
                : true
              : realization.has_comment,
          has_my_comment:
            realization.id === realization_id
              ? has_my_comment
              : realization.has_my_comment,
        })),
        error: "",
        error_field: "",
        success_message: DELETE_COMMENT_SUCCESS_MESSAGE,
        success_result: { id: realization_id },
      };
    }
    case DELETE_COMMENT_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case FETCH_COMMENTS_SUCCESS: {
      const {
        data: { comments, unread_comments_count },
        params,
      } = action.payload;
      const realizationId = parseInt(
        parseQueryParams(params.url)["realization_id"],
      );
      return {
        ...newState,
        comments: comments.map(c => ({
          ...c,
          created_at: new Date(c.created_at),
          updated_at: new Date(c.updated_at),
        })),
        unread_comments_count,
        realization:
          state.realization?.id === realizationId
            ? { ...state.realization, comment_count: comments.length }
            : state.realization,
        realizations: state.realizations.map(r =>
          r.id === realizationId ? { ...r, comment_count: comments.length } : r,
        ),
      };
    }
    case FETCH_COMMENTS_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case CREATE_FEEDBACK_SUCCESS: {
      const realizationId = action.payload.data.realization_id;
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: CREATE_FEEDBACK_SUCCESS_MESSAGE,
        realizations: state.realizations.map(realization => ({
          ...realization,
          has_my_feedback:
            realization.has_my_feedback || realization.id === realizationId,
        })),
      };
    }
    case CREATE_FEEDBACK_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case UPDATE_FEEDBACK_SUCCESS: {
      return {
        ...newState,
        success_message: UPDATE_FEEDBACK_SUCCESS_MESSAGE,
      };
    }
    case UPDATE_FEEDBACK_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
      };
    }
    case DELETE_FEEDBACK_SUCCESS: {
      const { realization_id, has_my_feedback } = action.payload.data;
      return {
        ...newState,
        realizations: state.realizations.map(realization => ({
          ...realization,
          has_my_feedback:
            realization.id === realization_id
              ? has_my_feedback
              : realization.has_my_feedback,
        })),
        error: "",
        error_field: "",
        success_message: DELETE_FEEDBACK_SUCCESS_MESSAGE,
      };
    }
    case DELETE_FEEDBACK_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case FETCH_FEEDBACKS_SUCCESS: {
      const {
        data: { feedbacks },
        params,
      } = action.payload;
      const realizationId = parseInt(
        parseQueryParams(params.url)["realization_id"],
      );
      return {
        ...newState,
        feedbacks: feedbacks.map(f => ({
          ...f,
          created_at: new Date(f.created_at),
          updated_at: new Date(f.updated_at),
        })),
        realization:
          state.realization?.id === realizationId
            ? { ...state.realization, feedback_count: feedbacks.length }
            : state.realization,
        realizations: state.realizations.map(r =>
          r.id === realizationId
            ? { ...r, feedback_count: feedbacks.length }
            : r,
        ),
      };
    }
    case FETCH_FEEDBACKS_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case CREATE_REALIZATION_STAMP_REACTION_SUCCESS: {
      const realizationId = action.payload.data.realization_id;
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: CREATE_REALIZATION_STAMP_REACTION_SUCCESS,
        success_result: { id: realizationId },
        realization_klasses: update_realization_klasses_state(
          newState.realization_klasses,
          realizationId,
        ),
        realizations: update_realizations_state(
          state.realizations,
          realizationId,
        ),
      };
    }
    case CREATE_REALIZATION_STAMP_REACTION_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
      };
    }
    case ADD_FAVORITE_SUCCESS: {
      const r = action.payload.params.url.match(/(\d+)\/add_favorite/);
      const realizationID = r ? parseInt(r[1], 10) : 0;
      return {
        ...newState,
        success_message: TOGGLE_STATE_SUCCESS_MESSAGE,
        realization_klasses: newState.realization_klasses.map(e =>
          e.realization.id === realizationID ? { ...e, is_favorite: true } : e,
        ),
        realizations: state.realizations.map(realization =>
          realization.id === realizationID
            ? { ...realization, is_favorite: true }
            : realization,
        ),
        reference_realizations: state.reference_realizations.map(realization =>
          realization.id === realizationID
            ? { ...realization, is_favorite: true }
            : realization,
        ),
        preferred_realizations: state.preferred_realizations.map(realization =>
          realization.id === realizationID
            ? { ...realization, is_favorite: true }
            : realization,
        ),
      };
    }
    case REMOVE_FAVORITE_SUCCESS: {
      const r = action.payload.params.url.match(/(\d+)\/remove_favorite/);
      const realizationID = r ? parseInt(r[1], 10) : 0;
      return {
        ...newState,
        success_message: TOGGLE_STATE_SUCCESS_MESSAGE,
        realization_klasses: newState.realization_klasses.map(e =>
          e.realization.id === realizationID ? { ...e, is_favorite: false } : e,
        ),
        realizations: state.realizations.map(realization =>
          realization.id === realizationID
            ? { ...realization, is_favorite: false }
            : realization,
        ),
        reference_realizations: state.reference_realizations.map(realization =>
          realization.id === realizationID
            ? { ...realization, is_favorite: false }
            : realization,
        ),
        preferred_realizations: state.preferred_realizations.map(realization =>
          realization.id === realizationID
            ? { ...realization, is_favorite: false }
            : realization,
        ),
      };
    }
    case FETCH_REALIZATION_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case FETCH_CATEGORIZED_REALIZATION_SUCCESS: {
      const { data, meta } = action.payload.data;
      const ids = newState.realizations.map(r => r.id);
      return {
        ...newState,
        success_message: FETCH_CATEGORIZED_REALIZATION_SUCCESS_MESSAGE,
        pagy_info: meta.pagy_info,
        realizations: (meta.pagy_info.page <= 1
          ? [...data.realizations]
          : [
              ...newState.realizations,
              ...data.realizations.filter(d => ids.indexOf(d.id) === -1),
            ]
        ).map(r => ({
          ...r,
          created_at: new Date(r.created_at),
          executed_at: r.executed_at ? new Date(r.executed_at) : undefined,
        })),
      };
    }
    case FETCH_CATEGORIZED_REALIZATION_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case CREATE_REALIZATION_SUCCESS: {
      const data = action.payload.data.realization;
      const realization = {
        ...data,
        created_at: new Date(data.created_at),
      };
      return {
        ...newState,
        success_message:
          data.kind === "roots"
            ? CREATE_REALIZATION_SUCCESS_MESSAGE
            : CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE,
        realization,
        realizations: [],
      };
    }
    case CREATE_REALIZATION_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
      };
    }
    case UPDATE_REALIZATION: {
      return {
        ...newState,
      };
    }
    case UPDATE_REALIZATION_SUCCESS: {
      const { realization } = action.payload.data;
      let updatedRealizations = [...state.realizations];
      const updatedRealization = {
        ...realization,
        created_at: new Date(realization.created_at),
      };
      const itemIndex = state.realizations.findIndex(
        r => r.id === realization.id,
      );
      if (itemIndex >= 0) {
        updatedRealizations = [
          ...state.realizations.slice(0, itemIndex),
          { ...state.realizations[itemIndex], ...updatedRealization },
          ...state.realizations.slice(itemIndex + 1),
        ];
      }
      return {
        ...newState,
        success_message: UPDATE_REALIZATION_SUCCESS_MESSAGE,
        realization: updatedRealization,
        realizations: updatedRealizations,
      };
    }
    case UPDATE_REALIZATION_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
      };
    }
    case DELETE_REALIZATION_SUCCESS: {
      const { id } = action.payload.data;
      return {
        ...newState,
        realizations: [...newState.realizations.filter(r => r.id !== id)],
        success_message: DELETE_REALIZATION_SUCCESS_MESSAGE,
      };
    }
    case DELETE_REALIZATION_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
      };
    }
    case SHARE_REALIZATION_SUCCESS: {
      const data = action.payload.data.realization;
      const realization = {
        ...data,
        created_at: new Date(data.created_at),
        executed_at: data.executed_at ? new Date(data.executed_at) : undefined,
      };
      return {
        ...newState,
        realization,
      };
    }

    case SHARE_REALIZATION_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
      };
    }

    case EXECUTE_REALIZATION_SUCCESS: {
      return {
        ...newState,
        success_message: EXECUTE_REALIZATION_SUCCESS_MESSAGE,
      };
    }
    case EXECUTE_REALIZATION_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
      };
    }
    case FETCH_ANALYZE_GEARBOXES_SUCCESS: {
      const { gearboxes } = action.payload.data;
      return {
        ...newState,
        analyzeGearboxes: gearboxes,
      };
    }
    case FETCH_ANALYZE_STUDENT_SUCCESS: {
      const { student_analyze } = action.payload.data;
      return {
        ...newState,
        student_analyze,
      };
    }
    case FETCH_ANALYZE_REALIZATIONS_SUCCESS: {
      const { realizations, meta } = action.payload.data;
      const { pagy_info } = meta;
      const realizationsData =
        pagy_info.page === 1
          ? realizations
          : [...newState.realizations_analyze, ...realizations];
      return {
        ...newState,
        realizations_analyze_pagy_info: pagy_info,
        realizations_analyze: realizationsData,
      };
    }
    case FETCH_CATEGORIES_SUCCESS: {
      return {
        ...newState,
        categories: action.payload.data.categories,
      };
    }
    case FETCH_CATEGORIES_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case FETCH_CATEGORIES_WITH_COUNT_SUCCESS: {
      return {
        ...newState,
        categories: action.payload.data.categories,
      };
    }
    case FETCH_CATEGORIES_WITH_COUNT_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case CREATE_CATEGORY_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: CREATE_CATEGORY_SUCCESS_MESSAGE,
      };
    }
    case CREATE_CATEGORY_FAILURE: {
      return { ...newState, error: UPDATE_CATEGORY_FAILED_MESSAGE };
    }
    case UPDATE_CATEGORY_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: UPDATE_CATEGORY_SUCCESS_MESSAGE,
      };
    }
    case UPDATE_CATEGORY_FAILURE: {
      return { ...newState, error: UPDATE_CATEGORY_FAILED_MESSAGE };
    }
    case DESTROY_CATEGORY_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: DESTROY_CATEGORY_SUCCESS_MESSAGE,
      };
    }
    case DESTROY_CATEGORY_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case REORDER_CATEGORIES_SUCCESS: {
      return { ...newState, error: "", error_field: "" };
    }
    case REORDER_CATEGORIES_FAILURE: {
      return { ...newState, error: action.payload.error.message };
    }
    case FETCH_REFERENCE_REALIZATIONS_SUCCESS: {
      return {
        ...newState,
        reference_realizations: action.payload.data.reference_realizations,
      };
    }
    case FETCH_PREFERRED_REALIZATIONS_SUCCESS: {
      const { prev_time } = action.payload.params;
      return {
        ...newState,
        preferred_realizations: !prev_time
          ? action.payload.data.preferred_realizations
          : [
              ...newState.preferred_realizations,
              ...action.payload.data.preferred_realizations,
            ],
      };
    }
    case CREATE_REALIZATION_FEEDBACKS_SUCCESS: {
      return {
        ...newState,
        realization_feedback_creatable: false,
        success_message: CREATE_REALIZATION_FEEDBACKS_SUCCESS_MESSAGE,
      };
    }
    case CREATE_REALIZATION_FEEDBACKS_FAILURE: {
      return {
        ...newState,
        error: CREATE_REALIZATION_FEEDBACKS_FAILURE_MESSAGE,
      };
    }
    case FETCH_REALIZATION_FEEDBACKS_SUCCESS: {
      return {
        ...newState,
        student_realization_feedbacks: action.payload.data.student,
      };
    }
    case ACTION_503:
      return {
        ...newState,
        isServiceUnavailable: true,
        success_message: ACTION_503_MESSAGE,
      };
    default:
      return newState;
  }
};

type ContextState = {
  contextState: AppState;
  dispatch(action: Actions): void;
};

export const Context = React.createContext<ContextState>({
  contextState: InitialAppState,
  dispatch(_) {
    console.warn("Context.Provider外からの呼び出し");
  },
});
