import React from "react";

export interface IAssessmentContent {
  id: number;
  titleForStudentGlide: string;
  titleForTeacherGlide: string;
  questionTitle: string;
  subQuestionTitle?: string;
  personA?: string;
  personB?: string;
  personASP?: string;
  personBSP?: string;
  questionContentForStudent: string;
  questionContentForTeacher: string;
  iconGlide1?: string;
  iconGlide2?: string;
  score1: string;
  score2: string;
  score3: string;
  score4: string;
  score5: string;
  score6: string;
  score7: string;
  score8: string;
  placeholder?: string;
}

interface IScoreLabels {
  label1: string;
  label2: string;
  label3: string;
  label4: string;
  label5: string;
  label6: string;
  label7: string;
  label8: string;
}

export const ASSESSMENT_CONTENT_BY_EXP = {
  1: {
    title: `主体性`,
    subTitle: `自分で考えて動く力`,
    desc: `身の回りに何か良くないことが起きた時に、誰かのせいにせず、自分でどうするかを考えようとする姿勢。
    この力は、「自分の考えや行動によって、身の回りに変化を起こした経験」を繰り返すことで身につけることができます。`,
    hint: `例：合唱コンクールで、みんながやる気を出してくれるように練習前に話し合いの時間をとった。／家族が家事を始める前に、自分から申し出て家事をやってみる。`,
  },
  2: {
    title: ``,
    subTitle: ``,
    desc: ``,
    hint: ``,
  },
  3: {
    title: ``,
    subTitle: ``,
    desc: ``,
    hint: ``,
  },
  4: {
    title: ``,
    subTitle: ``,
    desc: ``,
    hint: ``,
  },
  5: {
    title: ``,
    subTitle: ``,
    desc: ``,
    hint: ``,
  },
  6: {
    title: ``,
    subTitle: ``,
    desc: ``,
    hint: ``,
  },
  7: {
    title: ``,
    subTitle: ``,
    desc: ``,
    hint: ``,
  },
};

export const ASSESSMENT_STATUS: {
  title: string;
  desc: string;
  detail: string;
  reason: string;
  experienceNo: number;
  colorCode: string;
  hint: string;
  reference: string;
}[] = [
  {
    title: "主体性",
    desc: "自分で考えて動く力",
    detail: `身の回りに何か良くないことが起きた時に、誰かのせいにせず、自分でどうするかを考えよう`,
    reason: `自分の考えや行動によって、身の回りに変化を起こした`,
    experienceNo: 1,
    colorCode: "#42AF4D",
    hint: `例：合唱コンクールで、みんながやる気を出してくれるように練習前に話し合いの時間をとった。／家族が家事を始める前に、自分から申し出て家事をやってみる。`,
    reference:
      "身の回りの出来事に対して人のせいにせずに、自分で「こうしよう」と思おうとする姿勢。この力を伸ばすためには「自分の考えや行動によって、身の回りに変化を起こした経験」の繰り返しが大切です。",
  },
  {
    title: "実現思考",
    desc: "推進し実現する力",
    detail: `やりたいことや問題に対して、「どうすれば実現できるか」と順序立てて考えよう`,
    reason: `捉え方や考え方次第で、できないことができるようになった`,
    experienceNo: 2,
    colorCode: "#BB3E46",
    hint: `例：国語のテストの点を上げたくて、苦手分野の分析や漢字の練習ノルマを計画して実行したことがある。その結果点数が上がった。／ギターのプロになるために、オーディションの合格基準を研究して練習してみる。`,
    reference:
      "やりたいことや問題に対して、「どうすれば実現できるか」と順序立てて考えようとする姿勢。この力は、「捉え方や考え方次第で、できないことができるようになった経験」を繰り返すことで身につけることができます。",
  },
  {
    title: "他者視点",
    desc: "相手の立場で考える力",
    detail: `相手の想いや考えを想像した上で考えよう`,
    reason: `相手の立場に立って考え行動し、問題を解決したり、相手の気持ちに共感したりする`,
    experienceNo: 3,
    colorCode: "#BB9F3E",
    hint: `例：委員会の仕事で、先生に「図書室を初めて使う人のために利用方法のポスターを作ろう」と言われて作ったことがある。図書室を初めて使う人の視点に立って考えて作った。／運動が好きな人も苦手な人も、全員が楽しめる体育祭にするにはどうしたら良いのかを考えてみる。`,
    reference:
      "相手の想いや考えを想像した上で考えようとする姿勢。この力は、「相手の立場に立って考え行動し、問題を解決したり、相手の気持ちに共感したりする経験」を繰り返すことで身につけることができます。",
  },
  {
    title: "社会的有用感",
    desc: "社会的な視点で考える力",
    detail: `周りや社会のために自分には何ができるかを考え、誰かの役に立とう`,
    reason: `自分の行動によって身の回りをより良くすることができるという感覚をもとに行動を起こし、変化を生み出した`,
    experienceNo: 4,
    colorCode: "#3EA4BB",
    hint: `例：部活で後輩が辛そうにしていたので、声をかけて相談に乗ってあげたことがある。「アドバイスをもらえて元気が出た」と言ってくれた。／クラス行事に貢献するために、情報の授業で習ったPCスキルを活かしてプレゼン資料を作ってみる。`,
    reference:
      "周りや社会に対してどんな価値を提供したいか考え、誰かの役に立とうとする姿勢。この力は、「自分の行動によって身の回りをより良くすることができるという感覚をもとに行動を起こし、変化を生み出した経験」を繰り返すことで身につけることができます。",
  },
  {
    title: "成長志向",
    desc: "自分で成長機会を創る力",
    detail: `知らないこと・予想外のことに対して前向きに取り組み、自分の意志で成長しよう`,
    reason: `意図的に新しいことに取り組み、できなかったことができるようになったなど、自分の成長を実感した`,
    experienceNo: 5,
    colorCode: "#BB733E",
    hint: `例：英語を話せるようになりたいなら〇〇の本を読んだ方がいい、と言われて読んでみたら、長文読解に苦手がなくなった。／これからは料理の腕をもっと磨くために、人に教えることを意識したレシピノートを作ってインターネットで公開してみる`,
    reference:
      "知らないこと・予想外のことに対して前向きに取り組み、自分の意志で成長しようとする姿勢。この力は、意図的に新しいことに取り組み、できなかったことができるようになったなど「自分の成長を実感した経験」を繰り返すことで身につけることができます。",
  },
  {
    title: "視野拡大志向",
    desc: "自分で視野を広げる力",
    detail: `知らない分野や世界に関心を持ち、積極的に自分の世界観を広げよう`,
    reason: `自分の世界は自分の意志で広げられると理解し、世界で起きている課題や事象に関心を持ち、多くの選択肢の中から自分の行動を選択する`,
    experienceNo: 6,
    colorCode: "#A8A8A8",
    hint: `例：自分の行きたい大学や学部が決まらないので、あえて興味がない本を読んできたり先生になぜ先生になったのかを聞いてみたりしたら、視野が広がって行きたい学部を決めることができた。／夏休みにフランス語の雑誌を読んでみて、フランス語とフランスの文化について学んでみる。`,
    reference:
      "知らない分野や世界に関心を持ち、積極的に自分の世界観を広げようとする姿勢。この力は、「自分の世界は自分の意志で広げられると理解し、世界で起きている課題や事象に関心を持ち、多くの選択肢の中から自分の行動を選択する経験」を繰り返すことで身につけることができます。",
  },
  {
    title: "中長期的ビジョン構想力",
    desc: "自分で目的を設定する力",
    detail: `どんな未来を実現したいかを考え、日々の行動に自分なりの目的を決めよう`,
    reason: `自分で目的を設定してそれを実現する`,
    experienceNo: 7,
    colorCode: "#6D53D5",
    hint: `例：部活で嫌いだったトレーニングがあったが、どこの筋肉をつけるためにやっているのか、その筋肉をつけるとどんな動きができるようになるかを考えて取り組んだ。／今度の模試について、結果をちゃんと分析して苦手分野を把握したり、克服のための勉強計画を立ててみたりする。`,
    reference:
      "どんな未来を実現したいかを考え、日々の行動に自分なりの目的を決めようとする姿勢。この力は、「自分で目的を設定してそれを実現する経験」を繰り返すことで身につけることができます。",
  },
];

export const ASSESSMENT_AXIS = [
  { no: 1, label: "よくわからない", summary: "" },
  { no: 2, label: "そういうことか！", summary: "内容の理解・共感" },
  { no: 3, label: "言われたからやってみた", summary: "受動的経験" },
  {
    no: 4,
    label: "言われてやって、変化を実感した！",
    summary: "受動的経験による変化実感",
  },
  { no: 5, label: "自分からやってみた", summary: "主体的経験" },
  {
    no: 6,
    label: "自分でやって、変化を実感した！",
    summary: "主体的経験による変化実感",
  },
  {
    no: 7,
    label: "いつでも自分でやっている",
    summary: "日常生活における定着",
  },
  {
    no: 8,
    label: "応用して使い、人も巻き込んでいる",
    summary: "自己表現への応用・他者への影響",
  },
];

export const NEXT_ACTIONS_PAGE: IAssessmentContent = {
  id: 8,
  titleForStudentGlide: "7つの素養 まとめ",
  titleForTeacherGlide: "7つの素養 まとめ",
  questionTitle: "現状を把握し、未来について考えてみましょう。",
  questionContentForStudent: `自分の経験が増えるたびに、いつでも、何回でも記録しましょう。`,
  questionContentForTeacher: `自分の経験が増えるたびに、いつでも、何回でも記録しましょう。`,
  score1: "",
  score2: "",
  score3: "",
  score4: "",
  score5: "",
  score6: "",
  score7: "",
  score8: "",
};

export const SCORE_LABELS: IScoreLabels = {
  label1: "よくわからない",
  label2: "わかるけどできている自信はない",
  label3: "言われたからやってみた",
  label4: "言われてやって変化を実感した",
  label5: "自分からやってみた",
  label6: "自分でやって変化を実感した",
  label7: "日常の習慣になっている",
  label8: "周囲のサポートもしている",
};

export const SCORE_LABELS_JSX = {
  label1: <>よくわからない</>,
  label2: (
    <>
      わかるけどできて
      <br />
      いる自信はない
    </>
  ),
  label3: (
    <>
      言われたから
      <br />
      やってみた
    </>
  ),
  label4: (
    <>
      言われてやって
      <br />
      変化を実感した
    </>
  ),
  label5: (
    <>
      自分から
      <br />
      やってみた
    </>
  ),
  label6: (
    <>
      自分でやって
      <br />
      変化を実感した
    </>
  ),
  label7: (
    <>
      日常の習慣に
      <br />
      なっている
    </>
  ),
  label8: (
    <>
      周囲のサポート
      <br />
      もしている
    </>
  ),
};

export const SCORE_TEXTS: { [key: number]: string } = {
  1: "この力の考え方やこの力が必要な理由がよくわからない。",
  2: "この力を意識して行動したことはないが、大切な考え方だと思う。",
  3: "人から言われて、この力を意識して行動しようと取り組んだことがある。",
  4: "この力を意識して行動したことで、自分や周囲に具体的な変化を起こしたことがある。",
  5: "この力を強く意識して、自ら行動したことが何度もある。",
  6: "この力を強く意識して自ら行動したことで、自分や周囲に具体的な変化を起こしたことが何度もある。",
  7: "常にこの力を発揮して行動し、自分なりの反省や改善を日常的に行っている。",
  8: "常にこの力を発揮して未来に向けて行動でき、他者がこの力を発揮するサポートも行っている。",
};

export const LABELS: { [key: number]: string } = {
  1: "自分で考えて動く力",
  2: "推進し実現する力",
  3: "相手の立場で考える力",
  4: "社会的な視点で考える力",
  5: "自分で成長機会を創る力",
  6: "自分で視野を広げる力",
  7: "自分で目的を設定する力",
};

export const X_LABELS: string[] = [
  "自分で\n考えて\n動く力",
  "推進し\n実現する力",
  "相手の\n立場で\n考える力",
  "社会的な\n視点で\n考える力",
  "自分で\n成長機会を\n創る力",
  "自分で\n視野を\n広げる力",
  "自分で\n目的を\n設定する力",
];

export const X_LABELS_JSX = [
  <>
    自分で考えて
    <br />
    動く力
  </>,
  <>
    推進し
    <br />
    実現する力
  </>,
  <>
    相手の立場で
    <br />
    考える力
  </>,
  <>
    社会的な視点で
    <br />
    考える力
  </>,
  <>
    自分で成長
    <br />
    機会を創る力
  </>,
  <>
    自分で視野を
    <br />
    広げる力
  </>,
  <>
    自分で目的を
    <br />
    設定する力
  </>,
];

export const X_LABELS_JSX_SP = [
  <>主体性</>,
  <>
    実現
    <br />
    思考
  </>,
  <>
    他者
    <br />
    視点
  </>,
  <>
    社会的
    <br />
    有用感
  </>,
  <>
    成長
    <br />
    志向
  </>,
  <>
    視野
    <br />
    拡大志向
  </>,
  <>
    中長期的
    <br />
    ビジョン
    <br />
    構想力
  </>,
];

export const CSV_HEADERS = [
  "学年",
  "クラス",
  "実施日",
  "実施回",
  "ログインID",
  "学籍番号",
  "生徒名",
  "生徒・①自分で考えて動く力・経験",
  "生徒・①自分で考えて動く力・コメント",
  "生徒・②推進し実現する力・経験",
  "生徒・②推進し実現する力・コメント",
  "生徒・③相手の立場で考える力・経験",
  "生徒・③相手の立場で考える力・コメント",
  "生徒・④社会的な視点で考える力・経験",
  "生徒・④社会的な視点で考える力・コメント",
  "生徒・⑤自分で成長機会を創る力・経験",
  "生徒・⑤自分で成長機会を創る力・コメント",
  "生徒・⑥自分で視野を広げる力・経験",
  "生徒・⑥自分で視野を広げる力・コメント",
  "生徒・⑦自分で目的を設定する力・経験",
  "生徒・⑦自分で目的を設定する力・コメント",
  "生徒・まとめ・7つの素養を振り返っての「自分の強み」",
  "生徒・まとめ・将来の夢、もしくは将来どんな人間になりたいか",
  "生徒・まとめ・将来の目標実現に向けての課題や必要な経験",
  "先生・メモ",
];

export const REASON_LABEL: {
  upTexts: { [key: number]: string };
  downText: string;
  defaultText: string;
} = {
  upTexts: {
    1: "前回記録した時点から今日までに、この力について何か気づいたこと、考えたことがなかったか思い出して書いてみましょう。",
    2: "前回記録した時点から今日までに、この力がなぜ大切だと思ったのか、考えたことやきっかけになった経験があれば書いてみましょう。",
    3: "前回記録した時点から今日までに、この力を意識して取り組んだことを書いてみましょう。",
    4: "前回記録した時点から今日までに、この力を意識して取り組んだことや、取り組みによって自分や周囲にどんな変化を起こすことができたかを書いてみましょう。",
    5: "前回記録した時点から今日までに、この力を意識して自分から取り組んだ経験について、いくつかのエピソードを書いてみましょう。",
    6: "前回記録した時点から今日までに、この力を意識して自分から取り組んだ経験について、いくつかのエピソードを書いてみましょう。また取り組みによって自分や周囲にどんな変化を起こすことができたかを書いてみましょう。",
    7: "前回記録した時点から今日までに、この力を意識して自分から取り組んだ経験を書いてみましょう。またその取り組みにおいて「もっとこうすればよかった」「次はこうしたい」と思ったことや考えたことを書いてみましょう。",
    8: "前回記録した時点から今日までに、自分ではない他者がこの力を意識して行動する上でどんなサポート・支援を行うことができたのかを書いてみましょう。",
  },
  downText:
    "前回記録から自己評価を下げた理由について、考えたことや学んだこと、きっかけになった経験があれば書いてみましょう。",
  defaultText:
    "この段階を選んだ理由や、過去の経験を書いてみましょう。\nもしも「気づいたこと」や「今後やりたいこと」などもあれば、それも書きましょう。",
};

export const NEXT_ACTION_KINDS: number[] = [1, 2, 3];
