import React, { useRef } from "react";
import { useIonViewWillLeave } from "@ionic/react";
import clsx from "clsx";

import ClassSelector from "../../molecules/ClassSelector";
import RealizationItem from "../../molecules/RealizationItem";
import TimelineEntryDetailModal from "../../organisms/TimelineEntryDetailModal";
import BoxItem from "../../atoms/BoxItem";
import {
  ICategory,
  IClass,
  IComment,
  IOriginRealization,
  IRealizationCreateParams,
  IRealizationImage,
  IRealizationKlass,
  IStudentGearBox,
  ITeacher,
  ITimelinePagination,
  PreferredRealization,
  ReactableType,
  ReferenceRealization,
  ShareRealization,
} from "../../state";
import useIntersection from "../../hooks/useIntersection";
import TemplateModal from "../../organisms/TemplateModal";
import DerivativeRealizationForm from "../../organisms/Forms/DerivativeRealizationForm";
import ModalSelectActions from "../../molecules/student/ModalSelectActions";
import ShareRealizationItem from "../../molecules/ShareRealizationItem";
import TimelineEntryShareRealizationDetailModal from "../../organisms/TimelineEntryShareRealizationDetailModal";
import type { Stamp } from "../../libs/stamp";

import styles from "./Timeline.module.scss";

const createBalancedArray = (
  realization_klasses: IRealizationKlass[],
  reference_realizations: ReferenceRealization[],
  preferred_realizations: PreferredRealization[],
  calling?: boolean,
) => {
  // 初回読み込み時にまばらに表示されることを避けつつ、追加読み込みの際にそのまま表示されるようにしている
  if (calling && realization_klasses.length === 0) return [];

  const balancedArray = [];
  let klassIndex = 0;
  let referenceIndex = 0;
  let preferredIndex = 0;
  let toggle = true;

  while (
    klassIndex < realization_klasses.length ||
    referenceIndex < reference_realizations.length ||
    preferredIndex < preferred_realizations.length
  ) {
    if (klassIndex < realization_klasses.length) {
      balancedArray.push(realization_klasses[klassIndex]);
      klassIndex++;
    }
    if (toggle && preferredIndex < preferred_realizations.length) {
      balancedArray.push(preferred_realizations[preferredIndex]);
      preferredIndex++;
    } else if (!toggle && referenceIndex < reference_realizations.length) {
      balancedArray.push(reference_realizations[referenceIndex]);
      referenceIndex++;
    }
    toggle = !toggle; // toggle between preferred and reference
  }

  return balancedArray;
};

export interface TimelineProps {
  isDisplaying: boolean;
  current_id: number;
  classes: IClass[];
  realization_klasses: IRealizationKlass[];
  comments: IComment[];
  categories: ICategory[];
  isTimelinePast?: boolean;
  calling?: boolean;
  searchParam: {
    classId: number;
    className: string;
    text: string;
  };
  timeline_pagination: ITimelinePagination;
  pathname?: string;
  success_message?: string;
  teachers?: ITeacher[];
  gearBoxes?: IStudentGearBox[];
  student_id: number;
  showSelectActionModal?: boolean;
  closeSelectActionModal?: () => void;
  addFavorite?: (id: number) => void;
  removeFavorite?: (id: number) => void;
  search: (args: {
    classId?: number;
    className?: string;
    text?: string;
  }) => void;
  readMore: () => void;
  clearRealizationKlasses: () => void;
  createReport: (params: {
    realization_id?: number;
    comment_id?: number;
  }) => void;
  createComment: (realization_id: number, content: string) => void;
  updateComment: (realization_id: number, content: string) => void;
  deleteComment: (comment_id: number) => void;
  fetchComments: (realization_id: number) => void;
  clearOldComments: () => void;
  redirectToBox?: (box_id: number) => void;
  createRealization?: (
    realization: IRealizationCreateParams,
    submit_teacher_ids: number[],
    share_class_ids: number[],
    gearbox_id: number | null,
    image?: IRealizationImage,
  ) => void;
  createCategory?: (category: { name: string; color: string }) => void;
  reference_realizations: ReferenceRealization[];
  preferred_realizations: PreferredRealization[];
  createStampReaction?: (
    realization_id: number,
    type: ReactableType,
    stamp: Stamp,
  ) => void;
}

const Timeline = (props: TimelineProps) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const [selectedRealizationKlassId, setSelectedRealizationKlassId] =
    React.useState(0);
  const [selectedShareRealizationId, setSelectedShareRealizationId] =
    React.useState(0);
  const [showRealizationDerivativeModal, setShowRealizationDerivativeModal] =
    React.useState(false);
  const [
    showTimelineEntryShareRealizationDetailModal,
    setShowTimelineEntryShareRealizationDetailModall,
  ] = React.useState(false);

  const addDerivativeForRealizationItem = (realizationKlassId: number) => {
    setSelectedShareRealizationId(0);
    setSelectedRealizationKlassId(realizationKlassId);
    setShowTimelineEntryShareRealizationDetailModall(false);
    setShowRealizationDerivativeModal(true);
  };
  const addDerivativeForShareRealizationItemItem = (
    shareRealizationId: number | undefined,
  ) => {
    if (shareRealizationId === undefined)
      throw new Error("realizationId is undefined");
    setSelectedRealizationKlassId(0);
    setSelectedShareRealizationId(shareRealizationId);
    setShowTimelineEntryShareRealizationDetailModall(false);
    setShowRealizationDerivativeModal(true);
  };

  const onClickShareRealizationItem = (shareRealizationId: number) => {
    setSelectedRealizationKlassId(0);
    setSelectedShareRealizationId(shareRealizationId);
    setShowRealizationDerivativeModal(false);
    setShowTimelineEntryShareRealizationDetailModall(true);
  };

  const onCloseShareRealizationDetailModal = () => {
    setShowTimelineEntryShareRealizationDetailModall(false);
    setSelectedShareRealizationId(0);
  };

  const selectedRealizationKlass: IRealizationKlass | undefined = React.useMemo(
    () =>
      props.realization_klasses.find(t => t.id === selectedRealizationKlassId),
    [props.realization_klasses, selectedRealizationKlassId],
  );

  const selectedShareRealization: ShareRealization | undefined =
    props.reference_realizations.find(
      t => t.id === selectedShareRealizationId,
    ) ||
    props.preferred_realizations.find(t => t.id === selectedShareRealizationId);

  const realizationForDerivativeRealizationForm: IOriginRealization | null =
    selectedRealizationKlass !== undefined
      ? {
          id: selectedRealizationKlass.realization.id,
          content: selectedRealizationKlass.realization.content,
          created_at: new Date(
            selectedRealizationKlass.realization.created_at,
          ).toISOString(),
          kind: selectedRealizationKlass.realization.kind,
          executed_at: selectedRealizationKlass.realization.executed_at || null,
          student: selectedRealizationKlass.realization.student,
        }
      : selectedShareRealization !== undefined
      ? {
          id: selectedShareRealization.id,
          content: selectedShareRealization.content,
          created_at: selectedShareRealization.created_at,
          kind: selectedShareRealization.kind,
          executed_at: null,
          student: selectedShareRealization.student,
        }
      : null;

  useIntersection({
    target: loadMoreRef,
    enabled:
      props.isDisplaying &&
      !props.calling &&
      props.timeline_pagination.has_next,
    onIntersect: props.readMore,
  });

  useIonViewWillLeave(() => {
    setSelectedRealizationKlassId(0);
    setShowRealizationDerivativeModal(false);
  });

  const realizations = createBalancedArray(
    props.realization_klasses,
    props.reference_realizations,
    props.preferred_realizations,
    props.calling,
  );

  return (
    <div
      className={clsx(
        styles.wrapper,
        props.isTimelinePast && styles.wrapper__past,
      )}
    >
      <ModalSelectActions
        isOpen={props.showSelectActionModal ?? false}
        onClose={props.closeSelectActionModal}
      />
      {showRealizationDerivativeModal &&
        realizationForDerivativeRealizationForm && (
          <TemplateModal
            isOpen={showRealizationDerivativeModal}
            onClose={() => {
              setSelectedRealizationKlassId(0);
              setShowRealizationDerivativeModal(false);
            }}
            headerTitle="ストック"
          >
            <DerivativeRealizationForm
              calling={!!props.calling}
              createRealization={props.createRealization}
              createCategory={props.createCategory}
              originRealization={realizationForDerivativeRealizationForm}
              pathname={props.pathname || ""}
              success_message={props.success_message || ""}
              teachers={props.teachers || []}
              categories={props.categories}
              classes={props.classes}
              gearBoxes={props.gearBoxes || []}
            />
          </TemplateModal>
        )}
      {selectedRealizationKlass && !showRealizationDerivativeModal && (
        <TimelineEntryDetailModal
          current_id={props.current_id}
          realization={{
            ...selectedRealizationKlass.realization,
            is_favorite: selectedRealizationKlass.is_favorite,
            has_comment: selectedRealizationKlass.has_comment,
            student_name: selectedRealizationKlass.student_name,
            comment_count: selectedRealizationKlass.comment_count,
            created_at: selectedRealizationKlass.created_at,
            photo: selectedRealizationKlass.photo,
          }}
          comments={props.comments}
          isOtherStudent={
            props.student_id !== selectedRealizationKlass.realization.student_id
          }
          showDetail={!!selectedRealizationKlass}
          onClose={() => setSelectedRealizationKlassId(0)}
          deleteComment={props.deleteComment}
          createReport={props.createReport}
          addFavorite={props.addFavorite}
          removeFavorite={props.removeFavorite}
          createComment={props.createComment}
          updateComment={props.updateComment}
          clearOldComments={props.clearOldComments}
          isTimelinePast={props.isTimelinePast}
          addDerivative={() => {
            setShowRealizationDerivativeModal(true);
          }}
          createStampReaction={props.createStampReaction}
        />
      )}

      <TimelineEntryShareRealizationDetailModal
        showDetail={showTimelineEntryShareRealizationDetailModal}
        shareRealization={selectedShareRealization}
        isOtherStudent={
          props.student_id !== selectedShareRealization?.student_id
        }
        addFavorite={props.addFavorite}
        removeFavorite={props.removeFavorite}
        addDerivative={() => {
          addDerivativeForShareRealizationItemItem(
            selectedShareRealization?.id,
          );
        }}
        onClose={() => onCloseShareRealizationDetailModal()}
      />

      <div className={styles.title}>グループを選択</div>
      <ClassSelector
        classes={props.classes}
        selectedClassID={props.searchParam.classId}
        selectedClassName={props.searchParam.className}
        update={(args: { [key: string]: any }) => {
          props.search({
            classId: args.selectedClassID,
            className: args.selectedClassName,
          });
        }}
        clear={props.clearRealizationKlasses}
        isPrimaryClassOnly={props.isTimelinePast}
      />

      {realizations.map((item, index) => {
        if ("has_comment" in item) {
          const realization_klass = item;
          const {
            realization: { gearbox, categories },
          } = realization_klass;
          return gearbox ? (
            <BoxItem
              key={realization_klass.id}
              color="dark"
              title={gearbox.title}
              realizationsCount={gearbox.realizations_count ?? 0}
              isArchived={gearbox.is_archived}
              nameStatus={gearbox.name_status}
              boxCreatorName={gearbox.teacher.full_name ?? "ー"}
              searchText={props.searchParam.text}
              newestRealization={{
                id: realization_klass.realization.id,
                content: realization_klass.realization.content,
                student: realization_klass.realization.student,
              }}
              redirectTo={() => {
                if (!props.redirectToBox) return;
                props.redirectToBox(gearbox.id);
              }}
            />
          ) : (
            <RealizationItem
              calling={props.calling}
              key={realization_klass.id}
              realization={{
                ...realization_klass.realization,
                is_favorite: realization_klass.is_favorite,
                has_comment: realization_klass.has_comment,
                categories,
                comment_count: realization_klass.comment_count,
                created_at: realization_klass.created_at,
                student: realization_klass.realization.student,
              }}
              isTimelinePast={props.isTimelinePast}
              searchText={props.searchParam.text}
              onClick={() => {
                setSelectedRealizationKlassId(realization_klass.id);
                props.fetchComments(realization_klass.realization.id);
              }}
              addFavorite={props.addFavorite}
              removeFavorite={props.removeFavorite}
              isOtherStudent={
                props.student_id !== realization_klass.realization.student_id
              }
              addDerivative={() => {
                addDerivativeForRealizationItem(realization_klass.id);
              }}
            />
          );
        } else {
          return (
            <ShareRealizationItem
              key={`${index}_${item.id}`}
              calling={props.calling}
              shareRealization={item}
              isOtherStudent={props.student_id !== item.student_id}
              addDerivative={() => {
                addDerivativeForShareRealizationItemItem(item.id);
              }}
              addFavorite={props.addFavorite}
              removeFavorite={props.removeFavorite}
              onClick={() => {
                onClickShareRealizationItem(item.id);
              }}
            />
          );
        }
      })}

      {props.realization_klasses.length > 0 && (
        <div className={styles.loadMore} ref={loadMoreRef}>
          {props.calling ? "読み込み中" : ""}
        </div>
      )}
    </div>
  );
};

export default Timeline;
