import React from "react";

import Portal from "../Portal";

import styles from "./CommentModal.module.scss";

type Props = {
  isOpen: boolean;
  className?: string;
  onClickBackdrop?: () => void;
};

const CommentModal: React.FC<Props> = ({
  children,
  isOpen,
  className,
  onClickBackdrop,
}) => {
  return isOpen ? (
    <Portal>
      <div className={`${styles.modal} ${className}`}>
        <div className={`${styles.backdrop}`} onClick={onClickBackdrop} />
        {children}
      </div>
    </Portal>
  ) : null;
};

export default CommentModal;
