import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  IonContent,
  IonPage,
  useIonViewWillLeave,
  useIonViewWillEnter,
} from "@ionic/react";

import useThemeTutorial from "../../../hooks/useThemeTutorial";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import {
  Context,
  createReport,
  clearRealizations,
  fetchCategories,
  clearSuccessMessage,
  fetchTeachers,
  fetchGearBoxes,
  CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE,
  CREATE_REALIZATION_SUCCESS_MESSAGE,
  CREATE_CATEGORY_SUCCESS_MESSAGE,
  categorizedRealizationPath,
  fetchClasses,
  fetchGearthemeRealization,
} from "../../../store/student";
import styles from "../common.module.scss";
import ThemeHeader from "../../../molecules/ThemeHeader";
import FiltableHeader from "../../../organisms/NewFiltableHeader";
import TimelineTheme from "../../../templates/TimelineTheme";

const TimelineThemePage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const [searchText, setSearchText] = React.useState("");
  const [isDisplaying, setIsDisplaying] = React.useState(false);

  const [themeTutorial, updateThemeTutorial] = useThemeTutorial(
    contextState.geartheme,
    contextState.student.read_gear_tutorials,
    dispatch,
  );

  const fetchRealizationGearthemeForSearch = (searchText?: string) => {
    setSearchText(searchText || "");
    dispatch(
      peep(fetchGearthemeRealization(Number(params.id), null, searchText)),
    );
  };

  const loadRealizations = React.useCallback(() => {
    dispatch(peep(clearSuccessMessage()));
    const list = contextState.realizations;
    dispatch(
      peep(
        fetchGearthemeRealization(
          Number(params.id),
          list[list.length - 1].id,
          searchText,
        ),
      ),
    );
  }, [dispatch, peep, contextState.realizations, params.id, searchText]);

  useIonViewWillEnter(() => {
    dispatch(fetchCategories());
    fetchRealizationGearthemeForSearch();
    setIsDisplaying(true);
    dispatch(fetchTeachers());
    dispatch(fetchGearBoxes({ is_check_archived: true }));
    dispatch(fetchClasses());
  }, [params.id]);

  useIonViewWillLeave(() => {
    dispatch(clearRealizations());
    setIsDisplaying(false);
  });

  React.useEffect(() => {
    if (calling) return;
    const categoryIDs = contextState.realization?.categories?.map(
      category => category.id,
    );
    if (contextState.success_message === CREATE_CATEGORY_SUCCESS_MESSAGE) {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchCategories()));
    } else if (
      contextState.success_message === CREATE_REALIZATION_SUCCESS_MESSAGE ||
      contextState.success_message ===
        CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      history.push(`${categorizedRealizationPath}/${categoryIDs?.[0] ?? 0}`);
    }
  }, [
    dispatch,
    contextState.success_message,
    contextState.realization,
    history,
    calling,
    peep,
  ]);

  const createReportFunc = React.useCallback(
    (params: { realization_id?: number; comment_id?: number }) => {
      if (calling) return;
      dispatch(peep(createReport(params)));
    },
    [calling, dispatch, peep],
  );

  return (
    <IonPage>
      <FiltableHeader
        title="みんなの回答"
        unreadNotiCount={
          contextState.unread_comments_count +
          contextState.unread_notification_count +
          (contextState.student.read_gear_release_note ? 0 : 1)
        }
        searchText={searchText}
        goBack
        onChangeFunc={fetchRealizationGearthemeForSearch}
      />
      <IonContent className={`${styles.wrapper} ${styles.bgWhileBase}`}>
        <div className={styles.container}>
          {contextState.geartheme && (
            <ThemeHeader
              geartheme={contextState.geartheme}
              updateThemeTutorial={updateThemeTutorial}
              disableCreate={!themeTutorial.completed}
              hideList
            />
          )}
          <TimelineTheme
            isDisplaying={isDisplaying}
            calling={calling}
            current_id={contextState.student.id}
            realizations={contextState.realizations}
            comments={contextState.comments}
            searchText={searchText}
            student_id={contextState.student.id}
            geartheme_pagination={contextState.geartheme_pagination}
            loadRealizations={loadRealizations}
            createReport={createReportFunc}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TimelineThemePage;
