import React from "react";
import clsx from "clsx";

import styles from "./RealizationThumbnailImage.module.scss";

export interface RealizationThumbnailImageProps {
  alt: string;
  src: string | undefined;
  onClick?: (e: any) => void;
  isEdit?: boolean;
}

const RealizationThumbnailImage = (props: RealizationThumbnailImageProps) => {
  return (
    <div
      className={clsx(
        styles.thumbnailWrapper,
        props.isEdit ? styles.edit : styles.view,
      )}
    >
      <img
        className={styles.thumbnailImage}
        alt={props.alt}
        src={props.src}
        onClick={props.onClick}
      />
    </div>
  );
};

export default RealizationThumbnailImage;
