import vol11Stamp from "../assets/images/Stamp/vol1_1.png";
import vol12Stamp from "../assets/images/Stamp/vol1_2.png";
import vol13Stamp from "../assets/images/Stamp/vol1_3.png";
import vol14Stamp from "../assets/images/Stamp/vol1_4.png";
import vol15Stamp from "../assets/images/Stamp/vol1_5.png";
import vol21Stamp from "../assets/images/Stamp/vol2_1.png";
import vol22Stamp from "../assets/images/Stamp/vol2_2.png";
import vol23Stamp from "../assets/images/Stamp/vol2_3.png";
import vol31Stamp from "../assets/images/Stamp/vol3_1.png";
import vol32Stamp from "../assets/images/Stamp/vol3_2.png";
import vol33Stamp from "../assets/images/Stamp/vol3_3.png";
import vol41Stamp from "../assets/images/Stamp/vol4_1.png";
import vol42Stamp from "../assets/images/Stamp/vol4_2.png";
import vol43Stamp from "../assets/images/Stamp/vol4_3.png";
import vol51Stamp from "../assets/images/Stamp/vol5_1.png";
import vol52Stamp from "../assets/images/Stamp/vol5_2.png";
import vol53Stamp from "../assets/images/Stamp/vol5_3.png";
import vol61Stamp from "../assets/images/Stamp/vol6_1.png";
import vol62Stamp from "../assets/images/Stamp/vol6_2.png";
import vol63Stamp from "../assets/images/Stamp/vol6_3.png";
import vol71Stamp from "../assets/images/Stamp/vol7_1.png";
import vol72Stamp from "../assets/images/Stamp/vol7_2.png";
import vol73Stamp from "../assets/images/Stamp/vol7_3.png";

export type Stamp = {
  id: number;
  category: string;
  name: string;
  image?: string;
};

export const stampCategories: Record<string, string> = {
  vol1: "自分で考えて動く力",
  vol2: "推進し実現する力",
  vol3: "相手の立場で考える力",
  vol4: "社会的な視点で考える力",
  vol5: "自分で成長機会を創る力",
  vol6: "自分で視野を広げる力",
  vol7: "自分で目的を設定する力",
};

// バックエンドにも同じ情報がデータベースに入っていますが、
// いまのところ固定なのでAPIでの受け渡しはせず、ソースコード中に決め打ちします
export const stamps: Stamp[] = [
  { id: 1, category: "vol1", name: "vol1_1.png", image: vol11Stamp },
  { id: 2, category: "vol1", name: "vol1_2.png", image: vol12Stamp },
  { id: 3, category: "vol1", name: "vol1_3.png", image: vol13Stamp },
  { id: 4, category: "vol1", name: "vol1_4.png", image: vol14Stamp },
  { id: 5, category: "vol1", name: "vol1_5.png", image: vol15Stamp },
  { id: 6, category: "vol2", name: "vol2_1.png", image: vol21Stamp },
  { id: 7, category: "vol2", name: "vol2_2.png", image: vol22Stamp },
  { id: 8, category: "vol2", name: "vol2_3.png", image: vol23Stamp },
  { id: 9, category: "vol3", name: "vol3_1.png", image: vol31Stamp },
  { id: 10, category: "vol3", name: "vol3_2.png", image: vol32Stamp },
  { id: 11, category: "vol3", name: "vol3_3.png", image: vol33Stamp },
  { id: 12, category: "vol4", name: "vol4_1.png", image: vol41Stamp },
  { id: 13, category: "vol4", name: "vol4_2.png", image: vol42Stamp },
  { id: 14, category: "vol4", name: "vol4_3.png", image: vol43Stamp },
  { id: 15, category: "vol5", name: "vol5_1.png", image: vol51Stamp },
  { id: 16, category: "vol5", name: "vol5_2.png", image: vol52Stamp },
  { id: 17, category: "vol5", name: "vol5_3.png", image: vol53Stamp },
  { id: 18, category: "vol6", name: "vol6_1.png", image: vol61Stamp },
  { id: 19, category: "vol6", name: "vol6_2.png", image: vol62Stamp },
  { id: 20, category: "vol6", name: "vol6_2.png", image: vol63Stamp },
  { id: 21, category: "vol7", name: "vol7_1.png", image: vol71Stamp },
  { id: 22, category: "vol7", name: "vol7_2.png", image: vol72Stamp },
  { id: 23, category: "vol7", name: "vol7_3.png", image: vol73Stamp },
];

export function loadStamp(id?: number): Stamp | undefined {
  return stamps.find(stamp => stamp.id === id);
}

function groupBy<T extends Record<string, any>>(
  arr: T[],
  by: keyof T,
): Record<string, T[]> {
  return arr.reduce((result, item) => {
    const groupKey = item[by];
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(item);
    return result;
  }, {} as Record<string, T[]>);
}

function shuffle(arr: Stamp[]): Stamp[] {
  const idOrder = [
    6, 4, 5, 3, 8, 10, 22, 19, 12, 15, 23, 17, 7, 11, 18, 20, 1, 9, 21, 14, 2,
    16, 13,
  ];
  const orderMap = new Map(idOrder.map((value, index) => [value, index]));

  return arr.sort((a, b) => {
    const indexA = orderMap.get(a["id"]);
    const indexB = orderMap.get(b["id"]);

    // Ensure to handle cases where the property value might not be in the order array
    if (indexA === undefined && indexB === undefined) return 0;
    if (indexA === undefined) return 1;
    if (indexB === undefined) return -1;

    return indexA - indexB;
  });
}

export const stampsByCategory = groupBy(stamps, "category");
export const stampsByRandom = shuffle(stamps);
