import React from "react";
import { createPortal } from "react-dom";

interface Props {
  container?: Element;
}

const Portal: React.FC<Props> = ({
  children,
  container = document.querySelectorAll("ion-app.ion-page")[0],
}): React.ReactPortal => createPortal(children, container);

export default Portal;
