import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import React from "react";

import LoginHeader from "../../../organisms/LoginHeader";
import {
  clearComments,
  clearRealizationKlassesPast,
  clearSuccessMessage,
  clearSuccessResult,
  Context,
  createComment,
  createReport,
  DELETE_COMMENT_SUCCESS_MESSAGE,
  deleteComment,
  FETCH_REALIZATION_KLASSES_PAST_SUCCESS_MESSAGE,
  fetchCategories,
  fetchClassesPast,
  fetchComments,
  fetchRealizationKlassesPast,
  UPDATE_COMMENT_SUCCESS_MESSAGE,
  updateComment,
} from "../../../store/student";
import Timeline from "../../../templates/Timeline";
import styles from "../common.module.scss";
import useWaitApiCall from "../../../hooks/useWaitApiCall";

const INITIAL_SEARCH_PARAM = {
  classId: 0,
  className: "すべてのグループ",
  text: "",
};

const TimelinePastPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const [isDisplaying, setIsDisplaying] = React.useState(false);
  const [searchParam, setSearchParam] = React.useState({
    ...INITIAL_SEARCH_PARAM,
  });

  React.useEffect(() => {
    if (
      contextState.success_message ===
      FETCH_REALIZATION_KLASSES_PAST_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
    }
  }, [
    dispatch,
    contextState.success_message,
    contextState.realization_klasses,
  ]);

  React.useEffect(() => {
    if (
      contextState.success_message === UPDATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === DELETE_COMMENT_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchComments(contextState.success_result["id"])));
      dispatch(clearSuccessResult());
    }
  }, [
    dispatch,
    contextState.success_message,
    contextState.success_result,
    peep,
  ]);

  const fetchRealizationKlassesForSearch = (args?: {
    classId?: number;
    className?: string;
    text?: string;
  }) => {
    const param = { ...searchParam, ...args };
    setSearchParam(param);
    dispatch(peep(fetchRealizationKlassesPast(param.classId, null)));
  };
  const fetchRealizationKlassesForReadMore = () => {
    const s = searchParam;
    const list = contextState.realization_klasses_past;
    const prev_time = new Date(list[list.length - 1].created_at).toISOString();
    dispatch(peep(fetchRealizationKlassesPast(s.classId, prev_time)));
  };

  const clearRealizationKlassesFunc = React.useCallback(() => {
    dispatch(clearRealizationKlassesPast());
  }, [dispatch]);

  const createCommentFunc = React.useCallback(
    (realization_id: number, content: string) => {
      dispatch(peep(createComment(realization_id, content)));
    },
    [dispatch, peep],
  );

  const updateCommentFunc = React.useCallback(
    (comment_id: number, content: string) => {
      dispatch(peep(updateComment(comment_id, content)));
    },
    [dispatch, peep],
  );

  const deleteCommentFunc = React.useCallback(
    (comment_id: number) => {
      dispatch(peep(deleteComment(comment_id)));
    },
    [dispatch, peep],
  );

  const createReportFunc = React.useCallback(
    (params: { realization_id?: number; comment_id?: number }) => {
      dispatch(peep(createReport(params)));
    },
    [dispatch, peep],
  );

  const fetchCommentsFunc = React.useCallback(
    (realization_id: number) => {
      dispatch(fetchComments(realization_id));
    },
    [dispatch],
  );

  const clearOldCommentsFunc = React.useCallback(() => {
    dispatch(clearComments());
  }, [dispatch]);

  useIonViewWillEnter(() => {
    dispatch(fetchCategories());
    dispatch(fetchClassesPast());
    setIsDisplaying(true);
  }, []);

  useIonViewWillLeave(() => {
    setIsDisplaying(false);
    clearRealizationKlassesFunc();
  });

  React.useEffect(() => {
    if (contextState.past_classes[0]) {
      fetchRealizationKlassesForSearch({
        classId: contextState.past_classes[0].id,
        className: contextState.past_classes[0].full_name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextState.past_classes]);

  return (
    <IonPage>
      <LoginHeader
        title="タイムライン"
        unreadNotiCount={
          contextState.unread_comments_count +
          contextState.unread_notification_count +
          (contextState.student.read_gear_release_note ? 0 : 1)
        }
      />
      <IonContent className={styles.wrapperPast}>
        {contextState.past_classes.length > 0 && (
          <div className={styles.container}>
            <Timeline
              isDisplaying={isDisplaying}
              calling={calling}
              searchParam={searchParam}
              current_id={contextState.student.id}
              classes={contextState.past_classes}
              realization_klasses={contextState.realization_klasses_past}
              comments={contextState.comments}
              categories={contextState.categories}
              timeline_pagination={contextState.timeline_pagination}
              student_id={contextState.student.id}
              search={fetchRealizationKlassesForSearch}
              readMore={fetchRealizationKlassesForReadMore}
              clearRealizationKlasses={clearRealizationKlassesFunc}
              createReport={createReportFunc}
              createComment={createCommentFunc}
              updateComment={updateCommentFunc}
              deleteComment={deleteCommentFunc}
              fetchComments={fetchCommentsFunc}
              clearOldComments={clearOldCommentsFunc}
              isTimelinePast
              reference_realizations={[]}
              preferred_realizations={[]}
            />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default TimelinePastPage;
