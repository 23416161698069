import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import {
  chevronDownOutline as downIcon,
  chevronUpOutline as upIcon,
  checkmark,
} from "ionicons/icons";
import clsx from "clsx";

import { IGearboxTeacher, ITeacher } from "../../state";

import styles from "./TeacherSelectForm.module.scss";

export interface TeacherSelectFormProps {
  isOpen: boolean;
  teachers: ITeacher[];
  submitted_teachers?: IGearboxTeacher[];
  selected_teacher_ids: number[];
  update: (args: { [key: string]: any }) => void;
  disabled?: boolean;
  isSecond?: boolean;
}

const TeacherSelectForm = (props: TeacherSelectFormProps) => {
  const teacherList = React.useMemo(
    () =>
      [...props.teachers, ...(props.submitted_teachers ?? [])].reduce(
        (uniqueTeacherList: IGearboxTeacher[], teacher) =>
          !uniqueTeacherList.some(
            (uniqueTeacher: IGearboxTeacher) => uniqueTeacher.id === teacher.id,
          )
            ? [
                ...uniqueTeacherList,
                { id: teacher.id, full_name: teacher.full_name ?? "" },
              ]
            : uniqueTeacherList,
        [],
      ),
    [props.submitted_teachers, props.teachers],
  );
  const displayNames =
    props.selected_teacher_ids.length === 0
      ? "未選択"
      : props.selected_teacher_ids.length >= 2
      ? `${
          teacherList.filter(t => {
            return t.id === props.selected_teacher_ids[0];
          })[0]?.full_name
        } ...他${props.selected_teacher_ids.length - 1}人`
      : teacherList.filter(t => {
          return t.id === props.selected_teacher_ids[0];
        })[0]?.full_name;

  return (
    <>
      <IonItem
        className={clsx(
          styles.teacherItemToggle,
          props.disabled && styles.disabled,
          props.isSecond && styles.second,
        )}
        onClick={() => {
          props.update({
            showSubmitForm: !props.isOpen,
            showCategoryForm: false,
            showShareForm: false,
            showBoxForm: false,
          });
        }}
      >
        <IonLabel color="light" className={styles.title}>
          提出先
        </IonLabel>
        <div className={styles.selectedNames}>{displayNames}</div>

        {props.isOpen ? (
          <IonIcon icon={upIcon} slot="end" className={styles.arrowIcon} />
        ) : (
          <IonIcon icon={downIcon} slot="end" className={styles.arrowIcon} />
        )}
      </IonItem>
      <div className={props.isOpen ? styles.teacherArea : styles.close}>
        <ul className={styles.teacherSelector}>
          {teacherList.map(teacher => (
            <li
              key={teacher.id}
              className={styles.teacherItem}
              onClick={() => {
                if (props.selected_teacher_ids.includes(teacher.id)) {
                  const fileterdTeacherIDs = props.selected_teacher_ids.filter(
                    id => id !== teacher.id,
                  );
                  props.update({
                    selected_teacher_ids: fileterdTeacherIDs,
                  });
                } else {
                  props.update({
                    selected_teacher_ids: [
                      ...props.selected_teacher_ids,
                      teacher.id,
                    ],
                  });
                }
              }}
            >
              <div className={styles.teacherName}>
                {teacher.full_name}
                {props.selected_teacher_ids.includes(teacher.id) && (
                  <IonIcon
                    slot="end"
                    icon={checkmark}
                    className={styles.checkIcon}
                  />
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default TeacherSelectForm;
