import ja from "date-fns/locale/ja";
import { format, parse } from "date-fns";

import { ICategory } from "../state";

const Chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

export function delimitize(num: number) {
  return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

export function zeroPadding(num: number, padding = 2) {
  const paddingStr = Array.from(Array(padding), () => 0).join("");
  return (paddingStr + num.toString()).slice(-1 * padding);
}

export function randomStr(n: number) {
  let s = "";
  while (n > 0) {
    s += Chars[Math.floor(Math.random() * Chars.length)];
    n -= 1;
  }
  return s;
}

export function uniqueStr() {
  const now = new Date();
  return (
    now.getFullYear() +
    zeroPadding(now.getMonth() + 1) +
    zeroPadding(now.getDate()) +
    randomStr(6)
  );
}

export function getCurrentFiscalYear() {
  const now = new Date();
  return now.getMonth() < 3 ? now.getFullYear() - 1 : now.getFullYear();
}

export function addDate(date: Date, num: number) {
  return new Date(
    new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() +
      1000 * 60 * 60 * 24 * num,
  );
}

export function age(birthday: Date) {
  const today = new Date();
  let bias = 0;
  if (
    zeroPadding(today.getMonth()) + zeroPadding(today.getDate()) <
    zeroPadding(birthday.getMonth()) + zeroPadding(birthday.getDate())
  ) {
    bias = -1;
  }
  return today.getFullYear() - birthday.getFullYear() + bias;
}

export function convertUidToUuid(uid: string) {
  return (
    uid.slice(0, 8) +
    "-0000-4000-8000-" +
    uid
      .slice(8)
      .split("")
      .map(c => c.charCodeAt(0).toString(16))
      .join("")
  );
}

export function dateStr(d: Date | undefined | null, withoutYear?: boolean) {
  if (!d) return "";
  const date = new Date(d);
  return (
    (withoutYear ? "" : date.getFullYear().toString() + "年") +
    (date.getMonth() + 1) +
    "月" +
    date.getDate() +
    "日"
  );
}

export function simpleDateStr(params: {
  date: Date;
  year?: boolean;
  month?: boolean;
  day?: boolean;
}) {
  let result = "";
  if (params.year !== false) {
    result = `${params.date.getFullYear()}`;
  }
  if (params.month !== false) {
    result +=
      (result ? "/" : "") + `${zeroPadding(params.date.getMonth() + 1)}`;
  }
  if (params.day !== false) {
    result += (result ? "/" : "") + `${zeroPadding(params.date.getDate())}`;
  }
  return result;
}

export function dToS(date: Date) {
  return (
    date.getFullYear().toString() +
    "-" +
    zeroPadding(date.getMonth() + 1) +
    "-" +
    zeroPadding(date.getDate())
  );
}

export function dayStr(date: Date | undefined | null) {
  if (!date) return "";
  return ["日", "月", "火", "水", "木", "金", "土"][date.getDay()];
}

export function fetchIDFromPath() {
  const id = window.location.pathname.split("/").pop() as string;
  return Number(id);
}

export function simpleDateTimeStr(date: Date) {
  return format(date, "yyyy/MM/dd HH:mm", { locale: ja });
}

export function buildDate(date: Date | string) {
  if (typeof date === "string") {
    return new Date(date.replace(" +0900", "+09:00").replace(" ", "T"));
  } else {
    return new Date(date);
  }
}

export function parseQueryParams(url: string) {
  const result: { [key: string]: any } = {};
  const query = url.replace(/.*\?/, "").split("&");
  query.forEach(r => {
    const pair = r.split("=");
    const val = decodeURI(pair[1]);
    if (pair[0].match(/\[\]$/)) {
      const key = pair[0].replace("[]", "");
      if (!result[key]) {
        result[key] = [];
      }
      if (val !== "") {
        result[key].push(val);
      }
    } else {
      result[pair[0]] = val;
    }
  });
  return result;
}

export function parseLocationQueryParams() {
  const result: { [key: string]: any } = {};
  if (!window.location.href.match(/\?/)) {
    return result;
  }
  return parseQueryParams(window.location.href);
}

/**
 * Check if two arrays are equal
 * @param  {Array}   arr1 The first array
 * @param  {Array}   arr2 The second array
 * @return {Array}   different element array
 */
function arraysMissMatch(arr1: any[], arr2: any[]) {
  const arr = [];
  // Check if all items exist and are in the same order
  for (let i = 0; i < arr1.length; i++) {
    const d = diff(arr1[i], arr2[i]);
    if (typeof d !== "object" || Object.keys(d).length > 0) {
      arr.push(d);
    }
  }
  if (arr1.length < arr2.length) {
    for (let i = arr1.length; i < arr2.length; i++) {
      arr.push(arr2[i]);
    }
  }
  // Otherwise, return true
  return arr;
}

/**
 * Compare two items and push non-matches to object
 * @param  {*}      item1 The first item
 * @param  {*}      item2 The second item
 * @param  {String} key   The key in our object
 */
function compare(item1: any, item2: any, key: string) {
  const type1 = Object.prototype.toString.call(item1);
  const type2 = Object.prototype.toString.call(item2);
  const diffs: { [key: string]: any } = {};

  if (type1 !== type2) {
    diffs[key] = item2;
    return diffs;
  }

  if (item1 instanceof Date && item2 instanceof Date) {
    if (item1.getTime() !== item2.getTime()) {
      diffs[key] = item2;
    }
    return diffs;
  }

  if (type1 === "[object Object]") {
    const objDiff = diff(item1, item2);
    if (typeof objDiff !== "object" || Object.keys(objDiff).length > 0) {
      diffs[key] = objDiff;
    }
    return diffs;
  }

  if (type1 === "[object Array]") {
    const missMatch = arraysMissMatch(item1, item2);
    if (missMatch.length > 0) {
      diffs[key] = missMatch;
    }
    return diffs;
  }

  if (type1 === "[object Function]") {
    if (item1.toString() !== item2.toString()) {
      diffs[key] = item2;
    }
    return diffs;
  }

  if (item1 !== item2) {
    diffs[key] = item2;
  }
  return diffs;
}

export function diff(obj1: any, obj2: any) {
  let diffs: { [key: string]: any } = {};
  let key: string;

  if (obj1 === obj2) {
    return diffs;
  }
  if (typeof obj1 === "number" && typeof obj2 === "number") {
    if (obj1 !== obj2) {
      return obj2;
    }
    return diffs;
  }
  if (typeof obj1 === "string" && typeof obj2 === "string") {
    if (obj1 !== obj2) {
      return obj2;
    }
    return diffs;
  }
  if (typeof obj1 === "undefined" || obj1 === null) {
    if (typeof obj2 === "undefined" || obj2 === null) {
      return diffs;
    }
    return obj2;
  }

  if (Object.prototype.toString.call(obj1) === "[object Function]") {
    if (obj1.toString() === obj2.toString()) {
      return diffs;
    }
    return obj2;
  }

  if (obj1 instanceof Date && obj2 instanceof Date) {
    if (obj1.getTime() !== obj2.getTime()) {
      return obj2;
    }
    return diffs;
  }

  for (key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (obj2) {
        diffs = { ...diffs, ...compare(obj1[key], obj2[key], key) };
      } else {
        diffs[key] = undefined;
      }
    }
  }
  for (key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (!obj1.hasOwnProperty(key)) {
        diffs[key] = obj2[key];
      }
    }
  }
  return diffs;
}
export function setScrollTop(top: number) {
  const es = window.document.getElementsByTagName("ion-content");
  es[es.length - 1].getScrollElement().then(e => e.scrollTo(0, top));
}

export function convertLink(content: string) {
  return content
    .replace(/&/g, "&amp;")
    .replace(/"/g, "&quot;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(
      /\[([^\]]*)\]\(([^)]*)\)/g,
      '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>',
    );
}

export function isImageFile(file: File) {
  const validImageTypes = ["image/png", "image/jpg", "image/jpeg"];
  return validImageTypes.includes(file.type);
}

export function convertDateToDateString(date: Date) {
  return format(date, "yyyy年MM月dd日", { locale: ja });
}

export function getDateString(date: Date = new Date()) {
  return format(date, "yyyyMMdd", { locale: ja });
}

export function convertDateStringToDate(dateString: string) {
  return dateString
    ? parse(
        dateString.replace(/年|月|\//g, "-").replace(/日/g, ""),
        "yyyy-MM-dd",
        new Date(),
        {
          locale: ja,
        },
      )
    : null;
}

export const objectArrayToKeyArray = (
  array?: { [key: string]: any }[],
  key = "id",
) => {
  return array?.map(item => item[key]) ?? [];
};

export const getUniqueSimpleArray = (array: (number | string)[]) => {
  return Array.from(new Set(array));
};

export function mergeArraysByUniqueKey({
  uniqueKey = "id",
  arrays,
}: {
  uniqueKey?: string;
  arrays: { [key: string]: any }[][];
}) {
  return Array.from(
    arrays
      .flat()
      .reduce(
        (acc, curr) =>
          acc.set(curr[uniqueKey], { ...acc.get(curr[uniqueKey]), ...curr }),
        new Map<number, { [key: string]: any }>(),
      )
      .values(),
  );
}

export const formatTimeToString = (date: Date | null | undefined) =>
  date ? dToS(date) : date;

export const reduceCategoriesForTimeline = (
  categories: ICategory[] | undefined,
) => {
  if (!categories) {
    return [];
  }

  const maxLength = 3;
  if (categories.length <= maxLength) {
    return categories;
  }

  const result = categories.slice(0, maxLength);
  result.push({
    ...categories[maxLength],
    name: "他...",
    color: "#98999b",
  });
  return result;
};
