import React, { useRef } from "react";
import { useIonViewWillLeave } from "@ionic/react";

import { IComment, IRealization, ITimelinePagination } from "../../state";
import RealizationItem from "../../molecules/RealizationItem";
import TimelineEntryDetailModal from "../../organisms/TimelineEntryDetailModal";
import useIntersection from "../../hooks/useIntersection";

import styles from "./TimelineTheme.module.scss";

export interface TimelineThemeProps {
  isDisplaying: boolean;
  calling: boolean;
  current_id: number;
  realizations: IRealization[];
  comments: IComment[];
  searchText: string;
  student_id: number;
  geartheme_pagination: ITimelinePagination;
  loadRealizations: () => void;
  createReport: (params: {
    realization_id?: number;
    comment_id?: number;
  }) => void;
}

const TimelineTheme: React.FC<TimelineThemeProps> = ({
  isDisplaying,
  calling,
  current_id,
  realizations,
  comments,
  searchText,
  student_id,
  geartheme_pagination,
  loadRealizations,
  createReport,
}) => {
  const [state, setState] = React.useState({
    selectedRealizationID: 0,
  });

  const loadMoreRef = useRef<HTMLDivElement>(null);

  const selectedRealization = React.useMemo(
    () => realizations.find(r => r.id === state.selectedRealizationID),
    [realizations, state.selectedRealizationID],
  );

  useIonViewWillLeave(() => {
    setState(prevState => ({ ...prevState, selectedRealizationID: 0 }));
  });

  useIntersection({
    target: loadMoreRef,
    enabled:
      isDisplaying &&
      !calling &&
      !!realizations.length &&
      geartheme_pagination.has_next,
    onIntersect: () => loadRealizations(),
  });

  return (
    <div className={styles.wrapper}>
      {selectedRealization && (
        <TimelineEntryDetailModal
          current_id={current_id}
          realization={selectedRealization}
          isOtherStudent={student_id !== selectedRealization.student_id}
          comments={comments}
          hideReactions
          showDetail={!!selectedRealization}
          onClose={() => setState(s => ({ ...s, selectedRealizationID: 0 }))}
          deleteComment={() => null}
          createReport={createReport}
          createComment={() => null}
          updateComment={() => null}
          clearOldComments={() => null}
          addDerivative={() => null}
        />
      )}
      {realizations.map(realization => (
        <RealizationItem
          key={realization.id}
          calling={calling}
          realization={realization}
          searchText={searchText}
          isOtherStudent={student_id !== realization.student_id}
          hideReactions
          onClick={() => {
            setState(s => ({ ...s, selectedRealizationID: realization.id }));
          }}
          addDerivative={() => null}
        />
      ))}
      <div className={styles.loadMore} ref={loadMoreRef}>
        {calling ? "読み込み中" : ""}
      </div>
    </div>
  );
};

export default TimelineTheme;
