import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  IonIcon,
  IonButton,
} from "@ionic/react";
import React from "react";

import FiltableHeader from "../../../organisms/NewFiltableHeader";
import { Context, fetchGearBoxes, timelinePath } from "../../../store/student";
import TimelineBoxList from "../../../templates/TimelineBoxList";
import timelineIcon from "../../../assets/icons/people.svg";
import styles from "../common.module.scss";
import FabButton from "../../../atoms/FabButton";

const TimelineBoxListPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [searchText, setSearchText] = React.useState("");

  useIonViewWillEnter(() => {
    dispatch(fetchGearBoxes({ order_by_realization: true }));
  });

  return (
    <IonPage>
      <FiltableHeader
        title="BOXタイムライン"
        unreadNotiCount={
          contextState.unread_comments_count +
          contextState.unread_notification_count +
          (contextState.student.read_gear_release_note ? 0 : 1)
        }
        searchText={searchText}
        onChangeFunc={text => {
          setSearchText(text);
          dispatch(fetchGearBoxes({ title: text }));
        }}
        rightComponent={
          <IonButton
            className={styles.button}
            routerLink={timelinePath}
            routerDirection="root"
          >
            <IonIcon className={styles.timelineIcon} src={timelineIcon} />
          </IonButton>
        }
      />
      <IonContent className={styles.wrapper}>
        <div className={styles.container}>
          <TimelineBoxList
            searchText={searchText}
            gearBoxes={contextState.gearBoxes}
          />
        </div>
      </IonContent>
      <FabButton />
    </IonPage>
  );
};

export default TimelineBoxListPage;
