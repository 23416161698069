import { IonButton, IonLabel, IonModal } from "@ionic/react";
import clsx from "clsx";
import React, { ReactElement } from "react";

import styles from "./ModalConfirm.module.scss";

export interface ModalConfirmProps {
  loading?: boolean;
  isOpen: boolean;
  buttons: {
    title: string;
    type: "None" | "Success" | "Warning" | "Danger";
    action: () => void;
  }[];
  message: string | ReactElement;
  onClose?: () => void;
}

const ModalConfirm = (props: ModalConfirmProps) => {
  return (
    <IonModal
      isOpen={props.isOpen}
      onDidDismiss={props.onClose}
      className={styles.wrapper}
    >
      <div className={styles.modal}>
        <IonLabel className={styles.label}>{props.message}</IonLabel>
        <div className={styles.buttonWrapper}>
          {props.buttons.map((btn, index) => (
            <IonButton
              key={index}
              className={clsx(
                styles[btn.type],
                styles.btn,
                styles[`btn--${props.buttons.length}`],
              )}
              disabled={props.loading}
              onClick={btn.action}
            >
              {btn.title}
            </IonButton>
          ))}
        </div>
      </div>
    </IonModal>
  );
};

export default ModalConfirm;
