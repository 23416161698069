import React from "react";

import { Context } from "../../store/student";
import { ASSESSMENT_STATUS } from "../../constants/assessment-content";
import ComparisonBarChart from "../../atoms/ComparisonBarChart";

import styles from "./AssessmentComparisonBarChart.module.scss";

const AssessmentComparisonBarChart = () => {
  const { contextState } = React.useContext(Context);

  const expNos = React.useMemo(() => [1, 2, 3, 4, 5, 6, 7], []);

  const assessmentDiffData = React.useMemo(() => {
    const currentExps = contextState.assessment?.assessment_experiences;
    const prevExps =
      contextState.assessment?.last_assessment?.assessment_experiences;

    return expNos.map(no => {
      const currentNo = currentExps?.find(data => data.experience_no === no);
      const prevNo = prevExps?.find(data => data.experience_no === no);

      return {
        data: "",
        前回: prevNo?.score || 0,
        今回: currentNo?.score || 0,
      };
    });
  }, [contextState.assessment, expNos]);

  return (
    <div className={styles.assessmentComparison}>
      <div className={styles.title}>意識した素養</div>
      <div className={styles.wrapper}>
        <div className={styles.chart}>
          <ComparisonBarChart data={assessmentDiffData} />
        </div>
        <ul className={styles.labelList}>
          {ASSESSMENT_STATUS.map(status => (
            <li key={status.experienceNo} className={styles.label}>
              <span
                className={styles.disc}
                style={{ backgroundColor: status.colorCode }}
              />
              {status.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AssessmentComparisonBarChart;
