import { IonContent, IonList, useIonViewWillLeave } from "@ionic/react";
import React, { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  ICategory,
  IRealization,
  IPagyInfo,
  ITeacher,
  IClass,
  IFeedback,
  IComment,
  IStudentGearBox,
  IRealizationCreateParams,
  IRealizationImage,
  IRealizationUpdate,
  IStudent,
  ReactableType,
} from "../../state";
import RealizationTypeSelector from "../../molecules/RealizationTypeSelector";
import SelfRealizationItem from "../../molecules/SelfRealizationItem";
import RealizationDeleteForm from "../../organisms/Forms/RealizationDeleteForm";
import TemplateModal from "../../organisms/TemplateModal";
import useIntersection from "../../hooks/useIntersection";
import RealizationDetail from "../RealizationDetail";
import {
  CREATE_COMMENT_SUCCESS_MESSAGE,
  CREATE_FEEDBACK_SUCCESS_MESSAGE,
  CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE,
  CREATE_REALIZATION_STAMP_REACTION_SUCCESS,
  DELETE_COMMENT_SUCCESS_MESSAGE,
  DELETE_FEEDBACK_SUCCESS_MESSAGE,
  DELETE_REALIZATION_SUCCESS_MESSAGE,
  EXECUTE_REALIZATION_SUCCESS_MESSAGE,
  UPDATE_COMMENT_SUCCESS_MESSAGE,
  UPDATE_FEEDBACK_SUCCESS_MESSAGE,
  UPDATE_REALIZATION_SUCCESS_MESSAGE,
} from "../../store/student";
import { fetchIDFromPath } from "../../libs/Util";
import { Stamp } from "../../libs/stamp";

import styles from "./CategorizedRealizationList.module.scss";

export interface RouteParams {
  id: string;
}
export interface CategorizedRealizationListProps {
  student: IStudent;
  isDisplaying: boolean;
  categories: ICategory[];
  realizations: IRealization[];
  calling?: boolean;
  teachers: ITeacher[];
  classes: IClass[];
  feedbacks: IFeedback[];
  comments: IComment[];
  pathname: string;
  success_message: string;
  gearBoxes: IStudentGearBox[];
  type?: string;
  fetchCategorizedRealization: (page: number, type?: string) => void;
  clearSuccessMessage: () => void;
  clearComments: () => void;
  clearFeedbacks: () => void;
  createCategory: (category: ICategory) => void;
  fetchFeedbacks: (realization_id: number) => void;
  createFeedback: (realization_id: number, content: string) => void;
  updateFeedback: (feedback_id: number, content: string) => void;
  deleteFeedback: (feedback_id: number) => void;
  fetchComments: (realization_id: number) => void;
  createComment: (realization_id: number, content: string) => void;
  updateComment: (comment_id: number, content: string) => void;
  deleteComment: (comment_id: number) => void;
  createReport: (params: {
    realization_id?: number;
    comment_id?: number;
  }) => void;
  deleteRealization: (realization_id: number) => void;
  createWillRealization(
    realization: IRealizationCreateParams,
    submit_teacher_ids: number[],
    share_class_ids: number[],
    gearbox_id: number | null,
    image?: IRealizationImage,
  ): void;
  updateRealization(
    realization: IRealizationUpdate,
    submit_teacher_ids: number[],
    share_class_ids: number[],
  ): void;
  executeRealization(realization_id: number): void;
  updateReadLastComment: (realization_id: number, comment_id: number) => void;
  updateReadLastFeedback: (realization_id: number, feedback_id: number) => void;
  redirectToWillList: (category_id: number) => void;
  redirectToExecuteList: (category_id: number) => void;
  pagy_info: IPagyInfo;
  searchText: string;
  createStampReaction?: (
    realization_id: number,
    type: ReactableType,
    stamp: Stamp,
  ) => void;
}

const CategorizedRealizationList = (props: CategorizedRealizationListProps) => {
  const history = useHistory();
  const location = useLocation();

  const loadMoreRef = useRef<HTMLDivElement>(null);
  const categoryMap = { 0: { name: "すべて", color: "blue" } } as {
    [key: number]: { name: string; color: string };
  };
  props.categories.forEach(c => {
    categoryMap[c.id] = { name: c.name, color: c.color };
  });
  const [values, updateValues] = React.useState({
    realization_id: 0,
    showRealizationDeleteForm: false,
    realizationDetailId: 0,
    convert_category_id: 0,
  });
  const update = (args: { [key: string]: any }) => {
    updateValues(prevValues => ({ ...prevValues, ...args }));
  };

  const realizationDetail = React.useMemo(
    () => props.realizations.find(r => r.id === values.realizationDetailId),
    [props.realizations, values.realizationDetailId],
  );

  const filterRealizations = React.useMemo(() => {
    const categoryId = fetchIDFromPath();
    const realizationList = categoryId
      ? props.realizations.filter(r =>
          r.categories?.map(c => c.id).includes(categoryId),
        )
      : props.realizations;
    return realizationList.filter(realization => {
      if (!props.type) return true;
      return props.type === "roots"
        ? realization.kind === "roots"
        : props.type === "will"
        ? realization.kind === "will" && !realization.executed_at
        : realization.kind === "will" && realization.executed_at;
    });
  }, [props.realizations, props.type]);

  const fetchContents = React.useCallback(
    (realization_id: number, fetchFunctions: ((id: number) => void)[]) => {
      props.clearSuccessMessage();
      fetchFunctions.forEach(
        func => realization_id !== 0 && func(realization_id),
      );
    },
    [props],
  );

  useIntersection({
    target: loadMoreRef,
    enabled:
      props.isDisplaying &&
      !props.calling &&
      props.pagy_info.page < props.pagy_info.pages,
    onIntersect: () =>
      props.fetchCategorizedRealization(props.pagy_info.page + 1, props.type),
  });

  React.useEffect(() => {
    if (values.realizationDetailId) {
      props.fetchComments(values.realizationDetailId);
      props.fetchFeedbacks(values.realizationDetailId);
    } else {
      props.clearComments();
      props.clearFeedbacks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.realizationDetailId]);

  React.useEffect(() => {
    if (
      props.success_message ===
        CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE &&
      props.isDisplaying
    ) {
      props.clearSuccessMessage();
      update({
        realizationDetailId: 0,
        convert_category_id: 0,
      });
      props.redirectToWillList(values.convert_category_id);
    }
    if (
      props.success_message === UPDATE_REALIZATION_SUCCESS_MESSAGE &&
      props.isDisplaying
    ) {
      props.clearSuccessMessage();
    }
    if (
      props.success_message === CREATE_COMMENT_SUCCESS_MESSAGE ||
      props.success_message === UPDATE_COMMENT_SUCCESS_MESSAGE ||
      props.success_message === DELETE_COMMENT_SUCCESS_MESSAGE
    ) {
      fetchContents(values.realizationDetailId, [props.fetchComments]);
    }
    if (
      props.success_message === CREATE_FEEDBACK_SUCCESS_MESSAGE ||
      props.success_message === UPDATE_FEEDBACK_SUCCESS_MESSAGE ||
      props.success_message === DELETE_FEEDBACK_SUCCESS_MESSAGE
    ) {
      fetchContents(values.realizationDetailId, [props.fetchFeedbacks]);
    }
    if (props.success_message === CREATE_REALIZATION_STAMP_REACTION_SUCCESS) {
      fetchContents(values.realizationDetailId, [
        props.fetchComments,
        props.fetchFeedbacks,
      ]);
    }
    if (props.success_message === DELETE_REALIZATION_SUCCESS_MESSAGE) {
      props.clearSuccessMessage();
      update({
        realization_id: 0,
        showRealizationDeleteForm: false,
        realizationDetailId: 0,
      });
    }
    if (
      props.success_message === EXECUTE_REALIZATION_SUCCESS_MESSAGE &&
      props.isDisplaying
    ) {
      props.clearSuccessMessage();
      update({ realizationDetailId: 0 });
      props.redirectToExecuteList(realizationDetail?.categories?.[0]?.id ?? 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.success_message, fetchContents]);

  useIonViewWillLeave(() => {
    update({
      realization_id: 0,
      showRealizationDeleteForm: false,
      realizationDetailId: 0,
      convert_category_id: 0,
    });
  });

  const changeWillRealization = (
    realization: IRealizationCreateParams,
    submit_teacher_ids: number[],
    share_class_ids: number[],
    gearbox_id: number | null,
    image?: IRealizationImage,
  ) => {
    update({
      convert_category_id: realization.category_ids?.[0] ?? 0,
    });
    props.createWillRealization(
      realization,
      submit_teacher_ids,
      share_class_ids,
      gearbox_id,
      image,
    );
  };

  const updateRootRealization = (
    realization: IRealizationUpdate,
    submit_teacher_ids: number[],
    share_class_ids: number[],
  ) => {
    update({
      convert_category_id: realization.category_ids?.[0] ?? 0,
    });
    props.updateRealization(realization, submit_teacher_ids, share_class_ids);
  };

  const changeFilterList = (type?: "roots" | "will" | "exp") => {
    if (props.type === type) return;
    history.replace({
      search: type ? `?type=${type}` : undefined,
      state: location.state,
    });
    props.fetchCategorizedRealization(1, type);
  };

  return (
    <>
      <RealizationTypeSelector
        type={props.type}
        changeFilterList={changeFilterList}
      />
      <div className={styles.wrapper}>
        <RealizationDeleteForm
          isOpen={values.showRealizationDeleteForm}
          update={update}
          delete={props.deleteRealization}
          realization_id={values.realization_id}
        />
        {realizationDetail && (
          <TemplateModal
            headerTitle="詳細"
            isOpen={!!values.realizationDetailId}
            onClose={() =>
              update({
                realizationDetailId: 0,
              })
            }
          >
            <IonContent>
              <RealizationDetail
                calling={props.calling}
                student={props.student}
                realization={realizationDetail}
                categories={props.categories}
                teachers={props.teachers}
                classes={props.classes}
                feedbacks={props.feedbacks}
                comments={props.comments}
                pathname={props.pathname}
                success_message={props.success_message}
                gearBoxes={props.gearBoxes}
                clearSuccessMessage={props.clearSuccessMessage}
                createCategory={props.createCategory}
                createFeedback={props.createFeedback}
                updateFeedback={props.updateFeedback}
                deleteFeedback={props.deleteFeedback}
                createComment={props.createComment}
                updateComment={props.updateComment}
                deleteComment={props.deleteComment}
                createReport={props.createReport}
                deleteRealization={props.deleteRealization}
                createWillRealization={changeWillRealization}
                updateRealization={updateRootRealization}
                executeRealization={props.executeRealization}
                updateReadLastComment={props.updateReadLastComment}
                updateReadLastFeedback={props.updateReadLastFeedback}
                createStampReaction={props.createStampReaction}
              />
            </IonContent>
          </TemplateModal>
        )}
        <IonList className={styles.wrapper}>
          {filterRealizations &&
            filterRealizations.map(realization => (
              <SelfRealizationItem
                key={realization.id}
                realization={realization}
                onAction={() => update({ realizationDetailId: realization.id })}
                searchText={props.searchText}
              />
            ))}
          <div className={styles.loadMore} ref={loadMoreRef}>
            {props.calling ? "読み込み中" : ""}
          </div>
        </IonList>
      </div>
    </>
  );
};

export default CategorizedRealizationList;
