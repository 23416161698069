import React from "react";

import CommentItem from "../../molecules/CommentItem";
import ModalConfirm from "../../molecules/ModalConfirm";
import { IComment, IFeedback, IOnClickStudentAvatarParams } from "../../state";
import ReportForm from "../Forms/ReportForm";

export interface CommentListProps {
  calling?: boolean;
  comments: any;
  current_student_id?: number;
  current_teacher_id?: number;
  createReport?: (params: {
    realization_id?: number;
    comment_id?: number;
  }) => void;
  delete: (comment_id: number) => void;
  update: (args: { [key: string]: any }) => void;
  onAvatarClick?: (params: IOnClickStudentAvatarParams) => void;
}

const CommentList = (props: CommentListProps) => {
  const [values, updateValues] = React.useState({
    targetCommentID: 0,
    reportTargetId: 0,
  });
  const [showCommentConfigID, updateShowCommentConfigID] = React.useState(0);

  const [isShowConfirm, setIsShowConfirm] = React.useState(false);
  const [commentItemDelete, setCommentItemDelete] = React.useState(0);

  const showDialog = React.useCallback((id: number) => {
    updateValues(v => ({ ...v, targetCommentID: id }));
  }, []);

  const handleSelectCommentItem = (id: number) => {
    setCommentItemDelete(id);
    setIsShowConfirm(true);
  };

  const handleCloseConfirmModal = () => {
    setIsShowConfirm(false);
    setCommentItemDelete(0);
  };

  const handleDelete = (id: number) => {
    props.delete(id);
    updateShowCommentConfigID(0);
    handleCloseConfirmModal();
  };

  return (
    <>
      {props.createReport && (
        <ReportForm
          isOpen={values.targetCommentID !== 0}
          close={() => updateValues({ ...values, targetCommentID: 0 })}
          report={() => {
            props.createReport &&
              props.createReport({ comment_id: values.targetCommentID });
            updateValues({ ...values, targetCommentID: 0 });
          }}
        />
      )}
      {props.comments.map((comment: IComment | IFeedback) => (
        <CommentItem
          calling={props.calling}
          key={comment.id}
          comment={comment}
          current_student_id={props.current_student_id}
          current_teacher_id={props.current_teacher_id}
          showCommentConfigID={showCommentConfigID}
          updateShowCommentConfigID={updateShowCommentConfigID}
          showDialog={props.createReport ? showDialog : undefined}
          delete={handleSelectCommentItem}
          update={props.update}
          onAvatarClick={props.onAvatarClick}
        />
      ))}
      <ModalConfirm
        isOpen={isShowConfirm}
        message={`削除してよろしいですか？\nこの操作は取り消しができません。`}
        buttons={[
          {
            title: "キャンセル",
            type: "None",
            action: handleCloseConfirmModal,
          },
          {
            title: "削除する",
            type: "Danger",
            action: () => handleDelete(commentItemDelete),
          },
        ]}
      />
    </>
  );
};

export default CommentList;
