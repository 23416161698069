import React from "react";
import clsx from "clsx";

import styles from "./CategoryLabel.module.scss";

export interface CategoryLabelProps {
  label: string;
  editable?: boolean;
  color?: string;
  selectable?: boolean;
  count?: number;
  fontSmall?: boolean;
  onClick?: () => void;
}

const CategoryLabel = (props: CategoryLabelProps) => {
  const color = {
    background: props.color,
  };
  return (
    <div
      className={clsx(styles.wrapper, props.editable && styles.pointer)}
      onClick={props.onClick}
    >
      <div className={styles.colorArea}>
        <div className={styles.colorTip} style={color} />
      </div>
      <div>
        <div
          className={`${styles.labelArea} ${props.selectable && styles.light}`}
        >
          <span
            className={`${styles.label} ${props.fontSmall && styles.fontS}`}
          >
            {props.label}
          </span>
          <span className={styles.count}>
            {props.count?.toString() ? `[${props.count}]` : ""}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CategoryLabel;
