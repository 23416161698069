import React from "react";
import clsx from "clsx";
import { IonIcon } from "@ionic/react";
import {
  bulbSharp,
  chatbubble,
  chatbubbleOutline,
  heart,
  heartOutline,
  mail,
  mailOutline,
} from "ionicons/icons";

import { EXPERIENCE_DATA } from "../../constants/experienceConstant";
import { IRealization } from "../../state";
import { convertBrAndHref, replaceSearchTextAndUrl } from "../../libs/Convert";
import { reduceCategoriesForTimeline } from "../../libs/Util";
import CategoryTag from "../../atoms/CategoryTag";
import RealizationThumbnailImage from "../../atoms/RealizationThumbnailImage";
import RealizationContentHeader from "../RealizationContentHeader";
import OriginRealization from "../OriginRealization";
import boxIcon from "../../assets/icons/box16.svg";
import groupsIcon from "../../assets/icons/groups.svg";
import userIcon from "../../assets/icons/user.svg";
import derivativeIcon from "../../assets/icons/derivative.svg";

import styles from "./RealizationContent.module.scss";

type Props = {
  realization: IRealization;
  commentCount?: number;
  searchText?: string;
  hideReactions?: boolean;
  isSelf?: boolean;
  isDetail?: boolean;
  color?: "light" | "dark";
  onClickThumbnailImage: () => void;
  onClickDerivative?: () => void;
  onClickFavorite?: () => void;
};

const RealizationContent = ({
  realization,
  commentCount: externalCommentCount,
  searchText,
  hideReactions,
  isSelf,
  isDetail,
  color = "dark",
  onClickThumbnailImage,
  onClickDerivative,
  onClickFavorite,
}: Props) => {
  const isStudent = color === "dark";

  const commentCount = React.useMemo(
    () => externalCommentCount ?? realization.comment_count ?? 0,
    [realization, externalCommentCount],
  );

  const sharedClasses = realization.shared_classes
    ?.map(c => c.full_name)
    .join("/");

  const submittedTeachers = realization.submitted_teachers
    ?.map(t => t.full_name)
    .join("/");

  const categories = React.useMemo(() => {
    return isDetail
      ? realization.categories
      : reduceCategoriesForTimeline(realization.categories);
  }, [realization, isDetail]);

  const experienceLabels = React.useMemo(() => {
    return EXPERIENCE_DATA.filter(e =>
      realization.experience_nos?.includes(e.no),
    );
  }, [realization]);

  return (
    <div className={styles.wrapper}>
      <RealizationContentHeader
        realization={realization}
        showChecked={isStudent}
      />
      {(realization.gearbox || sharedClasses || submittedTeachers) && (
        <div className={styles.boxClassWrapper}>
          {realization.gearbox && (
            <div className={styles.area}>
              <IonIcon className={styles.icon} src={boxIcon} />
              <span>{realization.gearbox.title}</span>
            </div>
          )}
          {sharedClasses && (
            <div className={styles.area}>
              <IonIcon className={styles.icon} src={groupsIcon} />
              <span>{sharedClasses}</span>
            </div>
          )}
          {submittedTeachers && (
            <div className={styles.area}>
              <IonIcon className={styles.icon} src={userIcon} />
              <span>{submittedTeachers}</span>
            </div>
          )}
        </div>
      )}
      {realization.geartheme && (
        <div className={styles.boxClassWrapper}>
          {realization.geartheme && (
            <div className={clsx(styles.area)}>
              <IonIcon className={styles.icon} icon={bulbSharp} />
              <span>{realization.geartheme.title}</span>
            </div>
          )}
        </div>
      )}
      <div
        className={clsx(
          styles.content,
          isDetail ? styles.detail : styles.listItem,
          color === "dark" ? styles.dark : styles.light,
        )}
      >
        {isDetail ? (
          convertBrAndHref(realization.content)
        ) : (
          <span
            dangerouslySetInnerHTML={{
              __html: replaceSearchTextAndUrl(
                realization.content,
                searchText ?? "",
              ),
            }}
          />
        )}
      </div>
      {realization.image && (
        <RealizationThumbnailImage
          alt={realization.image.name}
          src={realization.image.thumbnail}
          onClick={e => {
            e.stopPropagation();
            onClickThumbnailImage();
          }}
        />
      )}
      {realization.origin_realization && (
        <OriginRealization
          realization={realization.origin_realization}
          isDetail={isDetail}
          showAuthor={isSelf}
        />
      )}
      <div className={styles.labelGroup}>
        {!!experienceLabels?.length &&
          experienceLabels.map(e => (
            <p key={e.no} className={styles.experience} style={e.styles}>
              {e.label}
            </p>
          ))}
        {!!categories?.length && (
          <div className={styles.categoryWrapper}>
            {categories.map(category => (
              <CategoryTag
                key={category.id}
                label={category.name ?? "すべて"}
                color={category.color || "#959595"}
                fontSmall
              />
            ))}
          </div>
        )}
      </div>
      {hideReactions ? (
        <div className={styles.footWrapper} />
      ) : (
        <div className={styles.footWrapper}>
          {onClickDerivative && (
            <div className={styles.reactionArea}>
              <IonIcon
                src={derivativeIcon}
                onClick={e => {
                  e.stopPropagation();
                  onClickDerivative();
                }}
                className={clsx(
                  styles.icon,
                  styles.enabled,
                  realization.has_derivative && styles.active,
                )}
              />
            </div>
          )}
          {(onClickFavorite ||
            typeof realization.favorite_count === "number") && (
            <div className={styles.reactionArea}>
              <IonIcon
                icon={realization.is_favorite ? heart : heartOutline}
                onClick={e => {
                  if (onClickFavorite) {
                    e.stopPropagation();
                    onClickFavorite();
                  }
                }}
                className={clsx(
                  styles.icon,
                  onClickFavorite && styles.enabled,
                  realization.is_favorite && styles.active,
                )}
              />
              {typeof realization.favorite_count === "number" && (
                <span className={styles.count}>
                  {realization.favorite_count}
                </span>
              )}
            </div>
          )}
          <div className={styles.reactionArea}>
            <IonIcon
              icon={realization.has_my_comment ? chatbubble : chatbubbleOutline}
              className={clsx(
                styles.icon,
                !isDetail &&
                  realization.read_last_comment_id <
                    (realization.latest_comment_id ?? 0) &&
                  styles.unread,
                realization.has_my_comment && styles.active,
              )}
            />
            <span className={styles.count}>{commentCount}</span>
          </div>
          {typeof realization.feedback_count === "number" && (
            <div className={styles.reactionArea}>
              <IonIcon
                icon={realization.has_my_feedback ? mail : mailOutline}
                className={clsx(
                  styles.icon,
                  !isDetail &&
                    realization.read_last_feedback_id <
                      (realization.latest_feedback_id ?? 0) &&
                    styles.unread,
                  realization.has_my_feedback && styles.active,
                )}
              />
              <span className={styles.count}>{realization.feedback_count}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RealizationContent;
