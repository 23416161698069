import { IRealization, IRealizationKlass } from "../state";

export function update_realizations_state(
  realizations: IRealization[],
  realizationId: number,
): IRealization[] {
  return realizations.map(realization => ({
    ...realization,
    comment_count:
      realization.id === realizationId
        ? (realization.comment_count || 0) + 1
        : realization.comment_count,
    has_comment:
      realization.id === realizationId ? true : realization.has_comment,
    has_my_comment:
      realization.has_my_comment || realization.id === realizationId,
  }));
}

export function update_realization_klasses_state(
  realization_klasses: IRealizationKlass[],
  realizationId: number,
): IRealizationKlass[] {
  return realization_klasses.map(realization_klass => ({
    ...realization_klass,
    comment_count:
      realization_klass.realization.id === realizationId
        ? realization_klass.comment_count + 1
        : realization_klass.comment_count,
    has_comment:
      realization_klass.realization.id === realizationId
        ? true
        : realization_klass.has_comment,
    realization: {
      ...realization_klass.realization,
      has_my_comment:
        realization_klass.realization.has_my_comment ||
        realization_klass.realization.id === realizationId,
    },
  }));
}
