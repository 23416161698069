import React from "react";

import { Context } from "../../store/student";

import styles from "./PastRealizationFeedbackList.module.scss";

const PastRealizationFeedbackList = () => {
  const { contextState } = React.useContext(Context);
  const { realization_feedbacks } = contextState.student_realization_feedbacks;

  return (
    <ul className={styles.list}>
      {realization_feedbacks.map(feedback => (
        <li key={feedback.id} className={styles.item}>
          <div className={styles.inner}>
            <p className={styles.date}>{feedback.created_at}</p>
            <p className={styles.content}>{feedback.content}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default PastRealizationFeedbackList;
