import React from "react";
import clsx from "clsx";

import styles from "./CategoryTag.module.scss";

export interface CategoryTagProps {
  label: string;
  color?: string;
  fontSmall?: boolean;
  verticalMargin?: boolean;
}

const CategoryTag = (props: CategoryTagProps) => {
  const color = {
    borderLeftColor: props.color,
  };

  return (
    <div
      className={clsx(
        styles.wrapper,
        props.verticalMargin && styles.verticalMargin,
      )}
      style={color}
    >
      <div className={clsx(styles.label, props.fontSmall && styles.fontS)}>
        {props.label}
      </div>
    </div>
  );
};

export default CategoryTag;
