import React from "react";

import { IReasonExperience } from "../../state";

import styles from "./assessmentComment.module.scss";

export interface AssessmentCommentProps {
  currentReasons: IReasonExperience[];
  placeholder?: string;
  setReason: (reason: string) => void;
}

const AssessmentComment: React.FC<AssessmentCommentProps> = ({
  currentReasons,
  placeholder,
  setReason,
}) => {
  const [currentReason, setCurrentReason] = React.useState<string>("");

  React.useEffect(() => {
    const currentReasonId = Math.max(
      ...currentReasons.map(reason => reason.id),
    );
    setCurrentReason(
      currentReasons.find(reason => reason.id === currentReasonId)?.reason ||
        "",
    );
  }, [currentReasons]);

  return (
    <div className={styles.assessmentComment}>
      <textarea
        placeholder={placeholder}
        onChange={e => {
          const value = e.target.value.replace(/\r\n|\r|\n/g, "\n");
          setCurrentReason(value);
          setReason(value);
        }}
        value={currentReason}
      />
    </div>
  );
};

export default AssessmentComment;
