import "./App.css";
import React from "react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { datadogRum } from "@datadog/browser-rum";
import { setupIonicReact } from "@ionic/react";
import "react-datepicker/dist/react-datepicker.css";

import cookie, { cookieNames } from "./libs/cookie";
import { useReducerWithEnhancedDispatch } from "./libs/enhancedDispatch";
import { diff } from "./libs/Util";
import {
  Actions,
  analyzePath,
  AppState,
  categorizedRealizationPath,
  Context,
  editProfilePath,
  fetchInitialData,
  homePath,
  InitialAppState,
  realizationListPath,
  prefixPath,
  realizationDetailPath,
  reducer,
  timelinePath,
  timelinePastPath,
  newRealizationPath,
  editCategoriesPath,
  updateStudentPasswordPath,
  notificationListPath,
  boxesListPath,
  assessmentPath,
  assessmentDescriptionPath,
  assessmentListPath,
  assessmentReferencePath,
  commentNotificationsPath,
  LOGOUT_SUCCESS_MESSAGE,
  gearthemesListPath,
  timelineFilteredPath,
  pastRealizationFeedbackPath,
} from "./store/student";
import SideMenu from "./molecules/student/SideMenu";
import Tab from "./organisms/Tab";
import Error503Page from "./templates/Error503Page";
import Login from "./pages/student/Login";
import TimelinePage from "./pages/student/TimelinePage";
import TimelinePastPage from "./pages/student/TimelinePastPage";
import AnalyzePage from "./pages/student/AnalyzePage";
import StudentDetailPage from "./pages/student/StudentDetailPage";
import RealizationListPage from "./pages/student/RealizationListPage";
import NewRealization from "./pages/student/NewRealization";
import TimelineBoxListPage from "./pages/student/TimelineBoxListPage";
import TimelineBoxDetailPage from "./pages/student/TimelineBoxDetailPage";
import CategorizedRealizationListPage from "./pages/student/CategorizedRealizationListPage";
import EditProfile from "./pages/student/EditProfile";
import EditCategories from "./pages/student/EditCategoriesPage";
import UpdateStudentPassword from "./pages/student/UpdateStudentPassword";
import CommentNotificationsPage from "./pages/student/CommentNotificationsPage";
import Notifications from "./pages/student/Notifications";
import RealizationDetailPage from "./pages/student/RealizationDetailPage";
import BoxesListPage from "./pages/student/BoxesListPage";
import RealizationBoxPage from "./pages/student/RealizationBoxPage";
import TimelineTheme from "./pages/student/TimelineTheme";
import TimelineFilteredPage from "./pages/student/TimelineFilteredPage";
import AssessmentPage from "./pages/student/AssessmentPage";
import AssessmentDescriptionPage from "./pages/student/AssessmentDescriptionPage";
import AssessmentListPage from "./pages/student/AssessmentListPage";
import AssessmentReferencePage from "./pages/student/AssessmentReferencePage";
import PastRealizationFeedbackPage from "./pages/student/PastRealizationFeedbackPage";

setupIonicReact({
  mode: "ios",
});

const reducerForApp =
  process.env.NODE_ENV === "production"
    ? reducer
    : (contextState: AppState, action: Actions) => {
        console.groupCollapsed(action.types);
        console.log("action", action);
        const result = reducer(contextState, action);
        console.log("diff", diff(contextState, result));
        console.log("prevState", contextState);
        console.log("currentState", result);
        console.groupEnd();
        return result;
      };

const SESSION_KEY = "gear_student_session_id";

function App() {
  if (window.location.href.match(/session_id=/)) {
    const result = window.location.href.split("session_id=").pop() as string;
    const sessionID = result.replace(/&.*$/, "");
    sessionStorage.setItem(SESSION_KEY, sessionID);
  }
  const savedSessionId =
    sessionStorage.getItem(SESSION_KEY) ||
    cookie.getCookie(cookieNames.student_session_id) ||
    "";
  const [contextState, dispatch] = useReducerWithEnhancedDispatch({
    reducer: reducerForApp,
    initialAppState: { ...InitialAppState, session_id: savedSessionId },
  });

  React.useEffect(() => {
    if (contextState.student.id) {
      datadogRum.setUser({
        id: contextState.student.id,
        type: "student",
        class_id: contextState.student.class_id,
        school_id: contextState.student.school_id,
      });
    }
  }, [
    contextState.student.id,
    contextState.student.class_id,
    contextState.student.school_id,
  ]);

  React.useEffect(() => {
    if (contextState.session_id) {
      if (window.location.href.match(/session_id=/)) {
        window.history.pushState(null, "", window.location.pathname);
      }
      dispatch(fetchInitialData());
    }
  }, [contextState.session_id, dispatch]);

  React.useEffect(() => {
    if (
      savedSessionId &&
      !sessionStorage.getItem(SESSION_KEY) &&
      !cookie.getCookie(cookieNames.student_session_id) &&
      !contextState.session_id
    ) {
      window.location.reload();
    } else if (!savedSessionId && contextState.session_id) {
      cookie.set(cookieNames.student_session_id, contextState.session_id, 365);
    } else if (savedSessionId && !contextState.session_id) {
      sessionStorage.removeItem(SESSION_KEY);
      cookie.deleteAll();
    }
  }, [savedSessionId, contextState.session_id]);

  React.useEffect(() => {
    if (
      contextState.success_message === LOGOUT_SUCCESS_MESSAGE ||
      contextState.expires < new Date()
    ) {
      window.location.reload();
    }
  }, [contextState]);

  return contextState.isServiceUnavailable ? (
    <Error503Page />
  ) : (
    <IonApp>
      <Context.Provider value={{ contextState, dispatch }}>
        {contextState.session_id ? (
          <>
            <IonReactRouter basename={prefixPath}>
              <SideMenu />
              <Tab>
                <IonRouterOutlet mode="ios" id="sideMenuContainer">
                  <Route
                    exact
                    path={newRealizationPath}
                    component={NewRealization}
                  />
                  <Route
                    exact
                    path={editCategoriesPath}
                    component={EditCategories}
                  />
                  <Route exact path={timelinePath} component={TimelinePage} />
                  <Route
                    exact
                    path={boxesListPath}
                    component={TimelineBoxListPage}
                  />
                  <Route
                    exact
                    path={`${boxesListPath}/:id`}
                    component={TimelineBoxDetailPage}
                  />
                  <Route
                    exact
                    path={timelinePastPath}
                    component={TimelinePastPage}
                  />
                  <Route
                    exact
                    path={realizationListPath}
                    component={RealizationListPage}
                  />
                  <Route exact path={editProfilePath} component={EditProfile} />
                  <Route
                    exact
                    path={`${realizationDetailPath}/:id`}
                    component={RealizationDetailPage}
                  />
                  <Route
                    exact
                    path={`${categorizedRealizationPath}/:id`}
                    component={CategorizedRealizationListPage}
                  />

                  <Route
                    exact
                    path={commentNotificationsPath}
                    component={CommentNotificationsPage}
                  />
                  <Route
                    exact
                    path={notificationListPath}
                    component={Notifications}
                  />
                  <Route
                    exact
                    path={`${realizationListPath}${boxesListPath}`}
                    component={BoxesListPage}
                  />
                  <Route
                    exact
                    path={`${realizationListPath}${boxesListPath}/:id`}
                    component={RealizationBoxPage}
                  />
                  <Route
                    exact
                    path={`${timelineFilteredPath}/:action`}
                    component={TimelineFilteredPage}
                  />
                  <Route
                    exact
                    path={analyzePath}
                    component={
                      contextState.student.g4s_enabled
                        ? StudentDetailPage
                        : AnalyzePage
                    }
                  />
                  <Route
                    exact
                    path={`${gearthemesListPath}/:id`}
                    component={TimelineTheme}
                  />
                  <Route
                    exact
                    path={`${assessmentPath}/:expNo`}
                    component={AssessmentPage}
                  />
                  <Route
                    exact
                    path={assessmentDescriptionPath}
                    component={AssessmentDescriptionPage}
                  />
                  <Route
                    exact
                    path={assessmentListPath}
                    component={AssessmentListPage}
                  />
                  <Route
                    exact
                    path={assessmentReferencePath}
                    component={AssessmentReferencePage}
                  />
                  <Route
                    exact
                    path={pastRealizationFeedbackPath}
                    component={PastRealizationFeedbackPage}
                  />
                  <Redirect exact from={homePath} to={newRealizationPath} />
                </IonRouterOutlet>
              </Tab>
            </IonReactRouter>
          </>
        ) : (
          <IonReactRouter basename={prefixPath}>
            <IonRouterOutlet>
              <Route exact path={homePath} component={Login} />
              <Route
                path={`${updateStudentPasswordPath}/:uuid`}
                component={UpdateStudentPassword}
                redirectPath={homePath}
                isMatch={!contextState.session_id}
              />
              <Redirect to={homePath} />
            </IonRouterOutlet>
          </IonReactRouter>
        )}
      </Context.Provider>
    </IonApp>
  );
}

export default App;
