import React from "react";
import clsx from "clsx";
import {
  IonAvatar,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { chevronForwardOutline, personCircle } from "ionicons/icons";

import { IRealization } from "../../state";
import ImageDetailModal from "../ImageDetailModal";
import Toast from "../Toast";
import RealizationContent from "../RealizationContent";

import styles from "./RealizationItem.module.scss";

export interface RealizationItemProps {
  calling?: boolean;
  realization: IRealization;
  searchText?: string;
  isTimelinePast?: boolean;
  isOtherStudent?: boolean;
  hideReactions?: boolean;
  onClick?: () => void;
  addFavorite?: (id: number) => void;
  removeFavorite?: (id: number) => void;
  addDerivative: () => void;
}

const RealizationItem: React.FC<RealizationItemProps> = ({
  calling,
  realization,
  searchText,
  isTimelinePast,
  isOtherStudent,
  hideReactions,
  onClick,
  addFavorite,
  removeFavorite,
  addDerivative,
}) => {
  const [state, setState] = React.useState({
    showImageDetail: false,
    showToast: false,
  });

  const derivable = React.useMemo(
    () =>
      isOtherStudent &&
      !isTimelinePast &&
      realization.gearbox?.name_status !== "unpublish" &&
      !realization.geartheme,
    [realization, isOtherStudent, isTimelinePast],
  );

  const toggleFavoriteFunc = React.useCallback(() => {
    if (calling) return;
    if (realization.is_favorite) {
      removeFavorite && removeFavorite(realization.id);
    } else {
      addFavorite && addFavorite(realization.id);
    }
  }, [addFavorite, removeFavorite, realization, calling]);

  return (
    <>
      <Toast
        type="success"
        showToast={state.showToast}
        onClose={() => setState({ ...state, showToast: false })}
        message="ストックしました"
      />
      {realization.image && (
        <ImageDetailModal
          show={state.showImageDetail}
          imageUrl={realization.image.url}
          imageName={realization.image.name}
          onClose={() => setState(s => ({ ...s, showImageDetail: false }))}
        />
      )}
      <IonItem
        className={clsx(
          styles.realizationItem,
          isTimelinePast && styles.realizationItem__past,
        )}
        lines="full"
        onClick={onClick}
        button
        detail={false}
      >
        <div className={styles.avatarArea}>
          {realization.student?.photo?.url ? (
            <IonAvatar color="light" className={styles.avatar}>
              <IonImg
                alt="プロフィール画像"
                src={realization.student.photo.url}
              />
            </IonAvatar>
          ) : (
            <IonIcon
              className={styles.avatar}
              icon={personCircle}
              slot="start"
              color="light"
            />
          )}
        </div>
        <IonGrid className={styles.grid}>
          <IonRow className={styles.row}>
            <div className={styles.wrapperContent}>
              <div className={styles.container}>
                <div className={styles.topWrapper}>
                  <div className={styles.nameArea}>
                    <IonLabel color="light">
                      <span className={styles.name}>
                        {realization.student?.full_name ?? ""}
                      </span>
                    </IonLabel>
                  </div>
                </div>
                <RealizationContent
                  realization={realization}
                  searchText={searchText}
                  hideReactions={hideReactions}
                  onClickThumbnailImage={() => {
                    setState(s => ({ ...s, showImageDetail: true }));
                  }}
                  onClickFavorite={
                    !isTimelinePast ? toggleFavoriteFunc : undefined
                  }
                  onClickDerivative={derivable ? addDerivative : undefined}
                />
              </div>
              <IonIcon
                icon={chevronForwardOutline}
                color="medium"
                className={styles.iconCaretRight}
              />
            </div>
          </IonRow>
        </IonGrid>
      </IonItem>
    </>
  );
};

export default RealizationItem;
