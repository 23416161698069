import React from "react";
import clsx from "clsx";
import {
  IonAvatar,
  IonGrid,
  IonImg,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";

import { ShareRealization } from "../../state";
import ShareRealizationContent from "../ShareRealizationContent";
import ShareRealizationUserIcon from "../../assets/icons/share_realization_user_icon.svg";

import styles from "./ShareRealizationItem.module.scss";

export interface ShareRealizationItemProps {
  calling?: boolean;
  shareRealization: ShareRealization;
  isOtherStudent: boolean;
  onClick?: () => void;
  addDerivative?: () => void;
  addFavorite?: (id: number) => void;
  removeFavorite?: (id: number) => void;
}

const ShareRealizationItem: React.FC<ShareRealizationItemProps> = ({
  calling,
  shareRealization,
  isOtherStudent,
  onClick,
  addDerivative,
  addFavorite,
  removeFavorite,
}) => {
  const onClickDerivative = () => {
    addDerivative !== undefined && addDerivative();
  };

  const onClickFavorite = () => {
    if (calling) return;
    if (shareRealization.is_favorite) {
      removeFavorite !== undefined && removeFavorite(shareRealization.id);
      return;
    }
    addFavorite !== undefined && addFavorite(shareRealization.id);
  };

  return (
    <>
      <IonItem
        className={clsx(styles.realizationItem)}
        lines="full"
        onClick={onClick}
        button
        detail={false}
      >
        <div className={styles.avatarArea}>
          <IonAvatar color="light" className={styles.avatar}>
            <IonImg alt="プロフィール画像" src={ShareRealizationUserIcon} />
          </IonAvatar>
        </div>
        <IonGrid className={styles.grid}>
          <IonRow>
            <div className={styles.wrapperContent}>
              <div className={styles.container}>
                <div className={styles.topWrapper}>
                  <div className={styles.nameArea}>
                    <IonLabel color="light">
                      <span className={styles.name}>
                        {shareRealization.student.full_name}
                      </span>
                    </IonLabel>
                  </div>
                </div>
                <ShareRealizationContent
                  shareRealization={shareRealization}
                  derivable={isOtherStudent}
                  onClickDerivative={onClickDerivative}
                  onClickFavorite={onClickFavorite}
                  isDetail={false}
                />
              </div>
            </div>
          </IonRow>
        </IonGrid>
      </IonItem>
    </>
  );
};

export default ShareRealizationItem;
