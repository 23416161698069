import { useEffect, RefObject } from "react";

type useIntersectionParams = {
  target: RefObject<HTMLElement>;
  enabled: boolean;
  onIntersect: () => void;
};

export default function useIntersection({
  target,
  enabled,
  onIntersect,
}: useIntersectionParams) {
  useEffect(() => {
    const el = target.current;

    if (!enabled || !el) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }

    const observer = new IntersectionObserver(entries =>
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          onIntersect();
        }
      }),
    );

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [target, enabled, onIntersect]);
}
