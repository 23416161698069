import React from "react";
import { addDays, addMonths, subDays } from "date-fns";

import { IRealizationHistory } from "../../state";
import { simpleDateStr } from "../../libs/Util";
import LineChart, { LineChartOption } from "../../molecules/LineChart";

export interface AnalyzeLineStudentChartProps {
  realization_histories: IRealizationHistory[];
  rangeDate: {
    startDate: Date | null;
    endDate: Date | null;
  };
  options?: LineChartOption;
  widthContainer?: number;
}

export interface IHistoriesPerSpan {
  date: Date;
  action_num: number;
  convert_num: number;
  realization_num: number;
}

const initialHistory = {
  action_num: 0,
  convert_num: 0,
  realization_num: 0,
};

const AnalyzeLineStudentChart: React.FC<AnalyzeLineStudentChartProps> = ({
  realization_histories,
  rangeDate,
  options = {},
  widthContainer = 1000,
}) => {
  const [state, setState] = React.useState({
    resultSet: [] as IHistoriesPerSpan[],
    width: 0,
  });

  const range = React.useMemo(() => {
    let end = new Date();
    let start = subDays(end, 7);
    if (rangeDate.startDate && rangeDate.endDate) {
      start = rangeDate.startDate;
      end = rangeDate.endDate;
    } else if (realization_histories.length) {
      start = realization_histories[0].created_at;
    }

    const distanceTime: number = end.getTime() - start.getTime();
    const days: number = distanceTime / (24 * 60 * 60 * 1000);
    return {
      start,
      end,
      days,
    };
  }, [rangeDate, realization_histories]);

  const updateWidth = React.useCallback(
    () =>
      setState(s => ({
        ...s,
        width:
          window.innerWidth < widthContainer
            ? window.innerWidth
            : widthContainer,
      })),
    [widthContainer],
  );

  React.useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [updateWidth]);

  React.useEffect(() => {
    const startDate = range.start;
    const endDate = range.end;
    const days = range.days;
    let resultSet = [] as IHistoriesPerSpan[];

    if (range.days <= 100) {
      let step = 1;
      if (range.days > 30) {
        step = 7;
      } else if (range.days > 10) {
        step = 3;
      }

      const c = Math.ceil(days / step) + 1;
      resultSet = Array.from(Array(c)).map((_, i) => ({
        ...initialHistory,
        date: addDays(startDate, i * step),
      }));
    } else {
      let step = 1;
      if (range.days > 365) {
        step = 3;
      }

      let d = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
      resultSet.push({
        ...initialHistory,
        date: d,
      });

      while (d < endDate) {
        d = addMonths(d, step);
        resultSet.push({
          ...initialHistory,
          date: d,
        });
      }
    }

    resultSet.forEach((r, i) => {
      r.realization_num = realization_histories.filter(
        h =>
          h.kind === "roots" &&
          h.created_at.getTime() >= resultSet[0].date.getTime() &&
          (i === resultSet.length - 1 ||
            h.created_at.getTime() < r.date.getTime()),
      ).length;

      r.convert_num = realization_histories.filter(
        h =>
          h.kind === "will" &&
          h.created_at.getTime() >= resultSet[0].date.getTime() &&
          (i === resultSet.length - 1 ||
            h.created_at.getTime() < r.date.getTime()),
      ).length;

      r.action_num = realization_histories.filter(
        h =>
          h.kind === "will" &&
          h.executed_at &&
          h.executed_at.getTime() >= resultSet[0].date.getTime() &&
          (i === resultSet.length - 1 ||
            h.executed_at.getTime() < r.date.getTime()),
      ).length;
    });
    setState(s => ({ ...s, resultSet }));
  }, [realization_histories, range]);

  const dataSet = {
    x: state.resultSet.map((r, idx) =>
      idx % 3 === 0 && idx !== state.resultSet.length - 1
        ? range.days <= 365
          ? simpleDateStr({ date: r.date, year: false })
          : simpleDateStr({ date: r.date, day: false })
        : "",
    ),
    elements: [
      {
        name: "ROOTS",
        color: "#3e9eff",
        data: state.resultSet.map(r => r.realization_num),
      },
      {
        name: "WILL",
        color: "#195cfb",
        data: state.resultSet.map(r => r.convert_num),
      },
      {
        name: "EXPERIENCE",
        color: "#e7c06c",
        data: state.resultSet.map(r => r.action_num),
      },
    ],
  };
  return (
    <LineChart
      options={{
        ...options,
        width: state.width,
      }}
      dataSet={dataSet}
    />
  );
};

export default AnalyzeLineStudentChart;
