import React from "react";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";

import { IRealizationHistory } from "../../state";
import { addDate, simpleDateStr } from "../../libs/Util";
import LineChart from "../../molecules/LineChart";
import IconCounterList from "../../molecules/IconCounterList";

import styles from "./AnalyzeLineChart.module.scss";

export interface AnalyzeLineChartProps {
  realization_histories: IRealizationHistory[];
  entrance_year: number;
  width: number;
}

export interface IHistoriesPerSpan {
  date: Date;
  action_num: number;
  convert_num: number;
  realization_num: number;
}

const initialHistory = {
  action_num: 0,
  convert_num: 0,
  realization_num: 0,
};

const AnalyzeLineChart = (props: AnalyzeLineChartProps) => {
  const [state, setState] = React.useState({
    span: "month",
    resultSet: [] as IHistoriesPerSpan[],
  });

  const realizationInfo = React.useMemo(() => {
    if (state.resultSet.length > 0) {
      const last = state.resultSet[state.resultSet.length - 1];
      return {
        realization_num: last.realization_num,
        conversion_num: last.convert_num,
        action_num: last.action_num,
      };
    } else {
      return {
        realization_num: 0,
        conversion_num: 0,
        action_num: 0,
      };
    }
  }, [state.resultSet]);

  React.useEffect(() => {
    const today = new Date();
    const startDate = addDate(today, 0);
    let resultSet = [] as IHistoriesPerSpan[];
    if (state.span === "week") {
      resultSet = Array.from(Array(8)).map((_, i) => ({
        ...initialHistory,
        date: addDate(startDate, i - 7),
      }));
    } else if (state.span === "month") {
      resultSet = Array.from(Array(11)).map((_, i) => ({
        ...initialHistory,
        date: addDate(startDate, i * 3 - 30),
      }));
    } else if (state.span === "year") {
      const year = today.getFullYear();
      const months = [];
      for (let i = 0; i < 12; i++) {
        let month = today.getMonth() - i;
        if (month < 0) month = -(12 + month);
        months.push(month);
      }
      resultSet = months.map(month => {
        if (month < 0) {
          return { ...initialHistory, date: new Date(year - 1, -month, 1) };
        }
        return { ...initialHistory, date: new Date(year, month, 1) };
      });
      resultSet.reverse();
    } else {
      const graduedeYear = props.entrance_year + 2;
      const months = [3, 6, 9, 0];
      for (let year = props.entrance_year; year <= graduedeYear; year += 1) {
        months.forEach(month => {
          if (month === 0) {
            resultSet.push({
              ...initialHistory,
              date: new Date(year + 1, month, 1),
            });
          } else {
            resultSet.push({
              ...initialHistory,
              date: new Date(year, month, 1),
            });
          }
        });
      }
    }

    resultSet.forEach((r, i) => {
      r.realization_num = props.realization_histories.filter(
        h =>
          h.kind === "roots" &&
          h.created_at.getTime() >= resultSet[0].date.getTime() &&
          (i === resultSet.length - 1 ||
            h.created_at.getTime() < r.date.getTime()),
      ).length;

      r.convert_num = props.realization_histories.filter(
        h =>
          h.kind === "will" &&
          h.created_at.getTime() >= resultSet[0].date.getTime() &&
          (i === resultSet.length - 1 ||
            h.created_at.getTime() < r.date.getTime()),
      ).length;

      r.action_num = props.realization_histories.filter(
        h =>
          h.kind === "will" &&
          h.executed_at &&
          h.executed_at.getTime() >= resultSet[0].date.getTime() &&
          (i === resultSet.length - 1 ||
            h.executed_at.getTime() < r.date.getTime()),
      ).length;
    });
    setState(s => ({ ...s, resultSet }));
  }, [state.span, props.realization_histories, props.entrance_year]);

  const dataSet = {
    x: state.resultSet.map((r, idx) =>
      idx % 3 === 0 && idx !== state.resultSet.length - 1
        ? ["all", "year"].indexOf(state.span) === -1
          ? simpleDateStr({ date: r.date, year: false })
          : simpleDateStr({ date: r.date, day: false })
        : "",
    ),
    elements: [
      {
        name: "ROOTS",
        color: "#3e9eff",
        data: state.resultSet.map(r => r.realization_num),
      },
      {
        name: "WILL",
        color: "#195cfb",
        data: state.resultSet.map(r => r.convert_num),
      },
      {
        name: "EXPERIENCE",
        color: "#e7c06c",
        data: state.resultSet.map(r => r.action_num),
      },
    ],
  };
  return (
    <>
      <IonSegment
        className={styles.segment}
        mode="ios"
        scrollable
        value={state.span}
        onIonChange={e =>
          setState({ ...state, span: e.detail.value as string })
        }
      >
        <IonSegmentButton className={styles.segmentButton} value="all">
          <IonLabel className={state.span !== "all" ? styles.inactive : ""}>
            すべて
          </IonLabel>
        </IonSegmentButton>
        <IonSegmentButton className={styles.segmentButton} value="year">
          <IonLabel className={state.span !== "year" ? styles.inactive : ""}>
            過去365日
          </IonLabel>
        </IonSegmentButton>
        <IonSegmentButton className={styles.segmentButton} value="month">
          <IonLabel className={state.span !== "month" ? styles.inactive : ""}>
            過去30日
          </IonLabel>
        </IonSegmentButton>
        <IonSegmentButton className={styles.segmentButton} value="week">
          <IonLabel className={state.span !== "week" ? styles.inactive : ""}>
            過去7日
          </IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <LineChart
        options={{
          width: props.width - 32,
        }}
        dataSet={dataSet}
      />
      <IconCounterList {...realizationInfo} />
    </>
  );
};

export default AnalyzeLineChart;
