import React from "react";
import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { useHistory } from "react-router-dom";

import { newRealizationPath } from "../../store/student";

import newIcon from "./../../assets/icons/new.svg";
import styles from "./FabButton.module.scss";

const FabButton = () => {
  const history = useHistory();

  return (
    <IonFab className={styles.fab}>
      <IonFabButton
        className={styles.fabButton}
        onClick={() => history.push(newRealizationPath)}
        routerDirection="none"
      >
        <IonIcon className={styles.icon} src={newIcon} />
      </IonFabButton>
    </IonFab>
  );
};

export default FabButton;
