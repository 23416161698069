import React from "react";
import Slider, { Settings } from "react-slick";
import { IonIcon } from "@ionic/react";

import Portal from "../../../atoms/Portal";
import tutorialFirst from "../../../assets/images/Tutorial/tutorial01.jpg";
import tutorialSecond from "../../../assets/images/Tutorial/tutorial02.jpg";
import tutorialThird from "../../../assets/images/Tutorial/tutorial03.jpg";
import tutorialFourth from "../../../assets/images/Tutorial/tutorial04.jpg";
import tutorialFifth from "../../../assets/images/Tutorial/tutorial05.jpg";
import tutorialSixth from "../../../assets/images/Tutorial/tutorial06.jpg";

import styles from "./InstructionModal.module.scss";
import closeIcon from "./../../../assets/icons/close.svg";
import nextIcon from "./../../../assets/icons/next.svg";
import prevIcon from "./../../../assets/icons/prev.svg";

export interface InstructionModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const InstructionModal: React.FC<InstructionModalProps> = ({
  isOpen,
  closeModal,
}) => {
  const slider = React.useRef<Slider>(null);
  const backdrop: React.MutableRefObject<HTMLDivElement | null> =
    React.useRef(null);
  const listImage = [
    tutorialFirst,
    tutorialSecond,
    tutorialThird,
    tutorialFourth,
    tutorialFifth,
    tutorialSixth,
  ];

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipe: true,
  };

  React.useEffect(() => {
    if (backdrop.current) {
      backdrop.current.addEventListener("touchstart", e => e.stopPropagation());
      backdrop.current.addEventListener("mousedown", e => e.stopPropagation());
    }
  }, [isOpen]);

  return isOpen ? (
    <Portal>
      <div className={`${styles.modal}`}>
        <div className={`${styles.backdrop}`} ref={backdrop} />
        <div className={styles.slide}>
          <Slider ref={slider} {...settings}>
            {listImage.map((img: string, index: number) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.image}
                  style={{ backgroundImage: `url(${img})` }}
                />
                {index !== listImage.length - 1 && (
                  <button
                    className={`${styles.item__button_close} ${
                      index > 2 && styles.black_light
                    }`}
                    onClick={closeModal}
                  >
                    <IonIcon
                      icon={closeIcon}
                      slot="start"
                      className={styles.item__icon_close}
                    />
                  </button>
                )}
                <button
                  className={`${styles.item__button_prev} ${
                    index === 3 && styles.turquoise
                  } ${index === 4 && styles.dodger_blue}`}
                  onClick={() => slider.current?.slickPrev()}
                  disabled={index === 0}
                >
                  <IonIcon
                    icon={prevIcon}
                    slot="start"
                    className={styles.item__icon_control}
                  />
                </button>
                <button
                  className={`${styles.item__button_next} ${
                    index === 3 && styles.turquoise
                  } ${index === 4 && styles.dodger_blue}`}
                  onClick={() => slider.current?.slickNext()}
                  disabled={index === listImage.length - 1}
                >
                  <IonIcon
                    icon={nextIcon}
                    slot="start"
                    className={styles.item__icon_control}
                  />
                </button>
                {index === listImage.length - 1 && (
                  <button
                    className={styles.item__button_finish}
                    onClick={closeModal}
                  >
                    はじめる
                  </button>
                )}
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </Portal>
  ) : null;
};

export default InstructionModal;
