import React from "react";

const URL_REGEX = new RegExp(
  "(" +
    // http:// or https://
    "https?:\\/\\/" +
    "[" +
    // [A-Za-z0-9_]
    "\\w" +
    // reserved word
    "!#$&'\\(\\)*+,/:;=?@\\[\\]" +
    // unreserved word
    "\\-.~%" +
    "]" +
    // on or more
    "+" +
    ")",
  "g",
);

export function convertBrAndHref(text: string) {
  if (!text) {
    return <></>;
  }
  return (
    <div>
      {text.split(URL_REGEX).map((d, r) =>
        d && d.match(URL_REGEX) ? (
          <a
            style={{
              color: "#aaf",
              wordBreak: "break-all",
            }}
            target="_blank"
            rel="noopener noreferrer"
            href={d}
            key={r}
          >
            {d}
          </a>
        ) : (
          <React.Fragment key={r}>{d}</React.Fragment>
        ),
      )}
    </div>
  );
}

const ESCAPE_REGEX = /[.*+?^${}()|[\]\\]/g;

export function escapeRegExp(str: string) {
  return str.replace(ESCAPE_REGEX, "\\$&");
}

export function replaceSearchTextAndUrl(text: string, searchText: string) {
  if (!searchText) {
    return replaceUrl(text);
  }
  const escapeSearchText = escapeRegExp(searchText);
  return text
    .replace(new RegExp(escapeSearchText), `<b>${searchText}</b>`)
    .replace(URL_REGEX, url => {
      return `<a style="color:#aaf;word-break: break-all;" target="_blank" href="${url}">${url}</a>`;
    });
}

export function replaceUrl(text: string) {
  return text.replace(URL_REGEX, url => {
    return `<a style="color:#aaf;word-break: break-all;" target="_blank" href="${url}">${url}</a>`;
  });
}
