import { IonIcon } from "@ionic/react";
import React from "react";

import {
  ICategory,
  IClass,
  IOriginRealization,
  IRealization,
  IRealizationCreateParams,
  IRealizationImage,
  IStudentGearBox,
  ITargetSelectedData,
  ITeacher,
} from "../../../state";
import { ReactComponent as ImageIcon } from "../../../assets/icons/image.svg";
import convertIcon from "../../../assets/icons/convert.svg";
import {
  getUniqueSimpleArray,
  isImageFile,
  mergeArraysByUniqueKey,
  objectArrayToKeyArray,
} from "../../../libs/Util";
import {
  SELECT_REALIZATION_BOX_FAILURE_MESSAGE,
  SELECT_REALIZATION_CLASS_FAILURE_MESSAGE,
  SELECT_REALIZATION_TEACHER_FAILURE_MESSAGE,
} from "../../../store/student";
import RealizationTextArea, {
  CONTENT_MAX_LENGTH,
} from "../../../atoms/RealizationTextArea";
import RealizationThumbnailImage from "../../../atoms/RealizationThumbnailImage";
import WordCounter from "../../../atoms/WordCounter";
import CategoryForm from "../../../molecules/CategoryForm";
import ExperienceSelectForm from "../../../molecules/ExperienceSelectForm";
import ImageDetailModal from "../../../molecules/ImageDetailModal";
import ModalConfirm from "../../../molecules/ModalConfirm";
import OriginRealization from "../../../molecules/OriginRealization";
import Toast, { TToastType } from "../../../molecules/Toast";
import TargetGroup, { ETargetSwitch } from "../../TargetGroup";
import TemplateModal from "../../TemplateModal";

import styles from "./RealizationConversionForm.module.scss";

export interface RealizationConversionFormProps {
  realization: IRealization;
  categories: ICategory[];
  teachers: ITeacher[];
  classes: IClass[];
  isOpen: boolean;
  calling?: boolean;
  pathname: string;
  success_message: string;
  gearboxes: IStudentGearBox[];
  close: () => void;
  createCategory: (category: ICategory) => void;
  createWillRealization(
    realization: IRealizationCreateParams,
    submit_teacher_ids: number[],
    share_class_ids: number[],
    gearbox_id: number | null,
    image?: IRealizationImage,
  ): void;
}

const RealizationConversionForm = (props: RealizationConversionFormProps) => {
  const { realization } = props;
  const submitted_teacher_ids = objectArrayToKeyArray(
    realization.submitted_teachers,
  );
  const sharedClassIDs = objectArrayToKeyArray(realization.shared_classes);
  const categoryIDs =
    realization.categories?.map(category => category.id) ?? [];

  const realizationImgInputRef = React.useRef<HTMLInputElement>(null);
  const [state, setState] = React.useState<
    {
      category_ids: number[];
      experience_nos?: number[];
      selected: boolean;
      shared: boolean;
      content: string;
      toast: {
        type: TToastType;
        message: string;
      } | null;
      image?: IRealizationImage;
      showCategoryForm: boolean;
      showNewCategoryForm: boolean;
      showSubmitForm: boolean;
      showShareForm: boolean;
      showBoxForm: boolean;
      showImageDetail: boolean;
      showImageInvalid: boolean;
      showImageExceedsSize: boolean;
    } & ITargetSelectedData
  >(() => {
    let targetSelectedData: ITargetSelectedData = {
      selected_box_id: null,
      selected_teacher_ids: [],
      selected_class_ids: [],
      targetSwitch: ETargetSwitch.ONLY_ME,
    };
    if (realization.gearbox?.id) {
      targetSelectedData = {
        ...targetSelectedData,
        selected_box_id: realization.gearbox.id,
        targetSwitch: ETargetSwitch.BOX,
      };
    } else if (realization.shared_classes?.length) {
      targetSelectedData = {
        ...targetSelectedData,
        selected_class_ids: sharedClassIDs,
        targetSwitch: ETargetSwitch.GROUP,
      };
    } else if (realization.submitted_teachers?.length) {
      targetSelectedData = {
        ...targetSelectedData,
        selected_teacher_ids: submitted_teacher_ids,
        targetSwitch: ETargetSwitch.TEACHER,
      };
    }
    return {
      category_ids: categoryIDs,
      experience_nos: realization.experience_nos ?? [],
      selected: false,
      shared: realization.shared,
      content: "",
      toast: null,
      image: undefined,
      showCategoryForm: false,
      showNewCategoryForm: false,
      showSubmitForm: false,
      showShareForm: false,
      showBoxForm: false,
      showImageInvalid: false,
      showImageExceedsSize: false,
      showImageDetail: false,
      ...targetSelectedData,
    };
  });
  const update = (args: { [key: string]: any }) => {
    setState(prevState => ({ ...prevState, ...args }));
  };

  const classes = realization.shared_classes?.length
    ? (mergeArraysByUniqueKey({
        arrays: [props.classes, realization.shared_classes],
      }) as IClass[])
    : props.classes;

  const filtedGearboxes = React.useMemo(() => {
    const gearboxes =
      props.realization.gearbox &&
      !props.gearboxes.some(box => box.id === props.realization.gearbox?.id)
        ? [...props.gearboxes, props.realization.gearbox]
        : props.gearboxes;
    return gearboxes.filter(
      gearbox =>
        !gearbox.is_archived || gearbox.id === props.realization.gearbox?.id,
    ) as IStudentGearBox[];
  }, [props.gearboxes, props.realization.gearbox]);

  const formatRealization = React.useMemo(() => {
    const { id, content, created_at, kind, executed_at } = realization;
    return {
      id,
      content,
      created_at,
      kind,
      executed_at,
    } as IOriginRealization;
  }, [realization]);

  React.useEffect(() => {
    if (realization) {
      const { experience_nos, shared } = realization;
      update({
        categoryIDs,
        experience_nos,
        shared,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realization]);

  const onSubmit = () => {
    if (state.targetSwitch === ETargetSwitch.BOX && !state.selected_box_id) {
      update({
        toast: {
          type: "danger",
          message: SELECT_REALIZATION_BOX_FAILURE_MESSAGE,
        },
      });
      return;
    }
    if (
      state.targetSwitch === ETargetSwitch.GROUP &&
      !state.selected_class_ids.length
    ) {
      update({
        toast: {
          type: "danger",
          message: SELECT_REALIZATION_CLASS_FAILURE_MESSAGE,
        },
      });
      return;
    }
    if (
      state.targetSwitch === ETargetSwitch.TEACHER &&
      !state.selected_teacher_ids.length
    ) {
      update({
        toast: {
          type: "danger",
          message: SELECT_REALIZATION_TEACHER_FAILURE_MESSAGE,
        },
      });
      return;
    }

    const selectedBox = state.selected_box_id
      ? props.gearboxes.find(box => box.id === state.selected_box_id)
      : undefined;

    props.createWillRealization(
      {
        category_ids: state.category_ids,
        experience_nos: state.experience_nos,
        content: state.content,
        kind: "will",
        submitted: state.selected_teacher_ids.length > 0,
        shared: state.selected_class_ids.length > 0,
        origin_realization_id: realization.id,
      },
      !!selectedBox
        ? selectedBox.id === realization.gearbox?.id
          ? (getUniqueSimpleArray([
              ...submitted_teacher_ids,
              selectedBox.teacher.id,
            ]) as number[])
          : [selectedBox.teacher.id]
        : state.selected_teacher_ids,
      !!selectedBox
        ? selectedBox.id === realization.gearbox?.id
          ? (getUniqueSimpleArray([
              ...sharedClassIDs,
              ...realization.gearbox.klasses.map(cls => cls.id),
            ]) as number[])
          : selectedBox.klasses.map(cls => cls.id)
        : state.selected_class_ids,
      state.selected_box_id ?? null,
      state.image,
    );
    props.close();
  };

  const removeImg = React.useCallback(() => {
    realizationImgInputRef.current &&
      (realizationImgInputRef.current.value = "");
    update({ image: undefined });
  }, []);

  const uploadImg = React.useCallback(
    e => {
      e.persist();
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      if (!isImageFile(file)) {
        removeImg();
        update({ showImageInvalid: true });
        return;
      }
      const fileSizeByMB = file.size / 1024 / 1024;
      if (fileSizeByMB > 5) {
        removeImg();
        update({ showImageExceedsSize: true });
        return;
      }
      update({
        image: {
          name: file.name,
          url: URL.createObjectURL(file),
          blob: file,
        },
      });
    },
    [removeImg],
  );

  return (
    <TemplateModal
      isOpen={props.isOpen}
      onClose={props.close}
      headerTitle="WILL化"
    >
      <div className={styles.wrapper}>
        <ModalConfirm
          isOpen={state.showImageInvalid}
          message={`無効なファイル形式です。\njpg/jpeg/png のみアップロード可能です。`}
          buttons={[
            {
              title: "キャンセル",
              type: "None",
              action: () => update({ showImageInvalid: false }),
            },
            {
              title: "OK",
              type: "Success",
              action: () => update({ showImageInvalid: false }),
            },
          ]}
        />
        <ModalConfirm
          isOpen={state.showImageExceedsSize}
          message={`アップロードに失敗しました。\nファイルサイズを5MB以下にしてください。`}
          buttons={[
            {
              title: "OK",
              type: "None",
              action: () => update({ showImageExceedsSize: false }),
            },
          ]}
        />
        {state.image && (
          <ImageDetailModal
            show={state.showImageDetail}
            imageUrl={state.image.url}
            imageName={state.image.name}
            onClose={() => update({ showImageDetail: false })}
          />
        )}
        <Toast
          type={state.toast?.type}
          showToast={!!state.toast}
          onClose={() => update({ toast: null })}
          message={state.toast?.message ?? ""}
        />
        <div className={styles.container}>
          <div className={styles.inputArea}>
            <div className={styles.textAreaWrapper}>
              <RealizationTextArea
                content={state.content}
                onChange={value => setState({ ...state, content: value })}
              />
            </div>
            <div className={styles.imgInputWrapper}>
              {state.image?.url ? (
                <div className={styles.realizationImg}>
                  <RealizationThumbnailImage
                    alt="Upload Image"
                    src={state.image.url}
                    onClick={e => {
                      e.stopPropagation();
                      update({
                        showImageDetail: true,
                      });
                    }}
                    isEdit={true}
                  />
                  <button onClick={removeImg}>✕</button>
                </div>
              ) : (
                <>
                  <input
                    ref={realizationImgInputRef}
                    accept=".png,.jpg,.jpeg"
                    type="file"
                    onChange={uploadImg}
                  />
                  <ImageIcon
                    onClick={() => realizationImgInputRef.current?.click()}
                  />
                </>
              )}
            </div>
          </div>
          <div className={styles.counter}>
            <WordCounter
              maxLength={CONTENT_MAX_LENGTH}
              targetLength={state.content.length}
            />
          </div>
          <div className={styles.originWrapper}>
            <OriginRealization realization={formatRealization} />
          </div>
          <CategoryForm
            pathname={props.pathname}
            success_message={props.success_message}
            calling={props.calling}
            isOpen={state.showCategoryForm}
            categories={props.categories}
            selectedCategoryIDs={state.category_ids}
            update={update}
            createCategory={props.createCategory}
          />
          <TargetGroup
            realization={realization}
            gearboxes={filtedGearboxes}
            teachers={props.teachers}
            classes={classes}
            showSubmitForm={state.showSubmitForm}
            showShareForm={state.showShareForm}
            showBoxForm={state.showBoxForm}
            targetSwitch={state.targetSwitch}
            selected_box_id={state.selected_box_id}
            selected_teacher_ids={state.selected_teacher_ids}
            selected_class_ids={state.selected_class_ids}
            update={update}
          />
          <ExperienceSelectForm
            checkedExperienceNos={state.experience_nos}
            update={update}
          />
        </div>
        <div className={styles.wrapperButton}>
          <button
            className={styles.convertButton}
            disabled={state.content === "" || props.calling}
            onClick={onSubmit}
          >
            <IonIcon icon={convertIcon} slot="start" className={styles.icon} />
            <span>
              WILLを
              <br />
              ストック
            </span>
          </button>
        </div>
      </div>
    </TemplateModal>
  );
};

export default RealizationConversionForm;
