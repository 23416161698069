import { IonIcon, IonItem, IonLabel, IonText } from "@ionic/react";
import React from "react";
import {
  chevronDownOutline as downIcon,
  chevronUpOutline as upIcon,
  checkmark,
} from "ionicons/icons";
import clsx from "clsx";

import { IStudentGearBox } from "../../state";
import ModalConfirm from "../../molecules/ModalConfirm";
import BoxTitle from "../../atoms/BoxTitle";

import styles from "./BoxSelectForm.module.scss";

export interface BoxSelectFormProps {
  isOpen: boolean;
  gearBoxes?: IStudentGearBox[];
  selected_box_id: number | null;
  update: (args: { [key: string]: any }) => void;
  setBoxTemplate?: (text: string) => void;
  isSecond?: boolean;
  stock_c_to_c_enabled?: boolean;
}

const BoxSelectForm = (props: BoxSelectFormProps) => {
  const [showConfirmTemplateBoxModal, setShowConfirmTemplateBoxModal] =
    React.useState<boolean>(false);
  const [selectedBoxId, setSelectedBoxId] = React.useState<number | null>(null);
  const gearboxes = (props.gearBoxes ?? []).sort((a, b) => {
    if (
      a.my_realizations_created_at === null &&
      b.my_realizations_created_at === null
    )
      return b.id - a.id;
    if (!a.my_realizations_created_at) return 1;
    if (!b.my_realizations_created_at) return -1;
    return a.my_realizations_created_at > b.my_realizations_created_at ? -1 : 1;
  });
  const selectedBox = gearboxes.find(box => box.id === selectedBoxId);

  React.useEffect(() => {
    if (!props.selected_box_id) {
      setSelectedBoxId(null);
    } else {
      setSelectedBoxId(props.selected_box_id);
    }
  }, [gearboxes, props.selected_box_id]);

  const applyTemplate = React.useCallback(() => {
    if (!props.setBoxTemplate) return;
    props.setBoxTemplate(selectedBox?.template?.content || "");
    props.update({
      selected_box_id: selectedBoxId,
    });
  }, [props, selectedBoxId, selectedBox]);

  return (
    <>
      <ModalConfirm
        isOpen={showConfirmTemplateBoxModal}
        message={`こちらのBOXを選択すると、入力欄に先生からの質問が挿入され、現在入力中の内容は失われます。BOXを切り替えますか？`}
        buttons={[
          {
            title: "キャンセル",
            type: "None",
            action: () => setShowConfirmTemplateBoxModal(false),
          },
          {
            title: "OK",
            type: "Success",
            action: () => {
              applyTemplate();
              setShowConfirmTemplateBoxModal(false);
            },
          },
        ]}
      />
      {props.stock_c_to_c_enabled && (
        <IonText color="light" className={styles.title}>
          ※他校の生徒にとって参考になるストックは、匿名で他校に共有されることがあります
        </IonText>
      )}
      <IonItem
        className={clsx(styles.boxItemToggle, props.isSecond && styles.second)}
        onClick={() => {
          props.update({
            showSubmitForm: false,
            showCategoryForm: false,
            showShareForm: false,
            showBoxForm: !props.isOpen,
          });
        }}
      >
        <IonLabel color="light" className={styles.title}>
          提出先
        </IonLabel>
        <div className={styles.selectedNames}>
          {selectedBox ? selectedBox.title : "未選択"}
        </div>
        {props.isOpen ? (
          <IonIcon icon={upIcon} slot="end" className={styles.arrowIcon} />
        ) : (
          <IonIcon icon={downIcon} slot="end" className={styles.arrowIcon} />
        )}
      </IonItem>
      <div className={props.isOpen ? styles.boxArea : styles.close}>
        <ul className={styles.boxSelector}>
          {gearboxes.map(box => (
            <li
              key={box.id}
              className={styles.boxItem}
              onClick={() => {
                if (props.selected_box_id === box.id) {
                  props.update({
                    selected_box_id: null,
                  });
                  return;
                }
                if (box.template) {
                  setShowConfirmTemplateBoxModal(true);
                  setSelectedBoxId(box.id);
                  return;
                }

                props.update({
                  selected_box_id: box.id,
                });
              }}
            >
              <div className={styles.boxName}>
                <BoxTitle
                  isArchived={box.is_archived}
                  nameStatus={box.name_status}
                  title={box.title}
                  wrap={true}
                />
              </div>
              <div className={styles.teacherClassWrapper}>
                {!!box.klasses.length &&
                  box.klasses.map(cls => (
                    <span key={cls.id} className={styles.class}>
                      {cls.full_name}
                    </span>
                  ))}
                <span className={styles.teacher}>{box.teacher.full_name}</span>
              </div>
              {props.selected_box_id === box.id && (
                <IonIcon
                  slot="end"
                  icon={checkmark}
                  className={styles.checkIcon}
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default BoxSelectForm;
