import { IonIcon } from "@ionic/react";
import React from "react";

import styles from "./IconCounter.module.scss";

export interface IconCounterProps {
  icon: string;
  title: string;
  className: string;
  counter: number;
  withBorder: boolean;
}

const IconCounter = (props: IconCounterProps) => {
  return (
    <div
      className={
        styles.iconCounter + " " + (props.withBorder ? styles.border : "")
      }
    >
      <div className={styles.title}>{props.title}</div>
      <IonIcon
        className={styles.icon + " " + props.className}
        icon={props.icon}
      />
      <div className={styles.counter}>{props.counter}</div>
    </div>
  );
};

export default IconCounter;
