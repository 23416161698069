import React from "react";
import { IonCol, IonGrid, IonIcon, IonItem, IonRow } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";

import ImageDetailModal from "../ImageDetailModal";
import { IRealization } from "../../state";
import RealizationContent from "../RealizationContent";

import styles from "./SelfRealizationItem.module.scss";

export type SelfRealizationItemProps = {
  realization: IRealization;
  searchText: string;
  onAction: () => void;
};

const SelfRealizationItem = (props: SelfRealizationItemProps) => {
  const [state, setState] = React.useState({
    showImageDetail: false,
  });

  return (
    <>
      {props.realization.image && (
        <ImageDetailModal
          show={state.showImageDetail}
          imageUrl={props.realization.image.url}
          imageName={props.realization.image.name}
          onClose={() => setState(s => ({ ...s, showImageDetail: false }))}
        />
      )}
      <IonItem lines="full" className={styles.item} onClick={props.onAction}>
        <IonGrid className={styles.grid}>
          <IonRow>
            <IonCol size="12">
              <div className={styles.wrapperContent}>
                <RealizationContent
                  realization={props.realization}
                  searchText={props.searchText}
                  isSelf
                  onClickThumbnailImage={() => {
                    setState(s => ({ ...s, showImageDetail: true }));
                  }}
                />
                <IonIcon
                  icon={chevronForwardOutline}
                  color="medium"
                  className={styles.iconCaretRight}
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    </>
  );
};

export default SelfRealizationItem;
