import {
  IonAvatar,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
} from "@ionic/react";
import { personCircle as personIcon } from "ionicons/icons";
import React from "react";

import { IFile } from "../../state";

import styles from "./StudentProfileForm.module.scss";

export interface StudentProfileFormProps {
  error: string;
  identity: string;
  school_name: string;
  class_name: string;
  first_name: string;
  last_name: string;
  first_name_kana: string;
  last_name_kana: string;
  photo: IFile | null;
  calling: boolean;
  updatePhoto: (photo: IFile) => void;
  cancel: () => void;
}

export const StudentProfileForm = (props: StudentProfileFormProps) => {
  const [state, setState] = React.useState({
    photo: props.photo,
    isSubmitted: false,
    error: props.error,
  });
  const photoInput = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    setState(s => ({ ...s, error: props.error }));
  }, [props.error]);

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (state.photo?.blob || (state.photo?.id && state.photo?.deleted)) {
        props.updatePhoto(state.photo);
      } else {
        props.cancel();
      }
    },
    [state, props],
  );

  return (
    <div className={styles.wrapper}>
      <IonList className={styles.profileList}>
        <IonItem>
          <input
            ref={photoInput}
            name="photo"
            type="file"
            style={{ display: "none" }}
            accept=".png,.jpg,.jpeg"
            onChange={(e: any) => {
              const file = e.currentTarget.files[0];
              if (!file) {
                return;
              }
              if (!file.type.match(/^image\//)) {
                alert("画像以外のファイルはアップロードできません");
                return;
              }
              if (
                file.type.toLowerCase() !== "image/jpg" &&
                file.type.toLowerCase() !== "image/jpeg" &&
                file.type.toLowerCase() !== "image/png"
              ) {
                alert("jpg/png以外の画像はアップロードできません");
                return;
              }
              setState({
                ...state,
                photo: {
                  ...(props.photo as IFile),
                  name: file.name,
                  url: URL.createObjectURL(file),
                  blob: file,
                },
              });
            }}
          />
          <IonLabel>
            {state.photo?.url ? (
              <IonAvatar>
                <img alt="プロフィール画像" src={state.photo.url} />
              </IonAvatar>
            ) : (
              <IonIcon
                icon={personIcon}
                slot="start"
                size="large"
                color="light"
              />
            )}
          </IonLabel>
          <IonButton
            fill="outline"
            color="secondary"
            disabled={props.calling}
            onClick={() => {
              photoInput.current && photoInput.current.click();
            }}
          >
            変更する
          </IonButton>
          {state.photo && state.photo.url && (
            <IonButton
              fill="outline"
              color="warning"
              disabled={props.calling}
              onClick={() => {
                (photoInput.current as HTMLInputElement).value = "";
                setState({
                  ...state,
                  photo: {
                    ...(state.photo as IFile),
                    url: "",
                    blob: undefined,
                    deleted: true,
                  },
                });
              }}
            >
              削除する
            </IonButton>
          )}
        </IonItem>
        <IonItem>
          <IonLabel color="light">ID</IonLabel>
          <IonNote color="light" slot="end">
            <h2>{props.identity}</h2>
          </IonNote>
        </IonItem>
        <IonItem>
          <IonLabel color="light">学校名</IonLabel>
          <IonNote color="light">
            <h2>{props.school_name}</h2>
          </IonNote>
        </IonItem>
        <IonItem>
          <IonLabel color="light">クラス</IonLabel>
          <IonNote color="light">
            <h2>{props.class_name}</h2>
          </IonNote>
        </IonItem>
        <IonItem>
          <IonLabel color="light">名前</IonLabel>
          <IonNote color="light">
            <h2>{props.last_name + " " + props.first_name}</h2>
          </IonNote>
        </IonItem>
      </IonList>
      <IonButton
        className={styles.postButton}
        strong
        expand="block"
        fill="outline"
        disabled={props.calling}
        onClick={onSubmit}
      >
        更新
      </IonButton>
      <IonButton
        className={styles.cancelButton}
        strong
        expand="block"
        fill="outline"
        disabled={props.calling}
        onClick={() => props.cancel()}
      >
        キャンセル
      </IonButton>
    </div>
  );
};

export default StudentProfileForm;
