import React from "react";

import ModalConfirm from "../../../molecules/ModalConfirm";

export interface RealizationDeleteFormProps {
  isOpen: boolean;
  realization_id: number;
  calling?: boolean;
  update: (args: { [key: string]: any }) => void;
  delete(realization_id: number): void;
}

const RealizationDeleteForm = (props: RealizationDeleteFormProps) => {
  return (
    <ModalConfirm
      isOpen={props.isOpen}
      loading={props.calling}
      message={`ストックを削除しますか？\nこの操作は取り消しができません。`}
      buttons={[
        {
          title: "キャンセル",
          action: () => props.update({ showRealizationDeleteForm: false }),
          type: "None",
        },
        {
          title: "削除する",
          action: () => {
            props.delete(props.realization_id);
            props.update({
              showRealizationDeleteForm: false,
            });
          },
          type: "Danger",
        },
      ]}
    />
  );
};

export default RealizationDeleteForm;
