export const cookieNames = {
  teacher_session_id: "session_id",
  student_session_id: "student_session_id",
};

const DOMAIN_NAME =
  process.env.NODE_ENV === "production" ? "enageed.net" : "localhost";
function set(cookieName: string, cookieValue: string, days: number) {
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = `${cookieName}=${cookieValue};${expires};domain=${DOMAIN_NAME};path=/`;
}

function getCookie(c_name: string) {
  let c_start, c_end;
  if (document.cookie.length > 0) {
    c_start = document.cookie.indexOf(c_name + "=");
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1;
      c_end = document.cookie.indexOf(";", c_start);
      if (c_end === -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
}

function deleteAll() {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie =
      name +
      `=;domain=${DOMAIN_NAME};path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}

const exportFunction = {
  set,
  getCookie,
  deleteAll,
};
export default exportFunction;
