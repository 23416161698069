import { IonContent, IonPage, useIonViewWillEnter } from "@ionic/react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Context, fetchGearBoxes } from "../../../store/student";
import styles from "../common.module.scss";
import { IStudentGearBox } from "../../../state";
import FabButton from "../../../atoms/FabButton";
import FiltableHeader from "../../../organisms/FiltableHeader";
import BoxesList from "../../../templates/BoxesList";

const BoxesListPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [currentState, setCurrentState] = React.useState({
    searching: false,
    searchText: "",
  });
  const [isFirstLoad, setIsFirstLoad] = React.useState<boolean>(true);
  const location = useLocation();
  const history = useHistory();

  useIonViewWillEnter(() => {
    setIsFirstLoad(false);
    dispatch(fetchGearBoxes());
  });

  React.useEffect(() => {
    !isFirstLoad &&
      dispatch(fetchGearBoxes({ title: currentState.searchText }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState.searchText, dispatch]);

  const toBoxDetail = (box: IStudentGearBox) =>
    history.push({
      pathname: `${location.pathname}/${box.id}`,
      state: {
        title: box.title,
        is_archived: box.is_archived,
        name_status: box.name_status,
      },
    });

  return (
    <IonPage>
      <FiltableHeader
        title="BOXリスト"
        unreadNotiCount={
          contextState.unread_comments_count +
          contextState.unread_notification_count +
          (contextState.student.read_gear_release_note ? 0 : 1)
        }
        searching={currentState.searching}
        searchText={currentState.searchText}
        setState={setCurrentState}
        goBack
      />
      <IonContent className={styles.wrapper}>
        <div className={styles.container}>
          <BoxesList
            search={currentState}
            gearBoxes={contextState.gearBoxes}
            pathname={location.pathname}
            toBoxDetail={toBoxDetail}
          />
        </div>
      </IonContent>
      <FabButton />
    </IonPage>
  );
};

export default BoxesListPage;
