import React from "react";
import { IonTextarea } from "@ionic/react";

import styles from "./RealizationTextArea.module.scss";

export interface RealizationTextAreaProps {
  content: string;
  onChange: (value: string) => void;
}

export const CONTENT_MAX_LENGTH = 500;

const RealizationTextArea: React.FC<RealizationTextAreaProps> = ({
  content,
  onChange,
}) => {
  return (
    <IonTextarea
      maxlength={CONTENT_MAX_LENGTH}
      className={styles.textArea}
      autoGrow
      placeholder="どんな気づき（ROOTS）がありましたか？または、何をしよう（WILL）と思いましたか？"
      value={content}
      rows={3}
      onIonChange={e => onChange(e.detail.value!)}
    />
  );
};

export default RealizationTextArea;
