import React from "react";

import BarChartBar from "../../atoms/BarChartBar";

interface BarChartOption {
  yAxisNum?: number;
  width?: number;
  height?: number;
  yAxisTextColor?: string;
  yAxisColor?: string;
  yAxisFontSize?: number;
  xAxisFontSize?: number;
  xAxisTextColor?: string;
  yAxisWidth?: number;
  yAxisMarginTop?: number;
  yAxisMarginBottom?: number;
  backgroundColor?: string;
  xGap?: number;
}

const initialData: Required<BarChartOption> = {
  yAxisNum: 6,
  width: 300,
  height: 240,
  yAxisTextColor: "#ccc",
  yAxisColor: "#888",
  yAxisFontSize: 10,
  xAxisFontSize: 10,
  xAxisTextColor: "#888",
  yAxisWidth: 20,
  yAxisMarginTop: 20,
  yAxisMarginBottom: 20,
  backgroundColor: "#131b24",
  xGap: 30,
};
export interface BarChartProps {
  dataSet: { name: string; value: number; color: string }[];
  options: BarChartOption;
}

function calcMax(data: number[], yAxisNum: number) {
  const max = Math.max.apply(null, data);
  const mod = max % yAxisNum;
  if (mod === 0) {
    return max;
  }
  return max + (yAxisNum - mod);
}

const BarChart = (props: BarChartProps) => {
  if (props.dataSet.length === 0) {
    return null;
  }
  const options = { ...initialData, ...props.options };
  const max = calcMax(
    props.dataSet.map(d => d.value),
    options.yAxisNum,
  );
  const maxDataHeight =
    options.height - options.yAxisMarginTop - options.yAxisMarginBottom;
  const yHeight = maxDataHeight / max;
  const xWidth = (options.width - options.yAxisWidth) / props.dataSet.length;
  const yGapValue = maxDataHeight / options.yAxisNum;
  return (
    <div>
      <svg
        version="1.1"
        width={options.width}
        height={options.height}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x={0}
          y={0}
          width={options.width}
          height={options.height}
          fill={options.backgroundColor}
        />
        {[...Array(options.yAxisNum + 1)].map((d, idx) => (
          <React.Fragment key={idx}>
            <line
              stroke={options.yAxisColor}
              strokeWidth={0.6}
              x1={0}
              x2={options.width}
              y1={options.height - idx * yGapValue - options.yAxisMarginBottom}
              y2={options.height - idx * yGapValue - options.yAxisMarginBottom}
            />
            <text
              fill={options.yAxisTextColor}
              x={10}
              y={
                options.height - idx * yGapValue - 6 - options.yAxisMarginBottom
              }
              fontSize={options.yAxisFontSize}
            >
              {Math.round(max / options.yAxisNum) * idx}
            </text>
          </React.Fragment>
        ))}
        {props.dataSet.map((d, idx) => (
          <BarChartBar
            key={idx}
            x={options.yAxisWidth + idx * xWidth}
            y={options.height - d.value * yHeight - options.yAxisMarginBottom}
            textPosition={options.height - 4}
            width={xWidth}
            gap={options.xGap}
            height={d.value * yHeight}
            color={d.color}
            textColor={options.xAxisTextColor}
            fontSize={options.xAxisFontSize}
            title={d.name}
          />
        ))}
      </svg>
    </div>
  );
};

export default BarChart;
