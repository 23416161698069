import { IonContent, IonPage, useIonViewWillEnter } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router-dom";

import useThemeTutorial from "../../../hooks/useThemeTutorial";
import {
  categorizedRealizationPath,
  Context,
  fetchCategoriesWithCount,
  fetchGearBoxes,
} from "../../../store/student";
import FabButton from "../../../atoms/FabButton";
import ThemeHeader from "../../../molecules/ThemeHeader";
import LoginHeader from "../../../organisms/LoginHeader";
import RealizationList from "../../../templates/RealizationList";
import styles from "../common.module.scss";

const RealizationListPage = () => {
  const history = useHistory();
  const { dispatch, contextState } = React.useContext(Context);

  const [themeTutorial, updateThemeTutorial] = useThemeTutorial(
    contextState.geartheme,
    contextState.student.read_gear_tutorials,
    dispatch,
  );

  useIonViewWillEnter(() => {
    dispatch(fetchCategoriesWithCount());
    dispatch(fetchGearBoxes());
  });

  return (
    <IonPage>
      <LoginHeader
        title="マイストック"
        unreadNotiCount={
          contextState.unread_comments_count +
          contextState.unread_notification_count +
          (contextState.student.read_gear_release_note ? 0 : 1)
        }
        onSearching={() => {
          history.push({
            pathname: `${categorizedRealizationPath}/0`,
            search: "searching",
          });
        }}
      />
      <IonContent className={styles.wrapper}>
        {contextState.categories.length > 0 && (
          <div className={styles.container}>
            {contextState.geartheme && (
              <ThemeHeader
                geartheme={contextState.geartheme}
                updateThemeTutorial={updateThemeTutorial}
                disableCreate={!themeTutorial.completed}
              />
            )}
            <RealizationList
              categories={contextState.categories}
              gearBoxes={contextState.gearBoxes}
            />
          </div>
        )}
        <FabButton />
      </IonContent>
    </IonPage>
  );
};

export default RealizationListPage;
