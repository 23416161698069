import { IonIcon, IonItem, IonLabel, IonText } from "@ionic/react";
import React from "react";
import {
  chevronDownOutline as downIcon,
  chevronUpOutline as upIcon,
  checkmark,
} from "ionicons/icons";
import clsx from "clsx";

import peopleIcon from "../../assets/icons/people.svg";
import { IClass } from "../../state";

import styles from "./ClassSelectForm.module.scss";

export interface ClassSelectFormProps {
  isOpen: boolean;
  label?: string;
  classes: IClass[];
  multiple?: boolean;
  selected_class_ids: number[];
  className?: string;
  onClick: () => void;
  update: (args: { [key: string]: any }) => void;
  disabled?: boolean;
  isSecond?: boolean;
  stock_c_to_c_enabled?: boolean;
}

const ClassSelectForm = (props: ClassSelectFormProps) => {
  const displayNames =
    props.selected_class_ids.length === 0
      ? "未選択"
      : props.selected_class_ids.length >= 2
      ? `${
          props.classes.filter(c => {
            return c.id === props.selected_class_ids[0];
          })[0]?.full_name
        } ...他${props.selected_class_ids.length - 1}`
      : props.classes.filter(c => {
          return c.id === props.selected_class_ids[0];
        })[0]?.full_name;

  return (
    <>
      <IonText color="light" className={styles.title}>
        {props.stock_c_to_c_enabled &&
          "※他校の生徒にとって参考になるストックは、匿名で他校に共有されることがあります"}
      </IonText>
      <IonItem
        className={clsx(
          styles.classItemToggle,
          props.disabled && styles.disabled,
          props.isSecond && styles.second,
          props.className || "",
        )}
        onClick={props.onClick}
      >
        {props.label && (
          <IonIcon
            icon={peopleIcon}
            color="light"
            slot="start"
            className={styles.airplaneIcon}
          />
        )}
        <IonLabel color="light" className={styles.title}>
          {props.label ?? "共有先"}
        </IonLabel>
        <div className={styles.selectedNames}>{displayNames}</div>

        {props.isOpen ? (
          <IonIcon icon={upIcon} slot="end" className={styles.arrowIcon} />
        ) : (
          <IonIcon icon={downIcon} slot="end" className={styles.arrowIcon} />
        )}
      </IonItem>
      <div className={props.isOpen ? styles.classArea : styles.close}>
        <ul className={styles.classSelector}>
          {props.classes.map(cls => (
            <li
              key={cls.id}
              className={styles.classItem}
              onClick={() => {
                if (props.selected_class_ids.includes(cls.id)) {
                  if (props.multiple) {
                    const filteredClassIDs = props.selected_class_ids.filter(
                      id => id !== cls.id,
                    );
                    props.update({
                      selected_class_ids: filteredClassIDs,
                    });
                  } else {
                    props.update({
                      selectedClassID: 0,
                    });
                  }
                } else {
                  if (props.multiple) {
                    props.update({
                      selected_class_ids: [...props.selected_class_ids, cls.id],
                    });
                  } else {
                    props.update({
                      selectedClassID: cls.id,
                    });
                  }
                }
              }}
            >
              <div className={styles.clsName}>
                {cls.full_name}
                {props.selected_class_ids.includes(cls.id) && (
                  <IonIcon
                    slot="end"
                    icon={checkmark}
                    className={styles.checkIcon}
                  />
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default ClassSelectForm;
