import React from "react";
import clsx from "clsx";
import { IonIcon } from "@ionic/react";
import { caretUp, caretDown } from "ionicons/icons";

import { ReactComponent as ArrowIcon } from "../../assets/icons/dropdown_secondary_arrow.svg";

import styles from "./Dropdown.module.scss";

type TValue = number | string | null;

interface IItem {
  label: string;
  value: TValue;
}

interface ISelectParams extends IItem {
  name: string;
}

export interface DropdownProps {
  variant?: "primary" | "secondary";
  className?: string;
  classNameLabelArea?: string;
  name: string;
  data: IItem[];
  defaultLabel?: string;
  defaultValue?: TValue;
  selectedValue?: TValue;
  onSelect: ({ value, label, name }: ISelectParams) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  variant = "primary",
  className = "",
  classNameLabelArea = "",
  name,
  data,
  defaultLabel = "選んでください",
  defaultValue = null,
  selectedValue,
  onSelect,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [state, setState] = React.useState<{
    isOpen: boolean;
    selectedLabel: string;
    selectedValue: TValue;
  }>({
    isOpen: false,
    selectedLabel: defaultLabel,
    selectedValue: defaultValue,
  });

  const handleClickOutside = React.useCallback(
    (e: { target: EventTarget | null }) => {
      if (
        state.isOpen &&
        ref.current &&
        !ref.current.contains(e.target as Node)
      ) {
        setState(s => ({ ...s, isOpen: false }));
      }
    },
    [state.isOpen],
  );

  React.useEffect(() => {
    setState(s => ({
      ...s,
      selectedLabel:
        data.find(item => item.value === selectedValue)?.label ?? defaultLabel,
      selectedValue: selectedValue ?? defaultValue,
    }));
  }, [selectedValue, data, defaultLabel, defaultValue]);

  React.useEffect(() => {
    onSelect({ value: state.selectedValue, label: state.selectedLabel, name });
  }, [onSelect, state.selectedValue, state.selectedLabel, name]);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  return (
    <div
      ref={ref}
      className={clsx(
        styles.wrapper,
        styles[variant],
        state.isOpen && styles.isOpen,
        state.selectedLabel !== defaultLabel && styles.active,
        className,
      )}
    >
      <div
        className={clsx(styles.labelArea, classNameLabelArea)}
        onClick={() => {
          if (state.isOpen) {
            setState(s => ({
              ...s,
              isOpen: false,
              selectedLabel: defaultLabel,
              selectedValue: defaultValue,
            }));
          } else {
            setState(s => ({ ...s, isOpen: !s.isOpen }));
          }
        }}
      >
        <span className={styles.label}>
          {state.isOpen ? defaultLabel : state.selectedLabel}
        </span>
        {variant === "primary" && (
          <IonIcon
            icon={state.isOpen ? caretUp : caretDown}
            className={styles.arrowIcon}
          />
        )}
        {variant === "secondary" && (
          <ArrowIcon className={clsx(styles.arrowIcon)} />
        )}
      </div>
      {state.isOpen && (
        <ul className={styles.menu} tabIndex={0}>
          {data.map((item, index) => (
            <li
              key={index}
              className={clsx(
                styles.option,
                state.selectedValue === item.value ? styles.active : "",
              )}
              onClick={() => {
                setState(s => ({
                  ...s,
                  isOpen: false,
                  selectedLabel: item.label,
                  selectedValue: item.value,
                }));
              }}
            >
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
