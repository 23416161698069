import React from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, useIonViewWillEnter } from "@ionic/react";
import clsx from "clsx";

import {
  Context,
  fetchAssessment,
  fetchRealizationCountByExperience,
} from "../../../store/student";
import {
  ASSESSMENT_STATUS,
  NEXT_ACTION_KINDS,
} from "../../../constants/assessment-content";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import common from "../common.module.scss";
import LoginHeader from "../../../organisms/LoginHeader";
import AssessmentStatus from "../../../organisms/AssessmentStatus";
import AssessmentComparisonBarChart from "../../../organisms/AssessmentComparisonBarChart";
import RealizationPieChart from "../../../organisms/RealizationPieChart";

import styles from "./AssessmentListPage.module.scss";

const AssessmentListPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [_calling, peep] = useWaitApiCall(contextState);
  const history = useHistory();

  const [experiences, setExperiences] = React.useState<{ [key: string]: null }>(
    {
      experience_score_1: null,
      experience_score_2: null,
      experience_score_3: null,
      experience_score_4: null,
      experience_score_5: null,
      experience_score_6: null,
      experience_score_7: null,
    },
  );

  const isUnFilledScore = React.useMemo(() => {
    const keys = Object.keys(experiences);
    const scores = keys.map(key => experiences[key]);
    return scores.includes(null);
  }, [experiences]);

  React.useEffect(() => {
    const results = contextState.assessment.assessment_experiences.map(exp => [
      `experience_score_${exp.experience_no}`,
      exp.score,
    ]);
    setExperiences(prevExperiences => {
      return { ...prevExperiences, ...Object.fromEntries(results) };
    });
  }, [history, contextState.assessment]);

  useIonViewWillEnter(() => {
    dispatch(peep(fetchAssessment()));
    dispatch(fetchRealizationCountByExperience());
  });

  const nextActionCompleted = React.useMemo(() => {
    return NEXT_ACTION_KINDS.every(kind => {
      const result = contextState.assessment.assessment_next_actions.find(
        nextActions => nextActions.kind === kind,
      );
      if (result && result.next_action.length === 0) return false;
      return Boolean(result);
    });
  }, [contextState.assessment.assessment_next_actions]);

  return (
    <IonPage>
      <LoginHeader title="自己評価ツール" unreadNotiCount={0} />
      <IonContent className={common.wrapper}>
        <div className={clsx(common.container, styles.container)}>
          <div className={styles.assessment}>
            <p className={styles.title}>7つの素養を評価する</p>
            <div className={styles.list}>
              {ASSESSMENT_STATUS.map(assessmentData => (
                <AssessmentStatus
                  key={assessmentData["experienceNo"]}
                  title={assessmentData["title"]}
                  desc={assessmentData["desc"]}
                  experienceNo={assessmentData["experienceNo"]}
                  isDisable={false}
                  experienceScore={
                    experiences[
                      `experience_score_${assessmentData["experienceNo"]}`
                    ]
                  }
                  checked={false}
                  colorCode={assessmentData["colorCode"]}
                />
              ))}
              <AssessmentStatus
                key={8}
                title={"まとめ"}
                desc={"未来について考える"}
                experienceNo={8}
                experienceScore={null}
                isDisable={isUnFilledScore}
                colorCode={"#172029"}
                checked={nextActionCompleted}
              />
            </div>
          </div>
          <div className={styles.chart}>
            <div className={styles.barChart}>
              <AssessmentComparisonBarChart />
            </div>
            <div className={styles.pieChart}>
              <RealizationPieChart />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AssessmentListPage;
