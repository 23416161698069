import React from "react";
import { IonIcon, IonItemGroup, IonItem, IonTextarea } from "@ionic/react";
import { bulbSharp } from "ionicons/icons";

import {
  ICategory,
  IClass,
  IRealization,
  ITeacher,
  IStudentGearBox,
  IRealizationImage,
  IRealizationUpdate,
  ITargetSelectedData,
} from "../../state";
import RealizationThumbnailImage from "../../atoms/RealizationThumbnailImage";
import WordCounter from "../../atoms/WordCounter";
import realizationIcon from "../../assets/icons/realization.svg";
import convertIcon from "../../assets/icons/convert.svg";
import {
  getUniqueSimpleArray,
  isImageFile,
  mergeArraysByUniqueKey,
  objectArrayToKeyArray,
} from "../../libs/Util";
import { ReactComponent as ImageIcon } from "../../assets/icons/image.svg";
import ImageDetailModal from "../../molecules/ImageDetailModal";
import ModalConfirm from "../../molecules/ModalConfirm";
import CategoryForm from "../../molecules/CategoryForm";
import ExperienceSelectForm from "../../molecules/ExperienceSelectForm";
import TargetGroup, { ETargetSwitch } from "../../organisms/TargetGroup";
import Toast, { TToastType } from "../../molecules/Toast";
import {
  INPUT_REALIZATION_CONTENT_FAILURE_MESSAGE,
  SELECT_REALIZATION_BOX_FAILURE_MESSAGE,
  SELECT_REALIZATION_CLASS_FAILURE_MESSAGE,
  SELECT_REALIZATION_TEACHER_FAILURE_MESSAGE,
} from "../../store/student";

import styles from "./RealizationEditForm.module.scss";

type TRealizationType = "ROOT" | "WILL";

export interface RouteParams {
  id: string;
}

export interface RealizationEditFormProps {
  realization: IRealization;
  categories: ICategory[];
  teachers: ITeacher[];
  classes: IClass[];
  calling?: boolean;
  pathname: string;
  success_message: string;
  gearBoxes: IStudentGearBox[];
  updateRealization(
    realization: IRealizationUpdate,
    submitted_teacher_ids: number[],
    share_class_ids: number[],
  ): void;
  createCategory(category: ICategory): void;
}

const CONTENT_MAX_LENGTH = 500;

const RealizationEditForm = (props: RealizationEditFormProps) => {
  const { realization } = props;
  const submitted_teacher_ids = objectArrayToKeyArray(
    realization.submitted_teachers,
  );
  const sharedClassIDs = objectArrayToKeyArray(realization.shared_classes);
  const categoryIDs =
    realization.categories?.map(category => category.id) ?? [];
  const realizationImgInputRef = React.useRef<HTMLInputElement>(null);
  const [state, setState] = React.useState<
    {
      realization_id: number;
      category_ids: number[];
      experience_nos?: number[];
      content: string;
      shared: boolean;
      image?: IRealizationImage | null;
      toast: {
        type: TToastType;
        message: string;
      } | null;
      showCategoryForm: boolean;
      showNewCategoryForm: boolean;
      showSubmitForm: boolean;
      showShareForm: boolean;
      showBoxForm: boolean;
      showImageInvalid: boolean;
      showImageExceedsSize: boolean;
      showImageDetail: boolean;
    } & ITargetSelectedData
  >(() => {
    let targetSelectedData: ITargetSelectedData = {
      selected_box_id: null,
      selected_teacher_ids: [],
      selected_class_ids: [],
      targetSwitch: ETargetSwitch.ONLY_ME,
    };
    if (realization.gearbox?.id) {
      targetSelectedData = {
        ...targetSelectedData,
        selected_box_id: realization.gearbox.id,
        targetSwitch: ETargetSwitch.BOX,
      };
    } else if (realization.shared_classes?.length) {
      targetSelectedData = {
        ...targetSelectedData,
        selected_class_ids: sharedClassIDs,
        targetSwitch: ETargetSwitch.GROUP,
      };
    } else if (realization.submitted_teachers?.length) {
      targetSelectedData = {
        ...targetSelectedData,
        selected_teacher_ids: submitted_teacher_ids,
        targetSwitch: ETargetSwitch.TEACHER,
      };
    }
    return {
      realization_id: realization.id,
      category_ids: categoryIDs,
      experience_nos: realization.experience_nos,
      content: realization.content,
      shared: realization.shared,
      image: realization.image,
      toast: null,
      showCategoryForm: false,
      showNewCategoryForm: false,
      showSubmitForm: false,
      showShareForm: false,
      showBoxForm: false,
      showImageInvalid: false,
      showImageExceedsSize: false,
      showImageDetail: false,
      ...targetSelectedData,
    };
  });
  const update = (args: { [key: string]: any }) => {
    setState(prevState => ({ ...prevState, ...args }));
  };

  const classes = realization.shared_classes?.length
    ? (mergeArraysByUniqueKey({
        arrays: [props.classes, realization.shared_classes],
      }) as IClass[])
    : props.classes;

  const filtedGearboxes = React.useMemo(() => {
    const gearboxes =
      props.realization.gearbox &&
      !props.gearBoxes.some(box => box.id === props.realization.gearbox?.id)
        ? [...props.gearBoxes, props.realization.gearbox]
        : props.gearBoxes;
    return gearboxes.filter(
      gearbox =>
        !gearbox.is_archived || gearbox.id === props.realization.gearbox?.id,
    ) as IStudentGearBox[];
  }, [props.gearBoxes, props.realization.gearbox]);

  React.useEffect(() => {
    if (realization) {
      const { gearbox } = realization;
      setState(s => ({
        ...s,
        selected_box_id: gearbox?.id ?? null,
        image: realization.image,
      }));
    }
  }, [realization]);

  const removeImg = React.useCallback(() => {
    realizationImgInputRef.current &&
      (realizationImgInputRef.current.value = "");
    setState(s => ({ ...s, image: undefined }));
  }, []);

  const uploadImg = React.useCallback(
    e => {
      e.persist();
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      if (!isImageFile(file)) {
        removeImg();
        setState(s => ({ ...s, showImageInvalid: true }));
        return;
      }
      const fileSizeByMB = file.size / 1024 / 1024;
      if (fileSizeByMB > 5) {
        removeImg();
        setState(s => ({ ...s, showImageExceedsSize: true }));
        return;
      }
      setState(s => ({
        ...s,
        image: {
          name: file.name,
          url: URL.createObjectURL(file),
          blob: file,
        },
      }));
    },
    [removeImg],
  );

  const setBoxTemplate = React.useCallback(
    text => {
      setState({ ...state, content: text });
    },
    [state],
  );

  const image = React.useMemo(() => {
    if (
      typeof state.image?.id === "number" &&
      typeof realization.image?.id === "number" &&
      state.image?.id === realization.image?.id
    )
      return undefined;
    else return state.image;
  }, [realization.image, state.image]);

  const onSubmit = (realizationType: TRealizationType) => {
    if (!state.content) {
      update({
        toast: {
          type: "danger",
          message: INPUT_REALIZATION_CONTENT_FAILURE_MESSAGE,
        },
      });
      return;
    }
    if (state.targetSwitch === ETargetSwitch.BOX && !state.selected_box_id) {
      update({
        toast: {
          type: "danger",
          message: SELECT_REALIZATION_BOX_FAILURE_MESSAGE,
        },
      });
      return;
    }
    if (
      state.targetSwitch === ETargetSwitch.GROUP &&
      !state.selected_class_ids.length
    ) {
      update({
        toast: {
          type: "danger",
          message: SELECT_REALIZATION_CLASS_FAILURE_MESSAGE,
        },
      });
      return;
    }
    if (
      state.targetSwitch === ETargetSwitch.TEACHER &&
      !state.selected_teacher_ids.length
    ) {
      update({
        toast: {
          type: "danger",
          message: SELECT_REALIZATION_TEACHER_FAILURE_MESSAGE,
        },
      });
      return;
    }

    let realizationObjectParam = {
      id: realization.id,
      category_ids: state.category_ids,
      experience_nos: state.experience_nos,
      content: state.content,
      kind: "roots" as "roots" | "will",
      gearbox_id: state.selected_box_id,
      ...(image ? { image } : {}),
      delete_image: !!realization.image && !state.image,
    };
    if (realizationType === "WILL") {
      realizationObjectParam = {
        ...realizationObjectParam,
        kind: "will",
      };
    }

    const selectedBox = state.selected_box_id
      ? props.gearBoxes.find(box => box.id === state.selected_box_id)
      : undefined;

    props.updateRealization(
      {
        ...realizationObjectParam,
      },
      !!selectedBox
        ? selectedBox.id === realization.gearbox?.id
          ? (getUniqueSimpleArray([
              ...submitted_teacher_ids,
              selectedBox.teacher.id,
            ]) as number[])
          : [selectedBox.teacher.id]
        : state.selected_teacher_ids,
      !!selectedBox
        ? selectedBox.id === realization.gearbox?.id
          ? (getUniqueSimpleArray([
              ...sharedClassIDs,
              ...realization.gearbox.klasses.map(cls => cls.id),
            ]) as number[])
          : selectedBox.klasses.map(cls => cls.id)
        : state.selected_class_ids,
    );
  };

  return (
    <IonItemGroup className={styles.wrapper}>
      <Toast
        type={state.toast?.type}
        showToast={!!state.toast}
        onClose={() => update({ toast: null })}
        message={state.toast?.message ?? ""}
      />
      <ModalConfirm
        isOpen={state.showImageInvalid}
        message={`無効なファイル形式です。\njpg/jpeg/png のみアップロード可能です。`}
        buttons={[
          {
            title: "キャンセル",
            type: "None",
            action: () => setState(s => ({ ...s, showImageInvalid: false })),
          },
          {
            title: "OK",
            type: "Success",
            action: () => setState(s => ({ ...s, showImageInvalid: false })),
          },
        ]}
      />
      <ModalConfirm
        isOpen={state.showImageExceedsSize}
        message={`アップロードに失敗しました。\nファイルサイズを5MB以下にしてください。`}
        buttons={[
          {
            title: "OK",
            type: "None",
            action: () =>
              setState(s => ({ ...s, showImageExceedsSize: false })),
          },
        ]}
      />
      {state.image && (
        <ImageDetailModal
          show={state.showImageDetail}
          imageUrl={state.image.url}
          imageName={state.image.name}
          onClose={() => setState(s => ({ ...s, showImageDetail: false }))}
        />
      )}
      <div className={styles.container}>
        {realization.geartheme && (
          <div className={styles.gearThemeWrapper}>
            <IonIcon className={styles.icon} icon={bulbSharp} />
            <span>{realization.geartheme.title}</span>
          </div>
        )}
        {realization.content && (
          <>
            <IonItem className={styles.item} lines="none">
              <IonTextarea
                autoGrow
                rows={3}
                maxlength={CONTENT_MAX_LENGTH}
                className={styles.textarea}
                placeholder={realization.content}
                value={state.content}
                onIonChange={e => {
                  setState({ ...state, content: e.detail.value ?? "" });
                }}
              />
            </IonItem>
            <div className={styles.imgInputWrapper}>
              {state.image?.url ? (
                <div className={styles.realizationImg}>
                  <RealizationThumbnailImage
                    alt="Upload Image"
                    src={state.image.url}
                    onClick={e => {
                      e.stopPropagation();
                      setState(s => ({
                        ...s,
                        showImageDetail: true,
                      }));
                    }}
                    isEdit={true}
                  />
                  <button onClick={removeImg}>✕</button>
                </div>
              ) : (
                <>
                  <input
                    ref={realizationImgInputRef}
                    accept=".png,.jpg,.jpeg"
                    type="file"
                    onChange={uploadImg}
                  />
                  <ImageIcon
                    onClick={() => realizationImgInputRef.current?.click()}
                  />
                </>
              )}
            </div>
            <div className={styles.counter}>
              <WordCounter
                maxLength={CONTENT_MAX_LENGTH}
                targetLength={state.content.length}
              />
            </div>
            <div className={styles.border} />
          </>
        )}
        <CategoryForm
          pathname={props.pathname}
          success_message={props.success_message}
          calling={props.calling}
          isOpen={state.showCategoryForm}
          categories={props.categories}
          selectedCategoryIDs={state.category_ids}
          update={update}
          createCategory={props.createCategory}
        />
        {!realization.geartheme && (
          <TargetGroup
            realization={realization}
            gearboxes={filtedGearboxes}
            teachers={props.teachers}
            classes={classes}
            showSubmitForm={state.showSubmitForm}
            showShareForm={state.showShareForm}
            showBoxForm={state.showBoxForm}
            targetSwitch={state.targetSwitch}
            selected_box_id={state.selected_box_id}
            selected_teacher_ids={state.selected_teacher_ids}
            selected_class_ids={state.selected_class_ids}
            setBoxTemplate={setBoxTemplate}
            update={update}
          />
        )}
        <ExperienceSelectForm
          checkedExperienceNos={state.experience_nos}
          update={update}
        />
      </div>
      <div className={styles.wrapperButton}>
        <button
          className={styles.createRealization}
          disabled={props.calling}
          onClick={() => onSubmit("ROOT")}
        >
          <IonIcon
            icon={realizationIcon}
            slot="start"
            className={styles.icon}
          />
          <span>
            ROOTSを
            <br />
            ストック
          </span>
        </button>
        <button
          className={styles.createConversion}
          disabled={props.calling}
          onClick={() => {
            onSubmit("WILL");
          }}
        >
          <IonIcon icon={convertIcon} slot="start" className={styles.icon} />
          <span>
            WILLを
            <br />
            ストック
          </span>
        </button>
      </div>
    </IonItemGroup>
  );
};

export default RealizationEditForm;
