import {
  IonIcon,
  IonItem,
  IonLabel,
  IonReorder,
  IonReorderGroup,
} from "@ionic/react";
import React from "react";
import {
  removeCircleOutline as removeIcon,
  addCircleOutline as addIcon,
} from "ionicons/icons";

import { ICategory } from "../../state";
import CategoryLabel from "../../atoms/CategoryLabel";
import NewCategoryForm from "../../molecules/NewCategoryForm";
import ModalConfirm from "../../molecules/ModalConfirm";

import styles from "./EditCategories.module.scss";

export interface EditCategoriesProps {
  categories: ICategory[];
  calling: boolean;
  values: { [key: string]: any };
  update: (args: { [key: string]: any }) => void;
  updateCategory(
    category_id: number,
    category: { name: string; color: string },
  ): void;
  createCategory(category: { name: string; color: string }): void;
  destroyCategory(category_id: number): void;
}

const EditCategories = (props: EditCategoriesProps) => {
  function doReorder(event: CustomEvent) {
    const categories = event.detail.complete(props.categories);
    props.update({
      categories,
    });
  }

  return (
    <>
      <ModalConfirm
        isOpen={props.values.showCategoryDeleteForm}
        message={
          <>
            ストックから活かし先を外し、
            <br />「{props.values.category_name}」を削除しますか？
            <br />
            この操作は取り消しができません。
          </>
        }
        buttons={[
          {
            title: "キャンセル",
            action: () => props.update({ showCategoryDeleteForm: false }),
            type: "None",
          },
          {
            title: "削除する",
            action: () => {
              props.destroyCategory(props.values.category_id);
              props.update({ showCategoryDeleteForm: false, category_id: 0 });
            },
            type: "Danger",
          },
        ]}
      />
      <IonItem className={styles.categoryItem}>
        <IonLabel color="light">活かし先を新規作成</IonLabel>
        <IonIcon
          className={styles.addIcon}
          slot="end"
          icon={addIcon}
          onClick={() => {
            props.update({
              showNewCategoryForm: true,
              editable: false,
            });
          }}
        />
      </IonItem>
      <NewCategoryForm
        calling={props.calling}
        values={props.values}
        visible={props.values.showNewCategoryForm}
        update={props.update}
        createCategory={props.createCategory}
        updateCategory={props.updateCategory}
      />
      <IonReorderGroup
        disabled={!props.values.editable || props.calling}
        onIonItemReorder={doReorder}
      >
        {props.categories &&
          props.categories.map(category => (
            <IonItem key={category.id} className={styles.categories}>
              {props.values.editable && (
                <IonIcon
                  className={styles.removeIcon}
                  icon={removeIcon}
                  slot="start"
                  onClick={() => {
                    if (props.calling) return;
                    props.update({
                      showCategoryDeleteForm: true,
                      category_id: category.id ? category.id : 0,
                      category_name: category.name,
                    });
                  }}
                />
              )}
              <CategoryLabel
                selectable
                editable={props.values.editable}
                label={category.name}
                color={category.color}
                count={category.realization_count}
                onClick={() => {
                  if (props.values.editable) {
                    props.update({
                      showNewCategoryForm: true,
                      category_id: category.id ? category.id : 0,
                      editCategory: true,
                      name: category.name,
                      color: category.color,
                    });
                  }
                }}
              />
              <IonReorder slot="end" className={styles.reorderIcon} />
            </IonItem>
          ))}
      </IonReorderGroup>
    </>
  );
};

export default EditCategories;
