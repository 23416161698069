import React from "react";
import clsx from "clsx";
import { IonButton, IonContent, IonIcon } from "@ionic/react";

import Popover from "../../atoms/Popover";
import smileIcon from "../../assets/icons/smile.svg";
import {
  Stamp,
  stamps,
  stampsByCategory,
  stampsByRandom,
} from "../../libs/stamp";

import type { LayoutType } from "./type";
import styles from "./StampSelector.module.scss";
import CategorizedLayout from "./CategorizedLayout";
import FlatLayout from "./FlatLayout";

type Props = {
  onSelect: (stamp: Stamp) => void;
  layout?: LayoutType;
};

export default function StampSelector(props: Props) {
  const layoutType = props.layout ?? "categorized";

  const popover = React.useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const selectStamp = (id: number) => {
    setPopoverOpen(false);
    const selected_stamp = stamps.find(stamp => stamp.id === id);
    if (selected_stamp) {
      props.onSelect(selected_stamp);
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    if (popover === null || popover.current == null) {
      return;
    }
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  return (
    <>
      <IonButton fill="clear" onClick={handleClick}>
        <IonIcon icon={smileIcon} />
      </IonButton>
      <Popover
        ref={popover}
        isOpen={popoverOpen}
        onDidDismiss={() => setPopoverOpen(false)}
        side="top"
        alignment="end"
        arrow={false}
        onClose={() => setPopoverOpen(false)}
      >
        <IonContent className={clsx("ion-padding", styles.content)}>
          {layoutType === "categorized" ? (
            <CategorizedLayout rows={stampsByCategory} onSelect={selectStamp} />
          ) : (
            <FlatLayout rows={stampsByRandom} onSelect={selectStamp} />
          )}
        </IonContent>
      </Popover>
    </>
  );
}
