import React from "react";
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { chevronBackOutline as backIcon } from "ionicons/icons";
import clsx from "clsx";

import styles from "./NavHeader.module.scss";

export interface NavHeaderProps {
  title: string;
  goBackUrl?: string;
  rightComponent?: React.ReactNode;
  className?: string;
  goBack?: () => void;
}

const NavHeader = (props: NavHeaderProps) => {
  const router = useIonRouter();
  return (
    <IonHeader className={clsx(styles.header, props.className ?? "")}>
      <IonToolbar className={styles.toolBar}>
        <IonTitle className={styles.title}>{props.title}</IonTitle>
        <IonButtons slot="start">
          <IonButton
            onClick={() => {
              if (props.goBack) {
                props.goBack();
                return;
              }
              props.goBackUrl
                ? router.push(props.goBackUrl, "back", "pop")
                : window.history.back();
            }}
          >
            <IonIcon icon={backIcon} color="light" />
          </IonButton>
        </IonButtons>
        {props.rightComponent && (
          <IonButtons slot="end">{props.rightComponent}</IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};

export default NavHeader;
