import { IonButton, IonLabel, IonModal } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router-dom";

import { timelineFilteredPath } from "../../../store/student";

import styles from "./ModalSelectActions.module.scss";

export enum TimelineSelectAction {
  HAS_DERIVATIVE = "has_derivative",
  IS_FAVORITE = "is_favorite",
  HAS_MY_COMMENT = "has_my_comment",
}

export interface ModalSelectActionsProps {
  isOpen: boolean;
  onClose?: () => void;
}

const ModalSelectActions = (props: ModalSelectActionsProps) => {
  const history = useHistory();
  const [selectAction, setSelectAction] = React.useState<TimelineSelectAction>(
    TimelineSelectAction.HAS_DERIVATIVE,
  );

  const closeModal = () => {
    setSelectAction(TimelineSelectAction.HAS_DERIVATIVE);
    props.onClose && props.onClose();
  };

  return (
    <IonModal isOpen={props.isOpen} className={styles.wrapper}>
      <div className={styles.modal}>
        <IonLabel className={styles.title}>条件を選択</IonLabel>
        <div className={styles.filterList}>
          <div className={styles.item}>
            <input
              type="radio"
              name="filterItem"
              id="has_derivative"
              checked={selectAction === TimelineSelectAction.HAS_DERIVATIVE}
              value={TimelineSelectAction.HAS_DERIVATIVE}
              onChange={() =>
                setSelectAction(TimelineSelectAction.HAS_DERIVATIVE)
              }
            />
            <label htmlFor="has_derivative">引用したストック</label>
          </div>
          <div className={styles.item}>
            <input
              type="radio"
              name="filterItem"
              id="is_favorite"
              checked={selectAction === TimelineSelectAction.IS_FAVORITE}
              value={TimelineSelectAction.IS_FAVORITE}
              onChange={() => setSelectAction(TimelineSelectAction.IS_FAVORITE)}
            />
            <label htmlFor="is_favorite">いいねしたストック</label>
          </div>
          <div className={styles.item}>
            <input
              type="radio"
              name="filterItem"
              id="has_my_comment"
              checked={selectAction === TimelineSelectAction.HAS_MY_COMMENT}
              value={TimelineSelectAction.HAS_MY_COMMENT}
              onChange={() =>
                setSelectAction(TimelineSelectAction.HAS_MY_COMMENT)
              }
            />
            <label htmlFor="has_my_comment">コメントしたストック</label>
          </div>
        </div>
        <div className={styles.buttonArea}>
          <IonButton
            className={styles.cancelButton}
            fill="outline"
            strong
            onClick={closeModal}
          >
            キャンセル
          </IonButton>
          <IonButton
            className={styles.button}
            fill="outline"
            strong
            onClick={() => {
              history.push(`${timelineFilteredPath}/${selectAction}`);
              closeModal();
            }}
          >
            検索
          </IonButton>
        </div>
      </div>
    </IonModal>
  );
};

export default ModalSelectActions;
