import React, { useRef, useState } from "react";
import { menuController } from "@ionic/core/components";
import { menu as menuIcon, search, searchOutline } from "ionicons/icons";
import { chevronBackOutline as backIcon } from "ionicons/icons";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonIcon,
  IonButton,
  useIonRouter,
} from "@ionic/react";

import cross from "../../assets/icons/cross.svg";
import BoxTitle from "../../atoms/BoxTitle";
import { TNameStatus } from "../../state";

import styles from "./FiltableHeader.module.scss";

interface HeaderState {
  searching: boolean;
  searchText: string;
}
export interface FiltableHeaderProps {
  title: string;
  isArchived?: boolean;
  nameStatus?: TNameStatus;
  unreadNotiCount: number;
  searching: boolean;
  searchText: string;
  setState: (state: HeaderState) => void;
  goBack?: boolean;
  goBackUrl?: string;
  rightComponent?: React.ReactNode;
}

const FiltableHeader = (props: FiltableHeaderProps) => {
  const router = useIonRouter();

  const searchInput = useRef<HTMLInputElement>(null);
  const [keyword, setKeyword] = useState("");

  React.useEffect(() => {
    props.setState({ searching: false, searchText: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  React.useEffect(() => {
    setKeyword(props.searchText);
  }, [props.searchText]);

  const submitSearch = () => {
    props.setState({
      searching: props.searching,
      searchText: keyword,
    });
    searchInput.current?.blur();
  };

  return (
    <>
      <IonHeader className={styles.header}>
        <IonToolbar className={styles.toolbar}>
          {props.searching ? (
            <div className={styles.searchArea}>
              <IonIcon
                icon={backIcon}
                color="light"
                className={styles.cancelBtn}
                onClick={() => {
                  props.setState({
                    searching: false,
                    searchText: "",
                  });
                }}
              />
              <input
                ref={searchInput}
                type="text"
                enterKeyHint="search"
                className={styles.searchBox}
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
                onKeyDown={e => {
                  if (e.key === "Enter" && !e.nativeEvent.isComposing) {
                    submitSearch();
                  }
                }}
              />
              <img
                className={styles.cross}
                src={cross}
                alt={"消す"}
                onClick={() => {
                  setKeyword("");
                  props.setState({
                    searching: props.searching,
                    searchText: "",
                  });
                }}
              />
              <button className={styles.searchBtn} onClick={submitSearch}>
                <IonIcon icon={search} className={styles.searchIcon} />
              </button>
            </div>
          ) : (
            <IonTitle className={styles.title}>
              <BoxTitle
                isArchived={props.isArchived}
                nameStatus={props.nameStatus}
                title={props.title}
              />
            </IonTitle>
          )}
          {props.searching ? (
            <IonButtons
              slot="start"
              onClick={() =>
                props.setState({
                  searching: false,
                  searchText: "",
                })
              }
            />
          ) : (
            <IonButtons slot="start">
              {props.goBack ? (
                <IonButton
                  onClick={() => {
                    props.goBackUrl
                      ? router.push(props.goBackUrl, "back", "pop")
                      : window.history.back();
                  }}
                >
                  <IonIcon icon={backIcon} color="light" />
                </IonButton>
              ) : (
                <IonMenuButton
                  autoHide={false}
                  onClick={() => menuController.open("sideMenuContainer")}
                >
                  <IonIcon icon={menuIcon} className={styles.menuIcon} />
                  {props.unreadNotiCount > 0 && (
                    <div className={styles.mark}>●</div>
                  )}
                </IonMenuButton>
              )}
            </IonButtons>
          )}
          {!props.searching && (
            <>
              {props.rightComponent && (
                <IonButtons slot="end">{props.rightComponent}</IonButtons>
              )}
              <IonIcon
                slot="end"
                icon={searchOutline}
                onClick={() =>
                  props.setState({
                    searching: true,
                    searchText: props.searchText,
                  })
                }
                className={styles.icon}
              />
            </>
          )}
        </IonToolbar>
      </IonHeader>
    </>
  );
};

export default FiltableHeader;
