import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { ASSESSMENT_STATUS } from "../../constants/assessment-content";

import styles from "./ComparisonBarChart.module.scss";

interface ComparisonBarChartProps {
  summary?: boolean;
  data: {
    data: string;
    前回: number;
    今回: number;
  }[];
}

const ComparisonBarChart: React.FC<ComparisonBarChartProps> = ({
  data,
  summary,
}) => {
  return (
    <div className={styles.comparison}>
      <ResponsiveContainer width={"100%"} height={266}>
        <BarChart data={data} barSize={7.5} barGap={10}>
          <CartesianGrid
            strokeDasharray="5"
            stroke="rgba(255, 255, 255, 0.1)"
          />
          <XAxis dataKey="data" hide={true} tick={false} />
          <YAxis
            tickCount={9}
            minTickGap={1}
            width={11}
            allowDecimals={false}
            tickLine={false}
            axisLine={false}
          />
          <Tooltip />
          {!summary && (
            <Bar dataKey="前回">
              {ASSESSMENT_STATUS.map(assessmentData => (
                <Cell
                  key={assessmentData.experienceNo}
                  fill={assessmentData.colorCode}
                />
              ))}
            </Bar>
          )}
          <Bar dataKey="今回">
            {ASSESSMENT_STATUS.map(assessmentData => (
              <Cell
                key={assessmentData.experienceNo}
                fill={assessmentData.colorCode}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
export default ComparisonBarChart;
