import React from "react";
import clsx from "clsx";
import { IonIcon, IonItem, IonLabel, IonAvatar } from "@ionic/react";
import {
  chevronForwardOutline as rightArrowIcon,
  personCircle,
} from "ionicons/icons";

import boxIcon from "../../assets/icons/box.svg";
import BoxTitle from "../../atoms/BoxTitle";
import { replaceSearchTextAndUrl } from "../../libs/Convert";
import {
  IGearboxNewestRealization,
  IOnClickStudentAvatarParams,
  TNameStatus,
} from "../../state";

import styles from "./BoxItem.module.scss";

export interface BoxItemProps {
  color?: "light" | "dark";
  className?: string;
  title: string;
  boxCreatorName: string;
  realizationsCount: number;
  isArchived: boolean;
  nameStatus: TNameStatus;
  newestRealization?: IGearboxNewestRealization | null;
  searchText?: string;
  redirectTo?: () => void;
  onAvatarClick?: (params: IOnClickStudentAvatarParams) => void;
}

const BoxItem: React.FC<BoxItemProps> = ({
  color = "light",
  className = "",
  title,
  boxCreatorName,
  realizationsCount,
  isArchived,
  nameStatus,
  newestRealization,
  searchText,
  redirectTo,
  onAvatarClick,
}) => {
  const content = React.useMemo(() => {
    return newestRealization?.content || "";
  }, [newestRealization]);

  const handleClickAvatar = React.useCallback(
    e => {
      if (!onAvatarClick || !newestRealization?.student) return;
      e.stopPropagation();

      const { id, full_name, photo, klass_id, klass_name } =
        newestRealization.student;
      onAvatarClick({
        student_id: id,
        name: full_name,
        photo,
        class_id: klass_id,
        class_name: klass_name,
      });
    },
    [onAvatarClick, newestRealization],
  );

  return (
    <IonItem
      className={clsx(
        styles.boxItem,
        styles[color],
        className,
        redirectTo && styles.pointer,
      )}
      lines="full"
      onClick={() => redirectTo && redirectTo()}
    >
      <div className={styles.boxWrapper}>
        <div className={styles.boxTopContent}>
          <IonIcon className={styles.boxIcon} src={boxIcon} />
          <div className={styles.boxInfo}>
            <div className={styles.boxTitle}>
              <IonLabel className={styles.label}>
                <BoxTitle
                  isArchived={isArchived}
                  nameStatus={nameStatus}
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: replaceSearchTextAndUrl(
                          title,
                          searchText || "",
                        ),
                      }}
                    />
                  }
                />
              </IonLabel>
              {redirectTo && (
                <IonIcon
                  className={styles.arrowIcon}
                  color="medium"
                  icon={rightArrowIcon}
                />
              )}
            </div>
            <div className={styles.boxAuthor}>
              <p>{boxCreatorName}</p>
              <div className={styles.realizationsCount}>
                <IonIcon icon={boxIcon} />
                <span>{realizationsCount}</span>
              </div>
            </div>
          </div>
        </div>
        {newestRealization && (
          <div className={styles.newestRealization}>
            <div
              className={clsx(
                styles.avatarArea,
                !!newestRealization?.student &&
                  !!onAvatarClick &&
                  styles.clicableAvatar,
              )}
              onClick={handleClickAvatar}
            >
              {newestRealization.student?.photo?.url ? (
                <IonAvatar className={styles.realizationAvatar}>
                  <img
                    alt="author avatar"
                    src={newestRealization.student.photo.url}
                  />
                </IonAvatar>
              ) : (
                <IonIcon
                  className={styles.realizationAvatar}
                  icon={personCircle}
                  slot="start"
                  color="light"
                />
              )}
            </div>
            <div className={styles.realizationInfo}>
              <p>{newestRealization.student?.full_name}</p>
              <p>{content}</p>
            </div>
          </div>
        )}
      </div>
    </IonItem>
  );
};

export default BoxItem;
