import React from "react";

import IconCounter from "../../atoms/IconCounter";

import styles from "./IconCounterList.module.scss";
import realizationIcon from "./../../assets/icons/realization.svg";
import convertIcon from "./../../assets/icons/convert.svg";
import executionIcon from "./../../assets/icons/execution.svg";

export interface IconCounterListProps {
  realization_num: number;
  conversion_num: number;
  action_num: number;
}

const IconCounterList = (props: IconCounterListProps) => {
  return (
    <div className={styles.iconCounterList}>
      <IconCounter
        title="ROOTS"
        icon={realizationIcon}
        className={styles.realizationNum}
        counter={props.realization_num}
        withBorder={true}
      />
      <IconCounter
        title="WILL"
        icon={convertIcon}
        className={styles.conversionNum}
        counter={props.conversion_num}
        withBorder={true}
      />
      <IconCounter
        title="EXPERIENCE"
        icon={executionIcon}
        className={styles.actionNum}
        counter={props.action_num}
        withBorder={false}
      />
    </div>
  );
};

export default IconCounterList;
