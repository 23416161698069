import { useCallback, useMemo } from "react";

import { IGearTheme } from "../state";
import { updateReadGearTutorial } from "../store/student";

const useThemeTutorial = (
  geartheme: IGearTheme | null,
  read_gear_tutorials: string[],
  dispatch: (action: any) => void,
): [{ completed: boolean }, () => void] => {
  const TUTORIAL_THEME_KEY = `theme${geartheme?.id}`;

  const completed = useMemo(() => {
    return read_gear_tutorials.includes(TUTORIAL_THEME_KEY);
  }, [read_gear_tutorials, TUTORIAL_THEME_KEY]);

  const updateThemeTutorial = useCallback(() => {
    !completed &&
      dispatch(
        updateReadGearTutorial([...read_gear_tutorials, TUTORIAL_THEME_KEY]),
      );
  }, [TUTORIAL_THEME_KEY, completed, read_gear_tutorials, dispatch]);

  return [{ completed }, updateThemeTutorial];
};

export default useThemeTutorial;
