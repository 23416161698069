import React from "react";
import { menuController } from "@ionic/core/components";
import { menu as menuIcon } from "ionicons/icons";
import { searchOutline } from "ionicons/icons";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonIcon,
  IonButton,
} from "@ionic/react";

import styles from "./LoginHeader.module.scss";

export interface LoginHeaderProps {
  title: string;
  updateTarget?: string;
  unreadNotiCount: number;
  onSearching?: () => void;
}

const LoginHeader = (props: LoginHeaderProps) => {
  return (
    <>
      <IonHeader className={styles.header}>
        <IonToolbar className={styles.toolbar}>
          <IonTitle className={styles.title}>{props.title}</IonTitle>
          <IonButtons slot="start">
            <IonMenuButton
              autoHide={false}
              onClick={() => menuController.open("sideMenuContainer")}
            >
              <IonIcon icon={menuIcon} className={styles.menuIcon} />
              {props.unreadNotiCount > 0 && (
                <div className={styles.mark}>●</div>
              )}
            </IonMenuButton>
          </IonButtons>
          {!!props.onSearching && (
            <IonButton
              slot="end"
              fill="clear"
              size="small"
              onClick={props.onSearching}
            >
              <IonIcon
                icon={searchOutline}
                className={styles.icon}
                color="light"
              />
            </IonButton>
          )}
        </IonToolbar>
      </IonHeader>
    </>
  );
};

export default LoginHeader;
