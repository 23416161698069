import { IonItem } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router-dom";

import CategoryLabel from "../../atoms/CategoryLabel";
import { ICategory, IStudentGearBox } from "../../state";
import {
  boxesListPath,
  categorizedRealizationPath,
  realizationListPath,
} from "../../store/student";
import BoxLabel from "../../atoms/BoxLabel";

import styles from "./RealizationList.module.scss";
export interface RealizationListProps {
  categories: ICategory[];
  gearBoxes: IStudentGearBox[];
}

const RealizationList = (props: RealizationListProps) => {
  const history = useHistory();
  const totalrealizationCount =
    props.categories.find(category => category.id === null)
      ?.realization_count ?? 0;
  const categories = props.categories.filter(c => {
    return c.id;
  });

  return (
    <div className={styles.wrapper}>
      {props.gearBoxes && props.gearBoxes.length > 0 && (
        <div className={styles.box}>
          <BoxLabel
            label="BOX"
            onClick={() => {
              history.push(`${realizationListPath}${boxesListPath}`);
            }}
          />
        </div>
      )}
      <IonItem
        className={styles.all}
        detail
        onClick={() => history.push(`${categorizedRealizationPath}/0`)}
      >
        <div className={styles.categoryLabel}>
          <CategoryLabel
            selectable
            label="すべて"
            color="blue"
            count={totalrealizationCount}
          />
        </div>
      </IonItem>
      {categories &&
        categories.map(category => (
          <IonItem
            key={category.id}
            className={styles.categories}
            detail
            onClick={() => {
              history.push(`${categorizedRealizationPath}/${category.id}`);
            }}
          >
            <div className={styles.categoryLabel}>
              <CategoryLabel
                selectable
                label={category.name}
                color={category.color}
                count={category.realization_count}
              />
            </div>
          </IonItem>
        ))}
    </div>
  );
};

export default RealizationList;
