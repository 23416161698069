import {
  IonContent,
  IonPage,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import React from "react";
import { useLocation } from "react-router-dom";

import { useQueryParams } from "../../../hooks/useQueryParams";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import {
  clearRealizations,
  Context,
  fetchBoxRealization,
  fetchCategories,
  clearPagyInfo,
  clearSuccessMessage,
  createCategory,
  fetchComments,
  createComment,
  updateComment,
  deleteComment,
  fetchFeedbacks,
  createFeedback,
  updateFeedback,
  deleteFeedback,
  createReport,
  updateRealization,
  updateReadLastComment,
  updateReadLastFeedback,
  shareRealization,
  deleteRealization,
  clearComments,
  clearFeedbacks,
  executeRealization,
  fetchGearBoxes,
  fetchTeachers,
  fetchClasses,
  EXECUTE_REALIZATION_SUCCESS_MESSAGE,
  CREATE_CATEGORY_SUCCESS_MESSAGE,
  createRealization,
  createRealizationStampReaction,
} from "../../../store/student";
import { fetchIDFromPath } from "../../../libs/Util";
import {
  ICategory,
  IRealizationCreateParams,
  IRealizationImage,
  IRealizationUpdate,
  ReactableType,
  TNameStatus,
} from "../../../state";
import FabButton from "../../../atoms/FabButton";
import FiltableHeader from "../../../organisms/FiltableHeader";
import RealizationBoxesList from "../../../templates/RealizationBoxesList";
import styles from "../common.module.scss";
import type { Stamp } from "../../../libs/stamp";

const RealizationBoxPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const [isDisplaying, setIsDisplaying] = React.useState(false);
  const location = useLocation<{
    title: string;
    is_archived: boolean;
    name_status: TNameStatus;
  }>();

  const { type } = useQueryParams<{
    type: string;
  }>();

  const [isFirstLoad, setIsFirstLoad] = React.useState<boolean>(true);
  const [currentState, setCurrentState] = React.useState({
    searching: false,
    searchText: "",
  });
  const router = useIonRouter();

  const loadRealizations = (
    page: number,
    searchText = "",
    type: string | undefined,
  ) => {
    const converted: boolean = type === "will";
    const executed: boolean = type === "exp";
    const roots: boolean = type === "roots";
    dispatch(clearSuccessMessage());
    dispatch(
      peep(
        fetchBoxRealization(
          fetchIDFromPath(),
          page,
          searchText,
          converted,
          executed,
          roots,
        ),
      ),
    );
  };

  useIonViewWillEnter(() => {
    setIsFirstLoad(false);
    dispatch(fetchCategories());
    dispatch(fetchGearBoxes());
    dispatch(fetchTeachers());
    dispatch(fetchClasses());
    loadRealizations(1, currentState.searchText, type);
  });

  useIonViewDidEnter(() => {
    setIsDisplaying(true);
  });

  useIonViewWillLeave(() => {
    setIsDisplaying(false);
    dispatch(clearRealizations());
    dispatch(clearPagyInfo());
  });

  React.useEffect(() => {
    if (contextState.success_message === EXECUTE_REALIZATION_SUCCESS_MESSAGE) {
      setIsDisplaying(false);
      dispatch(clearSuccessMessage());
      dispatch(clearRealizations());
      dispatch(clearPagyInfo());
    }
    if (
      isDisplaying &&
      contextState.success_message === CREATE_CATEGORY_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchCategories()));
    }
  }, [contextState.success_message, dispatch, isDisplaying, peep]);

  const fetchBoxRealizationFunc = (page: number, type: string | undefined) => {
    loadRealizations(page, currentState.searchText, type);
  };

  const createCategoryFunc = React.useCallback(
    (category: ICategory) => {
      dispatch(peep(createCategory(category)));
    },
    [dispatch, peep],
  );

  const fetchCommentsFunc = React.useCallback(
    (realization_id: number) => {
      dispatch(fetchComments(realization_id));
    },
    [dispatch],
  );

  const createCommentFunc = React.useCallback(
    (realization_id: number, content: string) => {
      if (calling) return;
      dispatch(peep(createComment(realization_id, content)));
    },
    [calling, dispatch, peep],
  );

  const updateCommentFunc = React.useCallback(
    (comment_id: number, content: string) => {
      if (calling) return;
      dispatch(peep(updateComment(comment_id, content)));
    },
    [calling, dispatch, peep],
  );

  const deleteCommentFunc = React.useCallback(
    (comment_id: number) => {
      if (calling) return;
      dispatch(peep(deleteComment(comment_id)));
    },
    [calling, dispatch, peep],
  );

  const fetchFeedbacksFunc = React.useCallback(
    (realization_id: number) => {
      dispatch(fetchFeedbacks(realization_id));
    },
    [dispatch],
  );

  const createFeedbackFunc = React.useCallback(
    (realization_id: number, content: string) => {
      dispatch(peep(createFeedback(realization_id, content)));
    },
    [dispatch, peep],
  );

  const updateFeedbackFunc = React.useCallback(
    (feedback_id: number, content: string) => {
      dispatch(peep(updateFeedback(feedback_id, content)));
    },
    [dispatch, peep],
  );

  const deleteFeedbackFunc = React.useCallback(
    (feedback_id: number) => {
      dispatch(peep(deleteFeedback(feedback_id)));
    },
    [dispatch, peep],
  );

  const createReportFunc = React.useCallback(
    (params: { realization_id?: number; comment_id?: number }) => {
      dispatch(peep(createReport(params)));
    },
    [dispatch, peep],
  );

  const createRealizationFunc = React.useCallback(
    (
      realization: IRealizationCreateParams,
      submit_teacher_ids: number[],
      share_class_ids: number[],
      gearbox_id: number | null,
      image?: IRealizationImage,
    ) => {
      if (calling) return;
      dispatch(
        peep(
          createRealization(
            realization,
            submit_teacher_ids,
            share_class_ids,
            gearbox_id,
            image,
          ),
        ),
      );
    },
    [calling, dispatch, peep],
  );

  const updateRealizationFunc = React.useCallback(
    (
      realization: IRealizationUpdate,
      submitted_teacher_ids: number[],
      share_class_ids: number[],
    ) => {
      dispatch(
        peep(
          updateRealization(
            realization,
            submitted_teacher_ids,
            share_class_ids,
          ),
        ),
      );
    },
    [dispatch, peep],
  );

  const updateReadLastCommentFunc = React.useCallback(
    (realization_id: number, comment_id: number) => {
      dispatch(peep(updateReadLastComment(realization_id, comment_id)));
    },
    [dispatch, peep],
  );

  const updateReadLastFeedbackFunc = React.useCallback(
    (realization_id: number, feedback_id: number) => {
      dispatch(peep(updateReadLastFeedback(realization_id, feedback_id)));
    },
    [dispatch, peep],
  );

  const createStampReactionFunc = (
    realization_id: number,
    type: ReactableType,
    stamp: Stamp,
  ) => {
    dispatch(
      peep(
        createRealizationStampReaction(realization_id, {
          type,
          realization_stamp_id: stamp.id,
        }),
      ),
    );
  };

  const shareRealizationFunc = React.useCallback(
    (realization_id: number) => {
      dispatch(shareRealization(realization_id));
    },
    [dispatch],
  );

  const executeRealizationFunc = React.useCallback(
    (realization_id: number) => {
      dispatch(peep(executeRealization(realization_id)));
    },
    [dispatch, peep],
  );

  const deleteRealizationFunc = React.useCallback(
    (realization_id: number) => {
      dispatch(peep(deleteRealization(realization_id)));
    },
    [dispatch, peep],
  );

  const clearSuccessMessageFunc = React.useCallback(() => {
    dispatch(clearSuccessMessage());
  }, [dispatch]);

  const clearCommentsFunc = React.useCallback(() => {
    dispatch(clearComments());
  }, [dispatch]);

  const clearFeedbacksFunc = React.useCallback(() => {
    dispatch(clearFeedbacks());
  }, [dispatch]);

  React.useEffect(() => {
    !isFirstLoad && loadRealizations(1, currentState.searchText, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState.searchText, dispatch]);

  const redirectToWillFunc = React.useCallback(
    (pathname, category_id) => {
      router.push(`${pathname}/${category_id}?type=will`);
    },
    [router],
  );

  const redirectToExecuteListFunc = React.useCallback(
    (pathname, category_id) => {
      router.push(`${pathname}/${category_id}?type=exp`);
    },
    [router],
  );

  return (
    <IonPage>
      <FiltableHeader
        title={(location.state || { title: "" }).title ?? ""}
        isArchived={!!location.state?.is_archived}
        nameStatus={location.state?.name_status}
        unreadNotiCount={
          contextState.unread_comments_count +
          contextState.unread_notification_count +
          (contextState.student.read_gear_release_note ? 0 : 1)
        }
        searching={currentState.searching}
        searchText={currentState.searchText}
        setState={setCurrentState}
        goBack
      />
      <IonContent className={styles.wrapper}>
        <div className={styles.container}>
          <RealizationBoxesList
            student={contextState.student}
            boxId={fetchIDFromPath()}
            isDisplaying={isDisplaying}
            calling={calling}
            categories={contextState.categories}
            searchText={currentState.searchText}
            realizations={contextState.realizations}
            pagy_info={contextState.pagy_info}
            teachers={contextState.teachers}
            classes={contextState.classes}
            feedbacks={contextState.feedbacks}
            comments={contextState.comments}
            pathname={location.pathname}
            success_message={contextState.success_message}
            gearBoxes={contextState.gearBoxes}
            type={type}
            createWillRealization={createRealizationFunc}
            shareRealization={shareRealizationFunc}
            deleteRealization={deleteRealizationFunc}
            clearSuccessMessage={clearSuccessMessageFunc}
            clearComments={clearCommentsFunc}
            clearFeedbacks={clearFeedbacksFunc}
            fetchFeedbacks={fetchFeedbacksFunc}
            createCategory={createCategoryFunc}
            createFeedback={createFeedbackFunc}
            updateFeedback={updateFeedbackFunc}
            deleteFeedback={deleteFeedbackFunc}
            fetchComments={fetchCommentsFunc}
            createComment={createCommentFunc}
            updateComment={updateCommentFunc}
            deleteComment={deleteCommentFunc}
            createReport={createReportFunc}
            fetchBoxRealization={fetchBoxRealizationFunc}
            updateRealization={updateRealizationFunc}
            executeRealization={executeRealizationFunc}
            updateReadLastComment={updateReadLastCommentFunc}
            updateReadLastFeedback={updateReadLastFeedbackFunc}
            redirectToWillList={redirectToWillFunc}
            redirectToExecuteList={redirectToExecuteListFunc}
            createStampReaction={createStampReactionFunc}
          />
        </div>
      </IonContent>
      <FabButton />
    </IonPage>
  );
};

export default RealizationBoxPage;
