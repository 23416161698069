import {
  IonContent,
  IonPage,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import React from "react";
import { useLocation } from "react-router-dom";

import { useQueryParams } from "../../../hooks/useQueryParams";
import useThemeTutorial from "../../../hooks/useThemeTutorial";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import { fetchIDFromPath } from "../../../libs/Util";
import {
  ICategory,
  IRealizationCreateParams,
  IRealizationImage,
  IRealizationUpdate,
  ReactableType,
} from "../../../state";
import {
  clearRealizations,
  Context,
  deleteRealization,
  fetchCategories,
  fetchCategorizedRealization,
  clearPagyInfo,
  clearSuccessMessage,
  fetchGearBoxes,
  fetchTeachers,
  fetchClasses,
  fetchComments,
  fetchFeedbacks,
  createCategory,
  createComment,
  updateComment,
  createFeedback,
  deleteComment,
  updateRealization,
  createReport,
  deleteFeedback,
  updateFeedback,
  updateReadLastComment,
  updateReadLastFeedback,
  clearComments,
  clearFeedbacks,
  CREATE_CATEGORY_SUCCESS_MESSAGE,
  createRealization,
  executeRealization,
  categorizedRealizationPath,
  createRealizationStampReaction,
} from "../../../store/student";
import FabButton from "../../../atoms/FabButton";
import ThemeHeader from "../../../molecules/ThemeHeader";
import FiltableHeader from "../../../organisms/FiltableHeader";
import NavHeader from "../../../organisms/NavHeader";
import CategorizedRealizationList from "../../../templates/CategorizedRealizationList";
import styles from "../common.module.scss";
import { Stamp } from "../../../libs/stamp";

export interface RouteParams {
  id: string;
}

const CategorizedRealizationListPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const [category, setCategory] = React.useState<ICategory>();
  const location = useLocation();
  const [currentState, setCurrentState] = React.useState({
    searching: false,
    searchText: "",
  });
  const [isDisplaying, setIsDisplaying] = React.useState(false);
  const [isFirstLoad, setIsFirstLoad] = React.useState<boolean>(true);
  const { pathname } = useLocation();
  const { type } = useQueryParams<{
    type: string;
  }>();
  const router = useIonRouter();

  const [themeTutorial, updateThemeTutorial] = useThemeTutorial(
    contextState.geartheme,
    contextState.student.read_gear_tutorials,
    dispatch,
  );

  React.useEffect(() => {
    const c = contextState.categories.filter(category => {
      return category.id === fetchIDFromPath();
    })[0];
    if (c) {
      setCategory(c);
    } else {
      setCategory({
        id: 0,
        name: "すべて",
        color: "blue",
      });
    }
  }, [contextState.categories]);

  useIonViewWillEnter(() => {
    const converted: boolean = type === "will";
    const executed: boolean = type === "exp";
    const roots: boolean = type === "roots";
    setIsFirstLoad(false);
    dispatch(peep(fetchCategories()));
    dispatch(fetchGearBoxes());
    dispatch(fetchTeachers());
    dispatch(fetchClasses());
    location.search.includes("searching") &&
      setCurrentState({ searchText: "", searching: true });
    dispatch(
      peep(
        fetchCategorizedRealization(
          fetchIDFromPath(),
          currentState.searchText,
          1,
          converted,
          executed,
          roots,
        ),
      ),
    );
  }, [type]);

  useIonViewDidEnter(() => {
    setIsDisplaying(true);
  });

  React.useEffect(() => {
    if (
      isDisplaying &&
      contextState.success_message === CREATE_CATEGORY_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchCategories()));
    }
  }, [contextState.success_message, dispatch, isDisplaying, peep]);

  useIonViewWillLeave(() => {
    setIsDisplaying(false);
    dispatch(clearRealizations());
    dispatch(clearPagyInfo());
  });

  const deleteRealizationFunc = React.useCallback(
    (realization_id: number) => {
      dispatch(peep(deleteRealization(realization_id)));
    },
    [dispatch, peep],
  );

  const fetchCategorizedRealizationFunc = React.useCallback(
    (page: number, type?: string) => {
      const converted: boolean = type === "will";
      const executed: boolean = type === "exp";
      const roots: boolean = type === "roots";
      dispatch(clearSuccessMessage());
      dispatch(
        peep(
          fetchCategorizedRealization(
            fetchIDFromPath(),
            currentState.searchText,
            page,
            converted,
            executed,
            roots,
          ),
        ),
      );
    },
    [dispatch, peep, currentState.searchText],
  );

  React.useEffect(() => {
    const converted: boolean = type === "will";
    const executed: boolean = type === "exp";
    const roots: boolean = type === "roots";

    !isFirstLoad &&
      dispatch(
        peep(
          fetchCategorizedRealization(
            fetchIDFromPath(),
            currentState.searchText,
            1,
            converted,
            executed,
            roots,
          ),
        ),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState.searchText, dispatch, peep, type]);

  const createCategoryFunc = React.useCallback(
    (category: ICategory) => {
      dispatch(peep(createCategory(category)));
    },
    [dispatch, peep],
  );

  const fetchCommentsFunc = React.useCallback(
    (realization_id: number) => {
      dispatch(fetchComments(realization_id));
    },
    [dispatch],
  );

  const createCommentFunc = React.useCallback(
    (realization_id: number, content: string) => {
      if (calling) return;
      dispatch(peep(createComment(realization_id, content)));
    },
    [calling, dispatch, peep],
  );

  const updateCommentFunc = React.useCallback(
    (comment_id: number, content: string) => {
      if (calling) return;
      dispatch(peep(updateComment(comment_id, content)));
    },
    [calling, dispatch, peep],
  );

  const deleteCommentFunc = React.useCallback(
    (comment_id: number) => {
      if (calling) return;
      dispatch(peep(deleteComment(comment_id)));
    },
    [calling, dispatch, peep],
  );

  const fetchFeedbacksFunc = React.useCallback(
    (realization_id: number) => {
      dispatch(fetchFeedbacks(realization_id));
    },
    [dispatch],
  );

  const createFeedbackFunc = React.useCallback(
    (realization_id: number, content: string) => {
      dispatch(peep(createFeedback(realization_id, content)));
    },
    [dispatch, peep],
  );

  const updateFeedbackFunc = React.useCallback(
    (feedback_id: number, content: string) => {
      dispatch(peep(updateFeedback(feedback_id, content)));
    },
    [dispatch, peep],
  );

  const deleteFeedbackFunc = React.useCallback(
    (feedback_id: number) => {
      dispatch(peep(deleteFeedback(feedback_id)));
    },
    [dispatch, peep],
  );

  const createStampReactionFunc = (
    realization_id: number,
    type: ReactableType,
    stamp: Stamp,
  ) => {
    dispatch(
      peep(
        createRealizationStampReaction(realization_id, {
          type,
          realization_stamp_id: stamp.id,
        }),
      ),
    );
  };

  const createReportFunc = React.useCallback(
    (params: { realization_id?: number; comment_id?: number }) => {
      dispatch(peep(createReport(params)));
    },
    [dispatch, peep],
  );

  const createRealizationFunc = React.useCallback(
    (
      realization: IRealizationCreateParams,
      submit_teacher_ids: number[],
      share_class_ids: number[],
      gearbox_id: number | null,
      image?: IRealizationImage,
    ) => {
      if (calling) return;
      dispatch(
        peep(
          createRealization(
            realization,
            submit_teacher_ids,
            share_class_ids,
            gearbox_id,
            image,
          ),
        ),
      );
    },
    [calling, dispatch, peep],
  );

  const updateRealizationFunc = React.useCallback(
    (
      realization: IRealizationUpdate,
      submitted_teacher_ids: number[],
      share_class_ids: number[],
    ) => {
      dispatch(
        peep(
          updateRealization(
            realization,
            submitted_teacher_ids,
            share_class_ids,
          ),
        ),
      );
    },
    [dispatch, peep],
  );

  const updateReadLastCommentFunc = React.useCallback(
    (realization_id: number, comment_id: number) => {
      dispatch(peep(updateReadLastComment(realization_id, comment_id)));
    },
    [dispatch, peep],
  );

  const updateReadLastFeedbackFunc = React.useCallback(
    (realization_id: number, feedback_id: number) => {
      dispatch(peep(updateReadLastFeedback(realization_id, feedback_id)));
    },
    [dispatch, peep],
  );

  const clearSuccessMessageFunc = React.useCallback(() => {
    dispatch(clearSuccessMessage());
  }, [dispatch]);

  const clearCommentsFunc = React.useCallback(() => {
    dispatch(clearComments());
  }, [dispatch]);

  const clearFeedbacksFunc = React.useCallback(() => {
    dispatch(clearFeedbacks());
  }, [dispatch]);

  const executeRealizationFunc = React.useCallback(
    (realization_id: number) => {
      dispatch(peep(executeRealization(realization_id)));
    },
    [dispatch, peep],
  );

  const redirectToWillListFunc = React.useCallback(
    category_id => {
      dispatch(clearPagyInfo());
      dispatch(clearRealizations());
      router.push(`${categorizedRealizationPath}/${category_id}?type=will`);
    },
    [router, dispatch],
  );

  const redirectToExecuteListFunc = React.useCallback(
    category_id => {
      dispatch(clearPagyInfo());
      dispatch(clearRealizations());
      router.push(`${categorizedRealizationPath}/${category_id}?type=exp`);
    },
    [router, dispatch],
  );

  return (
    <IonPage>
      {category &&
        (fetchIDFromPath() === 0 ? (
          <FiltableHeader
            title={category.name}
            unreadNotiCount={
              contextState.unread_comments_count +
              contextState.unread_notification_count +
              (contextState.student.read_gear_release_note ? 0 : 1)
            }
            searching={currentState.searching}
            searchText={currentState.searchText}
            setState={setCurrentState}
            goBack
          />
        ) : (
          <NavHeader title={category.name} />
        ))}
      <IonContent className={styles.wrapper}>
        <div className={styles.container}>
          {contextState.geartheme && (
            <ThemeHeader
              geartheme={contextState.geartheme}
              updateThemeTutorial={updateThemeTutorial}
              disableCreate={!themeTutorial.completed}
            />
          )}
          <CategorizedRealizationList
            student={contextState.student}
            isDisplaying={isDisplaying}
            calling={calling}
            searchText={currentState.searchText}
            categories={contextState.categories}
            realizations={contextState.realizations}
            teachers={contextState.teachers}
            classes={contextState.classes}
            feedbacks={contextState.feedbacks}
            comments={contextState.comments}
            pathname={pathname}
            success_message={contextState.success_message}
            gearBoxes={contextState.gearBoxes}
            type={type}
            createWillRealization={createRealizationFunc}
            fetchCategorizedRealization={fetchCategorizedRealizationFunc}
            clearSuccessMessage={clearSuccessMessageFunc}
            clearComments={clearCommentsFunc}
            clearFeedbacks={clearFeedbacksFunc}
            fetchFeedbacks={fetchFeedbacksFunc}
            createCategory={createCategoryFunc}
            createFeedback={createFeedbackFunc}
            updateFeedback={updateFeedbackFunc}
            deleteFeedback={deleteFeedbackFunc}
            fetchComments={fetchCommentsFunc}
            createComment={createCommentFunc}
            updateComment={updateCommentFunc}
            deleteComment={deleteCommentFunc}
            createReport={createReportFunc}
            deleteRealization={deleteRealizationFunc}
            updateRealization={updateRealizationFunc}
            executeRealization={executeRealizationFunc}
            updateReadLastComment={updateReadLastCommentFunc}
            updateReadLastFeedback={updateReadLastFeedbackFunc}
            redirectToWillList={redirectToWillListFunc}
            redirectToExecuteList={redirectToExecuteListFunc}
            pagy_info={contextState.pagy_info}
            createStampReaction={createStampReactionFunc}
          />
        </div>
        <FabButton />
      </IonContent>
    </IonPage>
  );
};

export default CategorizedRealizationListPage;
