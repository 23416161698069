import { IonContent, IonPage, useIonViewWillEnter } from "@ionic/react";
import React from "react";

import LoginHeader from "../../../organisms/LoginHeader";
import { Context, fetchCommentNotifications } from "../../../store/student";
import CommentNotifications from "../../../templates/CommentNotifications";
import styles from "../common.module.scss";

const CommentNotificationsPage = () => {
  const { dispatch, contextState } = React.useContext(Context);

  useIonViewWillEnter(() => {
    dispatch(fetchCommentNotifications());
  });

  return (
    <IonPage>
      <LoginHeader
        title="通知"
        unreadNotiCount={
          contextState.unread_comments_count +
          contextState.unread_notification_count +
          (contextState.student.read_gear_release_note ? 0 : 1)
        }
      />
      <IonContent className={styles.wrapper}>
        <div className={styles.container}>
          <CommentNotifications
            commentNotifications={contextState.commentNotifications}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CommentNotificationsPage;
