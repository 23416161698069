import React from "react";
import {
  IonIcon,
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";

import {
  analyzePath,
  clearSuccessMessage,
  Context,
  realizationListPath,
  subscribeRealizationKlasses,
  timelinePath,
} from "../../store/student";

import peopleIcon from "./../../assets/icons/people.svg";
import realizationIcon from "./../../assets/icons/realization.svg";
import analyzeIcon from "./../../assets/icons/analyze.svg";
import styles from "./Tab.module.scss";

const Tab = (props: any) => {
  const children = props.children as React.ReactElement;
  const { dispatch, contextState } = React.useContext(Context);
  const tabsRef = React.useRef<any>(null);

  React.useEffect(() => {
    let timer: any = null;
    if (contextState.session_id) {
      timer = setInterval(() => dispatch(subscribeRealizationKlasses()), 60000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [dispatch, contextState.session_id]);

  React.useEffect(() => {
    if (tabsRef.current) {
      const { tabBarRef } = tabsRef.current;
      dispatch(clearSuccessMessage());
      if (tabBarRef.current) {
        tabBarRef.current.state.tabs.timeline.currentHref = timelinePath;
        tabBarRef.current.state.tabs.realizationList.currentHref =
          realizationListPath;
        tabBarRef.current.state.tabs.analyze.currentHref = analyzePath;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsRef.current?.ionTabContextState.activeTab]);

  return (
    <IonTabs ref={tabsRef} className={styles.tabs}>
      {children}
      <IonTabBar slot="bottom" className={styles.tabBar} mode="ios">
        <IonTabButton
          tab="timeline"
          href={timelinePath}
          className={styles.button}
        >
          {contextState.unread_realization_klass_count > 0 && (
            <span className={styles.badge} />
          )}
          <IonIcon icon={peopleIcon} />
          <IonLabel>
            <div className={styles.tabLabel}>タイムライン</div>
          </IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="realizationList"
          href={realizationListPath}
          className={styles.button}
        >
          <IonIcon icon={realizationIcon} />
          <IonLabel>
            <div className={styles.tabLabel}>マイストック</div>
          </IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="analyze"
          href={analyzePath}
          className={styles.button}
        >
          <IonIcon icon={analyzeIcon} />
          <IonLabel>
            <div className={styles.tabLabel}>分析</div>
          </IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default Tab;
