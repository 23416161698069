export enum NextActionKind {
  KIND_1 = 1,
  KIND_2 = 2,
  KIND_3 = 3,
}

export const NextActionKinds = [
  {
    kind: NextActionKind.KIND_1,
    title:
      "7つの素養に書いた経験を読み返し「自分の強み」とは何か考えて書いてみよう。",
    shortTitle: "7つの素養を振り返っての「自分の強み」",
    placeholder:
      "例：部活で「どんな動きができるようになるのか」を考えてトレーニングするようにしたら、やる気も出たし動きも良くなった。言われたことに対して、自分なりの目的や意義を見出して取り組めるところが自分の強みだと思う。",
  },
  {
    kind: NextActionKind.KIND_3,
    title: "将来の夢、もしくは将来どんな人間になりたいかを考えて書いてみよう。",
    shortTitle: "将来の夢、もしくは将来どんな人間になりたいか",
    placeholder:
      "例：服のデザイナーになって、自分のデザインした服を多くの人に着てほしい。",
  },
  {
    kind: NextActionKind.KIND_2,
    title:
      "そのために必要な力や克服すべき課題はなんだろう？そしてそれを実現するために、今からできることはなんだろう？具体的な行動をイメージして書いてみよう。",
    shortTitle: "将来の目標実現に向けての課題や必要な経験",
    placeholder:
      "例：ファッションの専門学校に入るためには筆記試験があるので、特に数学や英語は今より頑張らないといけない。次のテストに向けて目標点数と計画表を作り、その通りに勉強してみる。",
  },
];
