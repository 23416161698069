import {
  IonContent,
  IonPage,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonRouter,
} from "@ionic/react";
import React from "react";
import { useLocation } from "react-router-dom";

import useWaitApiCall from "../../../hooks/useWaitApiCall";
import { fetchIDFromPath } from "../../../libs/Util";
import {
  ICategory,
  IRealizationCreateParams,
  IRealizationImage,
  IRealizationUpdate,
} from "../../../state";
import {
  categorizedRealizationPath,
  clearComments,
  clearFeedbacks,
  clearSuccessMessage,
  Context,
  createCategory,
  createComment,
  createFeedback,
  createReport,
  CREATE_CATEGORY_SUCCESS_MESSAGE,
  CREATE_COMMENT_SUCCESS_MESSAGE,
  CREATE_FEEDBACK_SUCCESS_MESSAGE,
  deleteComment,
  deleteFeedback,
  deleteRealization,
  DELETE_COMMENT_SUCCESS_MESSAGE,
  DELETE_FEEDBACK_SUCCESS_MESSAGE,
  fetchCategories,
  fetchClasses,
  fetchComments,
  fetchFeedbacks,
  fetchRealization,
  fetchTeachers,
  updateComment,
  updateFeedback,
  updateReadLastComment,
  updateReadLastFeedback,
  updateRealization,
  UPDATE_COMMENT_SUCCESS_MESSAGE,
  UPDATE_FEEDBACK_SUCCESS_MESSAGE,
  DELETE_REALIZATION_SUCCESS_MESSAGE,
  fetchGearBoxes,
  UPDATE_REALIZATION_SUCCESS_MESSAGE,
  createRealization,
  CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE,
  executeRealization,
  EXECUTE_REALIZATION_SUCCESS_MESSAGE,
  clearRealizations,
  clearPagyInfo,
  createRealizationStampReaction,
  CREATE_REALIZATION_STAMP_REACTION_SUCCESS,
} from "../../../store/student";
import RealizationDetail from "../../../templates/RealizationDetail";
import NavHeader from "../../../organisms/NavHeader";
import styles from "../common.module.scss";
import type { Stamp } from "../../../libs/stamp";

const RealizationDetailPage = (props: { history: any }) => {
  const { dispatch, contextState } = React.useContext(Context);
  const router = useIonRouter();
  const [calling, peep] = useWaitApiCall(contextState);
  const { pathname } = useLocation();
  const [isDisplaying, setIsDisplaying] = React.useState(false);

  useIonViewWillEnter(() => {
    setIsDisplaying(true);
    dispatch(fetchRealization(fetchIDFromPath()));
    dispatch(fetchCategories());
    dispatch(fetchGearBoxes());
    dispatch(fetchTeachers());
    dispatch(fetchClasses());
    dispatch(fetchComments(fetchIDFromPath()));
    dispatch(fetchFeedbacks(fetchIDFromPath()));
  });

  useIonViewDidLeave(() => {
    setIsDisplaying(false);
    dispatch(clearComments());
    dispatch(clearFeedbacks());
  });

  React.useEffect(() => {
    const categoryIDs = contextState.realization?.categories?.map(
      category => category.id,
    );
    if (
      contextState.success_message === CREATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === UPDATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === DELETE_COMMENT_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchComments(fetchIDFromPath())));
    }
    if (
      contextState.success_message === CREATE_FEEDBACK_SUCCESS_MESSAGE ||
      contextState.success_message === UPDATE_FEEDBACK_SUCCESS_MESSAGE ||
      contextState.success_message === DELETE_FEEDBACK_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchFeedbacks(fetchIDFromPath())));
    }
    if (
      contextState.success_message === CREATE_REALIZATION_STAMP_REACTION_SUCCESS
    ) {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchComments(fetchIDFromPath())));
      dispatch(peep(fetchFeedbacks(fetchIDFromPath())));
    }
    if (contextState.success_message === CREATE_CATEGORY_SUCCESS_MESSAGE) {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchCategories()));
    }
    if (
      contextState.success_message ===
        CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE &&
      isDisplaying
    ) {
      dispatch(clearSuccessMessage());
      props.history.push(
        `${categorizedRealizationPath}/${categoryIDs?.[0] ?? 0}?type="will"`,
      );
    }
    if (
      contextState.success_message === EXECUTE_REALIZATION_SUCCESS_MESSAGE &&
      isDisplaying
    ) {
      dispatch(clearSuccessMessage());
      dispatch(clearRealizations());
      dispatch(clearPagyInfo());
      props.history.push(
        `${categorizedRealizationPath}/${categoryIDs?.[0] ?? 0}?type="exp"`,
      );
    }
    if (
      contextState.success_message === UPDATE_REALIZATION_SUCCESS_MESSAGE &&
      isDisplaying
    ) {
      dispatch(clearSuccessMessage());
      props.history.push(
        `${categorizedRealizationPath}/${categoryIDs?.[0] ?? 0}`,
      );
    }
    if (
      contextState.success_message === DELETE_REALIZATION_SUCCESS_MESSAGE &&
      isDisplaying
    ) {
      dispatch(clearSuccessMessage());
      router.canGoBack()
        ? router.goBack()
        : router.push(
            `${categorizedRealizationPath}/${categoryIDs?.[0] ?? 0}`,
            "back",
            "pop",
          );
    }
  }, [
    dispatch,
    router,
    contextState.success_message,
    contextState.realization,
    props.history,
    peep,
    isDisplaying,
  ]);

  const deleteRealizationFunc = React.useCallback(
    (realization_id: number) => {
      dispatch(peep(deleteRealization(realization_id)));
    },
    [dispatch, peep],
  );

  const createCategoryFunc = React.useCallback(
    (category: ICategory) => {
      dispatch(peep(createCategory(category)));
    },
    [dispatch, peep],
  );

  const createCommentFunc = React.useCallback(
    (realization_id: number, content: string) => {
      if (calling) return;
      dispatch(peep(createComment(realization_id, content)));
    },
    [calling, dispatch, peep],
  );

  const updateCommentFunc = React.useCallback(
    (comment_id: number, content: string) => {
      if (calling) return;
      dispatch(peep(updateComment(comment_id, content)));
    },
    [calling, dispatch, peep],
  );

  const deleteCommentFunc = React.useCallback(
    (comment_id: number) => {
      if (calling) return;
      dispatch(peep(deleteComment(comment_id)));
    },
    [calling, dispatch, peep],
  );

  const createFeedbackFunc = React.useCallback(
    (realization_id: number, content: string) => {
      dispatch(peep(createFeedback(realization_id, content)));
    },
    [dispatch, peep],
  );

  const updateFeedbackFunc = React.useCallback(
    (feedback_id: number, content: string) => {
      dispatch(peep(updateFeedback(feedback_id, content)));
    },
    [dispatch, peep],
  );

  const deleteFeedbackFunc = React.useCallback(
    (feedback_id: number) => {
      dispatch(peep(deleteFeedback(feedback_id)));
    },
    [dispatch, peep],
  );

  const createReportFunc = React.useCallback(
    (params: { realization_id?: number; comment_id?: number }) => {
      dispatch(peep(createReport(params)));
    },
    [dispatch, peep],
  );

  const createRealizationFunc = React.useCallback(
    (
      realization: IRealizationCreateParams,
      submit_teacher_ids: number[],
      share_class_ids: number[],
      gearbox_id: number | null,
      image?: IRealizationImage,
    ) => {
      if (calling) return;
      dispatch(
        peep(
          createRealization(
            realization,
            submit_teacher_ids,
            share_class_ids,
            gearbox_id,
            image,
          ),
        ),
      );
    },
    [calling, dispatch, peep],
  );

  const updateRealizationFunc = React.useCallback(
    (
      realization: IRealizationUpdate,
      submitted_teacher_ids: number[],
      share_class_ids: number[],
    ) => {
      dispatch(
        peep(
          updateRealization(
            realization,
            submitted_teacher_ids,
            share_class_ids,
          ),
        ),
      );
    },
    [dispatch, peep],
  );

  const executeRealizationFunc = React.useCallback(
    (realization_id: number) => {
      dispatch(peep(executeRealization(realization_id)));
    },
    [dispatch, peep],
  );

  const updateReadLastCommentFunc = React.useCallback(
    (realization_id: number, comment_id: number) => {
      dispatch(peep(updateReadLastComment(realization_id, comment_id)));
    },
    [dispatch, peep],
  );

  const updateReadLastFeedbackFunc = React.useCallback(
    (realization_id: number, feedback_id: number) => {
      dispatch(peep(updateReadLastFeedback(realization_id, feedback_id)));
    },
    [dispatch, peep],
  );

  const createStampReactionFunc = (realization_id: number, stamp: Stamp) => {
    dispatch(
      peep(
        createRealizationStampReaction(realization_id, {
          type: "comment",
          realization_stamp_id: stamp.id,
        }),
      ),
    );
  };

  const clearSuccessMessageFunc = React.useCallback(() => {
    dispatch(clearSuccessMessage());
  }, [dispatch]);

  return (
    <IonPage>
      <NavHeader title="詳細" />
      <IonContent className={styles.wrapper}>
        {contextState.realization?.id === fetchIDFromPath() ? (
          <div className={styles.container}>
            <RealizationDetail
              calling={calling}
              student={contextState.student}
              realization={contextState.realization}
              categories={contextState.categories}
              teachers={contextState.teachers}
              classes={contextState.classes}
              feedbacks={contextState.feedbacks}
              comments={contextState.comments}
              pathname={pathname}
              success_message={contextState.success_message}
              gearBoxes={contextState.gearBoxes}
              clearSuccessMessage={clearSuccessMessageFunc}
              createCategory={createCategoryFunc}
              createFeedback={createFeedbackFunc}
              updateFeedback={updateFeedbackFunc}
              deleteFeedback={deleteFeedbackFunc}
              createComment={createCommentFunc}
              updateComment={updateCommentFunc}
              deleteComment={deleteCommentFunc}
              createReport={createReportFunc}
              createWillRealization={createRealizationFunc}
              deleteRealization={deleteRealizationFunc}
              updateRealization={updateRealizationFunc}
              executeRealization={executeRealizationFunc}
              updateReadLastComment={updateReadLastCommentFunc}
              updateReadLastFeedback={updateReadLastFeedbackFunc}
              createStampReaction={(realization_id, _type, stamp) =>
                createStampReactionFunc(realization_id, stamp)
              }
            />
          </div>
        ) : null}
      </IonContent>
    </IonPage>
  );
};

export default RealizationDetailPage;
