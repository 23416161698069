import {
  IonButton,
  IonInput,
  IonItemGroup,
  IonLabel,
  IonModal,
} from "@ionic/react";
import React, { useEffect } from "react";

import ColorPicker from "../../organisms/ColorPicker";
import { clearErrorMessage, Context } from "../../store/student";
import Toast from "../Toast";

import styles from "./NewCategoryForm.module.scss";

export interface NewCategoryFormProps {
  values: { [key: string]: any };
  visible: boolean;
  calling?: boolean;
  update: (args: { [key: string]: any }) => any;
  createCategory(category: { name: string; color: string }): void;
  updateCategory?: (
    category_id: number,
    category: { name: string; color: string },
  ) => void;
}

const NewCategoryForm = (props: NewCategoryFormProps) => {
  const { dispatch, contextState } = React.useContext(Context);
  const [state, setState] = React.useState({
    showToast: false,
    message: "",
  });

  useEffect(() => {
    if (!!contextState.error) {
      setState({
        showToast: true,
        message: contextState.error,
      });
      dispatch(clearErrorMessage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextState.error]);

  useEffect(() => {
    if (!!contextState.success_message) {
      props.update({
        showNewCategoryForm: false,
        name: "",
        color: "",
        editCategory: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextState.success_message]);

  return (
    <IonModal
      className={styles.wrapper}
      isOpen={props.visible}
      onDidDismiss={() => props.update({ showNewCategoryForm: false })}
      canDismiss={true}
    >
      <div className={styles.modalItems}>
        <Toast
          type="danger"
          showToast={state.showToast}
          onClose={() => setState({ showToast: false, message: "" })}
          message={state.message}
        />
        <IonItemGroup>
          <IonLabel color="light" className={styles.label}>
            {props.values.editCategory
              ? "活かし先を編集"
              : "活かし先を新規作成"}
          </IonLabel>
          <div className={styles.inputArea}>
            <IonInput
              value={props.values.name}
              className={styles.input}
              placeholder="活かし先を記入"
              onIonChange={e => props.update({ name: e.detail.value })}
            />
          </div>
          <ColorPicker colorValue={props.values.color} update={props.update} />
          <div className={styles.buttonArea}>
            <IonButton
              className={styles.cancelButton}
              fill="outline"
              strong
              disabled={props.calling}
              onClick={() =>
                props.update({
                  showNewCategoryForm: false,
                  name: "",
                  color: "",
                  editCategory: false,
                })
              }
            >
              キャンセル
            </IonButton>
            <IonButton
              className={styles.button}
              fill="outline"
              strong
              disabled={props.calling}
              onClick={() => {
                if (!props.values.name || !props.values.color) {
                  setState({
                    showToast: true,
                    message: "活かし先と色を入力してください",
                  });
                  return;
                }
                if (props.values.editCategory) {
                  props.updateCategory?.(props.values.category_id, {
                    name: props.values.name,
                    color: props.values.color,
                  });
                } else {
                  props.createCategory({
                    name: props.values.name,
                    color: props.values.color,
                  });
                }
              }}
            >
              保存
            </IonButton>
          </div>
        </IonItemGroup>
      </div>
    </IonModal>
  );
};

export default NewCategoryForm;
