import React from "react";
import Slider, { Settings } from "react-slick";
import { IonIcon } from "@ionic/react";

import Portal from "../../../atoms/Portal";
import { IReleaseAd } from "../../../state";

import styles from "./ReleaseAdModal.module.scss";
import closeIcon from "./../../../assets/icons/close.svg";
import nextIcon from "./../../../assets/icons/next.svg";
import prevIcon from "./../../../assets/icons/prev.svg";

export interface ReleaseAdModalProps {
  isOpen: boolean;
  release_ads: IReleaseAd[];
  closeModal: () => void;
  readReleaseAds: (release_ids: number[]) => void;
}

const ReleaseAdModal: React.FC<ReleaseAdModalProps> = ({
  isOpen,
  release_ads,
  closeModal,
  readReleaseAds,
}) => {
  const slider = React.useRef<Slider>(null);
  const backdrop: React.MutableRefObject<HTMLDivElement | null> =
    React.useRef(null);

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipe: true,
  };

  React.useEffect(() => {
    if (backdrop.current) {
      backdrop.current.addEventListener("touchstart", e => e.stopPropagation());
      backdrop.current.addEventListener("mousedown", e => e.stopPropagation());
    }
  }, [isOpen]);
  React.useEffect(() => {
    if (isOpen) {
      const release_ad_ids = release_ads.map(release_ad => release_ad.id);
      readReleaseAds(release_ad_ids);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, release_ads]);

  return isOpen ? (
    <Portal>
      <div className={`${styles.modal}`}>
        <div className={`${styles.backdrop}`} ref={backdrop} />
        <div className={styles.slide}>
          <Slider ref={slider} {...settings}>
            {release_ads.map((release_ad, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.image}
                  style={{ backgroundImage: `url(${release_ad.image_url})` }}
                />
                {index === release_ads.length - 1 && (
                  <button
                    className={styles.item__button_close}
                    onClick={() => closeModal()}
                  >
                    <IonIcon
                      icon={closeIcon}
                      slot="start"
                      className={styles.item__icon_close}
                    />
                  </button>
                )}
                <button
                  className={`${styles.item__button_prev} ${
                    index === 3 && styles.turquoise
                  } ${index === 4 && styles.dodger_blue}`}
                  onClick={() => slider.current?.slickPrev()}
                  disabled={index === 0}
                >
                  <IonIcon
                    icon={prevIcon}
                    slot="start"
                    className={styles.item__icon_control}
                  />
                </button>
                <button
                  className={`${styles.item__button_next} ${
                    index === 3 && styles.turquoise
                  } ${index === 4 && styles.dodger_blue}`}
                  onClick={() => slider.current?.slickNext()}
                  disabled={index === release_ads.length - 1}
                >
                  <IonIcon
                    icon={nextIcon}
                    slot="start"
                    className={styles.item__icon_control}
                  />
                </button>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </Portal>
  ) : null;
};

export default ReleaseAdModal;
