import React from "react";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import Portal from "../../atoms/Portal";

import styles from "./ImageDetailModal.module.scss";

export interface ImageDetailModalProps {
  show: boolean;
  imageUrl: string;
  imageName: string;
  onClose: () => void;
}

const ImageDetailModal: React.FC<ImageDetailModalProps> = ({
  show,
  imageUrl,
  imageName,
  onClose,
}) => {
  React.useEffect(() => {
    if (show && onClose) {
      const handleEscKey = (event: KeyboardEvent) => {
        event.key === "Escape" && onClose();
      };
      window.addEventListener("keydown", handleEscKey);
      return () => window.removeEventListener("keydown", handleEscKey);
    }
  }, [onClose, show]);

  return show ? (
    <Portal>
      <div className={styles.modal}>
        <div className={styles.backdrop}>
          <button onClick={onClose}>
            <IonIcon icon={closeOutline} className={styles.closeIcon} />
          </button>
          <img src={imageUrl} alt={imageName} />
        </div>
      </div>
    </Portal>
  ) : null;
};

export default ImageDetailModal;
