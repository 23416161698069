import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  IonContent,
  IonPage,
  useIonViewWillLeave,
  useIonViewWillEnter,
} from "@ionic/react";

import {
  Context,
  fetchComments,
  createReport,
  deleteComment,
  updateComment,
  createComment,
  removeFavorite,
  addFavorite,
  clearComments,
  fetchCategories,
  clearSuccessMessage,
  clearSuccessResult,
  CREATE_COMMENT_SUCCESS_MESSAGE,
  UPDATE_COMMENT_SUCCESS_MESSAGE,
  DELETE_COMMENT_SUCCESS_MESSAGE,
  fetchTeachers,
  CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE,
  CREATE_REALIZATION_SUCCESS_MESSAGE,
  CREATE_CATEGORY_SUCCESS_MESSAGE,
  categorizedRealizationPath,
  createRealization,
  createCategory,
  fetchClasses,
  FETCH_REALIZATION_KLASSES_FILTERED_SUCCESS_MESSAGE,
  fetchRealizationKlassesFiltered,
  clearRealizationKlassesFiltered,
  createRealizationStampReaction,
  CREATE_REALIZATION_STAMP_REACTION_SUCCESS,
} from "../../../store/student";
import styles from "../common.module.scss";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import {
  IRealizationCreateParams,
  IRealizationImage,
  ReactableType,
} from "../../../state";
import NavHeader from "../../../organisms/NavHeader";
import SelectActionRealization from "../../../templates/TimelineFiltered";
import { TimelineSelectAction } from "../../../molecules/student/ModalSelectActions";
import type { Stamp } from "../../../libs/stamp";

const TimelineFilteredPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const params = useParams<{ action: TimelineSelectAction }>();
  const { pathname } = useLocation();
  const history = useHistory();
  const [isDisplaying, setIsDisplaying] = React.useState(false);

  const title = React.useMemo(() => {
    return params.action === TimelineSelectAction.HAS_DERIVATIVE
      ? "引用したストック"
      : params.action === TimelineSelectAction.IS_FAVORITE
      ? "いいねしたストック"
      : "コメントしたストック";
  }, [params.action]);

  const contentRef = React.useRef<HTMLIonContentElement>(null);

  React.useEffect(() => {
    const latestEntry = contextState.realization_klasses[0];
    if (
      latestEntry &&
      contextState.success_message ===
        FETCH_REALIZATION_KLASSES_FILTERED_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
    }
  }, [
    dispatch,
    contextState.success_message,
    contextState.realization_klasses,
  ]);

  React.useEffect(() => {
    if (
      contextState.success_message === CREATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === UPDATE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === DELETE_COMMENT_SUCCESS_MESSAGE ||
      contextState.success_message === CREATE_REALIZATION_STAMP_REACTION_SUCCESS
    ) {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchComments(contextState.success_result["id"])));
      dispatch(clearSuccessResult());
      setTimeout(() => {
        contentRef.current?.scrollToBottom(500);
      }, 500);
    }
  }, [
    dispatch,
    contextState.success_message,
    contextState.success_result,
    peep,
  ]);

  const addFavoriteFunc = React.useCallback(
    (id: number) => {
      if (calling) return;
      dispatch(peep(addFavorite(id)));
    },
    [calling, dispatch, peep],
  );
  const removeFavoriteFunc = React.useCallback(
    (id: number) => {
      if (calling) return;
      dispatch(peep(removeFavorite(id)));
    },
    [calling, dispatch, peep],
  );

  const fetchRealizationKlassesForReadMore = () => {
    const list = contextState.realization_klasses;
    const prev_time = new Date(list[list.length - 1].created_at).toISOString();
    dispatch(peep(fetchRealizationKlassesFiltered(prev_time, params.action)));
  };

  const clearRealizationKlassesFilteredFunc = React.useCallback(() => {
    dispatch(clearRealizationKlassesFiltered());
  }, [dispatch]);

  const createCommentFunc = React.useCallback(
    (realization_id: number, content: string) => {
      dispatch(peep(createComment(realization_id, content)));
    },
    [dispatch, peep],
  );

  const updateCommentFunc = React.useCallback(
    (comment_id: number, content: string) => {
      if (calling) return;
      dispatch(peep(updateComment(comment_id, content)));
    },
    [calling, dispatch, peep],
  );

  const deleteCommentFunc = React.useCallback(
    (comment_id: number) => {
      if (calling) return;
      dispatch(peep(deleteComment(comment_id)));
    },
    [calling, dispatch, peep],
  );

  const createReportFunc = React.useCallback(
    (params: { realization_id?: number; comment_id?: number }) => {
      if (calling) return;
      dispatch(peep(createReport(params)));
    },
    [calling, dispatch, peep],
  );

  const fetchCommentsFunc = React.useCallback(
    (realization_id: number) => {
      dispatch(fetchComments(realization_id));
    },
    [dispatch],
  );

  const createRealizationFunc = React.useCallback(
    (
      realization: IRealizationCreateParams,
      submit_teacher_ids: number[],
      share_class_ids: number[],
      gearbox_id: number | null,
      image?: IRealizationImage,
    ) => {
      if (calling) return;
      dispatch(
        peep(
          createRealization(
            realization,
            submit_teacher_ids,
            share_class_ids,
            gearbox_id,
            image,
          ),
        ),
      );
    },
    [calling, dispatch, peep],
  );

  const createCategoryFunc = React.useCallback(
    (category: { name: string; color: string }) => {
      if (calling) return;
      dispatch(peep(createCategory(category)));
    },
    [calling, dispatch, peep],
  );

  const clearOldCommentsFunc = React.useCallback(() => {
    dispatch(clearComments());
  }, [dispatch]);

  const createStampReactionFunc = (
    realization_id: number,
    type: ReactableType,
    stamp: Stamp,
  ) => {
    dispatch(
      peep(
        createRealizationStampReaction(realization_id, {
          type,
          realization_stamp_id: stamp.id,
        }),
      ),
    );
  };

  useIonViewWillEnter(() => {
    dispatch(fetchCategories());
    dispatch(fetchClasses());
    dispatch(fetchTeachers());
    dispatch(peep(fetchRealizationKlassesFiltered(null, params.action)));
    setIsDisplaying(true);
  }, [params.action]);

  useIonViewWillLeave(() => {
    setIsDisplaying(false);
    clearRealizationKlassesFilteredFunc();
  });

  React.useEffect(() => {
    if (calling) return;
    const categoryIDs = contextState.realization?.categories?.map(
      category => category.id,
    );
    if (contextState.success_message === CREATE_CATEGORY_SUCCESS_MESSAGE) {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchCategories()));
    } else if (
      contextState.success_message === CREATE_REALIZATION_SUCCESS_MESSAGE ||
      contextState.success_message ===
        CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      history.push(`${categorizedRealizationPath}/${categoryIDs?.[0] ?? 0}`);
    }
  }, [
    dispatch,
    contextState.success_message,
    contextState.realization,
    history,
    calling,
    peep,
  ]);

  return (
    <IonPage>
      <NavHeader title={title} />
      <IonContent
        className={`${styles.wrapper} ${styles.bgWhileBase}`}
        ref={contentRef}
      >
        <div className={styles.container}>
          <SelectActionRealization
            isDisplaying={isDisplaying}
            calling={calling}
            current_id={contextState.student.id}
            classes={contextState.classes}
            realization_klasses={contextState.realization_klasses}
            comments={contextState.comments}
            categories={contextState.categories}
            timeline_pagination={contextState.timeline_pagination}
            success_message={contextState.success_message}
            pathname={pathname}
            teachers={contextState.teachers}
            gearBoxes={contextState.gearBoxes}
            student_id={contextState.student.id}
            addFavorite={addFavoriteFunc}
            removeFavorite={removeFavoriteFunc}
            readMore={fetchRealizationKlassesForReadMore}
            clearRealizationKlassesFiltered={
              clearRealizationKlassesFilteredFunc
            }
            createRealization={createRealizationFunc}
            createCategory={createCategoryFunc}
            createReport={createReportFunc}
            createComment={createCommentFunc}
            updateComment={updateCommentFunc}
            deleteComment={deleteCommentFunc}
            fetchComments={fetchCommentsFunc}
            clearOldComments={clearOldCommentsFunc}
            createStampReaction={createStampReactionFunc}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TimelineFilteredPage;
