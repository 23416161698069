import React from "react";
import {
  IonBadge,
  IonButton,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  useIonViewDidEnter,
} from "@ionic/react";
import clsx from "clsx";

import CommentList from "../../organisms/CommentList";
import {
  IComment,
  IFeedback,
  IOnClickStudentAvatarParams,
  IRealization,
  ReactableType,
} from "../../state";
import { parseLocationQueryParams, setScrollTop } from "../../libs/Util";
import Toast from "../../molecules/Toast";
import StampSelector from "../../molecules/StampSelector";
import type { Stamp } from "../../libs/stamp";

import styles from "./CommentFeedbackTabs.module.scss";

const TEXT_AREA_MIN_HEIGHT = 22;

export interface CommentFeedbackTabsProps {
  calling?: boolean;
  current_student_id?: number;
  current_teacher_id?: number;
  realization: IRealization;
  comments: IComment[];
  feedbacks: IFeedback[];
  createFeedback: (realization_id: number, content: string) => void;
  updateFeedback: (feedback_id: number, content: string) => void;
  deleteFeedback: (feedback_id: number) => void;
  createComment: (realization_id: number, content: string) => void;
  updateComment: (comment_id: number, content: string) => void;
  deleteComment: (comment_id: number) => void;
  updateReadLastComment?: (realization_id: number, comment_id: number) => void;
  updateReadLastFeedback?: (
    realization_id: number,
    feedback_id: number,
  ) => void;
  createReport?: (params: {
    realization_id?: number;
    comment_id?: number;
  }) => void;
  isTeacher?: boolean;
  onAvatarClick?: (params: IOnClickStudentAvatarParams) => void;
  createStampReaction?: (
    realization_id: number,
    type: ReactableType,
    stamp: Stamp,
  ) => void;
  // hasTab というプロパティ名は DashboardSharedRealization からの借用だが、タブがページ上にあるかどうかではなく、DOMツリーの外にあるかどうかである
  // DOMツリーの外にタブがある場合、その高さ分入力フォームの位置をずらさないといけない
  // 逆に内側にタブがある場合は、包含ブロックに対して位置が決まるので、ずらす必要がない
  hasTab?: boolean;
}

type ListType = ReactableType;
type StateProps = {
  listType: ListType;
  content: string;
  editTargetID: number;
  showToast: boolean;
};

export const CommentFeedbackTabs = (props: CommentFeedbackTabsProps) => {
  const [values, updateValues] = React.useState<StateProps>({
    listType: props.isTeacher ? "feedback" : "comment",
    content: "",
    editTargetID: 0,
    showToast: false,
  });
  const latestCommentID = props.comments[props.comments.length - 1]?.id;
  const latestFeedbackID = props.feedbacks[props.feedbacks.length - 1]?.id;
  const read_last_feedback_id = props.realization.read_last_feedback_id;
  const read_last_comment_id = props.realization.read_last_comment_id;
  const listRef = React.useRef<HTMLDivElement>(null);
  const commentRef = React.useRef(null);
  const textRef = React.useRef<HTMLTextAreaElement>(null);
  const timerRef = React.useRef<ReturnType<typeof setTimeout>>();

  const [commentHeight, setCommentHeight] =
    React.useState(TEXT_AREA_MIN_HEIGHT);

  useIonViewDidEnter(() => {
    if (parseLocationQueryParams()["comment"]) {
      timerRef.current = setTimeout(() => {
        setScrollTop(1000);
        listRef.current?.scrollTo(0, 10000);
        textRef.current?.focus();
      }, 100);
    }
    return () => timerRef.current && clearTimeout(timerRef.current);
  }, [timerRef]);

  React.useEffect(() => {
    if (commentRef.current) {
      const dom: HTMLTextAreaElement = commentRef.current as any;
      if (dom) {
        dom.scrollTop = 9999;
        setCommentHeight(
          Math.min(
            Math.max(TEXT_AREA_MIN_HEIGHT, dom.scrollTop),
            window.innerHeight * 0.3,
          ),
        );
      }
    }
    return;
  }, [values.content]);

  React.useEffect(() => {
    if (
      values.listType === "comment" &&
      props.updateReadLastComment &&
      !props.calling &&
      latestCommentID > read_last_comment_id
    ) {
      props.updateReadLastComment(props.realization.id, latestCommentID);
    }
  }, [props, values.listType, latestCommentID, read_last_comment_id]);

  React.useEffect(() => {
    if (
      values.listType === "feedback" &&
      props.updateReadLastFeedback &&
      !props.calling &&
      latestFeedbackID > read_last_feedback_id
    ) {
      props.updateReadLastFeedback(props.realization.id, latestFeedbackID);
    }
  }, [props, values.listType, latestFeedbackID, read_last_feedback_id]);

  const update = (args: { [key: string]: any }) => {
    updateValues(prevValues => ({ ...prevValues, ...args }));
  };

  return (
    <>
      <Toast
        type="danger"
        showToast={values.showToast}
        onClose={() => update({ showToast: false })}
        message="内容を入力してください"
      />
      <IonSegment
        className={clsx(
          styles.listSegment,
          props.isTeacher && styles.flexRowReverse,
        )}
        mode="md"
        value={values.listType}
        onIonChange={e => {
          update({
            listType: e.detail.value ?? "",
            editTargetID: 0,
            content: "",
          });
        }}
      >
        <IonSegmentButton className={styles.segmentButton} value="comment">
          <IonLabel>
            <div className={styles.segmentLabel}>
              みんなのコメント
              {latestCommentID > read_last_comment_id && (
                <IonBadge
                  color="danger"
                  mode="ios"
                  className={styles.commentBadge}
                >
                  &nbsp;
                </IonBadge>
              )}
            </div>
          </IonLabel>
        </IonSegmentButton>
        <IonSegmentButton className={styles.segmentButton} value="feedback">
          <IonLabel>
            <div className={styles.segmentLabel}>
              {props.isTeacher ? "フィードバック" : "先生からのフィードバック"}
              {latestFeedbackID > read_last_feedback_id && (
                <IonBadge
                  color="danger"
                  mode="ios"
                  className={styles.feedbackBadge}
                >
                  &nbsp;
                </IonBadge>
              )}
            </div>
          </IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <div
        className={clsx(
          styles.listWrapper,
          props.isTeacher && styles.wrapperLight,
        )}
      >
        <div ref={listRef} className={styles.commentList}>
          <CommentList
            current_student_id={props.current_student_id}
            current_teacher_id={props.current_teacher_id}
            createReport={props.createReport}
            delete={
              values.listType === "feedback"
                ? props.deleteFeedback
                : props.deleteComment
            }
            update={update}
            comments={
              values.listType === "feedback" ? props.feedbacks : props.comments
            }
            onAvatarClick={props.onAvatarClick}
          />
        </div>
        <div className={clsx(styles.container, props.hasTab && styles.hasTab)}>
          <div className={styles.inputArea} slot="fixed">
            <textarea
              placeholder={
                values.listType === "feedback"
                  ? props.isTeacher
                    ? "フィードバックする"
                    : "返信する"
                  : "コメントする"
              }
              style={{
                height: commentHeight + 18,
              }}
              ref={textRef}
              className={styles.input}
              value={values.content}
              onChange={e => {
                update({ content: e.target.value });
              }}
            />
            <textarea
              ref={commentRef}
              style={{
                position: "absolute",
                left: 9999,
                height: 0,
                zIndex: -1,
                width: "100%",
              }}
              className={styles.input}
              defaultValue={values.content}
              rows={1}
              tabIndex={-1}
            />
            {values.editTargetID > 0 && (
              <IonButton
                className={styles.cancelButton}
                disabled={props.calling}
                onClick={() => {
                  update({
                    content: "",
                    editTargetID: 0,
                  });
                }}
              >
                キャンセル
              </IonButton>
            )}
            {values.editTargetID === 0 && props.createStampReaction && (
              <StampSelector
                layout={props.current_student_id ? "flat" : "categorized"}
                onSelect={stamp => {
                  props.createStampReaction &&
                    props.createStampReaction(
                      props.realization.id,
                      values.listType,
                      stamp,
                    );
                }}
              />
            )}
            <IonButton
              className={styles.commentButton}
              disabled={props.calling}
              onClick={() => {
                if (values.content === "") {
                  update({ showToast: true });
                  return;
                }
                if (values.listType === "feedback") {
                  values.editTargetID > 0
                    ? props.updateFeedback(values.editTargetID, values.content)
                    : props.createFeedback(
                        props.realization.id,
                        values.content,
                      );
                } else {
                  values.editTargetID > 0
                    ? props.updateComment(values.editTargetID, values.content)
                    : props.createComment(props.realization.id, values.content);
                }
                update({
                  content: "",
                  editTargetID: 0,
                });
              }}
            >
              {values.editTargetID > 0 ? "更新" : "投稿"}
            </IonButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentFeedbackTabs;
