import React from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import { assessmentPath } from "../../store/student";
import { ReactComponent as StatusIconDone } from "../../assets/icons/status_icon_done.svg";
import { ReactComponent as StatusIconYet } from "../../assets/icons/status_icon_yet.svg";

import styles from "./AssessmentStatus.module.scss";

export interface AssessmentStatusProps {
  title: string;
  desc: string;
  experienceNo: number;
  experienceScore: number | null;
  isDisable: boolean;
  colorCode: string;
  checked: boolean;
}
const AssessmentPage: React.FC<AssessmentStatusProps> = ({
  title,
  desc,
  experienceNo,
  experienceScore,
  isDisable,
  colorCode,
  checked,
}) => {
  const history = useHistory();

  const handleDirectToCreateAssessment = React.useCallback(
    expNo => {
      history.push({ pathname: `${assessmentPath}/${expNo}` });
    },
    [history],
  );

  const statusIcon = React.useMemo(() => {
    return experienceScore || checked ? (
      <StatusIconDone className={styles.status} />
    ) : (
      <StatusIconYet className={styles.status} />
    );
  }, [experienceScore, checked]);

  return (
    <div
      className={clsx(styles.assessmentStatus, isDisable && styles.isDisable)}
      onClick={() => handleDirectToCreateAssessment(experienceNo)}
      style={{
        borderLeft: experienceNo === 8 ? "" : `solid 5px ${colorCode}`,
      }}
    >
      <div className={styles.wrapper}>
        <p className={styles.desc}>{desc}</p>
        <p className={styles.title}>{title}</p>
      </div>
      {statusIcon}
    </div>
  );
};

export default AssessmentPage;
